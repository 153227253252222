import React from 'react'
import { Box, Fade, Tooltip, withStyles } from '@material-ui/core'
import { Text } from '../..'
import clsx from 'clsx'
import useStyles from './ReportCard.styles'
import { colors } from '../../../Themes'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: colors.primary,
    maxWidth: 800,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0px 3px 6px #00000029'
  },
}))(Tooltip)

function ReportCard({ tittle, selected, img, onClick, disabled, free }) {
  
  const classes=useStyles()

  return (
    <HtmlTooltip TransitionComponent={Fade} TransitionProps={{ timeout: 500 }} 
      onClick={null} interactive disableHoverListener={disabled ? false : true}
      placement='top' arrow title={free ? 'This is available in paid plans.' : 'This is available in the Giga plan.'}>
      
      <Box role='button' className={clsx( classes.container, { [ classes.selected]: selected, [classes.disabled]: disabled })}  m={1} onClick={disabled ? null : onClick}>
     
        <img src={img} className={classes.img} />
        <div className={classes.titleDiv}>
          <Text size='footnote'>{tittle}</Text>
        </div>
      </Box>
    </HtmlTooltip>
   
    
  )
}

export default ReportCard
