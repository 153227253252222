import React, { useState } from 'react'
import { Modal, Text, TextInput } from '..'
import { colors } from '../../Themes'
import { useHistory } from 'react-router-dom'
import { TrialEnd, locked } from '../../Themes/Images'
import { SecondaryOutlinedButton } from '../Button/Button'
import { useAuth } from '../../Global'
import { Axios } from '../../Config'
import useStyles from './GigaAccountEndedModal.styles'
import { IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

function GigaAccountEndedModal({ isVisible }) {
  const classes = useStyles()
  const history = useHistory()
  const { getUpdatedUser } = useAuth()
  const [isLoading, setIsloading] = useState(false)
  const [trialToFree] = useState(true)

  const onSubmit= async ()=>{
    setIsloading(true)
    try {
      await Axios.post(`prod/user/feedback?acknowledgeFreeTrialEnd=${trialToFree}`,
      )
      await getUpdatedUser()
      setIsloading(false)
      history.push('/upgradeplan')
    } catch (error) {
      setIsloading(false)
    }
  }

  return (
    <Modal costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <div className={classes.subModal}>
          <div className={classes.text}>
            <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >You are now on the free plan!</Text>
          </div>
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.iconButtonClose }}
            onClick={() => onSubmit()}
          >
            <ClearIcon classes={{ root: classes.closeIcon }} />
          </IconButton>
        </div>
        <div style={{ height: 1, backgroundColor: '#EBEBEB', width: '100%', display: 'flex', marginBottom: 25  }} />
        <TrialEnd />
        <Text size='subnote' color={colors.primary} className={classes.spaceText}>Your free trial days are over, and your account is now on the free plan with limited reports. 
        To experience the full empowerment of data, you can upgrade to one of our paid plans.</Text>
      </div>
      <div className={classes.ModalWrapper}>
        <div style={{ height: 1, backgroundColor: '#EBEBEB', width: '100%', display: 'flex', marginTop: 5, marginBottom: 25  }} />
        <div className={classes.buttonsWrapper}>
          <SecondaryOutlinedButton
            onClick={()=>onSubmit()}
            className={classes.secondButton}
            loading={isLoading}
            style={{ backgroundColor: isLoading ? colors.disabled : colors.primary, border: isLoading ? colors.disabled : colors.primary }}
          >
         Explore plans
          </SecondaryOutlinedButton>
        </div>
      </div>
    </Modal>
  )
}

export default GigaAccountEndedModal