import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'
import { maxWidth } from '@material-ui/system'

const useStyles = makeStyles((theme) => ({

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #ddd',
        paddingBottom: 24,
        paddingTop: 24,
        marginBottom: 20
    },
    detailsHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8
    },
    firstPart: {
        overflowY: 'auto',
        maxHeight: '100vh'
    }
}
))

export default useStyles