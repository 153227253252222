import React, { useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Loading from 'react-lottie'
import moment from 'moment'
import {
  ListItem,
  List,
  Tooltip,
  IconButton,
  TablePagination,
} from '@material-ui/core'
import { CopyIcon, empty } from '../../Themes/Images'
import { Text } from '..'
import { colors } from '../../Themes'
import { useStyles } from './PaymentListForAddons.styles'
import SkeletonList from '../SkeletonList'

const CURRENCY = {
  USD: '$',
}

const EMPTY = {
  loop: true,
  autoplay: true,
  animationData: empty,
  rendererSettings: {
    className: 'loading',
  },
}

function PaymentListForAddons({ 
  payments, 
  openReceipt, 
  loading,
  rowsPerPage, 
  page, 
  handleChangePage, 
  handleChangeRowsPerPage, 
  rowsPerPageOptions, 
  btnProps,
  nextIconButtonProps,
  error
}) {
  const classes = useStyles()
  const [isCopied, setIsCopied] = useState(null)

  const sortedPayments = useMemo(() => payments.sort(((a, b) => {
    return new Date(b.eventTime) - new Date(a.eventTime)
  })), [payments])

  if (loading) {
    return (
      <SkeletonList purchase />
    )
  }

  if (sortedPayments.length === 0) {

    return (
      <div className="loadingContainer">
        <Text size='footnote' color={colors.grey} className={classes.margin} >No items found</Text>
        <Loading options={EMPTY} height={180} />
      </div>
    )
  }

  return (
    <List classes={{ root: classes.listRoot }}>
      {sortedPayments.length > 0 && sortedPayments.map((payment, index) => (
        <ListItem key={payment?.id} classes={{ root: classes.listItemRoot }} >
          {payment?.isPaid === 1 ?
            <span className={classes.paidBadge} >Paid</span>
            :
            <span className={classes.notPaidBadge} >Not Paid</span>
          }
          <div className={classes.firstWrapper}>
            <Text
              size="footnote"
              weight="bold"
              className={classes.space}
            >
              {moment(payment?.eventTime).format('LL')}
            </Text>
            <Text size="footnote"   > Purchase <span > {payment?.productName}</span> </Text>
            <Text size="footnote"   > Payment ID : <span className={classes.paymendId} onClick={() => openReceipt(payment?.receiptUrl)} > {payment?.paymentId}</span> </Text>
          </div>
          <div className={classes.secondWrapper}>
            <Text size="footnote" weight="bold" >US {CURRENCY?.[payment?.currency]}{payment?.saleGross}</Text>
            <CopyToClipboard onCopy={() => {
              setIsCopied(index)
              setTimeout(() => { setIsCopied(null) }, 3000)
            }} text={payment?.receiptUrl}>
              <IconButton classes={{ root: classes.iconButton }} >
                <Tooltip key={payment?.id} open={index === isCopied} title="Receipt url copied to clipboard" placement="top" arrow>
                  <CopyIcon className={classes.copyIcon} />
                </Tooltip>
              </IconButton>
            </CopyToClipboard>
          </div>
        </ListItem>
      ))}
      {sortedPayments.length !== 0 && 
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ page } ) =>  'Page ' + (page+1)}
        backIconButtonProps={btnProps}
        nextIconButtonProps={nextIconButtonProps}
      />}
      {
        sortedPayments.length === 0 && !error &&
        <div className="loadingContainer">
          <Text size='footnote' color={colors.grey} className={classes.margin} >No items found</Text>
          <Loading options={EMPTY} height={180} />
        </div>
      }
    </List>
  )
}

export default PaymentListForAddons
