import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Loading from 'react-lottie'
import moment from 'moment'
import {
  ListItem,
  List,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { CopyIcon, empty } from '../../Themes/Images'
import { Text } from '..'
import { colors } from '../../Themes'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useCStyles, useStyles } from './PaymentsList.styles'

const CURRENCY = {
  USD: '$',
}

const EMPTY = {
  loop: true,
  autoplay: true,
  animationData: empty,
  rendererSettings: {
    className: 'loading',
  },
}

function CircularIndeterminate() {
  const classes = useCStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

function PaymentsList({ payments, onLoadMore, showMore, openReceipt, loading }) {
  const classes = useStyles()
  const [isCopied, setIsCopied] = useState(null)

  if (loading) {
    return (
      <CircularIndeterminate />
    )
  }

  if (payments.length === 0) {
    return (
      <div className="loadingContainer">
        <Text size='footnote' color={colors.grey} className={classes.margin} >No items found</Text>
        <Loading options={EMPTY} height={180} />
      </div>
    )
  }

  return (
    <List classes={{ root: classes.listRoot }}>
      {payments.length > 0 && payments.map((payment, index) => (
        <ListItem key={payment?.id} classes={{ root: classes.listItemRoot }} >
          {payment?.is_paid === 1 ?
            <span className={classes.paidBadge} >Paid</span>
            :
            <span className={classes.notPaidBadge} >Not Paid</span>
          }
          <div className={classes.firstWrapper}>
            <Text
              size="footnote"
              weight="bold"
              className={classes.space}
            >
              {moment(payment?.payout_date).format('LL')}
            </Text>
            <Text size="footnote"   > Payment ID : <span className={classes.paymendId} onClick={() => openReceipt(payment?.receipt_url)} > {payment?.id}</span> </Text>
          </div>
          <div className={classes.secondWrapper}>
            <Text size="footnote" weight="bold" >US {CURRENCY?.[payment?.currency]}{payment?.amount}</Text>
            <CopyToClipboard onCopy={() => {
              setIsCopied(index)
              setTimeout(() => { setIsCopied(null) }, 3000)
            }} text={payment?.receipt_url}>
              <IconButton classes={{ root: classes.iconButton }} >
                <Tooltip key={payment?.subscription_id} open={index === isCopied} title="Receipt url copied to clipboard" placement="top" arrow>
                  <CopyIcon className={classes.copyIcon} />
                </Tooltip>
              </IconButton>
            </CopyToClipboard>
          </div>
        </ListItem>
      ))}
      {showMore &&   <Text size='footnote' color={colors.accent} className={classes.showMore}  onClick={onLoadMore} > Show More</Text>}
    </List>
  )
}

export default PaymentsList
