/* eslint-disable promise/always-return */
/* eslint-disable promise/no-nesting */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Global'
import { Auth } from 'aws-amplify'
import Axios from '../../Config/Axios'
import { PrimaryButton, TextInput, Modal, Text, Password } from '../../Components'
import { colors } from '../../Themes'
import { DeleteAccountSvg, EmailIcon, loginAnimation } from '../../Themes/Images'
import ClearIcon from '@material-ui/icons/Clear'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, IconButton } from '@material-ui/core'
import Loading from 'react-lottie'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useStyles from './DeleteAccount.styles'
import { useHistory } from 'react-router-dom'
import SucessModal from '../../Components/SuccessModal/SuccessModal'


const SECURITY_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loginAnimation,
  rendererSettings: {
    className: 'loginAnim',
  },
}

const SCREEN_CONTENT = {
  firstStep: 'firstStep',
  secondStep: 'secondStep',
  thirdStep: 'thirdStep'
}

const FirstStep = ({ onNextClick }) => {
  return (
    <div className="DeleteAccountSubcontainer">
      <div className="DeleteAccountDescription">
        <span className="deleteAccountLabel">
          Are you sure you want to delete your Dataplayer account?
        </span>
        <Text style={{ marginBottom: 15 }} size="footnote" color={colors.primary} >We would hate to see you go! </Text>
        <Text size="footnote" color={colors.primary} className="deleteAccountParagraph" >To delete your account, please make a request and we will process it. All your data will be deleted after 3 days and you won&apos;t be able to recover it. Your subscription will automatically be cancelled. We recommend you use the export function before deletion to retain any required information.</Text>

        <Text style={{ marginTop: 25 }} size="footnote" color={colors.primary} className="deleteAccountParagraph" >If you have any questions or require assistance, please reach out to:</Text>

        <a href="mailto:support@dataplayer.io" className="smallCard">
          <div className="mailIconContainer">
            <EmailIcon className="mailIcon" />
          </div>
          <div className="mailTo">
            <span className="sales">Customer Support</span>
            <div className="linkAddress">support@dataplayer.io</div>
          </div>
        </a>

      </div>
      <PrimaryButton
        className="submitButton"
        onClick={onNextClick}
        style={{
          backgroundColor: colors.primary,
          color: colors.white,
          maxWidth: 270
        }}
      >
        Next
      </PrimaryButton>
    </div>
  )
}

const CardUnderReason = ({ text, onClick, classes, buttonText, keyNo }) => {
  return (
    keyNo === 4 || keyNo === 5 || keyNo === 6 || keyNo === 7 ? null : 
      <div id='cardUnderReason' className={classes.cardText}>
        <div>
          <Text size='footnote'>{text}</Text>
        </div>
        <div style={{ display: buttonText === '' ? 'none' : 'hidden' }}>
         
          <PrimaryButton onClick={onClick} className={classes.discount}>{buttonText}</PrimaryButton>
        </div>
      </div>
  )
}

const ThirdStep = ({ goBack, password, setPassword, email, verifyPassword, loading, error, setInput, setValue, isVisible }) => {
  const classes = useStyles()

  return (
    <div className={classes.container} >
      <div className={classes.header} >
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonCloseThird }}
          onClick={() => {
            setInput('')
            setValue('')
            goBack(SCREEN_CONTENT.firstStep)
          }}
        >
          <ArrowBackIcon classes={{ root: classes.closeIcon }} />
          <p className={classes.back}>Back</p>

        </IconButton>
        <p className={classes.headerText} >Verify account</p>
      </div>
      <div className={classes.line} />
      <div className={classes.verifyWrapper} >
        <div className={classes.animationContainer}>
          <Loading options={SECURITY_OPTIONS} height={600} />
        </div>
        <div className={classes.cardContainer}>
          <Card className={classes.cardRoot} variant="outlined">
            <div className={classes.cardTitle}>
              <Text size='footnote' color={colors.white}  >For your own security, verify your account.</Text>
            </div>
            <CardContent classes={{ root: classes.MuiCardContentRoot }} >
              <Text size='h5' color={colors.accent} weight="bold" style={{ marginBottom: 29 }} >Enter your password</Text>
              <Text size="footnote" color={colors.primary} weight="light" style={{ marginBottom: 6 }} >Account</Text>
              <Text size="footnote" color={colors.primary} weight="regular">{email}</Text>
              <Password
                label="Password"
                value={password}
                setValue={setPassword}
              />
              {Boolean(error) && <span className="loginError" style={{ textAlign: 'start', color: colors.secondary, width: 365, lineHeight: 1.5 }}>{error}</span>}
              <PrimaryButton
                classes={{ root: classes.continueButton }}
                style={
                  password === '' || loading
                    ? { backgroundColor: colors.disabled, minHeight: 50 }
                    : { backgroundColor: colors.primary, minHeight: 50 }
                }
                disabled={password === '' || loading}
                loading={loading}
                onClick={verifyPassword}
              >
                Continue
              </PrimaryButton>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

const SecondStep = ({ goToVerify, value, setValue, input, setInput }) => {
  const history = useHistory()

  const navigate = (url) => history.push(url)
  const FORM_CONTROL = [
    {
      key: 1,
      value: 'I’ll evaluate Dataplayer another time.',
      control: <Radio color="primary" />,
      label: <Text size='footnote' color={colors.primary}>I’ll evaluate Dataplayer another time.</Text>,
      cardText: 'You can stay on our forever free plan until you are ready to explore more reports.',
      onClick: () => navigate('/dashboard'),
      buttonText: 'Go to dashboard'
    },
    {
      key: 2,
      value: 'It is not user-friendly.',
      control: <Radio color="primary" />,
      label: <Text size='footnote' color={colors.primary}>It is not user-friendly.</Text>,
      cardText: 'Please put more details on the space below, so we can improve.',
      onClick: () => navigate('/store'),
      buttonText: ''
    },
    {
      key: 3,
      value: 'The pricing is not within my budgeting limit.',
      control: <Radio color="primary" />,
      label: <Text size='footnote' color={colors.primary}>The pricing is not within my budgeting limit.</Text>,
      cardText: 'We can offer you a lifetime discount, contact us to get your code.',
      onClick: () => window.location = 'mailto:support@dataplayer.io',
      buttonText: 'Contact us'
    },
    {
      key: 4,
      value: 'I am going with another solution.',
      control: <Radio color="primary" />,
      label: <Text size='footnote' color={colors.primary}>I am going with another solution.</Text>,
      // cardText: 'Are you sure you want to delete account?',
      // onClick: () => navigate('/store')
    }
    , 
    {
      key: 5,
      value: 'I already have another account for my practice.',
      control: <Radio color="primary" />,
      label: <Text size='footnote' color={colors.primary}>I already have another account for my practice.</Text>,
      // cardText: 'Are you sure you want to delete account?',
      // onClick: () => navigate('/store')
    }
    , 
    {
      key: 6,
      value: 'The platform was not a fit for my needs/ The feature I will specify below was missing.',
      control: <Radio color="primary" />,
      label: <Text size='footnote' color={colors.primary}>The platform was not a fit for my needs/ The feature I will specify below was missing.</Text>,
      // cardText: 'Are you sure you want to delete account?',
      // onClick: () => navigate('/store')
    }
    , 
    {
      key: 7,
      value: 'Other',
      control: <Radio color="primary" />,
      label: <Text size='footnote' color={colors.primary}>Other.</Text>,
      // cardText: 'Are you sure you want to delete account?',
      // onClick: () => navigate('/store')
    }
  ]

  const classes = useStyles()
  const [clicked, setClicked] = useState({
    reason1: false,
    reason2: false,
    reason3: false,
    reason4: false,
    reason5: false,
    reason6: false,
    reason7: false,

  })

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleClick = (key) => {
    const stateCopy = { ...clicked }
    const nextValue = !stateCopy[key]
    Object.keys(stateCopy).forEach(key => stateCopy[key] = false)
    stateCopy[key] = nextValue
    setClicked(stateCopy)
  }

  return (
    <div className="DeleteAccountSubcontainer">
      <div className="DeleteAccountDescription">
        <span style={{ marginBottom: 10 }} className="deleteAccountLabel">
        Before you delete your account, we&apos;d really appreciate your feedback. <br></br> Why did you decide to delete your account?
        </span>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="reason"
            name="reasons"
            value={value}
            onChange={handleChange}
          >
            {FORM_CONTROL.map((item) => {
              return (
                <>
                  <FormControlLabel onChange={() => handleClick(item.key)} key={item.key} value={item.value} control={item.control} label={item.label} classes={{ root: classes.formControlLabel }} />
                  {clicked[item.key] && <CardUnderReason key={item.key} keyNo={item.key} onClick={item.onClick} text={item.cardText} buttonText={item.buttonText} classes={classes} />}
                </>
              )
            })}
          </RadioGroup>
        </FormControl>
        <Text size="footnote" color={colors.primary} className="deleteAccountParagraph"> * More details on the selected reason (or others) are welcome:</Text>

        <TextInput
          label='Give us your feedback - the more the better.'
          value={input}
          setValue={setInput}
          multiline={true}
          rows={4}
          className="inputttt"
        />
        <Text size="footnote" color={colors.primary} >Now it’s really time to say goodbye! </Text>
      </div>
      <PrimaryButton
        className="submitButton"
        onClick={goToVerify}
        disabled={!value || input.trim().length === 0}
        style={{
          backgroundColor: input === '' || !input || !value ? colors.disabled : colors.primary,
          color: colors.white,
          maxWidth: 250,
          marginTop: 15
        }}
      >
        Delete account
      </PrimaryButton>
    </div>
  )
}

const DeleteAccountSteps = {
  firstStep: FirstStep,
  secondStep: SecondStep,
  thirdStep: ThirdStep
}

function DeleteAccount() {
  const classes = useStyles()
  const { user, userAttributes, logout } = useAuth()
  const [deleteStatus, setDeleteStatus] = useState(SCREEN_CONTENT.firstStep)
  const DeleteSteps = DeleteAccountSteps[deleteStatus]
  const [loading, setLoading] = useState(false)
  const [opened, setOpened] = useState(false)
  const [value, setValue] = useState('')
  const [input, setInput] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isSuccessful, setIsSuccessful] = useState(false)

  const history = useHistory()


  const verifyPassword = () => {
    setLoading(true)
    Auth.signIn(user, password)
      .then(() => {
        setDeleteStatus(SCREEN_CONTENT.firstStep)
        // {(is_trialing || free) ?  showReferralModal() : checkout()}
        setOpened(true)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setError('The password you entered did not match our records. Please double-check it and try again.')
      })
  }

  const deleteAccount = async () => {
    const url =
      '/prod/user/requestAccountDeletion'
    const body = {
      deleteReason: `${value} ${input}`,
    }
    setLoading(true)
    try {
      await Axios({
        method: 'POST',
        url: url,
        data: body,
      })
      setLoading(false)
      setIsSuccessful(true)
      setOpened(false)
    }
 
    catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  // const handleOpen = () => {
  //   setOpened(true)
  // }

  const handleClose = () => {
    setOpened(false)
    setValue('')
    setInput('')
  }

  const handleCloseSuccess = () => {
    setIsSuccessful(false)
    history.push('/dashboard')
  }

  const submitReason = () => {
    setOpened(true)
  }

  return (
    <div className="deleteAccountContainer">
      <DeleteSteps
        onNextClick={() => setDeleteStatus(SCREEN_CONTENT.secondStep)}
      
        value={value}
        setValue={setValue}
        input={input}
        setInput={setInput}
        goToVerify={() => setDeleteStatus(SCREEN_CONTENT.thirdStep)}

        goBack={() => setDeleteStatus(SCREEN_CONTENT.secondStep)}
        verifyPassword={verifyPassword}
        password={password}
        setPassword={setPassword}
        email={userAttributes?.email}
        loading={loading}
        error={error}
        // openModal={handleOpen}
      />
      {isSuccessful ? 
        <Modal costumizedPaperStyles={classes.paperCostum} opened={isSuccessful} handleClose={handleCloseSuccess}>
        
          <div className={classes.modalContent}>
            <div className={classes.subModal}>
              {/* <div className={classes.text}>
                <Text style={{ alignItems: 'center' }} size='h5' color={colors.accent} weight='bold' >Delete account</Text>
              </div> */}
              <IconButton
                disableFocusRipple
                centerRipple={true}
                disableRipple={true}
                style={{ backgroundColor: 'transparent' }}
                classes={{ root: classes.iconButtonCloseSuccess }}
                onClick={() => handleCloseSuccess()}
              >
                <ClearIcon classes={{ root: classes.closeIcon }} />
              </IconButton>
            </div>
            <div className={classes.textSuccess}>
              <Text size='h4' color={colors.accent} weight='bold'>Your request was sent successfully!</Text>
            </div>
            <div className={classes.textSuccess}>
              <Text size='h6' color={colors.primary} weight='bold'>Our support team will reach out soon.</Text>
            </div>
          </div>
        </Modal>
        : 
        <Modal costumizedPaperStyles={classes.paperCostum} opened={opened} handleClose={handleClose}>
        
          <div className={classes.modalContent}>
            <div className={classes.subModal}>
              <div className={classes.text}>
                <Text style={{ alignItems: 'center' }} size='h5' color={colors.accent} weight='bold' >Delete account</Text>
              </div>
              <IconButton
                disableFocusRipple
                centerRipple={true}
                disableRipple={true}
                style={{ backgroundColor: 'transparent' }}
                classes={{ root: classes.iconButtonClose }}
                onClick={() => handleClose()}
              >
                <ClearIcon classes={{ root: classes.closeIcon }} />
              </IconButton>
            </div>
         
            <div className={classes.divider} style={{ marginTop: 20, marginBottom: 30 }} />
            <DeleteAccountSvg />
         
            <Text size='subnote' className="modalDescription">
           Are you sure you want to delete your account?
            </Text>
            <div className={classes.divider} style={{ marginTop: 10, marginBottom: 0 }} />
            <div className={classes.modalButtons}>
              <PrimaryButton
                onClick={() => handleClose()}
                className={classes.secondButton}
                style={{ border: '1px solid #cccccc' }}
              >
             Cancel
              </PrimaryButton>
              <PrimaryButton
                onClick={deleteAccount}
                className={classes.firstButton}
                loading={loading}
                style={{ backgroundColor: loading ? colors.disabled : colors.primary }}
              >
             Request
              </PrimaryButton>

            </div>
          </div>
        </Modal>
      }
     
    </div>
  )
}

export default DeleteAccount
