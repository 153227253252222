import React, { useMemo } from 'react'
import Loading from 'react-lottie'
import { withStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@material-ui/core'
import { colors } from '../../../Themes'
import { empty } from '../../../Themes/Images'
import { Text } from '../..'
import { tableCellStyles, tableRowStyles, useStylesTable } from './PaymentTable.styles'
import { SkeletonTableForFileHistory } from '../../SkeletonTable/SkeletonTable'
import updatedReportName from '../../../Utils/uppercaseFIrstLetter'

const EMPTY = {
  loop: true,
  autoplay: true,
  animationData: empty,
  rendererSettings: {
    className: 'loading',
  },
}

const StyledTableCell = withStyles((theme) => (tableCellStyles))(TableCell)

const StyledTableRow = withStyles((theme) => (tableRowStyles))(TableRow)

export default function PaymentTable({ 
  files, 
  loading, 
  rowsPerPage, 
  page, 
  handleChangePage, 
  handleChangeRowsPerPage, 
  rowsPerPageOptions, 
  btnProps,
  nextIconButtonProps,
  error
}) {
  const classes = useStylesTable()

  const sortedFiles = useMemo(() => files?.sort(((a, b) => {
    return new Date(b.uploadDate) - new Date(a.uploadDate)
  })), [files])

  if (loading) {
    return (
      <SkeletonTableForFileHistory />
    )
  }

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow className={classes.tableFont}>
            <StyledTableCell><Text size='footnote' weight='bold'>File Name</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Template Name</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Upload Date</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Type</Text></StyledTableCell>
            <StyledTableCell align="center"><Text size='footnote' weight='bold'>Status</Text></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedFiles.length > 0 && sortedFiles.map((file) =>
            (
              <StyledTableRow key={file?.id}>
                <StyledTableCell>
                  <Text 
                    size='footnote' 
                    weight="normal" 
                    color={colors.primary} 
                  >  
                    {file?.fileName}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text 
                    size='footnote' 
                    weight="normal" 
                    color={colors.primary} 
                  >  
                    {updatedReportName(file?.reportName)}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left" component="th" scope="row">
                  <Text size='footnote'>{file?.uploadDate}</Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text 
                    size='footnote' 
                    weight="normal" 
                    color={colors.primary} 
                  >  
                    {file?.fileType}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className={file?.status === 'ERROR' ? classes.errorBadge : file?.status === 'ACTIVE' ? classes.successBadge : ''}>{file?.status === '_' ? '' : updatedReportName(file?.status)}</span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {sortedFiles?.length !== 0 && 
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ page } ) =>  'Page ' + (page+1)}
        backIconButtonProps={btnProps}
        nextIconButtonProps={nextIconButtonProps}
      />}
      {
        sortedFiles?.length === 0 && !error &&
        <div className="loadingContainer">
          <Text size='footnote' color={colors.grey} className={classes.margin} >No items found</Text>
          <Loading options={EMPTY} height={180} />
        </div>
      }
    </TableContainer>
  )
}