import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const useStyles2 = makeStyles((theme) => ({
  root: {
    minHeight: 150,
    backgroundColor: colors.drawer,
    borderRadius: 5,
    padding: '12px 16px',
   
  },
  chip: {
    backgroundColor: colors.white,
    color: colors.primary,
  },
  input: {
    fontSize: 16
  },
  
}))

export default useStyles2