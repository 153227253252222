import React, { useEffect, useRef, useState } from 'react'
import { Auth } from 'aws-amplify'
import toast from 'react-hot-toast'
import { SuccessToast } from '../Notification/Notification'
import { Modal, PrimaryButton, Text } from '..'
import { colors } from '../../Themes'
import ReactCodeInput from 'react-code-input'
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import confirmEmailModalStyles from './ConfirmEmailModal.styles'

function ConfrimEmailModal({ isVisible, cancel, setEmailVerifySuccess }) {
  const classes = confirmEmailModalStyles()
  let codeRef = useRef()
  const [pin, setPin] = useState('')
  const [error, setError] = useState('')
  const [isCodeResend, setIsCodeResend] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(501))

  useEffect(() => {
    setError('')
    setIsCodeResend(false)
  }, [pin])

  async function onConfirmPin() {
    setLoading(true)
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', pin)
      setLoading(false)
      toast.custom((t) => (
        <SuccessToast t={t} message='Your email has been verified successfully!'/>
      ))
      setEmailVerifySuccess(true)
      cancel()
    } catch (error) {
      setError(error?.message)
      setLoading(false)
    }
  }

  const resendCode = async () => {
    try {
      await Auth.verifyCurrentUserAttribute('email')
      setIsCodeResend(true)
    } catch (err) {
      if (err?.name==='LimitExceededException'){
        setError(err.message)
      }
    }
  }

  const clearInputCodeInput = () => {
    if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus()
    codeRef.current.state.input[0] = ''
    codeRef.current.state.input[1] = ''
    codeRef.current.state.input[2] = ''
    codeRef.current.state.input[3] = ''
    codeRef.current.state.input[4] = ''
    codeRef.current.state.input[5] = ''
  }

  const onCancel=()=>{
    setPin('')
    setError('')
    setIsCodeResend(false)
    cancel()
  }

  const keyPress = (e) => {
    e.preventDefault()
    onConfirmPin()
  }

  return (
    <Modal costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Text style={{ alignItems: 'center' }} size='h5' color={colors.accent} weight='bold' className={classes.spaceTittle} >PIN Verification</Text>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              disableRipple={true}
              style={{ backgroundColor: 'transparent' }}
              classes={{ root: classes.iconButtonClose }}
              onClick={() => onCancel()}
            >
              <ClearIcon classes={{ root: classes.closeIcon }} />
            </IconButton>
          </div>
        </div>
        <div className={classes.divider} style={{ marginTop: 25 }}></div>
        <Text size='subnote' color={colors.primary} className={classes.spaceText}>Enter the code from the email we just sent you to complete the verification process.</Text>
        <form  className={classes.form} onSubmit={keyPress}>
          <ReactCodeInput
            ref={codeRef}
            value={pin}
            style={{ alignSelf: 'center' }}
            onChange={
              (res) => {
                setPin(res)
              }
            }
            type='number'
            fields={6}
            inputStyle={{
              flex: '0 1 12%',
              maxWidth: isMobile ? 33 : 50,
              height: isMobile ? 33 : 50,
              fontFamily: 'monospace',
              marginRight: isMobile ? 4 : 16,
              MozAppearance: 'textfield',
              borderRadius: '5px',
              fontSize: isMobile ? 16 : 32,
              textAlign: 'center',
              backgroundColor: '#F5F5F5',
              color: colors.primary,
              border: '1px solid #F5F5F5',
            }}
            // {...codeProps}
          />
          {Boolean(error) && (
            <span className="ConfirmPasswordError" style={{ maxWidth: '100%', marginTop: 12, alignSelf: 'center' }}>{error}</span>
          )}
          { isCodeResend ? 
            <span className={classes.resendCode} style={{ textDecoration: 'none', color: colors.disabled }} > Code sent</span>
            : 
            <span className={classes.resendCode} onClick={() => {
              clearInputCodeInput()
              resendCode()
            }
            }> Resend code </span>
          }
          <div className={classes.divider} style={{ marginTop: 10, marginBottom: 35 }}></div>
          <div className={classes.buttonsWrapper}>
            <PrimaryButton
              className={classes.cancelButton}
              onClick={onCancel}
              style={{ border: '1px solid #cccccc' }}
            > Cancel
            </PrimaryButton>
            <PrimaryButton
              type='submit'
              className={classes.nextButton}
              disabled={Boolean(error) || pin.length < 6||isLoading}
              loading={isLoading}
              style={{
                backgroundColor: Boolean(error) || pin.length < 6 ||isLoading ? colors.disabled : colors.primary,
                color: colors.white,
              }}
              onClick={onConfirmPin}
            >
                        Next
            </PrimaryButton>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ConfrimEmailModal
