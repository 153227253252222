import React, { useState, useEffect } from 'react'
import { Text, TextInput } from '../../../Components'
import useStyles from './AIAssistantModal.styles'
import { SecondaryOutlinedButton3 } from '../../../Components/Button/Button'
import { AmazingIcon, BadIcon, FeedbackAILogo, OkayIcon } from '../../../Themes/Images'
import { IconButton, Modal } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import Modal1 from '../../../Components/Modal/Modal'
import SucessModal from '../../../Components/SuccessModal/SuccessModal'
import axios from 'axios'
import { ErrorToast, SuccessToast } from '../../../Components/Notification/Notification'
import toast from 'react-hot-toast'
import { Axios } from '../../../Config'

const ratingsData = [
  { label: 'Bad', icon: <BadIcon /> },
  { label: 'Okay', icon: <OkayIcon /> },
  { label: 'Amazing', icon: <AmazingIcon /> },
]

function AIFeedbackModal({ isOpen, onCancel }) {
  const classes = useStyles()
  const [values, setValues] = useState({
    message: '',
    rate: ''
  })
  const [isDisabled, setDisabled] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [selectedRating, setSelectedRating] = useState({})


  const handleRatingClick = (item) => {
    // console.log(item)
    setSelectedRating(item.label)
    // console.log('🚀 ~ handleRatingClick ~ label:', item.label)
    setValues((prevValues) => ({
      ...prevValues,
      rate: item.label,
    }))
  }

  const handleClose = () => {
    setSuccess(false)
    setValues('')
    setError()
    setDisabled(true)
    setLoading(false)
    onCancel()
  }
  const handleFeedback = () => {
    // console.log(values.rate)
    // console.log(values)
    setLoading(true)
    Axios.post('/prod/user/aiFeedback?type=feedback',
      {
        message: values?.message,
        rate: values?.rate
      }
    )
      .then(
        // eslint-disable-next-line promise/always-return
        (res) => {
          // console.log(res)
          toast.custom((t) => (
            <SuccessToast t={t} message='Thank you for your message. We will reach out soon.' />
          ))
          setLoading(false)
        }).catch(e => {
          console.log(e)
          setLoading(false)
          toast.custom((t) => (
            <ErrorToast t={t} message='Something went wrong, please try again.' />
          ))
        })
  }
  useEffect(() => {
    if (
      Object.keys(error).length !== 0 ||
      values.message === ''
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [
    values?.message,
    error,
  ])

  const feedbackModal = (
    <div className={classes.ModalWrapper}>
      <FeedbackAILogo className={classes.modalAILogo} />
      <div className={classes.header}>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent', padding: 0 }}
          classes={{ root: classes.iconButtonClose }}
          onClick={handleClose}
        >
          <ClearIcon classes={{ root: classes.iconButtonClose }} />
        </IconButton>
      </div>
      <div className={classes.firstPart}>
        <Text size='footnote'>How was your experience with Dataplayer?</Text>
        <div className={classes.ratings}>
          {ratingsData.map((item, index) => (
            <div
              key={index}
              value={values?.rate}
              onClick={() => handleRatingClick(item)}
              className={
                item.label === selectedRating
                  ? item.label === 'Bad'
                    ? classes.activeRatingItemBad
                    : item.label === 'Okay'
                      ? classes.activeRatingItemOkay
                      : item.label === 'Amazing'
                        ? classes.activeRatingItemGreat
                        : ''
                  : classes.ratingItem
              }
            >
              {item.icon}
              <Text size='small'>{item.label}</Text>
            </div>
          ))}

        </div>
      </div>
      <div className={classes.secondPart}>
        <Text size='footnote'>Leave a comment</Text>
        <TextInput
          costumizedRootStyles={classes.root}
          name='comment'
          costumizedInputStyles={classes.input}
          label='More is less'
          value={values?.message}
          onChange={(e) => setValues(prev => ({ ...prev, message: e.target.value }))}
          multiline={true}
          rows={6}
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <SecondaryOutlinedButton3
          className={classes.cancelButton}
          onClick={handleClose}
        >
          Cancel
        </SecondaryOutlinedButton3>
        <SecondaryOutlinedButton3
          className={classes.nextButton}
          disabled={isDisabled || isLoading}
          loading={isLoading}
          onClick={() => {
            handleFeedback()
            setLoading(true)
            setTimeout(() => {
              setLoading(false)
              onCancel()
            }, 2000)
          }}
        >
          Submit
        </SecondaryOutlinedButton3>
      </div>
    </div>
  )
  return (
    <Modal1 opened={isOpen} onClose={handleClose} type="noBlur" costumizedPaperStyles={success ? classes.paperCostum2 : classes.paperCostum} >
      {success ? <SucessModal isSmart={false} onCancel={onCancel} /> :
        feedbackModal}

    </Modal1>
  )
}

export default AIFeedbackModal