import React, { useState } from 'react'
import {
  ListItem,
  List,
  makeStyles,
} from '@material-ui/core'
import {  useStyles } from '../PaymentList/PaymentsList.styles'
import { Skeleton } from '@material-ui/lab'
import useStylesSkeleton from './SkeletonList.styles'

const ListItems = ({ classes, classesSkeleton, purchase }) => (
  <ListItem classes={{ root: classes.listItemRoot }} >
    <div className={classes.firstWrapper}>
      <Skeleton  classes={{ root: classesSkeleton.root2 }} width={100} height={20}  />
      {purchase &&
         <Skeleton  classes={{ root: classesSkeleton.root2 }} width={100} height={20}  />
      }
      <Skeleton  classes={{ root: classesSkeleton.root2 }} width={100} height={20} />
    </div>
    <div className={classes.secondWrapper}>
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={30} height={20}  />
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={30} height={20}  />
    </div>
  </ListItem>
)

function SkeletonList({ purchase }) {
  const classes = useStyles()
  const classesSkeleton = useStylesSkeleton()

  return (
    <List classes={{ root: classes.listRoot }}>
      <ListItems classes={classes} classesSkeleton={classesSkeleton} purchase={purchase} />
      <ListItems classes={classes} classesSkeleton={classesSkeleton} purchase={purchase} />
      <ListItems classes={classes} classesSkeleton={classesSkeleton} purchase={purchase} />
      <ListItems classes={classes} classesSkeleton={classesSkeleton} purchase={purchase} />
      <ListItems classes={classes} classesSkeleton={classesSkeleton} purchase={purchase} />
    </List>
  )
}

const ListItemsFileUpload = ({ classes, classesSkeleton }) => (
  <ListItem classes={{ root: classes.listItemRoot }} >
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div className={classes.firstWrapper}>
        <Skeleton  classes={{ root: classesSkeleton.root2 }} width={100} height={20}  />
        <Skeleton  classes={{ root: classesSkeleton.root2 }} width={100} height={20} />
        <Skeleton style={{ position: 'absolute', top: -6, right: -1  }}  classes={{ root: classesSkeleton.root2 }} width={70} height={30} />
      </div>
      {/* <div className={classes.secondWrapperFile}>
        <Skeleton classes={{ root: classesSkeleton.root2 }} width={60} height={20}  />
        <Skeleton classes={{ root: classesSkeleton.root2 }} width={60} height={20}  />
      </div> */}
    </div>
  </ListItem>
)

const ListItemsFileManualUpload = ({ classes, classesSkeleton }) => (
  <ListItem classes={{ root: classes.listItemRoot }} >
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div className={classes.firstWrapper}>
        <Skeleton  classes={{ root: classesSkeleton.root2 }} width={100} height={20}  />
        <Skeleton  classes={{ root: classesSkeleton.root2 }} width={100} height={20} />
        <Skeleton style={{ position: 'absolute', top: -6, right: -1  }}  classes={{ root: classesSkeleton.root2 }} width={70} height={30} />
      </div>
      <div className={classes.secondWrapperFile}>
        <Skeleton classes={{ root: classesSkeleton.root2 }} width={60} height={20}  />
      </div>
    </div>
  </ListItem>
)

export function SkeletonListFileUpload() {
  const classes = useStyles()
  const classesSkeleton = useStylesSkeleton()

  return (
    <List classes={{ root: classes.listRootFile }}>
      <ListItemsFileUpload classes={classes} classesSkeleton={classesSkeleton} />
      <ListItemsFileUpload classes={classes} classesSkeleton={classesSkeleton} />
      <ListItemsFileUpload classes={classes} classesSkeleton={classesSkeleton} />
      <ListItemsFileUpload classes={classes} classesSkeleton={classesSkeleton} />
      <ListItemsFileUpload classes={classes} classesSkeleton={classesSkeleton} />
    </List>
  )
}

export function SkeletonListFileManualFile() {
  const classes = useStyles()
  const classesSkeleton = useStylesSkeleton()

  return (
    <List classes={{ root: classes.listRootFile }}>
      <ListItemsFileManualUpload classes={classes} classesSkeleton={classesSkeleton} />
      <ListItemsFileManualUpload classes={classes} classesSkeleton={classesSkeleton} />
      <ListItemsFileManualUpload classes={classes} classesSkeleton={classesSkeleton} />
      <ListItemsFileManualUpload classes={classes} classesSkeleton={classesSkeleton} />
      <ListItemsFileManualUpload classes={classes} classesSkeleton={classesSkeleton} />
    </List>
  )
}

export default SkeletonList