import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const changeEmailModalStyles = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  spaceText: {
    margin: '22px 0 15px 0'
  },
  spaceText2: {
    margin: '17px 0 0px 0'
  },
  spaceTittle: {
    alignSelf: 'center'
  },
  resendCode: {
    color: colors.primary,
    textDecoration: 'underline',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '48px 0'
  },
  errorInput: {
    color: colors.secondary,
    padding: '0 12px',
    fontSize: 16,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  cancelButton: {
    flex: '0 0 48%',
    backgroundColor: colors.white,
    color: colors.primary,
    height: 50,
    fontSize: 14,
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white
    },
  },
  nextButton: {
    flex: '0 0 48%',
    height: 50,
    fontSize: 14,
  },
  iconButtonClose: {
    position: 'absolute',
    right: 10,
    top: 15
  },
  divider: {
    display: 'flex',
    height: 1,
    width: '100%', 
    backgroundColor: '#E6E6E6'
  }
}))

export default changeEmailModalStyles