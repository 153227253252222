import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuth } from '../../Global'

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const { isAuthenticated } = useAuth()

  return (
    <Route
      {...otherProps}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={otherProps.redirectTo ? otherProps.redirectTo : '/login'}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
}

export default PrivateRoute
