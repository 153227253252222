import axios from "axios";
import { Auth } from "aws-amplify";

const instance = axios.create({
  baseURL: process.env.REACT_APP_AI_CHAT_PATH
  /* other custom settings */
});

instance.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        var idTokenExpire = session.getIdToken().getExpiration();
        var refreshToken = session.getRefreshToken();
        var currentTimeSeconds = Math.round(+new Date() / 1000);
        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser().then((res) => {
            res.refreshSession(refreshToken, (err, data) => {
              if (err) {
                Auth.signOut();
              } else {
                config.headers.Authorization =
                  "Bearer " + data.getAccessToken().jwtToken;
                console.log('token', data.getAccessToken().jwtToken)
                // config.headers.source = 'test';
                resolve(config);
              }
            });
          });
        } else {     
          config.headers.Authorization =
            "Bearer " + session.getAccessToken().jwtToken;
          // console.log('token', session.getAccessToken().jwtToken)
          resolve(config);
        }
      })
      .catch((err) => {
        console.log('🚀 ~ res.refreshSession ~ err:', err)
        // No logged-in user: don't set auth header
        resolve(config);
      });
  });
});

export default instance;
