import React from 'react'
import Select from 'react-select'
import Text from '../../Text'
import selectStyles from './SelectInput.styles'

function SelectInput({
  label,
  placeholder,
  value,
  onChange,
  options,
  account,
  selectCostumStyles
}) {

  return (
    <>
      <Select
        styles={selectCostumStyles ? selectCostumStyles : selectStyles}
        label={<Text size='subnote' color='hsl(0, 0%, 50%)'>{label}</Text>}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        options={options}
        aria-label={placeholder}
        name={placeholder}
        maxMenuHeight={account && 125}
      />
    </>
  )
}

export default SelectInput
