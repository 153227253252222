import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles({
  root: {
    height: 44,
    width: 44,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 44,
    height: 44,
    backgroundColor: colors.white,
    border: '1px solid '+ colors.primary,
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.accent,
    border: '1px solid '+ colors.accent,
    position: 'relative',
    'input:hover ~ &': {
      backgroundColor: colors.accentHover,
    },
  },
  label: {
    position: 'absolute',
    top: 10
  }
})

export default useStyles