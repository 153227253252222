/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect,  } from 'react'
import { ContentContainer, Text,  SecondLoader } from '../../../Components'
import { colors } from '../../../Themes'
import AddOnsCard from '../../../Components/AddOnsCard'
import { Link, useHistory } from 'react-router-dom'
import info from '../../../Images/info.png'
import { Axios } from '../../../Config'
import { useAuth, useSubscription } from '../../../Global'
import CardSkeleton from '../../../Components/CardSkeleton'
import useStyles from './Store.styles'
import XEROModal from '../../../Components/XEROModal'
import { Chip } from '@material-ui/core'

const staticCardData = [
  {
    name: 'Customisation Request',
    id: 40000,
    description: 'Send your requests for dashboard customisations you would like to get.',
    icon: 'https://dyl2njn37ytxk.cloudfront.net/store/Customisation%20Request.svg',
    buttonText: 'Request',
    type: 'Static',
  },
  {
    name: 'Customisations Purchase',
    id: 40001,
    description: 'Enter your personalised customisations code to continue with payment.',
    icon: 'https://dyl2njn37ytxk.cloudfront.net/store/Customisations%20Purchase.svg',
    buttonText: 'Enter code',
    type: 'Static',
  },
  {
    name: 'Custom Targets',
    id: 40002,
    description: 'You can set targets for different metrics for your business, site locations or practitioners.',
    icon: 'https://dyl2njn37ytxk.cloudfront.net/helpCenter/Other/Smart%20target%20purchase.svg',
    buttonText: 'Request',
    type: 'Static',
  },

]

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

const Store = () => {
  const classes = useStyles()
  const history = useHistory()
  const { is_trialing, plan, xeroDashboard, cancellationEffectiveDate } = useAuth()
  const { subscription, plan2 } = useSubscription()
  
  const [subscriptionBasedAddons, setSubscriptionBasedAddons] = useState([])
  const [oneTimePurchaseAddons, setOneTimePurchaseAddons] = useState([])
  const [additionalLogIns, setAdditionalLogIns] = useState('')
  const [manualFileIntegration, setManualFileIntegration] = useState('')
  const [xero, setXero] = useState('')
  const [isEligible, setIsEligible] = useState()
  const [isLoading, setIsLoading] = useState(true)
  // const [isXero, SetIsXero] = useState(false)

  // const handleOpenXero = (item) => {
  //   history.push({
  //     state: item
  //   })
  //   SetIsXero(true)
   
  // }

  // const handleCloseXero = () => {
  //   SetIsXero(false)
   
  // }

  const free = plan === 'free'

  const removeAddOn = () => {
    history.push({ pathname: '/settings/payment-information', 
      state: {
        selectedTab: 1
      } })
  }


  const getAddOns = async()=> {
    try {
      let { data }= await Axios.get('/prod/getProducts')
      setSubscriptionBasedAddons(data.data.products?.filter((item)=>item.isOneOffCharge === false))
      setOneTimePurchaseAddons(data.data.products?.filter((item)=>item.isOneOffCharge === true))
      setAdditionalLogIns(data.data.products?.filter((item)=>item.isOneOffCharge === false)[0]?.name)
      setManualFileIntegration(data.data.products?.filter((item)=>item.isOneOffCharge === false)[1]?.name)
      setXero(data.data.products?.filter((item)=>item.isOneOffCharge === false)[2]?.name)
      setIsEligible(data.data.products?.filter((item)=>item.isOneOffCharge === false)[0]?.isEligible)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
    }
  }
  useEffect(() => {
    getAddOns()
  }, [])
  
  const slides = [
    { url: 'https://images3.alphacoders.com/582/582354.jpg', title: 'gtr' },
    { url: 'https://images8.alphacoders.com/486/486211.jpg', title: 'bmw' },
    { url: 'https://images2.alphacoders.com/881/881019.jpg', title: 'bmw forest' },
    
  ]
  
  return (
    <ContentContainer
      header={
        <div className={classes.subheader1}>
          <Text size='subnote' weight='bold'  >Dataplayer Store</Text>
        </div>
      }
    >
      {/* <div className={classes.carouselContainer}>
        <div className={classes.sliderContainer}>
          <ImageSlider slides={slides}/> 
        </div>
      </div>
      <div className={classes.border}></div> */}
      <div className={classes.sectionTitleWrapper}>
        <div className={classes.sectionTitle}>
          <Text size='h6' weight='bold' color={colors.primary} > Customisations </Text>
          <Text size='footnote' className={classes.subtitle} color={colors.grayish} >Request extra customisations for your account, no matter the subscription plan.</Text>
        </div>
      </div>
      <div className={classes.cardWrapper}>
        {isLoading ? 
          <>
            <CardSkeleton store staticCard isEligible />
            <CardSkeleton store staticCard isEligible />
            <CardSkeleton store staticCard isEligible />
          </> :
          staticCardData.map((item)=> <AddOnsCard 
            key={item.name}
            name={item.name} 
            description={item.description}
            img={item.icon}
            buttonText={item.buttonText}
            staticCard
            isEligible
            isFree={free}
          />)}
        
      </div>
      <div className={classes.border}></div>
      <div className={classes.sectionTitleWrapper}>
        <div className={classes.sectionTitle}>
          <Text size='h6' weight='bold' color={colors.primary} > One-time purchases </Text>
          <Text size='footnote' className={classes.subtitle} color={colors.grayish} > Get more insights through extra reports, customisations or coaching sessions.    </Text>
        </div>
      </div>
      <div className={classes.cardWrapper} >
        {isLoading ? 
          <>
            <CardSkeleton store isEligible />
            <CardSkeleton store isEligible />
            <CardSkeleton store isEligible />
          </>  : 
          oneTimePurchaseAddons.map((item)=> <AddOnsCard
            key={item.id} 
            name={item.name} 
            price={item.basePrice}
            description={item.description} 
            img={item.icon}
            isOneOffCharge={item.isOneOffCharge}
            isEligible={item.isEligible}
            customPrice={item.customPrice}
            buttonHandler={() => history.push({
              pathname: `/storedetails/${item.id}`,
              state: item
            })}
          />)
        }
      </div>
      <div className={classes.border}></div>
      {!isEligible || (isEligible && xeroDashboard && (additionalLogIns || manualFileIntegration) && (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) 
      || (isEligible && !xeroDashboard && (additionalLogIns || manualFileIntegration || xero) && (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined))) ? 
        <div className={classes.sectionTitleWrapperAddOn}>
          <div className={classes.sectionTitleAddOn}>
            <Text size='h6' weight='bold' color={colors.primary}   >  Subscription-based Add-ons </Text>
            <Chip style={{ height: 25, backgroundColor: colors.background }} className={classes.chip} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans.</Text>} />
            {/* <Text size='footnote' className={classes.subtitle} color={colors.grayish} > Add a package to your subscription to get more data or give access to multiple users.  </Text> */}
          </div>
          <div style={{ paddingBottom: 10 }}>
            <Text size='footnote' className={classes.subtitle} color={colors.grayish} > Add a package to your subscription to get more data or give access to multiple users.  </Text>
          </div>
        </div>
        :
        <div className={classes.sectionTitleWrapper}>
          <div className={classes.sectionTitle}>
            <Text size='h6' weight='bold' color={colors.primary}   >  Subscription-based Add-ons </Text>
            <Text size='footnote' className={classes.subtitle} color={colors.grayish} > Add a package to your subscription to get more data or give access to multiple users.  </Text>
          </div>
        </div>
      }
     
      <div className={classes.cardWrapper} >
        {isLoading ? 
          <>
            <CardSkeleton store isEligible={(is_trialing || free || isEmpty(subscription)) ? false : true } />
            <CardSkeleton store isEligible={(is_trialing || free || isEmpty(subscription)) ? false : true } />
            <CardSkeleton store isEligible={(is_trialing || free || isEmpty(subscription)) ? false : true } />
          </>  :
          subscriptionBasedAddons.map((item)=> <AddOnsCard
            key={item.id} 
            name={item.name} 
            price={item.basePrice}
            description={item.description} 
            img={item.icon}
            isOneOffCharge={item.isOneOffCharge}
            isEligible={item.isEligible}
            customPrice={item.customPrice}
            paymentType={item.paymentType}
            isXero={xeroDashboard}
            remove={() => removeAddOn()}
            // handleOpenXero={() => handleOpenXero(item)}
            buttonHandler={() => history.push({
              pathname: `/storedetails/${item.id}`,
              state: item
            })}
          />)
        }
      </div>
      <div className={classes.border}></div>
      { <div className={classes.noteWrapper}>   
        <img className={classes.info} width={24} height={24} src={info} />
        <Text size='footnote'>
          <Text className={classes.note} weight='medium' size='subnote'>Note:</Text> 
            For more information on your active add-ons or purchases, or to cancel a subscription, please refer to 
          <Link
            className={classes.link}  
            to={{
              pathname: '/settings/payment-information',
              state: {
                selectedTab: !is_trialing && !isEmpty(subscription) ? 1 : 0
              }
            }}>Payment Information</Link>. 
        </Text>
      </div>
      }
      {/* <XEROModal
        isVisible={isXero}
        cancel={handleCloseXero} 
      /> */}
    </ContentContainer>
  )
}

export default Store