import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  TargetsFreeAccountMessage: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& p': {
      margin: '3px 0',
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
    '& a': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  compareButton: {
    margin: ' 20px 0px 25px 0',
    width: '330px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  targetsContainer: {
    display: 'flex',
    marginInline: 'auto'
  }
}))

export default useStyles