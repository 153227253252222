import React from 'react'
import {  withStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'
import { selectedStyles, styledTabStyles, styledTabsStyles, useStyles } from './PriceTabs.styles'
import { SelectInput } from '../Input'
import Text from '../Text'
import { colors } from '../../Themes'

const StyledTabs = withStyles(styledTabsStyles)((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles(styledTabStyles)((props) => <Tab disableRipple {...props} />)

function PriceTabs({ value, handleChange, priceValues, onChange, SELECT_OPTIONS }) {
  const classes = useStyles()

  return (
    <div className={classes.tabsWrapper}>
      <StyledTabs value={value} onChange={handleChange} selected={true} aria-label="styled tabs example" >
        <StyledTab label="Monthly"  /> 
        <StyledTab  label={
          <React.Fragment>
            Annually
            <br />
            <span style={{ fontSize: 'smaller' }}>(save 20%)</span>
          </React.Fragment>
        }  style={{ marginLeft: -40,  }} />
      </StyledTabs>
      <div className={classes.dropdown}>
        <SelectInput
          value={priceValues}
          selectCostumStyles={selectedStyles}
          label="Currency"
          placeholder="USD"
          options={SELECT_OPTIONS}
          onChange={onChange}
        />
        <Text style={{ fontStyle: 'italic', textAlign: 'end' }} size='footnote' color={colors.textGray}>Prices exclude VAT/GST.</Text>
      </div>
    </div>
  )
}

export default PriceTabs
