import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const styledTabsStyles = (theme) => ({
  root: {
    width: '100%',
    justifyContent: 'start',
    [theme.breakpoints.down(900)]: {
      width: '100%',
    },
    [theme.breakpoints.down(760)]: {
      width: '100%',
    }
  },
  indicator: {
    display: 'none',
    justifyContent: 'center',
    backgroundColor: 'red',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
  flexContainer: {
    [theme.breakpoints.down(760)]: {
      justifyContent: 'center',
    }
   
  }
}
)

const styledTabStyles = (theme) => ({
  root: {
    textTransform: 'none',
    color: colors.primary,
    backgroundColor: colors.background,
    borderRadius: 50,
    width: '40%',
    opacity: 1,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
  },
  selected: {
    opacity: 1,
    backgroundColor: colors.primary,
    zIndex: 2,
    color: 'white',
  }
})

const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 0,
    gap: 0,
    [theme.breakpoints.down(760)]: {
      flexDirection: 'column',
      marginBottom: 0,
      gap: 16
      
    },
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(760)]: {
      width: '100%'
      
    },
  }
}))

const selectedStyles = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 44,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 18,
    zIndex: 3,
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 58,
    flex: 1,
    minWidth: 300,
    maxWidth: '100% !important',
    width: '100% !important'
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 58,
    borderRadius: 5,
    '&:hover': {
      border: `1px solid ${colors.accentHover}`,
    },
    border: '0px solid lightgray',
    boxShadow: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      paddingLeft: 12,
      backgroundColor: colors.background,
      height: 38,
      marginTop: 8,
      color: colors.primary,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
        cursor: 'pointer'
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
  indicatorSeparator: (styles) => ({ display: 'none' })
}

export { styledTabsStyles, styledTabStyles, useStyles, selectedStyles }