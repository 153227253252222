import React from 'react'
import { Modal, PrimaryButton, Text } from '..'
import { colors } from '../../Themes'
import { PaymentFail } from '../../Themes/Images'
import useStyles from './BillNotPaidModal.styles'

function BillNotPaidModal({ isVisible, type, plan, updatePayment }) {
  const classes = useStyles()

  return (
    <Modal costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <div className={classes.image}>
          <img src={PaymentFail} alt='' />
        </div>
        <Text  size='h5' color={colors.accent} weight='bold' className={classes.spaceTittle} >Oops, your payment transaction for the {type} {plan} plan has failed.</Text>
        <Text size='subnote' color={colors.primary} className={classes.spaceText}>To get access to Dataplayer again, please update your payment information, or get in touch with our customer support.</Text>
        <PrimaryButton
          classes={{ root: classes.updatePaymentButton }}
          onClick={updatePayment}
        > Update payment
        </PrimaryButton>
        <PrimaryButton
          classes={{ root: classes.contactUsButton }}
          onClick={() => {
            let d = document
            let s = d.createElement('script')
            s.type = 'text/javascript'
            s.id = 'zsiqscript'
            s.defer = true
            s.src = 'https://salesiq.zoho.eu/widget'
            let t = d.getElementById('zohoChat')
            t.parentNode.insertBefore(s, t)
          }}
        >
                    Contact us
        </PrimaryButton>
      </div>
    </Modal>
  )
}

export default BillNotPaidModal
