/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Password, PrimaryButton, Text } from '../../Components'
import Loading from 'react-lottie'
import toast from 'react-hot-toast'
import { ErrorToast, SuccessToast } from '../../Components/Notification/Notification'
import { loginAnimation, GradientLogo, loadingDataplayer } from '../../Themes/Images'
import { useAuth } from '../../Global'
import { Redirect } from 'react-router-dom'
import { colors } from '../../Themes'
import { useWindowDimensions } from '../../Utils'
import useStyles from '../Login/Login.styles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Auth } from 'aws-amplify'
import { RenderPasswordCondition } from '../../Components'
const LOADING_OPTIONS = {
    loop: true,
    autoplay: true,
    animationData: loginAnimation,
    rendererSettings: {
        className: 'loginAnim',
    },
}

const VisitDataplayer = () => {
    const classes = useStyles()
    return (
        <div className={classes.visitDataplayerContainer}>
            <Text weight="medium" color={colors.white} > Want to learn more ?</Text>
            <PrimaryButton
                className={classes.visitDataplayerMobileButton}
                onClick={() => window.open('https://dataplayer.io/')}
            >
                Visit Dataplayer
            </PrimaryButton>
        </div>
    )
}

const re = {
    lowerCase: /.*[a-z].*/,
    capital: /[A-Z]+/,
    digit: /[0-9]+/,
    symbols: /[-!$%^#@&*()_+|~=`{}[\]:";'<>?,./]+/,
}

function ForcedNewPassword() {

    const classes = useStyles()
    const { width } = useWindowDimensions()
    const { isAuthenticated, userAcc, forceChangePassword, setChangePassValues, isPassIn, passError, login: LoginGlobal, loginError, codeError } = useAuth()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [error, setError] = useState()
    const [validatePassowrd, setValidatePassword] = useState({
        minimumLength: false,
        number: false,
        upperCase: false,
        lowerCase: false,
        symbols: false,
        focused: false,
        isVisible: false,
    })

    const [disabled, setDisabled] = useState(false)

    const keyPress = (e) => {
        e.preventDefault()
        submit()
    }

    const onChangePassword = (text) => {
        setValidatePassword((prev) => ({
            ...prev,
            upperCase: re.capital.test(text),
            lowerCase: re.lowerCase.test(text),
            number: re.digit.test(text),
            symbols: re.symbols.test(text),
            minimumLength: text.length >= 8 && text.length < 99,
        }))
        setNewPassword(text)
    }


    const submit = async () => {
        try {
            if (newPassword === '' || confirmPassword === '') {
                setError('Hang on, both fields need to be filled out!')
                setDisabled(true)
                return
            }
            if (!navigator.onLine) {
                setError('You are offline. Please check your internet connection.')
                setDisabled(true)
                return
            }
            if (newPassword !== confirmPassword) {
                setError('The passwords do not match.')
                setDisabled(true)
                return
            }
            await forceChangePassword(userAcc, newPassword)
            handleSuccess()
        } catch (err) {
            handleError(err)
        }
    }

    const handleSuccess = () => {
        toast.custom((t) => (
            <SuccessToast t={t} message='Password changed successfully!' />
        ))
        setError(undefined)
        setDisabled(false)
    }

    const handleError = (err) => {
        console.error(err.message)
        toast.custom((t) => (
            <ErrorToast t={t} message='There was something wrong!' />
        ))
        setError('There was something wrong!')
        setDisabled(true)
    }

    useEffect(() => {
        if (newPassword && confirmPassword && userAcc?.username) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [newPassword, confirmPassword, userAcc?.username])

    useEffect(() => {
        if (loginError) {
            setChangePassValues((prev) => {
                return {
                    ...prev,
                    passError: null
                }
            })
        }
    }, [password, newPassword])

    if (isAuthenticated) {
        return <Redirect
            to={{
                pathname: '/dashboard',
                state: { fromLogin: true }
            }}
        />
    }

    return (
        <>
            <div className={classes.loginContainer}>
                <div className={classes.loginAnimationContainer}>
                    <h1 className={classes.loginTitle}>INTELLIGENT INSIGHTS</h1>
                    <Loading options={LOADING_OPTIONS} height={500} />
                    <PrimaryButton
                        className={classes.visitDataplayerButton}
                        onClick={() => window.open('https://dataplayer.io/')}
                    >
                        Visit Dataplayer
                    </PrimaryButton>
                </div>
                <form className={classes.loginForm} onSubmit={keyPress}>
                    <div className={classes.loginWrapper}>
                        <GradientLogo className={classes.gradientLogo} />
                        <h1 style={{ color: colors.accent }}>Set new password</h1>
                        <Text size='small' color='#5C5C5C' weight="light" style={{ marginTop: '-7px', marginBottom: 5, display: 'block' }}>
                            Username: <b>{userAcc?.username || 'No account available to continue'}</b>
                        </Text>
                        <Password
                            label="New password"
                            value={newPassword}
                            setValue={onChangePassword}
                            onFocus={() =>
                                setValidatePassword((prev) => ({
                                    ...prev,
                                    focused: true,
                                    isVisible: true,
                                }))
                            }
                            onBlur={() =>
                                setValidatePassword((prev) => ({
                                    ...prev,
                                    focused: false,
                                }))
                            }
                        />
                        {validatePassowrd?.isVisible && <RenderPasswordCondition validatePassowrd={validatePassowrd} />}
                        <Password
                            label="Confirm new password"
                            value={confirmPassword}
                            setValue={setConfirmPassword}
                        />
                        {Boolean(error) && <span style={{ marginBottom: 6 }} className="loginError">{error}</span>}
                        <PrimaryButton
                            type="submit"
                            // disabled={Object.keys(errors).length !== 0}
                            disabled={disabled || isPassIn}
                            loading={isPassIn}
                            style={
                                disabled || isPassIn
                                    ? { backgroundColor: colors.disabled, color: colors.white, minHeight: 50, marginTop: 6 }
                                    : { backgroundColor: colors.primary, minHeight: 50, marginTop: 6 }
                            }
                            className={classes.loginButton}
                        >
                            Save and Log In
                        </PrimaryButton>
                    </div>
                </form>
            </div>
            {width < 960 && <VisitDataplayer />
            }
        </>
    )
}

export default ForcedNewPassword
