import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '18px 0',
    alignSelf: 'flex-start',
    [theme.breakpoints.between('sm', 1280)]: {
      maxWidth: '50%'
    },
  },
  profileUsernameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 32,
    gap: 8,
    [theme.breakpoints.down('1200')]: {
      marginLeft: 10,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px !important',
    },
  }, 
}))

export default useStyles