/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useState, useEffect, useReducer } from 'react'
import Select from 'react-select'
import clsx from 'clsx'
import { SelectInput } from '../../Components/Input'
import { List, Divider, IconButton, Hidden, useMediaQuery, useTheme } from '@material-ui/core'
import { ErrorToast } from '../../Components/Notification/Notification'
import toast from 'react-hot-toast'
import { Route, Link, Redirect, Switch, withRouter, useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { MARKETING_OPTIONS, CURRENCY_OPTIONS, SELECT_OPTIONS } from './utils/data'
import Axios from '../../Config/Axios'
import { BillNotPaidModal, Drawer, DrawerListItem, DrawerNestedListItem, VerifyEmailCardDrawer, PrimaryButton, TextInput, StepperModal, Text } from '../../Components'
import {
  DashboardIcon,
  LogoutIcon,
  SettingsIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  logoIcon,
  logoName,
  info,
  infoRed,
  TargetIcon,
  Xero,
  StoreIcon,
  ReportsIcon,
  HelpIcon,
  uploadFile,
  HalaxyUpload,
  UploadFile,
  AIICon
} from '../../Themes/Images'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { colors } from '../../Themes'
import { useAuth, useMenu, useSubscription } from '../../Global'
import { useWindowDimensions } from '../../Utils'

import Settings from '../Settings/Settings'
import Dashboard from '../Dashboard/Dashboard'
import Tooltips from '../Tooltips/Tooltips'

import NotFound from '../NotFound/NotFound'
import UpgradeSubscription from '../UpgradeSubscription/UpgradeSubscription'
import UpgradePlan from '../UpgradePlan/UpgradePlan'
import GigaAccountEndedModal from '../../Components/GigaAccountEndedModal/GigaAccountEndedModal'
import TrialDaysCard from '../../Components/TrialDaysCard/TrialDaysCard'
import CostumerFeedbackInput from '../../Components/CustomerFeedbackInput/CostumerFeedbackInput'
import CostumerModalFeedback from '../../Components/CostumerModalFeedback/CostumerModalFeedback'
import Store from '../Addons/Store'
import StoreProductDetails from '../Addons/StoreProductDetails'
import { useStyles, selectStyles } from './Wrapper.styles'
import { Auth } from 'aws-amplify'
import ReferalCard from '../../Components/ReferalCard'
import InactiveToActiveModal from '../../Components/InactiveToActiveModal'
import XERODashboard from '../XERODashboard'
import DeletePlan from '../DeletePlan'
import { DrawerNestedListItemDashboards } from '../../Components/Drawer/Drawer'
import MultipleDashboard from '../MultipleDashboards'
import AIAssistant from '../AI-Assistant'
import { DateRangePickerDivided } from '../../Components/DatePicker/DateRangePicker'


const DRAWER_FILES = [
  {
    title: 'Halaxy upload',
    Icon: HalaxyUpload,
    to: '/settings/myFiles',
    component: Link,
  },
]

const DRAWER_UPLOAD_FILE = [
  {
    title: 'Upload files',
    Icon: UploadFile,
    to: '/settings/uploadFile',
    component: Link,
  },
]

const DRAWER_OTHER_ITEMS2 = [
  {
    title: 'Store',
    Icon: StoreIcon,
    to: '/store',
    component: Link,
  },
]

const DRAWER_OTHER_ITEMS = [
  {
    title: 'Reports',
    Icon: ReportsIcon,
    to: '/settings/report',
    component: Link,
  },
]

const DRAWER_ITEMS = [
  {
    title: 'Targets',
    Icon: TargetIcon,
    to: '/settings/targets',
    component: Link,
  },

]

const dashboardItem = [
  {
    title: 'Dashboard',
    Icon: DashboardIcon,
    to: '/dashboard',
    component: Link,
  },
]

const aiAssistantItem = [
  {
    title: 'AI Assistant',
    Icon: AIICon,
    to: '/ai-assistant',
    component: Link,
  },
]

const dashboardItemProve = [
  {
    title: 'Dashboardsssssss',
    Icon: DashboardIcon,
    to: '/dashboard',
    component: Link,
  },
]

const xeroDashboardItems = [
  {
    title: 'XERO Dashboard',
    Icon: Xero,
    to: '/xero',
    component: Link,
  },
]

const DRAWER_ITEMS_MOBILE = [
  {
    title: 'Settings',
    Icon: () => <SettingsIcon style={{ width: 22, height: 22, marginLeft: 15 }} />,
    to: '/settings',
    component: Link,
  },

]

const REGIONS_OPTIONS = [
  { value: 'London', label: 'London' },
  { value: 'Sydney', label: 'Sydney' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Singapore', label: 'Singapore' }
]

function formatDate(date = new Date()) {
  const year = date?.toLocaleString('default', { year: 'numeric' })
  const month = date?.toLocaleString('default', { month: '2-digit' })
  const day = date?.toLocaleString('default', { day: '2-digit' })

  return [year, month, day].join('-')
}

const ConfirmSignupModal = ({ onSuccessSave }) => {
  const { getUpdatedUser } = useAuth()
  const classes = useStyles()
  const { logout, userAttributes } = useAuth()
  const [modalValues, setModalValues] = useState({
    location: '',
    apiKey: '',
    foundedFrom: null,
    foundedByOther: null,
    currency: '',
    financial_year_end_date: '',
    financial_year_start_date: '',
    accountDomain: '',
    region: '',
    errors: {},
  })

  const [disabled, setDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [isStep1Disabled, setIsStep1Disabled] = useState(false)
  const [isStep2Disabled, setIsStep2Disabled] = useState(false)

  const goToNextStep = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1)
    }
  }
  const goToPreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    }
  }

  const { width } = useWindowDimensions()

  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1))
  const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), 0, 1))

  const startDateFormatted = formatDate(startDate)
  const endDateFormatted = formatDate(endDate)

  useEffect(() => {
    if (currentStep === 1) {
      if (
        modalValues?.location !== '' &&
        modalValues?.apiKey !== '' &&
        Object.keys(modalValues?.errors).length === 0
      ) {
        setIsStep1Disabled(false)
      } else {
        setIsStep1Disabled(true)
      }
    }
    // console.log(modalValues)
  }, [modalValues.location, modalValues.apiKey, modalValues?.errors])

  useEffect(() => {
    if (currentStep === 2) {
      if (
        modalValues?.currency !== '' &&
        // modalValues?.financial_year_end_date !== '' &&
        // modalValues?.financial_year_start_date !== '' &&
        (userAttributes?.given_name !== 'practicehub' ||
          (modalValues?.accountDomain !== '' && modalValues?.region !== ''))
      ) {
        setIsStep2Disabled(true)
      } else {
        setIsStep2Disabled(false)
      }
    }
  }, [currentStep, modalValues.currency, modalValues.accountDomain, modalValues.region])

  const handleChangeSelect = (selectedOption, key) => {
    setModalValues((prev) => ({
      ...prev,
      [key]: selectedOption,
    }))
  }

  const handleChangeApiKey = (value) => {
    setModalValues((prev) => ({
      ...prev,
      apiKey: value,
      errors: {},
    }))
  }
  const handleChangeAccountDomain = (value) => {
    setModalValues((prev) => ({
      ...prev,
      accountDomain: value,
      errors: {},
    }))
  }

  const timezoneValue = modalValues?.location?.value

  const getCountryFromTimezone = (timezone) => {
    if (!timezone) return ''
    return timezone.split('/')[0]
  }

  const countryValue = getCountryFromTimezone(timezoneValue)

  const onSave = () => {

    if (startDateFormatted > endDateFormatted) {
      toast.custom((t) => (
        <ErrorToast t={t} message='The fiscal year start date cannot be later than the end date.' />
      ))
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    const data = {}
    data.apikey = modalValues?.apiKey
    data.country = countryValue
    data.timezone = timezoneValue
    // This will be used later - sending hardcoded data for the moment
    // data.financial_year_start_date = startDateFormatted
    // data.financial_year_end_date = endDateFormatted
    data.financial_year_start_date = moment().startOf('year').format('YYYY-MM-DD')
    data.financial_year_end_date = moment().endOf('year').format('YYYY-MM-DD')
    data.currency = modalValues?.currency?.value
    // console.log(data)

    if (userAttributes?.given_name === 'practicehub') {
      data.accountDomain = modalValues?.accountDomain || ''
      data.region = modalValues?.region?.value || ''
    }

    if (modalValues?.foundedFrom?.value !== 'Other' && modalValues?.foundedFrom !== null) {
      data.marketing_source = modalValues?.foundedFrom?.value
    }

    if (modalValues?.foundedFrom?.value === 'Other') {
      if (modalValues?.foundedByOther?.trim()?.length > 0) {
        data.marketing_source = modalValues?.foundedByOther
      }
    }

    Axios.post(
      '/prod/user/trial',
      { ...data }
    )
      .then((r) => {
        onSuccessSave()
        // eslint-disable-next-line promise/no-nesting
        getUpdatedUser().then((res) => {
          // console.log('updated user', data)
          setIsLoading(false)
        }).catch(e => console.log(e))
      })
      .catch((error) => {
        if (modalValues?.errors?.apiKey !== '') {
          // console.log('Inside', modalValues?.errors?.apiKey)
          setCurrentStep(1)
          setIsLoading(false)
          setModalValues((prev) => ({
            ...prev,
            errors: { apiKey: error?.response?.data?.message },
          }))
          return
        }
        toast.custom((t) => (
          <ErrorToast t={t} message='Something went wrong. Please try again.;' />
        ))
        setIsLoading(false)
        if (error.response) {
          setModalValues((prev) => ({
            ...prev,
            errors: { apiKey: error?.response?.data?.message },
          }))
        }
      })
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    // <Modal costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
    <div className={classes.ModalWrapper}>
      {currentStep === 2 &&
        <>
          <div className={classes.inputContainer}>
            {/* Will be used later */}
            {/* <p
              style={{
                fontFamily: 'Roboto',
                fontWeight: '700',
              }}
            >
              Define the fiscal year for the metrics in the fiscal year tab
            </p>
            <div className={classes.dateRangePickerDivContainer}>
              <DateRangePickerDivided
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                callBack={() => null}
                isMobile={width < 600}
              />
            </div>
            {modalValues?.errors.financial_year_end_date && (
              <p className={classes.errorInput}>{modalValues?.errors.financial_year_end_date}</p>
            )} */}
            {userAttributes?.given_name === 'practicehub' &&
              <>
                <p
                  className={classes.onboardTitle}
                >
                  Write your Account Domain:
                </p>
                <TextInput
                  costumizedInputStyles={
                    classes['input_' + Boolean(modalValues?.errors?.accountDomain)]
                  }
                  error={modalValues?.errors?.accountDomain}
                  value={modalValues?.accountDomain}
                  setValue={handleChangeAccountDomain}
                  name="accountDomain"
                  label="Account domain"
                  inputLabelStyle={{ fontSize: 16 }}
                  required
                />
                {modalValues?.errors?.accountDomain && (
                  <p className={classes.errorInput}>{modalValues?.errors?.accountDomain}</p>
                )}
                <p
                  className={classes.onboardTitleRegion}
                >
                  Select the region:
                </p>
                <Select
                  styles={selectStyles}
                  label={<Text size='subnote' color='hsl(0, 0%, 50%)'>Region</Text>}
                  placeholder="Region"
                  value={modalValues?.region}
                  options={REGIONS_OPTIONS}
                  onChange={(e) => { handleChangeSelect(e, 'region') }}
                  inputLabelStyle={{ fontSize: 16 }}
                  required

                />
              </>
            }
            <p
              className={classes.onboardTitleCurrency}
            >
              Select the currency your business operates in:
            </p>
            <Select
              styles={selectStyles}
              label={<Text size='subnote' color='hsl(0, 0%, 50%)'>Currency</Text>}
              placeholder="Currency"
              value={modalValues?.currency}
              options={CURRENCY_OPTIONS}
              onChange={(e) => { handleChangeSelect(e, 'currency') }}
              inputLabelStyle={{ fontSize: 16 }}
              required

            />
            {
              <div
                className={classes.onboardTitleNote}
              >
                <img
                  style={{
                    marginRight: 8,
                  }}
                  src={infoRed}
                  alt="Nana"
                />
                <p
                  className={classes.noteText}
                >
                  Note: You cannot change the currency afterward.
                </p>
              </div>
            }

          </div>
          <div className={classes.divider} />
          <div className={classes.buttonsWrapper}>
            <PrimaryButton
              className={classes.cancelButton}
              onClick={goToPreviousStep}
            >Back
            </PrimaryButton>
            <PrimaryButton
              className={classes.nextButton}
              loading={isLoading}
              disabled={!isStep2Disabled || isLoading}
              style={
                !isStep2Disabled || isLoading
                  ? { backgroundColor: colors.disabled, color: colors.white, height: 57 }
                  : { backgroundColor: colors.primary, height: 57 }
              }
              type="submit"
              onClick={onSave}
            >
              Finish
            </PrimaryButton>
          </div>
        </>
      }

      {currentStep === 1 &&
        <>
          <div className={classes.inputContainer}>
            <p
              className={classes.onboardTitle}
            >
              PMS API Key
            </p>

            <TextInput
              costumizedInputStyles={
                classes['input_' + Boolean(modalValues?.errors?.apiKey)]
              }
              error={modalValues?.errors?.apiKey}
              value={modalValues?.apiKey}
              setValue={handleChangeApiKey}
              name="apikey"
              label="API Key"
              inputLabelStyle={{ fontSize: 16 }}
            />
            {modalValues?.errors?.apiKey && (
              <p className={classes.errorInput}>{modalValues?.errors?.apiKey}</p>
            )}
            {userAttributes?.given_name === 'Cliniko' && <div
              className={classes.integrationNote}
              onClick={() =>
                openInNewTab(
                  'https://help.cliniko.com/en/articles/1023957-generate-a-cliniko-api-key'
                )
              }
            >
              <img
                className={classes.noteImage}
                src={info}
                alt="Nana"
              />
              <p
                className={classes.noteText}
              >
                Click here to learn how to generate your {userAttributes?.given_name} API Key.
              </p>
            </div>}
            {userAttributes?.given_name === 'practicehub' && <div
              className={classes.integrationNote}
              onClick={() =>
                openInNewTab(
                  'https://blog.dataplayer.io/connect-practicehub-to-your-dataplayer-account/'
                )
              }
            >
              <img
                className={classes.noteImage}
                src={info}
                alt="Nana"
              />
              <p
                className={classes.noteText}
              >
                Click here to learn how to generate your PracticeHub API Key.
              </p>
            </div>}
            {userAttributes?.given_name === 'Nookal' && <div
              className={classes.integrationNote}
              onClick={() =>
                openInNewTab(
                  'https://www.nookal.com/help/connecting-nookal-to-dataplayer'
                )
              }
            >
              <img
                className={classes.noteImage}
                src={info}
                alt="Nana"
              />
              <p
                className={classes.noteText}
              >
                Click here to learn how to generate your {userAttributes?.given_name} API Key.
              </p>
            </div>}
            <div
              style={{
                marginTop: 20,
                marginBottom: 8
              }}
            >
            </div>
            <Select
              styles={selectStyles}
              label={<Text size='subnote' color='hsl(0, 0%, 50%)'>Location</Text>}
              placeholder="Select your timezone"
              value={modalValues?.location}
              onChange={(e) => { handleChangeSelect(e, 'location') }}
              options={SELECT_OPTIONS}
              inputLabelStyle={{ fontSize: 16 }}

            />
            <div className={classes.discoverDP}>
              <p
                className={classes.discoverDPText}
              >
                How did you discover Dataplayer?
              </p>
              <p
                className={classes.discoverDPTextOpt}
              >
                {'(optional)'}
              </p>
            </div>
            <div className={classes.marketingInput}>
              <Select
                styles={selectStyles}
                label={<Text size='subnote' color='hsl(0, 0%, 50%)'>Location</Text>}
                placeholder="Select an option"
                value={modalValues?.foundedFrom}
                onChange={(e) => { handleChangeSelect(e, 'foundedFrom') }}
                options={MARKETING_OPTIONS}
              />
              {modalValues?.foundedFrom?.value === 'Other' &&
                <TextInput
                  costumizedRootStyles={
                    classes.otherInput
                  }
                  costumizedInputStyles={
                    classes.specifyReason
                  }
                  error={Boolean(modalValues?.errors?.apiKey)}
                  value={modalValues?.foundedByOther}
                  setValue={(e) => {
                    if (e?.length < 100) {
                      handleChangeSelect(e, 'foundedByOther')
                    }
                  }}
                  name="apikey"
                  label="Please Specify"
                />}
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.buttonsWrapper}>
            <PrimaryButton
              className={classes.cancelButton}
              onClick={logout}
            >Log out
            </PrimaryButton>
            <PrimaryButton
              className={classes.nextButton}
              loading={isLoading}
              disabled={isStep1Disabled || isLoading}
              style={
                isStep1Disabled || isLoading
                  ? {
                    backgroundColor: colors.disabled,
                    color: colors.white,
                    height: 57
                  }
                  : {
                    backgroundColor: colors.primary,
                    height: 57
                  }
              }
              type="submit"
              onClick={goToNextStep}
            >
              Next
            </PrimaryButton>

          </div>
        </>
      }
    </div>
    // </Modal>
  )
}

function MiniDrawer() {
  const classes = useStyles()
  const history = useHistory()
  const { dashboard_id } = useParams()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(760))
  const {
    logout,
    userAttributes,
    website_locked,
    end_of_trial_date,
    is_trialing,
    showFreeTrialModal,
    data_loading,
    active_dash,
    account_state,
    plan2,
    subscription_id,
    xeroDashboard,
    given_name,
    fileUploadActive,
    additional_dashboards,
    getUpdatedUser,
    createChatSession,
    chatsLeft,
    setMessageList,
    aiAssistant
  } = useAuth()
  const { subscription, plan } = useSubscription()
  const { width } = useWindowDimensions()
  const {
    selectedItem,
    isVisible,
    isExpanded,
    isFullScreen,
    toggleDrawer,
    toggleDrawerExpansion,
    setSelectedRoute,
    toggleDrawerOnHelpCenterExpansion
  } = useMenu()
  const [isEmailVerified, setEmailVerified] = useState(false)
  const [costumerFeedbackState, setCostumerFeedbackState] = useState({
    show: false,
    isModalOpen: false
  })
  const [userData, setUserData] = useState({})
  const [steps, setSteps] = useState({
    current: 0,
    elements: [ConfirmSignupModal],
    isVisible: false,
  })

  const getUser = async () => {
    try {
      const { attributes } = await Auth.currentUserInfo()
      let newUserData = { ...attributes }
      setUserData(newUserData)
    } catch (error) {
      console.log(error)
    }
  }

  // const profitWell = window?.profitwell('user_id', subscription_id )

  useEffect(() => {
    getUser()
    // profitWell
  }, [])


  const onCloseCostumerFeedback = () => {
    setCostumerFeedbackState((prev) => (
      {
        ...prev,
        show: false
      }
    ))
  }
  const onOpenCostumerFeedbackModal = () => {
    setCostumerFeedbackState((prev) => (
      {
        ...prev,
        isModalOpen: true
      }
    ))
  }
  const onCloseCostumerFeedbackModal = () => {
    setCostumerFeedbackState((prev) => (
      {
        show: false,
        isModalOpen: false
      }
    ))
  }

  const current = window.location.pathname
  const getGreetingTime = (currentTime) => {
    if (!currentTime || !currentTime.isValid()) {
      return 'Hello'
    }
    const splitAfternoon = 12
    const splitEvening = 17
    const currentHour = parseFloat(currentTime.format('HH'))

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      return 'Good afternoon'
    } else if (currentHour >= splitEvening) {
      return 'Good evening'
    }
    return 'Good morning'
  }

  useEffect(() => {
    // when user has given a api key but he hasnt yet completed the middle name
    // if (!website_locked) { /* && !userAttributes?.hasOwnProperty('middle_name') */
    //   setSteps(prev => ({
    //     current: 1,
    //     elements: [...prev?.elements],
    //     isVisible: true
    //   }))
    // }
    if (website_locked) {
      setSteps(prev => ({
        current: 0,
        elements: [...prev?.elements],
        isVisible: true
      }))
    }
  }, [website_locked, userAttributes?.hasOwnProperty('middle_name')])

  useEffect(() => {
    if (localStorage.getItem('feedback')) {
      let date = localStorage.getItem('feedback')
      let start = moment(date)
      let end = moment().format('YYYY-MM-DD')
      // Difference in number of days
      let days = moment.duration(start.diff(end)).asDays()

      if (days <= -12) {
        setCostumerFeedbackState((prev) => (
          {
            show: true,
            isModalOpen: false
          }
        ))
        localStorage.setItem('feedback', JSON.stringify(moment().format('YYYY-MM-DD')))
      }
    } else {
      localStorage.setItem('feedback', JSON.stringify(moment().format('YYYY-MM-DD')))
      setCostumerFeedbackState((prev) => (
        {
          show: true,
          isModalOpen: false
        }
      ))
    }
  }, [])
  useEffect(() => {
    setSelectedRoute(current)
  }, [current])

  useEffect(() => {
    if (userAttributes?.email_verified) {
      setEmailVerified(true)
    } else {
      setEmailVerified(false)
    }

  }, [userAttributes])

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
    let d = document
    let s = d.createElement('script')
    s.type = 'text/javascript'
    s.id = 'zsiqscript'
    s.defer = true
    s.src = 'https://salesiq.zoho.eu/widget'
    let t = d.getElementById('zohoChat')
    t.parentNode.insertBefore(s, t)
  }

  const greeting = getGreetingTime(moment())

  const handleOpenAdditional = () => {
    setOpen(!open)
    toggleDrawerOnHelpCenterExpansion()
  }

  const noAdditionalDashboards = additional_dashboards?.length === 0

  const newCHat = () => {
    if (chatsLeft === 0) {
      setMessageList([])
      toggleDrawer()
    }
    else {
      toggleDrawer()
    }
  }

  return (
    <Switch>
      <Route
        path={[
          '/dashboard',
          '/dashboard/:dashboard_id',
          '/xero',
          '/tooltips',
          '/settings',
          '/settings/account',
          '/settings/delete-account',
          '/settings/change-password',
          '/settings/terms&conditions',
          '/settings/targets',
          '/settings/payment-information',
          // '/enter-data',
          '/settings/report',
          '/settings/unsubscribe/:productId',
          '/store',
          '/ai-assistant'

        ]}
      >
        <div className={classes.root}>
          <div className={isFullScreen ? 'drawerWrapper' : ''}>
            <Hidden xsDown>
              <IconButton
                style={{ position: 'fixed' }}
                disableFocusRipple
                centerRipple={true}
                onClick={toggleDrawerExpansion}
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: isExpanded,
                  [classes.drawerClose]: !isExpanded,
                })}
              >
                {isExpanded ? (
                  <ArrowLeftIcon fill="#ee4352" className="toggleDrawerIcon" />
                ) : (
                  <ArrowRightIcon fill="#ee4352" className="toggleDrawerIcon" />
                )}
              </IconButton>
            </Hidden>
            <Drawer
              onOpen={toggleDrawer}
              onClose={toggleDrawer}
              variant={width > 760 ? 'permanent' : 'temporary'}
              isExpanded={isExpanded}
              isVisible={isVisible}
            >

              <Hidden smUp>
                <div className="logoContainer">


                  <div style={{ display: 'flex' }}>
                    <img src={logoIcon} className="logoIcon" onClick={() => {
                      history.push('/dashboard')
                      toggleDrawer()
                    }} />
                    {(isExpanded || width <= 760) && (
                      <img
                        src={logoName}
                        className="logoName"
                        onClick={() => {
                          history.push('/dashboard')
                          toggleDrawer()
                        }}
                      />
                    )}
                  </div>
                  <IconButton
                    disableFocusRipple
                    centerRipple={true}
                    classes={{ root: classes.iconButton }}
                    onClick={toggleDrawer}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </div>
              </Hidden>
              <div className="drawerContent">
                <div>
                  <Divider className="divider" variant="middle" />
                  <List>
                    {noAdditionalDashboards && dashboardItem.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}

                    {!noAdditionalDashboards &&
                      <DrawerNestedListItemDashboards
                        index={14}
                        key={14}
                        title="Dashboards"
                        Icon={DashboardIcon}
                        isActive={open}
                        withTitle={isExpanded || width <= 760}
                        onClick={() =>
                          handleOpenAdditional()
                        }
                        open={open}
                      //  items={additional_dashboards}
                      />
                    }

                    {xeroDashboard && xeroDashboardItems.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {DRAWER_ITEMS.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {fileUploadActive && DRAWER_UPLOAD_FILE.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {given_name === 'Halaxy' && DRAWER_FILES.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {given_name !== 'Halaxy' && DRAWER_OTHER_ITEMS.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    {DRAWER_OTHER_ITEMS2.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}
                    <DrawerNestedListItem
                      index={4}
                      key={4}
                      title="Help Centre"
                      Icon={HelpIcon}
                      // isActive={open}
                      withTitle={isExpanded || width <= 760}
                      onClick={() =>
                        window.open(
                          'https://dataplayer.zohodesk.eu/portal/en-gb/signin',
                          '_blank'
                        )
                      }
                    // open={open}
                    />
                    {isMobile && DRAWER_ITEMS_MOBILE.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}

                      />
                    ))}
                    <DrawerNestedListItem
                      index={5}
                      key={5}
                      title="Chat with us"
                      Icon={() => <ChatBubbleIcon style={{ width: 22, height: 22, marginLeft: 18, backgroundColor: 'transparent', color: colors.accent }} />}
                      isActive={open}
                      withTitle={isExpanded || width <= 760}
                      onClick={() => handleClick()}
                    // open={open}
                    />
                    {aiAssistant && aiAssistantItem.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={newCHat}
                        component={item.component}
                        to={item.to}
                      />
                    ))}


                  </List>
                </div>
                <div>
                  {(plan?.[0]?.name === 'Mega' || plan?.[0]?.name === 'Giga') && <ReferalCard isExpanded={isExpanded} isMobile={isMobile} onClick={toggleDrawer} />}
                  {!isEmailVerified && isExpanded && current !== '/settings/account' && <VerifyEmailCardDrawer onClose={() => setEmailVerified(true)} />}
                  {is_trialing && <TrialDaysCard isExpanded={isExpanded} end_of_trial_date={end_of_trial_date} />}
                  {isMobile &&
                    <div className={classes.buttonDiv}>
                      <PrimaryButton
                        onClick={() => history.push('/upgradeplan')}
                        className={classes.button}>
                        Upgrade now
                      </PrimaryButton>
                    </div>
                  }

                  <Divider style={{ marginInline: '25px !important' }} className="divider2" variant="middle" />

                  <DrawerListItem
                    index={9999}
                    title="Log out"
                    Icon={LogoutIcon}
                    isActive={true}
                    withTitle={isExpanded || width <= 760}
                    onClick={logout}
                  />
                </div>
              </div>
            </Drawer>
          </div>
          <main className={classes.content}>
            <div className="homeContent">
              <Route exact path="/dashboard" component={Dashboard} />
              {additional_dashboards?.length > 0 && <Route exact path="/dashboard/:dashboard_id" component={MultipleDashboard} />}
              <Route exact path="/xero" component={XERODashboard} />
              {/* <Route exact path="/enter-data" component={ManuallyAddData} /> */}
              <Route exact path="/tooltips" component={Tooltips} />
              <Route exact path="/store" component={Store} />
              <Route exact path="/ai-assistant" component={aiAssistant && AIAssistant} />
              <Route
                exact
                path={[
                  '/settings',
                  '/settings/account',
                  '/settings/myFiles',
                  '/settings/uploadFile',
                  '/settings/delete-account',
                  '/settings/change-password',
                  '/settings/terms&conditions',
                  '/settings/targets',
                  '/settings/payment-information',
                  '/settings/report',
                  '/settings/unsubscribe/:productId'
                ]}
                component={Settings}
              />
            </div>
            {costumerFeedbackState.show
              &&
              <CostumerFeedbackInput
                onClose={onCloseCostumerFeedback}
                onOpenCostumerFeedbackModal={onOpenCostumerFeedbackModal}
              />
            }
          </main>
          <BillNotPaidModal
            isVisible={subscription?.state === 'paused'}
            type={plan?.[0]?.billing_type === 'month' ? 'monthly' : plan?.[0]?.billing_type === 'year' ? 'yearly ' : ''}
            plan={plan?.[0]?.name || ''}
            updatePayment={() => window.open(subscription?.update_url, '_blank')}
          />
          <StepperModal
            isVisible={steps.isVisible}
            current={steps.current}
            elements={steps.elements}
            onSuccessAPI={() => {
              setSteps(prev => ({
                ...prev,
                isVisible: false
                // current: prev.current + 1,
              }))
            }}
          />
          <GigaAccountEndedModal
            isVisible={showFreeTrialModal === true && active_dash === true}
          />
          <InactiveToActiveModal isVisible={account_state === 'inactive' && active_dash === false && plan?.length === 0} />
          <CostumerModalFeedback
            isVisible={costumerFeedbackState.isModalOpen}
            cancel={onCloseCostumerFeedbackModal}
          />
        </div>
      </Route>
      <Route exact path="/storedetails/:productId" component={StoreProductDetails} />
      <Route exact path="/upgradeplan" component={UpgradePlan} />
      <Route exact path="/upgrade" component={UpgradeSubscription} />
      <Route exact path="/cancelPlan" component={DeletePlan} />
      <Redirect to="/dashboard" path={['/', '/reset-password', '/login']} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default withRouter(MiniDrawer)
