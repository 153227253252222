import React, { useEffect, useState } from 'react'
import Text from '../../../Components/Text'
import AddOnsCard from '../../../Components/AddOnsCard'
import { Axios } from '../../../Config'
import DateRangePicker from '../../../Components/DatePicker'
import { useWindowDimensions } from '../../../Utils'
import { colors } from '../../../Themes'
import PaymentListForAddons from '../../../Components/PaymentListForAddons/PaymentListForAddons'
import PaymentsTableForAddons from '../../../Components/PaymentListForAddons/PaymentsTableForAddons'
import { useHistory } from 'react-router-dom'
import { useAuth, useSubscription } from '../../../Global'
import Loading from 'react-lottie'
import { empty } from '../../../Themes/Images'
import CardSkeleton from '../../../Components/CardSkeleton'
import useStyles from './AddOnsInfo.styles'

function slicePayments(payments, limit) {
  if (payments && payments.length > 0) {
    return payments.slice(0, limit).reverse()
  }
  return []
}

function slicePayments2(payments, limit) {
  if (payments && payments.length > 0) {
    return payments.slice(0, limit)
  }
  return []
}

function receiveDate(str, time) {
  let date = new Date(str),
    getYear = date.toLocaleString('default', { year: 'numeric' }),
    getMonth = date.toLocaleString('default', { month: '2-digit' }),
    getDay = date.toLocaleString('default', { day: '2-digit' }),
    dateFormat = getYear + '-' + getMonth + '-' + getDay + time
  return [dateFormat]
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

const emptyLoaderOptions = {
  loop: true,
  autoplay: true,
  animationData: empty,
  rendererSettings: {
    className: 'loading',
  },
}

const AddOnsInfo = () => {
  const classes = useStyles()
  const { width } = useWindowDimensions()
  const history = useHistory()
  const { is_trialing, plan: globalPlan } = useAuth()
  const { subscription, } = useSubscription()
  let tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)
  const [currentPage, setCurrentPage] = useState(0)
  const [isAddonsLoading, setIsAddonsLoading] = useState(true)
  const [activeAddOns, setActiveAddOns] = useState([])
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1))
  const [endDate, setEndDate] = useState(tomorrow)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [nextToken, setNextToken] = useState(null) // so when we push the next token to the tokenData array the first item will be null
  const [tokenData, setTokenData] = useState([]) 
  const [error, setError] = useState('')
  const [numberOfitemsShown, setNumberOfItemsToShown] = useState(6)

  const free = globalPlan === 'free'

  
  const getAddOns = async () => {
    try {
      setIsAddonsLoading(true)
      let { data }= await Axios.get('/prod/getSubscriptionModifiers')
      setActiveAddOns(data?.data)
      setIsAddonsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAddOns()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showMore = () => {
    if (numberOfitemsShown + 6 <= activeAddOns.length) {
      setNumberOfItemsToShown(numberOfitemsShown + 6)
    } else {
      setNumberOfItemsToShown(activeAddOns.length)
    }
  }

  const showLess = () => {
    setNumberOfItemsToShown(6)
  }

  const [paymentsValues, setPaymentsValues] = useState({
    payments: [],
    limit: 5,
    loading: false
  })
  
  const getPayments = async () => {
    setPaymentsValues({
      ...paymentsValues,
      loading: true
    })
    try {
      const { data }= await Axios.get(`/prod/getProductOrders?limit=${rowsPerPage}&beginTime=${receiveDate(startDate, 'T00:00:00Z')}&endTime=${receiveDate(endDate, 'T23:59:59Z')}`)
      if (data.success === true) {
        setPaymentsValues({
          ...paymentsValues,
          payments: data?.data.productOrders,
          loading: false
        })
        const token = data.data.nextToken
        setNextToken(token)
        setTokenData((prevState)=> [ ...prevState, nextToken]) // so the first token is null when navigating back to the first Page
      } else {
        setPaymentsValues({
          ...paymentsValues,
          loading: false
        })
      }
    } catch (error) {
      setError(error)
      setPaymentsValues({
        loading: false
      })
    }
  }
  useEffect(() => {
    getPayments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, startDate, endDate])

  const removeAddOn = async (item, id) => {
    history.push({
      pathname: `/settings/unsubscribe/${id}`,
      state: item
    })
  }

  const handleChangePage = async (token) => {
    if (token === null) {
      return
    } 
    try {
      setPaymentsValues({
        ...paymentsValues,
        loading: true
      })
      const { data } = await Axios.get(`/prod/getProductOrders?nextToken=${token}&limit=${rowsPerPage}&beginTime=${receiveDate(startDate, 'T00:00:00Z')}&endTime=${receiveDate(endDate, 'T23:59:59Z')}`)
      setPaymentsValues({
        ...paymentsValues,
        payments: data?.data.productOrders,
        loading: false
      })
      const newNextToken = data.data.nextToken
      setNextToken(newNextToken)
      // checking if the nextToken is already in the array so we don't push the same token twice when navigating back and forth
      let isTokenAlreadyInState = tokenData.includes(nextToken)
      !isTokenAlreadyInState && setTokenData((prevState)=> [...prevState, nextToken]) 
      setCurrentPage((prevstate)=> prevstate+1)
    }
    catch (e) {
      console.log(e)
    }
  }

  const goBackPage = async (token) => {
    try {
      setPaymentsValues({
        ...paymentsValues,
        loading: true
      })
      if (token === null) {
        const { data } = await Axios.get(`/prod/getProductOrders?limit=${rowsPerPage}&beginTime=${receiveDate(startDate, 'T00:00:00Z')}&endTime=${receiveDate(endDate, 'T23:59:59Z')}`)
        setPaymentsValues({
          ...paymentsValues,
          payments: data?.data.productOrders,
          loading: false
        })
        const newNextToken = data.data.nextToken
        setNextToken(newNextToken)
      } else {
        const { data } = await Axios.get(`/prod/getProductOrders?nextToken=${token}&limit=${rowsPerPage}&beginTime=${receiveDate(startDate, 'T00:00:00Z')}&endTime=${receiveDate(endDate, 'T23:59:59Z')}`)
        setPaymentsValues({
          ...paymentsValues,
          payments: data?.data.productOrders,
          loading: false
        })
        const newNextToken = data.data.nextToken
        setNextToken(newNextToken)
      }
      setCurrentPage((prevstate)=> prevstate-1)
    } catch (error) {
      console.log(error)
    }
  }
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setCurrentPage(0)
    setTokenData([null]) // so the tokenData array doesnt get hold old tokens
  }
  const openReceipt = (url) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  const table = (
    <>
      <Text size="subnote" weight="regular" >Billing Information</Text>
      <div className={classes.tableWrapper}>
        <Text size="footnote" weight="regular" className={classes.chooseDateText} >Choose Date</Text>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          callBack={getPayments}
          isMobile={width < 770}
        />
        {width > 1150  ?
          <PaymentsTableForAddons
            // error={error?.response?.data?.error?.message}
            loading={paymentsValues.loading}
            payments={slicePayments(paymentsValues?.payments, rowsPerPage)}
            openReceipt={openReceipt}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            btnProps={ { onClick: () => goBackPage(tokenData[currentPage-1]) } }
            nextIconButtonProps= { { onClick: ()=>handleChangePage(nextToken), disabled: nextToken === null ? true : false }}
          />
          : 
          <PaymentListForAddons
            // error={error?.response?.data?.error?.message}
            loading={paymentsValues.loading}
            payments={slicePayments(paymentsValues?.payments, paymentsValues?.limit)}
            openReceipt={openReceipt}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            btnProps={ { onClick: () => goBackPage(tokenData[currentPage-1]) } }
            nextIconButtonProps= { { onClick: ()=>handleChangePage(nextToken), disabled: nextToken === null ? true : false }}
          />
        }
      </div>
      {/* {error && <div>{error.response.data.error.message}</div>} */}
    </>
  )

  if (is_trialing || isEmpty(subscription) || free ) {
    return <div className={classes.container} >{table}</div> 
  }
 
  return (
    <div className={classes.container}>
      <Text style={{ marginBottom: 10 }} size="subnote" weight="bold" >Manage Subscription-based add-ons</Text>
      { isAddonsLoading ?
        <div style={{ display: 'inline-flex', flexWrap: 'wrap', }}>
          <CardSkeleton smallCard /> 
          <CardSkeleton smallCard /> 
          <CardSkeleton smallCard /> 
        </div> : activeAddOns?.length === 0 ? 
          <div className="loadingContainer">
            <Text size='footnote' color={colors.grey} style={{ marginTop: 32 }} >No subscriptions found</Text>
            <Loading options={emptyLoaderOptions} height={180} />
          </div>:
          <div className={classes.cardsWrapper}>
            {slicePayments2(activeAddOns, numberOfitemsShown).map((item)=> <AddOnsCard
              key={item.modifierId}
              name={item.name}
              price={item.amount}
              description={item.description}
              billingType={item.billingType}
              nextPayment={item.nextPayment}
              img={item.icon}
              smallCard
              remove={() => removeAddOn(item, item.modifierId)}
            />)}
          </div>}
      {(activeAddOns?.length >= numberOfitemsShown   ) 
      &&
      <> 
        <div style={{ display: 'flex',  alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
          <a style={{ color: colors.accent, textDecoration: 'none', cursor: 'pointer' }} onClick={() => activeAddOns?.slice(numberOfitemsShown, numberOfitemsShown + 6) < 6 ? showLess() : showMore()}>
            {(activeAddOns?.slice(numberOfitemsShown, numberOfitemsShown + 6) < 6) ? 'Show less' : 'Show more'}</a>
          
        </div>
       
      </>
      }

      
      <div className={classes.line} />
      {table}
    </div>
  )
}

export default AddOnsInfo