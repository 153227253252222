const chatBoxSuggestions = [
    'How many patients cancelled this week by appointment type?',
    'How is my revenue trending over months for this year?',
    'Give me a report on my monthly revenue for the past year.',
    'What is the revenue generated from new patient appointments versus follow-up appointments in the last 6 months?',
    'How does the patient average visit for each practitioner compare?',
    'Which practitioner had the highest rebook rate over the past year?',
    'What are the top 3 appointment types based on the number of appointments?',
    'How has the retention rate changed over the past 2 years?',
    'How do you project the revenue and number of patients for the next quarter?',
    'What was the average appointment duration per appointment type last month?',
    'What is the revenue distribution between practitioners for this year?',
]

const LoadingWords = [
    'Loading...',
    'Please wait...',
    'Fetching data...',
]

const SuggestionWords = [
    'Here are a few recommendations for you:',
    'Consider these options for your situation:',
    'I’ve got some suggestions that might help you:',
    'Take a look at these suggestions:',
    'Here are some tips that could be useful:',
    'You might find these suggestions helpful:',
    'Check out these recommendations:'
]

export { chatBoxSuggestions, LoadingWords, SuggestionWords }