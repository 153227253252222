/* eslint-disable line-comment-position */
import React from 'react'
import parse from 'html-react-parser'
import clsx from 'clsx'
import {
  Collapse,
  CardContent,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Pressable } from '../index'
import { colors } from '../../Themes'
import useStyles from './Collapse.styles'

function CollapseTooltips({
  title,
  description,
  isExpanded,
  expand,
}) {
  const classes = useStyles()

  return (
    <>
      <Pressable
        aria-expanded={isExpanded}
        role='button'
        onClick={expand}
        classes={
          isExpanded
            ? { root: classes.pressableOpen }
            : { root: classes.pressable }
        }
      >
        <div className={classes.collapseContent}>
          <p
            className={clsx(classes.title, {
              [classes.expandOpenTittle]: isExpanded,
            })}
          >
            {parse(title)}
          </p>
          <ExpandMoreIcon
            className={clsx(classes.expand, {
              [classes.expandOpen]: isExpanded,
            })}
          />
        </div>
      </Pressable>
      <Collapse
        className={classes.collapse}
        in={isExpanded}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          <span className="preformated">{parse(description)}</span>
        </CardContent>
      </Collapse>
    </>
  )
}

export default CollapseTooltips
