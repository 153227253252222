/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react'
import ChipInput from 'material-ui-chip-input'
import useStyles2 from './ReportEmailInput.styles'

function isEmail(email) {
  return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email)
}

function InList(items, email) {
  return items.includes(email)
}

function ReportEmailInput({ values, setValues, key, limit }) {
  const classes = useStyles2()
  const [state, setState] = useState({
    error: null
  })

  const onAdd=(newVal)=>{
    if (newVal && isValid(newVal.trim())) {
      setValues({
        ...values,
        share_report_emails: [...values.share_report_emails, newVal],
      })
    }
  }

  const handleDelete = item => {
    setValues({
      ...values,
      share_report_emails: values.share_report_emails.filter(i => i !== item),
    })
  }

  const onUpdateInput=()=>{
    setState({
      error: null
    })
  }

  function isValid(email) {
    let error = null
    if (InList( values.share_report_emails, email)) {
      error = `${email} has already been added.`
    }
    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`
    }
    if (error) {
      setState({ ...state, error })
      return false
    }
    return true
  }

  return (
  // uncontrolled input
    <ChipInput
      key={key}
      newChipKeyCodes={[32]}
      InputProps={{
        disabled: values?.share_report_emails?.length>=limit
      }}
      classes={{ root: classes.root, chip: classes.chip, input: classes.input }}
      disableUnderline
      value={values.share_report_emails}
      onAdd={(chip) => onAdd(chip)}
      onUpdateInput={onUpdateInput}
      error={state.error}
      helperText={state.error}
      onDelete={(chip, index) => handleDelete(chip, index)}
      placeholder="Press space or enter after"
      alwaysShowPlaceholder
    />
  )
}

export default ReportEmailInput

ReportEmailInput.defaultProps={
  limit: 3
}