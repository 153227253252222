/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import PropTypes from 'prop-types'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import { getCroppedImg } from './canvasUtils'
import { styles } from './styles'
import { PrimaryButton } from '../..'
import { Divider } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Text from '../../Text'
import { Axios } from '../../../Config'
import { useAuth } from '../../../Global'

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
}

function CropImageInput({ classes, setValue,  imageSrc, setImageSrc, onClose }) {
  const { setPicture }=useAuth()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [progress, setProgress] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      const upladedPic=  await Axios.post(
        '/prod/user/update/logo',
        { base64_logo: croppedImage },
        {
          onUploadProgress: data => {
            // Set the progress value to show the progress bar
            setProgress(Math.round( data.loaded / data.total *100))
          },
        }
      )
      setPicture(upladedPic?.data?.url)
      setImageSrc(null)
      setValue({ url: croppedImage, data: croppedImage })
      onClose()
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  return (
    <div style={{ width: '100%' }}>
      <React.Fragment>
        <div className={classes.cropContainer}>
          <Cropper
            image={imageSrc}
            crop={crop}
            cropShape="round"
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            restrictPosition={false}
          />
        </div>
        { !progress ?
          <>
            <Divider/>
            <div className={classes.controls}>
              <div className={classes.sliderContainer}>
                <Text size='footnote' weight='regular'>Zoom</Text>
                <Slider
                  color="secondary"
                  value={zoom}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: classes.slider, colorSecondary: classes.sliderColor, thumbColorSecondary: classes.thumbColor }}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              <PrimaryButton
                className={classes.button}
                onClick={saveCroppedImage} 
              >
               Save
              </PrimaryButton>
            </div>
          </>
          :
          <LinearProgressWithLabel classes={{ colorPrimary: classes.linearProggres }} value={progress}/>
        }
      </React.Fragment>
    </div>
  )
}

export default withStyles(styles)(CropImageInput)
