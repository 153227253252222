import { makeStyles } from '@material-ui/core'

const useStyles3 = makeStyles((theme) => ({
  container: {
    flex: 1,  
    display: 'flex',
    alignItems: 'center',
    marginLeft: -10,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}))

export default useStyles3