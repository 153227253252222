import { colors } from '../../Themes'

const tabsStyles = {
  root: {
    backgroundColor: colors.transparent,
    color: colors.white,
    textTransform: 'none',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.transparent,
    height: 3,
    '& > span': {
      borderRadius: 2,
      maxWidth: 30,
      width: '100%',
      backgroundColor: colors.white,
    },
  },
}

const tabStyles = {
  root: {
    textTransform: 'none',
    color: colors.white,
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 18,
    borderRadius: 10,
    '&:focus': {
      opacity: 1,
    },
  },
}
export { tabsStyles, tabStyles }