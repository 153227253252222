/* eslint-disable react/no-unescaped-entities */
/* eslint-disable indent */
import React from 'react'
import { Card, IconButton } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import { Text } from '..'
import { colors } from '../../Themes'
import { useHistory } from 'react-router'
import { emptyCalendar } from '../../Themes/Images'
import moment from 'moment'
import useStyles from './TrialDaysCard.styles'

function daysRemaining(date) {
  const IsoDateTo = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
  let eventdate = moment(IsoDateTo)
  let todaysdate = moment()
  return eventdate.diff(todaysdate, 'days')
}

function TrialDaysCard({ end_of_trial_date, isExpanded }) {
  const classes = useStyles()
  const history=useHistory()

  if (!isExpanded){

    return  (
      <div className={classes.imgContainer} style={{ padding: '18px 12px', marginBottom: 48 }}>
      <img src={emptyCalendar} className={classes.img} />
      <Text size="h4" weight='bold' color={colors.accent} className={classes.centerImgText} style={{ transform: daysRemaining(end_of_trial_date) < 10 ? 'translate(40%, -40%)' : 'translate(-12%, -40%)' }}>
        {daysRemaining(end_of_trial_date)}</Text>
      </div>
    )
  
  }

  return (
    <Card className={classes.root}>
      <CardContent classes={{ root: classes.cardContentRoot }}>
          <div className={classes.imgContainer}>
          <img src={emptyCalendar} className={classes.img} />
          <Text size="h4" weight='bold' color={colors.accent} className={classes.centerImgText} style={{ transform: 'translate(-50%, -50%)' }}>{daysRemaining(end_of_trial_date)}</Text>
          </div>
        <div className={classes.headerWrapper} >
        <Text size="footnote" weight='regular' color={colors.primary}>Days left from your Giga trial!</Text>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          classes={{ root: classes.iconButtonRoot, label: classes.labelRoot }}
          style={{ backgroundColor: 'transparent' }}
          onClick={() => history.push('/upgradeplan')}
        >
          <p className={classes.linkTo}>Upgrade Now</p>
        </IconButton>
        </div>    
      </CardContent>
    </Card>
  )
}

export default TrialDaysCard
