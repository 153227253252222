import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'
import { border, borderBottom, borderColor, borderRadius, borderTop, display, flexbox, fontSize, height, margin, maxHeight, maxWidth, minHeight, padding, textAlign, width } from '@material-ui/system'
import { getLCP } from 'web-vitals'
import zIndex from '@material-ui/core/styles/zIndex'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    margin: 24,
    background: colors.background,
    height: '100%',
    borderRadius: 5,
    flexDirection: 'row',
    gap: 24,
    [theme.breakpoints.down(760)]: {
      margin: 0
    },
    // marginRight: 0 //for the moment 
  },
  ModalWrapper: {
    zIndex: 999999999999999,
    height: '100vh',
    width: '100%',
    position: 'absolute',
    top: 0,
    background: colors.white
  },
  ModalWrapperChat: {
    zIndex: 999999999999999,
    height: '100vh',
    width: '90%',
    position: 'absolute',
    top: 0,
    background: colors.white,
    overflowY: 'scroll',
    maxHeight: '100vh'
  },
  // chatHistoryContainer: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   paddingBlock: 16,
  //   borderBottom: '1px solid #DDD',
  //   position: 'absolute',
  //   zIndex: 99,
  //   left: 0,
  //   bottom: -200,
  //   width: '100%',
  //   paddingLeft: 24,
  //   backgroundColor: colors.white
  // },
  chatsListMobile: {
    maxHeight: 800, /* Adjust this height based on your layout */
    overflowY: 'auto', /* Allow vertical scrolling */
    overflowX: 'hidden', /* Prevent horizontal scrolling */
    // paddingRight: 16, /* Optional: Add some padding */
    // border: '1px solid #ccc' /* Optional: Add a border for better visibility */
    /* You can also add other styles as needed */
  },
  container: {
    display: 'flex',
    // margin: 24,
    background: colors.white,
    height: '100%',
    // borderRadius: 5,
    flexDirection: 'column',
    position: 'relative',
    flex: 3
  },
  headerDesktop: {
    display: 'flex',
    alignItems: 'center',
    paddingBlock: 16,
    borderBottom: '1px solid #DDD',
    // zIndex: 100,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBlock: 16,
    borderBottom: '1px solid #DDD',
    zIndex: 100,
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '20%',
    marginLeft: 32
  },
  logoAndTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '80%',
    marginLeft: 24,
    gap: 16
  },
  titleMobileHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: 4
  },
  requests: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '80%',
    marginRight: 24,
    alignItems: 'center'
  },
  requestsMobileHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '20%',
    marginRight: 24,
    alignItems: 'center',
    gap: 24
  },
  historyContainer: {
    background: colors.white,
    flex: 2,
    maxWidth: 341,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down(760)]: {
      display: 'none'
    }
  },
  // historyContainerMobile: {
  //   // maxWidth: 194,
  //   // mawHeight: 160,
  //   // background: colors.white,
  //   // display: 'flex',
  //   // justifyContent: 'space-between',
  //   // flexDirection: 'column',
  // },
  historyContainerMobile: {
    maxWidth: 194,
    mawHeight: 160,
    background: colors.white,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  yourChats: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    paddingBlock: 16,
    paddingLeft: 16,
    borderBottom: '1px solid #ddd'
  },
  yourChatsMobile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    paddingLeft: 16,
    paddingBlock: 16,
    borderBottom: '1px solid #ddd',
    justifyContent: 'space-between'
  },
  chat: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px auto',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    // justifyContent: 'flex-end',
    width: 720,
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down(1150)]: {
      width: '100%',
    },
    // [theme.breakpoints.down(1000)]: {
    //   width: 550,
    // },
    // [theme.breakpoints.down(900)]: {
    //   width: 550,
    // },
    [theme.breakpoints.down(760)]: {
      width: '100%',
    },
  },
  bottomContainer: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    // marginTop: 'auto',
    maxWidth: 660,
    marginInline: 16,
    background: 'white',
    width: '100%',
    height: '58px',
    flexDirection: 'column',
    zIndex: 100,
    [theme.breakpoints.down(1200)]: {
      width: '98%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '96%',
    },
    [theme.breakpoints.down(1050)]: {
      width: '92%',
    },
    [theme.breakpoints.down(1000)]: {
      width: '92%',
    },
    [theme.breakpoints.down(950)]: {
      width: '90%',
    },
    [theme.breakpoints.down(900)]: {
      width: '88%',
    },
    [theme.breakpoints.down(850)]: {
      width: '86%',
    },
  },
  bottomContainerWithNote: {
    display: 'flex',
    position: 'absolute',
    bottom: 58,
    // marginTop: 'auto',
    background: 'white',
    maxWidth: 660,
    width: '100%',
    marginInline: 16,
    height: '58px',
    flexDirection: 'column',
    zIndex: 100,
    [theme.breakpoints.down(1200)]: {
      width: '98%',

    },
    [theme.breakpoints.down(1200)]: {
      width: '96%',
      bottom: 80
    },
    [theme.breakpoints.down(1100)]: {
      width: '96%',
      bottom: 80
    },
    [theme.breakpoints.down(1050)]: {
      width: '92%',
    },
    [theme.breakpoints.down(1000)]: {
      width: '92%',
    },
    [theme.breakpoints.down(950)]: {
      width: '90%',
    },
    [theme.breakpoints.down(900)]: {
      width: '88%',
    },
    [theme.breakpoints.down(850)]: {
      width: '86%',
    },
  },
  
  bottomContainerForQuestions: {
    display: 'flex',
    position: 'absolute',
    bottom: 92,
    // marginTop: 'auto',
    background: 'white',
    maxWidth: 660,
    width: '100%',
    marginInline: 16,
    height: '58px',
    flexDirection: 'column',
    zIndex: 100,
    [theme.breakpoints.down(1200)]: {
      width: '98%',

    },
    [theme.breakpoints.down(1200)]: {
      width: '96%',
      // bottom: 136
    },
    [theme.breakpoints.down(1150)]: {
      // width: '96%',
      bottom: 132
    },
    [theme.breakpoints.down(1100)]: {
      width: '96%',
      // bottom: 80
    },
    [theme.breakpoints.down(1050)]: {
      width: '92%',
    },
    [theme.breakpoints.down(1000)]: {
      width: '92%',
    },
    [theme.breakpoints.down(950)]: {
      width: '90%',
    },
    [theme.breakpoints.down(900)]: {
      width: '88%',
    },
    [theme.breakpoints.down(850)]: {
      width: '86%',
    },
    [theme.breakpoints.down(850)]: {
     bottom: 132
    },
    [theme.breakpoints.down(760)]: {
      width: '86%',
      // bottom: 122
    },
  },
  chatContainer: {
    display: 'flex',
    background: 'white',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',

    [theme.breakpoints.down(1200)]: {
      width: '98%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '96%',
    },
    [theme.breakpoints.down(1050)]: {
      width: '92%',
    },
    [theme.breakpoints.down(1000)]: {
      width: '92%',
    },
    [theme.breakpoints.down(950)]: {
      width: '90%',
    },
    [theme.breakpoints.down(900)]: {
      width: '86%',
    },
    [theme.breakpoints.down(850)]: {
      width: '84%',
    }
  },
  noChatschatContainer: {
    display: 'flex',
    background: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',

    [theme.breakpoints.down(1200)]: {
      width: '98%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '96%',
    },
    [theme.breakpoints.down(1050)]: {
      width: '92%',
    },
    [theme.breakpoints.down(1000)]: {
      width: '92%',
    },
    [theme.breakpoints.down(950)]: {
      width: '90%',
    },
    [theme.breakpoints.down(900)]: {
      width: '86%',
    },
    [theme.breakpoints.down(850)]: {
      width: '84%',
    }
  },
  messagesContainer: {
    display: 'flex',
    background: 'white',
    alignItems: 'start',
    justifyContent: 'flex-start',
    width: '100%',
    // height: '50%',
    flexDirection: 'column',
    gap: 5,

  },
  noChatsLeftMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  welcomeText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    marginLeft: 16,
    // marginTop: 29,
    [theme.breakpoints.down(1200)]: {
      paddingInline: 16
    },
    [theme.breakpoints.down(960)]: {
      marginLeft: 0
    },
  },
  middleText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    flexDirection: 'column',
    marginTop: 29,
    [theme.breakpoints.down(760)]: {
      maxWidth: 350,
      // paddingInline: 16
    },
  },
  middleText2: {
    marginTop: 16,
    maxWidth: 450,
    [theme.breakpoints.down(760)]: {
      maxWidth: 350,
      // paddingInline: 16
    },
  },
  detailsIcon: {
    // position: 'absolut'
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    gap: 6,
    marginBottom: 8
  },
  suggestions: {
    display: 'flex',
    width: '100%',
    gap: 25,
    height: 58
  },
  suggestion: {
    width: '100%',
    background: 'gray',
  },
  aiUser: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  chatSuggestions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    cursor: 'pointer',
    maxWidth: 225,
    background: colors.background,
    minHeight: 114,
    paddingLeft: 16,
    paddingRight: 16,
    alignItems: 'center',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    border: '1px solid rgba(220, 60, 84, 0.3)',
    borderRadius: 5,
    flex: 1,
    [theme.breakpoints.down(940)]: {
      flex: 'none',
      minHeight: 85,
    },
    [theme.breakpoints.between(790, 890)]: {
      maxWidth: 156,
    },
    [theme.breakpoints.between(760, 790)]: {
      maxWidth: 140,
    },
    [theme.breakpoints.down(760)]: {
      minWidth: 160,
      minHeight: 114,
    },
  },

  suggestedMessageBox: {
    display: 'flex',
    gap: 12,
    maxWidth: '71%',
    marginLeft: 68,
    [theme.breakpoints.down(980)]: {
      maxWidth: '81%'
    },
    [theme.breakpoints.down(760)]: {
      flexDirection: 'column',
      marginLeft: 30
    },
    [theme.breakpoints.down(540)]: {
      marginLeft: 16
    },
  },
  chatSuggestionsMsg: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    cursor: 'pointer',
    maxWidth: 270,
    background: colors.background,
    padding: 8,
    alignItems: 'center',
    whiteSpace: 'wrap',
    border: '1px solid rgba(220, 60, 84, 0.3)',
    borderRadius: 5,
    // borderImage: 'linear-gradient(to right, #DC3C54, #90205D) 1',
    // borderImageSlice: '20%',
    [theme.breakpoints.up(1150)]: {
      maxWidth: '43%'
    },
    [theme.breakpoints.down(1150)]: {
      maxWidth: '38%'
    },
    [theme.breakpoints.up(980)]: {
      maxWidth: '84%'
    },
    [theme.breakpoints.down(760)]: {
      maxWidth: '86%'
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: '77%'
    },
    // [theme.breakpoints.down(540)]: {
    //   maxWidth: 272,
    // },
  },
  suggestionIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  chatsList: {
    // maxHeight: 574,
    // [theme.breakpoints.down(960)]: {
    //   maxHeight: 'none'
    // }
  },
  containerOfChats: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
    width: '100%',
    paddingLeft: 16,
    borderBottom: '1px solid #ddd',
    paddingBlock: 16,
  },
  today: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingLeft: 16,
    paddingTop: 20,
    paddingBottom: 4
  },
  todayMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingLeft: 0,
    paddingTop: 20,
    paddingBottom: 4
  },
  
  currentChatMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingLeft: 0,
    borderBottom: '1px solid #ddd',
    paddingBlock: 16,
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#ddd', 
    },
  },
  currentChat: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingLeft: 16,
    borderBottom: '1px solid #ddd',
    paddingBlock: 16,
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#ddd', 
    },
  },
  selectedChat: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingLeft: 16,
    borderBottom: '1px solid #ddd',
    paddingBlock: 16,
    backgroundColor: '#ddd', 
  },
  suggestedBox: {
    marginTop: 12,
    marginInline: 16,
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    // whiteSpace: 'wrap',
    [theme.breakpoints.down(1200)]: {
      paddingInline: 16,

    },
    [theme.breakpoints.down(1150)]: {
      paddingInline: 16,
      flexDirection: 'column'

    },
    [theme.breakpoints.down(940)]: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      marginLeft: 0

    },
    [theme.breakpoints.down(680)]: {
      paddingInline: 0,
      marginLeft: 16,
      flexWrap: 'nowrap',
      overflowX: 'scroll',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 400
    },
    [theme.breakpoints.down(450)]: {
      maxWidth: 310
    },
    [theme.breakpoints.down(350)]: {
      maxWidth: 270
    },
  },
  feedback: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    borderTop: '1px solid #ddd',
    paddingBlock: 16
  },
  feedbackMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  reportProblem: {
    display: 'flex',
    flexDirection: 'row',
    gap: 18,
    alignItems: 'center',
    paddingLeft: 16,
    cursor: 'pointer',
    justifyContent: 'flex-start'
  },
  reportProblemMobile: {
    display: 'flex',
    flexDirection: 'row',
    gap: 18,
    alignItems: 'center',
    cursor: 'pointer'
  },
  spacer: {
    height: 16,
    width: '100%',
    backgroundColor: 'red !important'
  },
  inputHolder: {
    display: 'flex',
    // width: '100%',
    // maxHeight: 58,
    // background: 'white',
    // alignItems: 'center',
    // justifyContent: 'center',
    // paddingInline: 16,
    // width: '100%',
    // position: 'absolute',
    // bottom: -22,
    marginBottom: 24,
    [theme.breakpoints.down(760)]: {
      marginLeft: -10
    },
    [theme.breakpoints.down(500)]: {
      marginLeft: -20
    },
    [theme.breakpoints.down(450)]: {
      // paddingInline: 40,
      // marginLeft: -30
    },
    [theme.breakpoints.down(400)]: {
      paddingInline: 10,
      // marginLeft: -35
    },
    [theme.breakpoints.down(360)]: {
      paddingInline: 60,
      marginLeft: -45
    },
  },
  chatNote: {
    backgroundColor: 'rgb(49, 65, 85, 0.2)',
    paddingBlock: 12,
    paddingLeft: 16,
    border: '1px solid #314155',
    borderRadius: 5,
    marginBottom: 12,
  },
  chatNoteForQuestions: {
    backgroundColor: 'rgb(49, 65, 85, 0.2)',
    paddingBlock: 12,
    paddingLeft: 16,
    border: '1px solid #314155',
    borderRadius: 5,
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: ''
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1140)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 8
    },
  },
  input: {
    width: '90%',
    maxHeight: 58,
    // marginTop: -32,
    // [theme.breakpoints.down(20)]: {
    //   width: '88%',
    // },
  },
  inputCustom: {
    maxHeight: 58,
    marginTop: 0,
  },
  sendMessage: {
    width: '10%',
    height: 58,
  },
  receivedErrorMessage: {
    display: 'flex',
    flexDirection: 'column',
    width: '67%',
    marginLeft: 16,
    borderRadius: '5px 5px 5px 0px',
    border: '1px solid #EE4352',
    background: 'rgba(238, 67, 82, 0.1)',
    padding: 16,
    [theme.breakpoints.down(980)]: {
      width: 428
    },
    [theme.breakpoints.down(760)]: {
      marginLeft: '32px !important',
      // width: 300
    },
    [theme.breakpoints.down(540)]: {
      marginLeft: '16px !important',
      width: 260
    },

  },
  sentMessageContainer: {
    width: '100%',
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16

  },
  timeSent: {
    width: '91%',
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    // paddingRight: 140,
    [theme.breakpoints.down(760)]: {
      width: '95%'
    },
    [theme.breakpoints.down(540)]: {
      paddingRight: 10,
    },
  },
  timeReceived: {
    width: '100%',
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 64,
    [theme.breakpoints.down(760)]: {
      paddingLeft: 62,
    },
    [theme.breakpoints.down(540)]: {
      paddingLeft: 18,
    },
  },
  timeReceivedWithNote: {
    width: '100%',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 64,
    [theme.breakpoints.down(760)]: {
      paddingLeft: 60,
      marginBottom: 94
    },
    [theme.breakpoints.down(540)]: {
      paddingLeft: 18,
    },
  },
  overflowChat: {
    position: 'absolute',
    bottom: 50,
    width: '100%',
    overflowY: 'auto',
    maxHeight: '91%',
    overflowX: 'hidden',
    [theme.breakpoints.down(1150)]: {
      width: '96%'
    },
    [theme.breakpoints.down(950)]: {
      width: '90%'
    },
    [theme.breakpoints.down(800)]: {
      maxHeight: '90%',
    },
    [theme.breakpoints.down(760)]: {
      maxHeight: '92%',
      width: '100%'
    },
    [theme.breakpoints.down(500)]: {
      maxHeight: '90%',
    },
  },
  overflowNote: {
    position: 'absolute',
    bottom: 138,
    width: '100%',
    overflowY: 'auto',
    maxHeight: '76%',
    overflowX: 'hidden',
    [theme.breakpoints.down(1150)]: {
      width: '96%',
      bottom: 176,
      maxHeight: '70%',
    },
    [theme.breakpoints.down(950)]: {
      width: '90%'
    },
    [theme.breakpoints.down(800)]: {
      // maxHeight: '70%',
      // bottom: 180
    },
    [theme.breakpoints.down(760)]: {
      maxHeight: '74%',
      width: '100%',
      bottom: 178,
    },
    // [theme.breakpoints.down(500)]: {
    //   maxHeight: '74%',
    // },
  },
  overflowChatSmallMobile: {
    position: 'absolute',
    bottom: 50,
    width: '100%',
    overflowY: 'auto',
    maxHeight: '88%',
    overflowX: 'hidden',
    [theme.breakpoints.down(760)]: {
      maxHeight: '90%',
    },
    [theme.breakpoints.down(500)]: {
      maxHeight: '88.5%',
    },
  },
  overflowChatSmallerMobile: {
    position: 'absolute',
    bottom: 50,
    width: '100%',
    overflowY: 'auto',
    maxHeight: '88%',
    overflowX: 'hidden',
    [theme.breakpoints.down(760)]: {
      maxHeight: '88%',
    },
    [theme.breakpoints.down(500)]: {
      maxHeight: '87.5%',
    },
  },
  overflowChatSmallestMobile: {
    position: 'absolute',
    bottom: 50,
    width: '100%',
    overflowY: 'auto',
    maxHeight: '84%',
    overflowX: 'hidden',
    [theme.breakpoints.down(760)]: {
      maxHeight: '85%',
    },
    [theme.breakpoints.down(500)]: {
      maxHeight: '84.5%',
    }
  },
  receivedMessageContainer: {
    width: '100%',
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  receivedSuggestedMessageContainer: {
    width: '100%',
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  loading: {
    width: '100%',
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  sentMessage: {
    display: 'flex',
    maxWidth: '67%',
    marginRight: 16,
    borderRadius: '5px 5px 0px 5px',
    background: '#DDD',
    padding: 16,
    [theme.breakpoints.down(760)]: {
      marginRight: '32px !important',
    },
    [theme.breakpoints.down(500)]: {
      marginRight: '8px !important',
    },
  },
  receivedMessage: {
    display: 'flex',
    flexDirection: 'column',
    width: '67%',
    // maxWidth: 370,
    marginLeft: 16,
    borderRadius: '5px 5px 5px 0px',
    background: '#F5F5F5',
    padding: 16,
    [theme.breakpoints.up(980)]: {
      maxWidth: '67%'
    },
    // [theme.breakpoints.down(980)]: {
    //   width: 428
    // },
    [theme.breakpoints.down(760)]: {
      marginLeft: '32px !important',
      maxWidth: '67%'
      // width: 300
    },
    [theme.breakpoints.down(540)]: {
      marginLeft: '16px !important',
      // width: 260
    },
  },
  receivedMessageTyping: {
    display: 'flex',
    maxWidth: '70%',
    marginLeft: 16,
    borderRadius: '5px 5px 5px 0px',
    background: '#F5F5F5',
    padding: 16,
    minWidth: 65,
    [theme.breakpoints.down(760)]: {
      marginLeft: '32px !important',
    },
    [theme.breakpoints.down(500)]: {
      marginLeft: '8px !important',
    },
  },
  errorService: {
    display: 'flex',
    maxWidth: '67%',
    marginLeft: 16,
    borderRadius: '5px 5px 5px 0px',
    background: 'rgba(238, 67, 82, 0.1)',
    padding: 16,
    border: '1px solid rgba(238, 67, 82, 0.97)',
    [theme.breakpoints.down(760)]: {
      marginLeft: '32px !important',
    },
    [theme.breakpoints.down(500)]: {
      marginLeft: '8px !important',
    },
  },
  avatar: {
    display: 'flex',
    alignItems: 'end',
    [theme.breakpoints.down(760)]: {
      display: 'none'
    },
  },
  avatarSugg: {
    display: 'transparent',
    [theme.breakpoints.down(760)]: {
      display: 'none'
    },
  },
  logo: {
    height: 48,
    width: 48,
    objectFit: 'contain',
    [theme.breakpoints.down(760)]: {
      height: 36,
      width: 36,
    },
  },
  button: {
    height: 58
  },
  loadMoreButton: {
    border: 'none',
    color: colors.grayish,
    fontSize: '14px',
    backgroundColor: 'transparent',
    marginBlock: 8,
    paddingLeft: 16,
    cursor: 'pointer',
  },
  loadMoreDiv: {
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#f0f0f0', 
    },
  },
  loadMore: {
    display: 'flex',
    alignItems: 'center',
    gap: 14
  },
  imageContainer: {
    width: '100%',
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    // [theme.breakpoints.down(760)]: {
    //   width: 300
    // },
  },
  image: {
    display: 'flex',
    borderRadius: '5px 5px 5px 0px',
    background: '#F5F5F5',
    padding: 16,
    marginLeft: 16,
    maxWidth: '67%',
    // width: 374,
    [theme.breakpoints.up(980)]: {
      maxWidth: '67%'
    },
    [theme.breakpoints.down(760)]: {
      marginLeft: '32px !important',
      maxWidth: '67%'
    },
    [theme.breakpoints.down(540)]: {
      marginLeft: '16px !important',
      // width: 260
    },
  },
  imgZoom: {
    position: 'sticky',
    // right: 156,
    cursor: 'pointer'
  },
  img: {
    minWidth: 200,
    minHeight: 200,
    objectFit: 'contain',
    // maxWidth: '67%',
    // maxHeight: 'auto',
    [theme.breakpoints.down(760)]: {
      // maxWidth: 250,
    },
  },
  newChat: {
    width: 145,
    [theme.breakpoints.down(1030)]: {
      width: 64,
    },
    [theme.breakpoints.down(760)]: {
      width: 100,
    },
  },
  newChatForNote: {
    width: 145,
    [theme.breakpoints.down(760)]: {
      width: 100,
    },
    marginRight: 16
  },
  note: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 12,
    paddingBottom: 20,
    textAlign: 'center',
    // [theme.breakpoints.down(760)]: {
    // },
    // [theme.breakpoints.down(500)]: {
    //   justifyContent: 'flex-start',
    //   paddingInlineStart: 10,
    //   maxWidth: '70%'
    // },
    [theme.breakpoints.down(400)]: {
      paddingInline: 10,
      justifyContent: 'center',
    },
  },
  question: {
    position: 'absolute',
    right: 24,
    bottom: 4,
    [theme.breakpoints.down(500)]: {
      bottom: 10,
      right: 12,
    },
  },
  questionMark: {
    cursor: 'pointer'
  },
  textTooltip: {
    display: 'flex',
    padding: 16,
    zIndex: '9999 !important',
    gap: 8
  },
}))

export default useStyles