/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Text, TextInput } from '..'
import { IconButton } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import { Axios } from '../../Config'
import { SecondaryOutlinedButton3, SecondaryOutlinedButton4 } from '../Button/Button'
import SucessModal from '../SuccessModal/SuccessModal'
import contactUsModalStyles from './ContactUsModal.styles'

function ContactUsModal({ isVisible, cancel, name }) {
  const classes = contactUsModalStyles()
  const [value, setValue] = useState('')
  const [isDisabled, setDisabled] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    if (
      value.length === 0
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [value])

  const onSubmit = async (description) => {
    setLoading(true)
    try {
      await Axios.post('/prod/contactUs',
        {
          details: description,
          customisationType: name
        }
      ).then((response) => console.log(response)
      )
      setLoading(false)
      setValue('')
      setDisabled(true)
      setSuccess(true)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  const onCancel = () => {
    setSuccess(false)
    setValue('')
    setDisabled(true)
    setLoading(false)
    cancel()
    setError()
  }

  const contactUsModal = (
    <div className={classes.ModalWrapper}>
      <div className={classes.subModal}>
        <div className={classes.text}>
          <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >{name}</Text>
        </div>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => onCancel()}
        >
          <ClearIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
      </div>
      <div className={classes.border} />
      <div className={classes.text2}>
        <Text size='subnote' color={colors.primary} className={classes.spaceText2}>{name === 'Business Coaching' ? 'Please let us know any details on how we can help you in our coaching session.' : 'Write here all your requirements for your multiple accounts or executive dashboard, so we can prepare a proposal for you.'}</Text>
      </div>
      <div className={classes.subText}>
        <Text size='subnote' color={colors.primary} weight='bold' className={classes.underTitle} >Details:</Text>
      </div>
      <div className={classes.inputForm}>
        <TextInput
          name='reason'
          costumizedInputStyles={classes.input}
          label='Type all the details'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          multiline={true}
          rows={6}
        />
      </div>
      {error &&
          <div className={classes.errorDiv}>
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{error.response.data.error.message}</Text>
          </div>
      }
      <div className={classes.border2} />
      <div className={classes.buttonsWrapper}>
        <SecondaryOutlinedButton4
          className={classes.cancelButton}
          onClick={onCancel}
        > Cancel
        </SecondaryOutlinedButton4>
        <SecondaryOutlinedButton3
          className={classes.nextButton}
          disabled={isDisabled || isLoading}
          loading={isLoading}
          style={{
            backgroundColor: isDisabled || isLoading ? colors.disabled : colors.primary,
            color: colors.white,
            border: isDisabled ? colors.disabled : colors.primary
          }}
          onClick={() => onSubmit(value)}
        >
            Send
        </SecondaryOutlinedButton3>
      </div>
    </div>
  )

  return (
    <Modal type="noBlur" costumizedPaperStyles={success ? classes.paperCostum2 : classes.paperCostum} opened={isVisible}>
      {success ? <SucessModal isSmart={false} onCancel={onCancel} /> : 
        contactUsModal}
    </Modal>
  )
}

export default ContactUsModal