import React, { useEffect, useState } from 'react'
import { TextInput } from '../..'
import { SelectInput } from '../../Input'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { selectStyles2, selectStyles3 } from '../AccountFinancialDetails/AccountFinancialDetails.styles'
import useStyles4 from './AccountLocationDetails.styles'
import { SELECT_OPTIONS } from '../../../Screens/Wrapper/utils/data'

function AccountLocationDetails({
  values,
  countryValue,
  timezoneValue,
  handleChange,
  handleBlur,
  setFieldValue,
}) {
  const classes = useStyles4()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedTimezone, setSelectedTimezone] = useState(null)

  useEffect(() => {
    // Find the timezone option object based on the value
    const timezoneOption = SELECT_OPTIONS.find(option => option.value === values.timezone)
    setSelectedTimezone(timezoneOption || null)
  }, [values.timezone])

  const handleChangeSelect = (option) => {
    setFieldValue('timezone', option.value) 
    // console.log(option.value)
  }

  return (
    <Grid item lg={12} md={12} xl={12} xs={12}>
      <Grid container spacing={isMobile ? 0 : 2}>
        <Grid item xl={4} lg={4} md={6} xs={12}>
          <TextInput
            costumizedInputStyles={classes.input}
            name="country"
            label='Country'
            value={values.country}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} xs={12}>
          <SelectInput
            value={selectedTimezone}
            selectCostumStyles={isMobile ? selectStyles3 : selectStyles2}
            label='Time Zone'
            placeholder="Time Zone"
            options={SELECT_OPTIONS}
            onChange={handleChangeSelect}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AccountLocationDetails
