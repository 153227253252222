import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      padding: '0px'
    },
  },
  iconRoot: {
    color: colors.primary,
    paddingLeft: 0,
    marginBottom: 10
  },
  iconLabel: {
    justifyContent: 'flex-start',
    color: colors.primary
  },
  continueButton: {
    width: '430px',
    alignSelf: 'flex-start',
    fontSize: 14,
    [theme.breakpoints.down('1050')]: {
      width: '100%'
    },
  },
  passwordRootInput: {
    maxWidth: 430,
    flexGrow: 0,
    [theme.breakpoints.down('1050')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.up(2000)]: {
      width: '70%'
    },
  },
  passwordInput: {
    marginBottom: 0,
  },
  span: {
    marginLeft: 5,
    fontWeight: 'bold'
  }
}))

export default useStyles