import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '47%',
    padding: 12,
    border: '1px solid #EBEBEB',
    transition: 'all 0.3s ease-in-out',
    borderRadius: 5,
    boxShadow: 'none',
    [theme.breakpoints.up(2580 )]: {
      width: '30.5%'
    },
    [theme.breakpoints.down(1665)]: {
      width: '45%'
    },
    [theme.breakpoints.down(1200)]: {
      width: '100%'
    },
    [theme.breakpoints.down(430)]: {
      maxWidth: 330
    },
    [theme.breakpoints.down(390)]: {
      maxWidth: 270
    },
    [theme.breakpoints.down(330)]: {
      maxWidth: 240
    },
  },
  hover: {
    '&:hover': {
      transition: 'all 0.3s ease-in',
      boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
      top: '-4px',
      backgroundColor: 'white', 
      zIndex: 1000,
      transform: 'scaleX(1.05) scaleY(1.1)',
    },
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 144,
    height: 95,
    objectFit: 'contain',
    [theme.breakpoints.down(390)]: {
      width: 100,
      height: 85,
    },
    [theme.breakpoints.down(330)]: {
      width: 100,
      height: 65,
    },
  },
  contentInnerWrapper: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
  },
  contentTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: 98,
    width: '100%',
    borderBottom: '1px solid #EBEBEB',
    overflow: 'hidden',
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionsRoot: {
    padding: 0,
    marginTop: 12,
  },
  priceTextWrapper: {
    width: '144px',
    textAlign: 'center'
  },
  descriptionWrapper: {
    marginTop: 8,
    marginBottom: 8
  },
  smallCard: {
    width: '30.5%',
    [theme.breakpoints.down(1823)]: {
      width: '47%'
    },
    [theme.breakpoints.down(1665)]: {
      width: '45%'
    },
    [theme.breakpoints.down(1200)]: {
      width: '100%'
    },
    [theme.breakpoints.between(2100, 2500 )]: {
      width: '30.5%'
    },
  },
  smallCardPriceTextWrapper: {
    minWidth: '144px',
    textAlign: 'center'
  },
  smallCardActionWrapper: {
    width: '100%',
    display: 'flex',
    gap: 24,
    alignItems: 'center',
  },
  smallCardActionTextWrapper: { 
    display: 'flex', 
    flexDirection: 'column', 
    width: '75%', 
    padding: '8px 0px', 
    gap: 6,
    [theme.breakpoints.down(1680)]: {
      width: '70%'
    },
  },
  smallCardLastSection: { 
    width: '100%', 
    textAlign: 'center', 
    paddingTop: '16px',
    paddingBottom: '4px',
    marginRight: 8
  },
  root2: {
    background: '#c9c9c9',
    borderRadius: 5
  }
}))

export default useStyles