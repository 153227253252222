import React from 'react'
import { Button, ButtonBase } from '@material-ui/core'
import PropTypes from 'prop-types'
import Loading from 'react-lottie'
import { withStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'
import { loadingDataplayer } from '../../Themes/Images'
import { outlinedStyles, pressableStyles, secondStyles, secondaryOutlinedStyles, secondaryOutlinedStyles2,
  secondaryOutlinedStyles3, secondaryOutlinedStyles4, secondaryOutlinedStyles5, styles } from './Button.styles'
import Text from '../Text'

const LOADING_DATAPLAYER = {
  loop: true,
  autoplay: true,
  animationData: loadingDataplayer,
  rendererSettings: {
    className: 'xMidYMid slice',
  },
}

const PrimeButton = withStyles(styles)(props => <Button {...props} />)

const SecondButton = withStyles(secondStyles)(props => <Button {...props} />)

const OutlinedButton = withStyles(outlinedStyles)(props => <Button variant="outlined" {...props} />)

const Pressable = withStyles(pressableStyles)(props => <ButtonBase {...props} />)


const PrimaryButton=({ children, loading, ...rest })=>{
  return (
    <PrimeButton
      {...rest} 
    >
      {loading ? (
        <div>
          <Loading options={LOADING_DATAPLAYER} height={40} />
        </div>
      ) : children}
    </PrimeButton>
  )
}
PrimaryButton.propTypes={
  loading: PropTypes.bool
}
PrimaryButton.defaultProps = {
  loading: false,
}



const SecondaryButton=({ children, loading, disabled, ...rest })=>{
  return (
    <SecondButton
      style={disabled ? { backgroundColor: colors.disabled }: null}
      disabled={disabled}
      {...rest} 
    >
      {loading ? (
        <div>
          <Loading options={LOADING_DATAPLAYER} height={40} />
        </div>
      ) : children}
    </SecondButton>
  )
}
SecondaryButton.propTypes={
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}
SecondaryButton.defaultProps = {
  loading: false,
  disabled: false,

}

const SecondaryOutlined = withStyles(secondaryOutlinedStyles)(props => <Button variant="outlined" {...props} />)

const SecondaryOutlined2 = withStyles(secondaryOutlinedStyles2)(props => <Button variant="outlined" {...props} />)

const SecondaryOutlined3 = withStyles(secondaryOutlinedStyles3)(props => <Button variant="outlined" {...props} />)

const SecondaryOutlined4 = withStyles(secondaryOutlinedStyles4)(props => <Button variant="outlined" {...props} />)

const SecondaryOutlined5 = withStyles(secondaryOutlinedStyles5)(props => <Button variant="outlined" {...props} />)


const SecondaryOutlinedButton=({ children, loading, disabled, ...rest })=>{
  return (
    <SecondaryOutlined
      style={disabled ? { backgroundColor: colors.disabled }: null}
      disabled={disabled}
      {...rest} 
    >
      {loading ? (
        <div>
          <Loading options={LOADING_DATAPLAYER} height={30} />
        </div>
      ) : children}
    </SecondaryOutlined>
  )
}

const SecondaryOutlinedButton2=({ children, loading, disabled, ...rest })=>{
  return (
    <SecondaryOutlined2
      style={disabled ? { backgroundColor: colors.disabled }: null}
      disabled={disabled}
      {...rest} 
    >
      {loading ? (
        <div>
          <Loading options={LOADING_DATAPLAYER} height={30} />
        </div>
      ) : children}
    </SecondaryOutlined2>
  )
}

const SecondaryOutlinedButton3=({ children, loading, verifying, disabled, ...rest })=>{
  return (
    <SecondaryOutlined3
      style={disabled ? { backgroundColor: colors.disabled }: null}
      disabled={disabled}
      {...rest} 
    >
      {loading ? (
        <div>
          <Loading options={LOADING_DATAPLAYER} height={30} />
        </div>
      ) : verifying ? (
        <Text color={colors.white} size='footnote'>Verifying...</Text>
      ): children}
    </SecondaryOutlined3>
  )
}

const SecondaryOutlinedButton4=({ children, loading, disabled, ...rest })=>{
  return (
    <SecondaryOutlined4
      style={disabled ? { backgroundColor: colors.disabled }: null}
      disabled={disabled}
      {...rest} 
    >
      {loading ? (
        <div>
          <Loading options={LOADING_DATAPLAYER} height={30} />
        </div>
      ) : children}
    </SecondaryOutlined4>
  )
}

const SecondaryOutlinedButton5=({ children, loading, disabled, ...rest })=>{
  return (
    <SecondaryOutlined5
      style={disabled ? { backgroundColor: colors.disabled }: null}
      disabled={disabled}
      {...rest} 
    >
      {loading ? (
        <div>
          <Loading options={LOADING_DATAPLAYER} height={30} />
        </div>
      ) : children}
    </SecondaryOutlined5>
  )
}

SecondaryOutlinedButton.propTypes={
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}
SecondaryOutlinedButton.defaultProps = {
  loading: false,
  disabled: false,

}

SecondaryOutlinedButton2.propTypes={
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}
SecondaryOutlinedButton2.defaultProps = {
  loading: false,
  disabled: false,

}

SecondaryOutlinedButton3.propTypes={
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}
SecondaryOutlinedButton3.defaultProps = {
  loading: false,
  disabled: false,

}

SecondaryOutlinedButton4.propTypes={
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}
SecondaryOutlinedButton4.defaultProps = {
  loading: false,
  disabled: false,

}

SecondaryOutlinedButton5.propTypes={
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}
SecondaryOutlinedButton5.defaultProps = {
  loading: false,
  disabled: false,

}



export {
  PrimaryButton,
  SecondaryButton,
  OutlinedButton,
  SecondaryOutlinedButton,
  SecondaryOutlinedButton2,
  SecondaryOutlinedButton3,
  SecondaryOutlinedButton4,
  SecondaryOutlinedButton5,
  Pressable,
}


