/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import Input from '@material-ui/core/Input'
import { prettoSliderStyles, useStylesSlider } from './SliderInput.styles'

const PrettoSlider = withStyles(prettoSliderStyles)(Slider)

export default function CustomizedSlider({ value, setValue, max }) {
  const classes = useStylesSlider()
 
  const handleSliderChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value))
  }

  const handleBlur = () => {
    if (value < 0) {
      setValue(0)
    } else if (value > max) {
      setValue(max)
    }
  }
  
  return (
    <div className={classes.root}>
      <PrettoSlider
        value={typeof value === 'number' ? value : 0}
        onChange={handleSliderChange}
        valueLabelDisplay="auto" 
        aria-label="pretto slider"
        defaultValue={0}
        min={0}
        step={1}
        max={max}
      />
      <Input
        className={classes.input}
        value={value}
        margin="dense"
        onChange={handleInputChange}
        onBlur={handleBlur}
        defaultValue={0}
        placeholder="0"
        inputProps={{
          step: 1,
          min: 0,
          max: max,
          type: 'number',
          'aria-labelledby': 'input-slider'
        }}
      />
    </div>
  )
}

CustomizedSlider.defaultProps={
  max: 10
}