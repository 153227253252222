// export default ContactUsModalCustomisation  
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Text, TextInput } from '..'
import { IconButton } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import { Axios } from '../../Config'
import { SecondaryOutlinedButton3 } from '../Button/Button'
import { uploadFileModalStyles, selectedStyles } from './UpdateFileUpload.styles'
import ManualFileUpload from '../ManualFileUpload/ManualFileUpload'
import axios from 'axios'
import toast from 'react-hot-toast'
import { ErrorToast, SuccessToast } from '../Notification/Notification'
import { useAuth } from '../../Global'

function containsWhitespace(str) {
  return /\s/.test(str)
}
  
function isCsv(fileName) {
  return fileName?.includes('csv')
}

const updatedReportName = (str) => str?.toLowerCase().charAt(0).toUpperCase() + str?.slice(1).toLowerCase()

function UpdateFileUpload({ isVisible, cancel, onSuccess }) {
  const classes = uploadFileModalStyles()
  const [isDisabled, setDisabled] = useState(true)
  const [error, setError] = useState()
  const [isLoading, setLoading] = useState(false)
  const [myFile, setMyFile] = useState(null)
  const [url, setUrl] = useState('')
  const [formDt, setFormDt] = useState()
  const [progress, setProgress] = useState(0)
  const controllerRef = useRef()
  const { fileToEdit } = useAuth()

  const isCsvFile = isCsv(myFile?.type)
  const containsWhitespaceFile = containsWhitespace(myFile?.name)

  useEffect(() => {
    if (myFile === null) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [myFile])

  
  const onCancel = () => {
    setMyFile(null)
    setError()
    setDisabled(true)
    setLoading(false)
    cancel()
    controllerRef?.current?.abort()
  }

  const cancelFileSubmit = () => {
    setMyFile(null)
  }

  const properSubmit = async (e) => {
    e?.preventDefault()
    setLoading(true)
    controllerRef.current = new AbortController()
    try {
      if ( typeof myFile === 'undefined' ) return

      const formData = new FormData()
  
      formData.append('file', myFile, 'manual file integration')

      setFormDt(formData)
      
      const data = await Axios.get(`/prod/user/fileUploads/getPresignedURL?fileName=${fileToEdit?.fileName}.csv&reportContentType=${(fileToEdit?.reportName).toUpperCase()}&fileProcessType=update`,
      )
      setUrl(data?.data?.data?.signedURL)
      
      await axios( {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          let percent = 0
          percent = Math.floor((loaded * 100) / total)
                
          if (progress <= 100) {
            setProgress(percent) 
          }
        },
        method: 'put',
        // eslint-disable-next-line max-len
        url: data?.data?.data?.signedURL,
        data: myFile,
        signal: controllerRef?.current?.signal
      })
      setLoading(false)
      setMyFile(null)
      setProgress(0)
      cancel()
      onSuccess()
      toast.custom((t) => (
        <SuccessToast t={t} message='File updated successfully!'/>
      ))
    } catch (error) {
      setError(error)
      setLoading(false)
      setProgress(0)
      setMyFile(null)
      setDisabled(false)
      if (error?.name === 'CanceledError') {
        toast.custom((t) => (
          <ErrorToast t={t} message='You canceled the update request'/>
        ))
      }
    }
  }

  const updateModal = (
    <div className={classes.ModalWrapper}>
      <div className={classes.subModal}>
        <div className={classes.text}>
          <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >Update File</Text>
        </div>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => onCancel()}
        >
          <ClearIcon classes={{ root: classes.iconButtonClose }} />
        </IconButton>
      </div>
      <div className={classes.border} />
      <Text size='footnote'>Upload the latest data for the file below. <br />Please do not change the file format.</Text>
      <div className={classes.subText2}>
        <Text size='subnote' color={colors.primary} weight='medium' className={classes.underTitle} >Current file</Text>
      </div>
      <div className={classes.reportNameContainer}>
        <Text size='footnote'>File name</Text>
        <Text color={colors.purple} size='footnote'>{fileToEdit?.fileName}</Text>
      </div>
      <div className={classes.subText2}>
        <Text size='subnote' color={colors.primary} weight='medium' className={classes.underTitle} >Upload latest version</Text>
      </div>
      <ManualFileUpload fileToUpload={myFile} setFileToUpload={setMyFile} cancelSubmit={cancelFileSubmit} 
        isCsv={isCsvFile} containsWhitespace={containsWhitespaceFile} progress={progress} setProgress={setProgress} isLoading={isLoading} />
      {error &&
          <div className={classes.errorDiv}>
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{error?.response?.data?.error?.message}</Text>
          </div>
      }
      <div className={classes.border2} />
      <div className={classes.buttonsWrapper}>
        <SecondaryOutlinedButton3
          className={classes.cancelButton}
          onClick={onCancel}
        > Cancel
        </SecondaryOutlinedButton3>
        <SecondaryOutlinedButton3
          className={classes.nextButton}
          disabled={isDisabled || isLoading || !isCsvFile || containsWhitespaceFile}
          loading={isLoading}
          style={{
            backgroundColor: isDisabled || isLoading || !isCsvFile || containsWhitespaceFile ? colors.disabled : colors.primary,
            color: colors.white,
          }}
          onClick={() => properSubmit()}
        >
          Update
        </SecondaryOutlinedButton3>
      </div>
    </div>
  )

  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      {updateModal}
    </Modal>
  )
}

export default UpdateFileUpload

