import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router-dom'
import { Text, TextInput } from '../../../Components'
import { SecondaryOutlinedButton5 } from '../../../Components/Button/Button'
import { SuccessToast } from '../../../Components/Notification/Notification'
import { Axios } from '../../../Config'
import { colors } from '../../../Themes'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { useStyles, selectedStyles } from './Unsubscribe.styles'
import { info } from '../../../Themes/Images'
import { SelectInput } from '../../../Components/Input'
import { useAuth } from '../../../Global'
import updatedReportName from '../../../Utils/uppercaseFIrstLetter'

const Unsubscribe = () => {
  const classes = useStyles()
  const { state } = useLocation()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [value, setValue] = useState('')
  const [values, setValues] = useState('')
  const [dropdownValues, setDropdownValues] = useState('')
  const { userUploadedFileType, getUpdatedUser, activeFileTypesCount, userUploadedFileTypeCount } = useAuth()

  const isManualFile = state?.name === 'Manual file integration'

  const allFilesUploaded = activeFileTypesCount === userUploadedFileTypeCount

  useEffect(() => {
    if (
      (dropdownValues === '' && isManualFile) || (value?.length === 0 && !isManualFile)
    ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [value, dropdownValues, isManualFile])

  const removeAddOn = async (id, value, value2) => {
    let body = {}
    if (isManualFile) 
      body = {
        modifierId: +id,
        // deleteReason: value2.concat(' ', value),
        deleteReason: value2,
        reportName: dropdownValues?.value
      }
    else {
      body = {
        modifierId: +id,
        deleteReason: value2.concat(' ', value),
      }
    }
    try {
      setIsLoading(true)
      await Axios.post('/prod/deleteSubscriptionModifier',
        body
      // eslint-disable-next-line promise/always-return
      ).then((response) => {
        setIsLoading(false)
        Axios.get('/prod/user/fileUploads/getUploadsTypes')
        history.push({ pathname: '/settings/payment-information', 
          state: {
            selectedTab: 1
          } })
      })
      await getUpdatedUser()
      toast.custom((t) => (
        <SuccessToast t={t} message='The add-on was cancelled successfully!'/>
      ))
    } catch (error) {
      console.log(error.message)
    }
  }
  
  const handleChange = (event) => {
    setValue(event.target.value)
  }
 
  const handleChangeDropdown = (value) => {
    setDropdownValues(value)
  }

 
  const RESULT_ARRAY = userUploadedFileType?.map(item => ({
    ...item,
    label: updatedReportName(item?.label)
  }))

  let OPTIONS_ARRAY = []

  if (allFilesUploaded) {
    OPTIONS_ARRAY = [...RESULT_ARRAY]
  }
  else {
    OPTIONS_ARRAY = [...RESULT_ARRAY, { label: 'No file uploaded', value: 'No file uploaded', status: 'DONE' }]
  }

  const ARRAY_WITHOUT_REVIEW = [...OPTIONS_ARRAY]

  return (
    <div className={classes.container}>  
      <div className={isManualFile ? classes.subContainerManual : classes.subContainer}>
        <Text size="subnote" weight='bold' >{isManualFile ? 'Select the file you want to remove:' : 'Before you cancel your add-on subscription, we’d really appreciate your feedback.'}</Text>
        <Text size="subnote" weight='bold' >{!isManualFile && 'Is there a specific reason for unsubscribing?'}</Text>  
        {isManualFile && 
        <div style={{ display: 'flex', gap: 10, maxWidth: 600,  marginTop: 10 }}>
          <img src={info} width={16} height={16} style={{ objectFit: 'contain', marginTop: 2 }} />
          <Text size='footnote'>If you delete the file, you won’t be able to upload new data anymore.</Text>
        </div>
        }
      </div>
      {isManualFile && 
        <div style={{ maxWidth: '100%', marginBottom: 10 }}>
          <SelectInput
            value={dropdownValues}
            selectCostumStyles={selectedStyles}
            label="Requests"
            placeholder="Select"
            options={ARRAY_WITHOUT_REVIEW}
            onChange={(val) => handleChangeDropdown(val)}
          />
        </div>
      }
    
     
      {!isManualFile &&  <div className={classes.form}>
        <FormControl component="fieldset">
          <RadioGroup aria-label="reason" name="reason" value={value} onChange={handleChange}>
            <FormControlLabel value="Budget limits." control={<Radio color="primary" />} label={<Text color={colors.primary} size='footnote'>Budget limits.</Text>} />
            <FormControlLabel value="The initial data on Dataplayer is sufficient." control={<Radio color="primary" />} label={<Text color={colors.primary} size='footnote'>The initial data on Dataplayer is sufficient.
            </Text>} />
            <FormControlLabel value="I will try it later. " control={<Radio color="primary" />} label={<Text color={colors.primary} size='footnote'>I will try it later.</Text>}/>
            <FormControlLabel value="It was not a fit for my needs." control={<Radio color="primary" />} label={<Text color={colors.primary} size='footnote'>It was not a fit for my needs.</Text>}/>
          </RadioGroup>
        </FormControl>
      </div>}
      <div>
        <Text size='footnote'>More details or other reasons are welcome:</Text>
      </div>
      <div className={classes.inputForm}>
        <TextInput
          name='reason'
          costumizedInputStyles={classes.input}
          label={<Text size='footnote' color={colors.primary}>Give us your feedback - the more the better.</Text>}
          value={values}
          onChange={(e) => setValues(e.target.value)}
          multiline={true}
          rows={4}
        />
      </div>
      <div className={classes.buttonDiv}>
        <SecondaryOutlinedButton5
          loading={isLoading}
          onClick={() => removeAddOn(state?.modifierId, values, value)}
          className={classes.cancelButton}
          disabled={isDisabled || isLoading}
          style={{
            backgroundColor: isDisabled || isLoading ? colors.disabled : colors.primary,
            color: colors.white,
          }}
        > {isManualFile ? 'Delete file' : 'Confirm Cancellation'}
        </SecondaryOutlinedButton5>
      </div>
    </div>
  )
}

export default Unsubscribe