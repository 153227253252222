import React from 'react'
import toast from 'react-hot-toast'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import useStylesSuccesMessage from './Notification.styles'
import {  CheckImg, ErrorImg } from '../../Themes/Images'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled"  {...props} />
}

function SuccessNotify({ open, setOpen, message, type }) {
  const classes = useStylesSuccesMessage()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert icon={<CheckImg  className={classes.img} fontSize="inherit" />} 
          classes={{ filledSuccess: clsx( classes.primary, classes[type] )  }} onClose={handleClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

function SuccessToast({ t, message }) {
  const classes = useStylesSuccesMessage()
  return (
    <Alert icon={<CheckImg  className={classes.img} fontSize="inherit" />}
      classes={{ filledSuccess: classes.primary  } }  onClose={() => toast.remove(t.id)} severity="success">
      <div className={classes.message}>{message}</div>
    </Alert>
  )
}

function ErrorToast({ t, message }) {
  const classes = useStylesSuccesMessage()

  return (
    <Alert icon={<ErrorImg className={classes.img} fontSize="inherit" />} 
      classes={{ filledError: classes.error  } }  onClose={() => toast.remove(t.id)} severity="error">
      {message}
    </Alert>
  )
}

export  { SuccessNotify, SuccessToast, ErrorToast }
