/* eslint-disable react/react-in-jsx-scope */
import StyledTabs from '@material-ui/core/Tabs'
import StyledTab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'
import { tabStyles, tabsStyles } from './Tabs.styles'

const Tabs = withStyles(tabsStyles)(props => <StyledTabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const Tab = withStyles(tabStyles)(props => <StyledTab {...props} />)

export {
  Tabs,
  Tab
}