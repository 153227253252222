import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'
import { maxWidth } from '@material-ui/system'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 15,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px' 
  },
  subContainerManual: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px' 
  },
  form: {
    marginBottom: 20,
  },
  inputForm: {
    marginBottom: 10,
    [theme.breakpoints.between('sm', 'lg')]: {
      maxWidth: '500px',
    },
    [theme.breakpoints.down(760)]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '750px',
    },
  },
  loaderWrapper: {
    display: 'flex',
    height: '30vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonDiv: {
    maxWidth: '450px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '60%',
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      maxWidth: '400px',
    },
  },
  cancelButton: {
    width: '100%',
    backgroundColor: colors.primary,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.primary,
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    border: '1px solid #314155',
    width: 24,
    height: 24,
  },
  checkedIcon: {
    backgroundColor: colors.primary
  },
  input: {
    maxWidth: window.innerWidth < 760 && '100%',
    width: window.innerWidth > 760 && 500,
  }
}))

const selectedStyles = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 44,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 18,
    zIndex: 3
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 58,
    flex: 1,
    maxWidth: window.innerWidth < 760 ? '100%' : 500
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 58,
    borderRadius: 5,
    '&:hover': {
      border: `1px solid ${colors.accentHover}`,
    },
    border: '0px solid lightgray',
    boxShadow: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      paddingLeft: 12,
      backgroundColor: colors.background,
      height: 38,
      marginTop: 8,
      color: colors.primary,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
        cursor: 'pointer'
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
  indicatorSeparator: (styles) => ({ display: 'none' })
}

export  { useStyles, selectedStyles }