/* eslint-disable promise/always-return */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { TextInput, PrimaryButton } from '../../Components'
import { ResetPassword, GradientLogo, loginAnimation } from '../../Themes/Images'
import { Auth } from 'aws-amplify'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link, useHistory } from 'react-router-dom'
import { colors } from '../../Themes'
import Verification from '../Verification/Verification'
import NewPassword from '../NewPassword/NewPassword'
import useStyles from './ForgotPassword.styles'

const re = {
  lowerCase: /.*[a-z].*/,
  capital: /[A-Z]+/,
  digit: /[0-9]+/,
  symbols: /[-!$%^#@&*()_+|~=`{}\[\]:";'<>?,.\/]+/,
}

const SCREEN_CONTENT = {
  username: 'username',
  verification: 'verification',
  newPassword: 'newPassword',
}

const ForgotSteps = {
  username: Username,
  verification: Verification,
  newPassword: NewPassword,
}

function Username({ username, setUserName, error, sendEmail }) {
  const classes = useStyles()
  return (
    <>
      <h1 style={{ color: colors.accent }}>Reset password</h1>
      <span className={classes.description}>
        Enter the username connected to this account and we will send you an
        email with a verification code to reset your password.
      </span>
      <TextInput label="Username" value={username} setValue={setUserName} />
      {Boolean(error) && <span className="loginError">{error}</span>}
      <div className={classes.loginButtons}>
        <Link
          style={{
            display: 'flex',
            fontWeight: 700,
            textDecoration: 'none',
            color: colors.secondary,
            alignItems: 'center',
          }}
          to="/login"

        >
          <ArrowBackIcon classes={{ root: classes.rootBackIcon }} />
          <span style={{ fontWeight: 'bold' }} className="logoutDrawer">Back to Login</span>
        </Link>
        <PrimaryButton
          className={classes.loginButton}
          onClick={sendEmail}
          disabled={username === ''}
          style={
            username === ''
              ? { backgroundColor: colors.disabled, color: colors.white, minHeight: 57 }
              : { backgroundColor: colors.primary, minHeight: 57 }
          }
        >
          Send
        </PrimaryButton>
      </div>
    </>
  )
}

function ForgotPassword() {
  const classes = useStyles()
  const history = useHistory()
  const [forgotStatus, setForgotStatus] = useState(SCREEN_CONTENT.username)
  const ForgotStep = ForgotSteps[forgotStatus]
  const [username, setUserName] = useState('')
  const [pin, setPin] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState()
  const [validatePassowrd, setValidatePassword] = useState({
    minimumLength: false,
    number: false,
    upperCase: false,
    lowerCase: false,
    symbols: false,
    focused: false,
    isVisible: false,
  })

  const onChangePassword = (text) => {
    setValidatePassword((prev) => ({
      ...prev,
      upperCase: re.capital.test(text),
      lowerCase: re.lowerCase.test(text),
      number: re.digit.test(text),
      symbols: re.symbols.test(text),
      minimumLength: text.length >= 8 && text.length < 99,
    }))
    setNewPassword(text)
  }
  function handleOffline() {
    setError('You are offline. Please check your internet connection.')
  }

  function handleOnline() {
    setError(undefined)
  }

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    if (!navigator.onLine) {
      handleOffline()
    } else {
      handleOnline()
    }

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, []) 

  const sendEmail = () => {
    if (username === '') {
      setError('Username cannot be empty!')
    } else if (!navigator.onLine) {
      setError('You are offline. Please check your internet connection.')
    } else {
      Auth.forgotPassword(username)
        .then(() => {
          setForgotStatus(SCREEN_CONTENT.verification)
          setError(undefined)
        })
        .catch((err) => {
          setError('Please enter an existing username.')
        })
    }
  }

  const submitChanges = () => {
    if (newPassword === '' || confirmPassword === '') {
      setError('Hang on, both fields need to be filled out!')
    } else if (!navigator.onLine) {
      setError('You are offline. Please check your internet connection.')
    } else if (newPassword !== confirmPassword) {
      setError('The passwords do not match.')
    } else {
      Auth.forgotPasswordSubmit(username, pin, newPassword)
        .then(() => {
          history.goBack()
        })
        .catch(() => {
          setForgotStatus(SCREEN_CONTENT.verification)
          setError('The PIN you inserted doesn’t match the one we sent. Please check the value and try again.')
        })
    }
  }

  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginAnimationContainer}>
        <ResetPassword className={classes.image} />
      </div>
      <div className={classes.loginForm}>
        <div className={classes.content}>
          <GradientLogo className={classes.gradientLogo} />
          <ForgotStep
            username={username}
            setUserName={setUserName}
            error={error}
            sendEmail={sendEmail}
            pin={pin}
            setPin={setPin}
            next={() => {
              setForgotStatus(SCREEN_CONTENT.newPassword)
              setError(undefined)
            }}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            submitChanges={submitChanges}
            // password condition
            validatePassowrd={validatePassowrd}
            setValidatePassword={setValidatePassword}
            onChangePassword={onChangePassword}
          />
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
