import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import clsx from 'clsx'
import { colors } from '../../Themes'
import Text from '../Text'
import useStyles from './CostumerFeedbackRadioButton.styles'

const values=[
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
  {
    value: '10',
    label: '10',
  },
]
  
// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles()
  
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<div className={clsx(classes.icon, classes.checkedIcon)} > 
        <Text size='subnote' color={colors.white} >{props.label}</Text>
      </div>}
      icon={<div className={classes.icon} >
        <Text size='subnote' >{props.label}</Text>
      </div>}
      {...props}
    />
  )
}

export default function CostumerFeedbackRadioButton({ value, handleChange }) {
 
  return (
    <FormControl component="fieldset">
      <RadioGroup aria-label="gender" name="feedback" value={value} onChange={handleChange} row >
        {values.map(({ value, label }, index)=>{
          return <FormControlLabel key={index} value={value} control={<StyledRadio label={label}/>} />
        })}
      </RadioGroup>
    </FormControl>
  )
}