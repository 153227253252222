/* eslint-disable camelcase */
/* eslint-disable promise/no-nesting */
/* eslint-disable promise/always-return */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { NumberInput, PrimaryButton, TextInput } from '..'
import { colors } from '../../Themes'
import { useMoreInfoValidation } from '../../Utils/validation'
import Auth from '@aws-amplify/auth'
import { useAuth } from '../../Global'
import getMoreInfoModalStyles from './GetMoreInfoModal.styles'

function joinFullName(firstname, lastname) {
  let fullName = `${firstname} ${lastname}`
  return fullName
}

function GetMoreInfoModal({ onSuccessInfo }) {
  const classes = getMoreInfoModalStyles()
  const { getUpdatedUser, logout } =useAuth()
  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    role: '',
    numberOfEmployees: ''
  })
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    Auth.currentAuthenticatedUser()
      .then((data) => {
        Auth.updateUserAttributes(data, {
          name: joinFullName(values.firstname, values.lastname),
          middle_name: values?.role,
          locale: values?.numberOfEmployees,
        })
          .then(() => {
            getUpdatedUser()
            setLoading(false)
            onSuccessInfo()
          })
          .catch(() => setLoading(false)
          )
      })
      .catch(() => { })
  }

  const {
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
  } = useMoreInfoValidation(onSubmit, setValues)

  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
            values.firstname !== '' &&
            values.lastname !== '' &&
            values.role !== '' &&
            values.numberOfEmployees !== ''
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    values?.firstname,
    values?.lastname,
    values?.role,
    values?.numberOfEmployees,
    errors,
  ])

  return (
      <div className={classes.ModalWrapper}>
        <div className={classes.fullNameWrapper}>
          <div className={classes.fullNameInput}>
            <TextInput
              costumizedInputStyles={
                classes['input_' + Boolean(errors?.firstname)]
              }
              error={Boolean(errors?.firstname)}
              placeholder="First name"
              name="firstname"
              label="First name"
              value={values?.firstname || ''}
              setValue={(value) => handleChange(value, 'firstname')}
              onBlur={(e) => handleOnBlur(e)}
            />
            {errors?.firstname && (
              <p className={classes.errorInput}>{errors?.firstname}</p>
            )}
          </div>
          <div className={classes.fullNameInput}>
            <TextInput
              costumizedInputStyles={
                classes['input_' + Boolean(errors?.lastname)]
              }
              error={Boolean(errors?.lastname)}
              // hiddenLabel={true}
              placeholder="Last name"
              name="lastname"
              label="Last name"
              value={values?.lastname || ''}
              setValue={(value) => handleChange(value, 'lastname')}
              onBlur={(e) => handleOnBlur(e)}
            />
            {errors?.lastname && (
              <p className={classes.errorInput}>{errors?.lastname}</p>
            )}
          </div>
        </div>
        <TextInput
          costumizedInputStyles={
            classes['input_' + Boolean(errors?.role)]
          }
          error={Boolean(errors?.role)}
          placeholder="Job Title"
          name='role'
          label='Job Title'
          value={values?.role || ''}
          setValue={(value) => handleChange(value, 'role')}
          onBlur={(e) => handleOnBlur(e)}
        />
        {errors?.role && (
          <p className={classes.errorInput}>{errors?.role}</p>
        )}
        <NumberInput
          costumizedInputStyles={classes.numberInput}
          error={Boolean(errors?.numberOfEmployees)}
          type="number"
          name="numberOfEmployees"
          label="Number of employees"
          placeholder='Number of employees'
          value={values?.numberOfEmployees || ''}
          setValue={(value) => handleChange(value, 'numberOfEmployees')}
          onBlur={(e) => handleOnBlur(e)}
        />
        {errors?.numberOfEmployees && (
          <p className={classes.errorInput}>{errors?.numberOfEmployees}</p>
        )}
        <PrimaryButton
          className={classes.button}
          disabled={disabled || loading}
          loading={loading}
          style={
            disabled || loading
              ? { backgroundColor: colors.disabled, color: colors.white, minHeight: 50 }
              : { minHeight: 50 }
          }
          onClick={handleSubmit}
        >
                    Save
        </PrimaryButton>
        <PrimaryButton
          className={classes.cancelButton}
          onClick={logout}
        >
                    Log Out
        </PrimaryButton>
      </div>
    // </Modal>
  )
}

export default GetMoreInfoModal
