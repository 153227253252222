import React from 'react'
import { ProfileAvatar, Text } from '../..'
import useStyles from './AccountAvatarInfo.styles'

function AccountAvatarInfo({ setChangePhotoValues, user, value }) {
  const classes=useStyles()

  return (
    <div className={classes.root}>
      <ProfileAvatar 
        picture={value?.url}
        alt={user} 
        onClick={
          () => setChangePhotoValues(prev => ({
            ...prev,
            isVisible: true,
          })
          )
        }
      />
      <div className={classes.profileUsernameWrapper}>
        <Text size='body' className="profileUsername">{user || ''}</Text>
        <b style={{ textAlign: 'center' }} className="accountType">Primary account</b>
      </div>
    </div>
  )
}

export default AccountAvatarInfo
