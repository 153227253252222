/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable promise/always-return */
import React, { useEffect, useState } from 'react'
import { useSubscription, useAuth } from '../../Global'
import { PaymentsList, PaymentsTable, PrimaryButton, SecondLoader, TabPanel, Text, } from '../../Components'
import { colors } from '../../Themes'
import { Axios } from '../../Config'
import { useHistory, useLocation } from 'react-router'
import DateRangePicker from '../../Components/DatePicker'
import { useWindowDimensions } from '../../Utils'
import AddOnsInfo from '../Addons/AddOnsInfo'
import TabsForAddons from '../../Components/TabsForAddons/TabsForAddons'
import useStyles from './PaymentInformation.styles'
import { Link } from 'react-router-dom'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function slicePayments(payments, limit) {
  if (payments && payments.length > 0) {
    return payments.slice(0, limit).reverse()
  }
  return []
}

function convertDate(str) {
  let date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [date.getFullYear(), mnth, day].join('-')
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

function PaymentInformation() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { selectedTab } = location?.state?? {}
  let tomorrow = new Date()
  const { width } = useWindowDimensions()
  const { subscription, refetchSubscription, isLoadingSub, plan, isLoadingPlan, refetchPlan } = useSubscription()
  const { is_trialing, plan: globalPlan } = useAuth()
  const [tabValue, setTabValue] = useState(selectedTab ? selectedTab : 0)
  const [paymentsValues, setPaymentsValues] = useState({
    payments: [],
    limit: 5,
    loading: false
  })
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1))
  const [endDate, setEndDate] = useState(tomorrow)
  tomorrow.setDate(new Date().getDate() + 1)

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    // check if user is costumize , (to unmound component)
    refetchSubscription()
    refetchPlan()
    getPayments()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPayments = () => {
    setPaymentsValues({
      ...paymentsValues,
      loading: true
    })
    Axios.get(`/prod/user/subscription/payments?from=${convertDate(startDate)}&to=${convertDate(endDate)}`)
      .then(({ data }) => {
        if (data?.success === true) {
          setPaymentsValues({
            ...paymentsValues,
            payments: data?.response,
            loading: false
          })
        } else {
          setPaymentsValues({
            ...paymentsValues,
            loading: false
          })
        }
      })
      .catch((e) => console.log(e))
  }
  
  const onLoadMore = () => {
    setPaymentsValues({
      ...paymentsValues,
      limit: paymentsValues?.limit + 5
    })
  }

  const openReceipt = (url) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  if (isLoadingSub || isLoadingPlan) {
    return <SecondLoader/>
  }

  if (is_trialing || isEmpty(subscription) ) {
    return <AddOnsInfo/>
  }

  return (
    <div className={classes.container}>
      <TabsForAddons
        value={tabValue}
        handleChange={handleChangeTab}
      />
      <TabPanel value={tabValue} index={0}>
        <Text size="body" weight="regular" >Plan Information</Text>
        <div className={classes.planInformationContainer}>
          <div className={classes.planInfo}>
            <Text size="subnote" weight="bold" className={classes.textSpace}  >Dataplayer {plan?.[0]?.name || ''} Account</Text>
            <Text size="subnote" weight="light" color='#818181' className={classes.textSpace} >{plan?.[0]?.billing_type === 'month' ? 'Monthly' : 'Annual' || ''} payment</Text>
          </div>
          <div className={classes.upgradeDowngrade}>
            <PrimaryButton
              classes={{ root: classes.button }}
              onClick={() => history.push('/upgradeplan')}

            >
                    Upgrade Plan
            </PrimaryButton>
            <div className={classes.downgradeLink}>
              <Link to='/upgradeplan'><Text color={colors.primary} size='footnote'>Cancel subscription</Text></Link>
            </div>
          </div>
        </div>
        <div className={classes.line} />
        <Text size="body" weight="regular" >Payment Information</Text>
        <div className={classes.planInformationContainer} >
          <div className={classes.planInfo}>
            <Text size="subnote" weight="bold" className={classes.textSpace} >${plan?.[0]?.recurring_price?.USD || ''}</Text>
            <Text size="subnote" weight="light" color={colors.footnote} className={classes.textSpace} >Next payment on {subscription?.next_payment?.date || ''}</Text>
            <Text size="subnote" weight="light" color={colors.footnote} className={classes.textSpace} > {capitalizeFirstLetter(subscription?.payment_information?.card_type || '')} {subscription?.payment_information?.payment_method || ''}  ending **** {subscription?.payment_information?.last_four_digits || ''}</Text>
          </div>
          <PrimaryButton
            classes={{ root: classes.manageButton }}
            onClick={() => window.open(subscription?.update_url)}
          >
                    Update payment method
          </PrimaryButton>
        </div>
        <div className={classes.line} />
        <Text size="body" weight="regular" >Billing Information</Text>
        <div className={classes.tableWrapper}>
          <Text size="footnote" weight="regular" className={classes.chooseDateText} >Choose Date</Text>
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            callBack={getPayments}
            isMobile={width < 770}
          />
          {width > 1150 ?
            <PaymentsTable
              loading={paymentsValues.loading}
              payments={slicePayments(paymentsValues.payments, paymentsValues?.limit)}
              onLoadMore={onLoadMore}
              showMore={paymentsValues?.payments.length >= paymentsValues.limit}
              openReceipt={openReceipt}
            />
            :
            <PaymentsList
              loading={paymentsValues.loading}
              payments={slicePayments(paymentsValues.payments, paymentsValues?.limit)}
              onLoadMore={onLoadMore}
              showMore={paymentsValues?.payments.length >= paymentsValues.limit}
              openReceipt={openReceipt}
            />
          }
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AddOnsInfo />
      </TabPanel>
    </div>
  )
}

export default PaymentInformation
