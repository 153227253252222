import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  passSubcontainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: 15
    },
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    maxWidth: 350,
    [theme.breakpoints.down('1050')]: {
      display: 'none',
    },
    [theme.breakpoints.between('1050', 'md')]: {
      maxWidth: 350,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
  },
  image: {
    margin: 'auto',
    width: '100%',
    height: 'auto'
  },
  // Change Password Step
  passwordRootInput: {
    maxWidth: 430,
    flexGrow: 0,
    [theme.breakpoints.down('1050')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.up(2000)]: {
      width: '70%'
    },
  },
  passwordInput: {
    marginBottom: 0
  },
  changePasswordButton: {
    width: '430px',
    alignSelf: 'flex-start',
    fontSize: 14,
    [theme.breakpoints.down('1050')]: {
      width: '100%'
    },
  }
}))

export default useStyles