import React from 'react'
import clsx from 'clsx'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import useStyles from './Modal.styles'

function Modal1({ opened, handleClose, children, costumizedPaperStyles, type, variant }) {
  const classes = useStyles()
  const modalClassName = clsx(
    classes.modal,
    {
      [classes.explanationModal]: variant === 'explanation', // Apply different class if variant is 'explanation'
    }
  )
  const paperClassName = clsx(
    classes.paper,
    classes.customizedPaperClass,
    {
      [classes.explanationPaper]: variant === 'explanation',
      [classes.chatMenuModal]: variant === 'chat',
    }
  )
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={modalClassName}
      open={opened}
      onClose={handleClose}
      closeAfterTransition
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{ classes: { root: clsx(classes.backdrop, classes[type]) } }}
    >
      <Fade in={opened}>
        <div className={paperClassName}>
          {children}
        </div>
      </Fade>
    </Modal>
  )
}

export default Modal1
