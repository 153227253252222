// export default ContactUsModalCustomisation  
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Text, TextInput } from '..'
import { IconButton } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import { Axios } from '../../Config'
import { SecondaryOutlinedButton3 } from '../Button/Button'
import { SelectInput } from '../Input'
import SucessModal from '../SuccessModal/SuccessModal'
import { contactUsModalCustomisationStyles, selectedStyles } from './ContactUsModalCustomisation.styles'

const SELECT_OPTIONS = [
  { value: 'Dashboard Customisations',  label: 'Dashboard Customisations' },
  // { value: 'Custom Email Reports', label: 'Custom Email Reports' },
  { value: 'Other', label: 'Other' },
]

function ContactUsModalCustomisation({ isVisible, cancel }) {
  const classes = contactUsModalCustomisationStyles()
  const [value, setValue] = useState('')
  const [dropdownValues, setDropdownValues] = useState('')
  const [isDisabled, setDisabled] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (
      value.length === 0 || dropdownValues === ''
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [value, dropdownValues])

  const onSubmit = async (category, description) => {
    setLoading(true)
    try {
      await Axios.post('/prod/contactUs',
        {
          customisationType: category,
          details: description
        }
      ).then((response) => console.log(response)
      )
      setSuccess(true)
      setLoading(false)
      setValue('')
      setDropdownValues('')
      setDisabled(true)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }
  
  const onCancel = () => {
    setSuccess(false)
    setValue('')
    setDropdownValues('')
    setError()
    setDisabled(true)
    setLoading(false)
    cancel()
    
  }

  const handleChange = (value) => {
    setDropdownValues(value)
  }

  const contactUsModalCustomisation = (
    <div className={classes.ModalWrapper}>
      <div className={classes.subModal}>
        <div className={classes.text}>
          <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >Request Customisation</Text>
        </div>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => onCancel()}
        >
          <ClearIcon classes={{ root: classes.iconButtonClose }} />
        </IconButton>
      </div>
      <div className={classes.border} />
      <div className={classes.text2}>
        <Text size='subnote' color={colors.primary} className={classes.spaceText2}>Please, add below a short description of the dashboard customisations or the email reports you would like to get.</Text>
      </div>
      <div className={classes.subText2}>
        <Text size='subnote' color={colors.primary} weight='bold' className={classes.underTitle} >Customisation Type:</Text>
      </div>
      <div className={classes.dropwdownWrapper}>
        <SelectInput
          value={dropdownValues}
          selectCostumStyles={selectedStyles}
          label="Requests"
          placeholder="Select"
          options={SELECT_OPTIONS}
          onChange={(val) => handleChange(val)}
          account
        />
      </div>
      <div className={classes.subText}>
        <Text size='subnote' color={colors.primary} weight='bold' className={classes.underTitle} >Details:</Text>
      </div>
      <div className={classes.inputForm}>
        <TextInput
          name='reason'
          costumizedInputStyles={classes.input}
          label='Type all the details'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          multiline={true}
          rows={6}
        />
      </div>
      {error &&
          <div className={classes.errorDiv}>
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{error.response.data.error.message}</Text>
          </div>
      }
      <div className={classes.border2} />
      <div className={classes.buttonsWrapper}>
        <SecondaryOutlinedButton3
          className={classes.cancelButton}
          onClick={onCancel}
        > Cancel
        </SecondaryOutlinedButton3>
        <SecondaryOutlinedButton3
          className={classes.nextButton}
          disabled={isDisabled || isLoading}
          loading={isLoading}
          style={{
            backgroundColor: isDisabled || isLoading ? colors.disabled : colors.primary,
            color: colors.white,
          }}
          onClick={() => onSubmit(dropdownValues.value, value)}
        >
          Send
        </SecondaryOutlinedButton3>
      </div>
    </div>
  )

  
  return (
    <Modal type="noBlur" costumizedPaperStyles={success ? classes.paperCostum2 : classes.paperCostum} opened={isVisible}>
      {success ? <SucessModal isSmart={false} onCancel={onCancel} /> : 
        contactUsModalCustomisation}

      
    </Modal>
  )
}



export default ContactUsModalCustomisation

