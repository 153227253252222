/* eslint-disable react/no-unescaped-entities */
/* eslint-disable indent */
import React from 'react'
import { Card, IconButton } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import { Text } from '..'
import { colors } from '../../Themes'
import { useHistory } from 'react-router'
import moment from 'moment'
import useStyles from './ReferalCard.styles'
import { Referal, ReferalMobile } from '../../Themes/Images'
import { Link } from 'react-router-dom'


function ReferalCard({ isExpanded, isMobile, onClick }) {
  const classes = useStyles()
  const history=useHistory()

  if (!isExpanded){

    return  (
   <></>
    )
  
  }

  return (
    <Card id='referalCard' className={classes.root}>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        {isMobile ? <ReferalMobile /> : <div className={classes.image}><Referal /></div>}
        
         <div className={classes.textContainer}>
          <div className={classes.upperText}>
            <Text size='footnote'>Refer a friend and get rewarded.</Text>
          </div>
          <div onClick={onClick} className={classes.linkDiv}>
            <Link to="/settings/account" className={classes.link}><Text weight='medium' size='subnote' color={colors.purple}>Refer now</Text></Link>
          </div>
         </div>
      </CardContent>
    </Card>
  )
}

export default ReferalCard
