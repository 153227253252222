import React, { useState } from 'react'
import {  Divider, IconButton, Icon } from '@material-ui/core'
import { Modal, PrimaryButton, Text } from '../../..'
import CropImageInput from '../CropImageInput'
import CloseIcon from '@material-ui/icons/Close'
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar'
import { addcamera, deleteBin } from '../../../../Themes/Images'
import { Axios } from '../../../../Config'
import { useAuth } from '../../../../Global'
import { colors } from '../../../../Themes'
import useStyles from './PictureModal.styles'

function PictureModal({ isVisible, onClose, picture, alt, setValue }) {
  const classes = useStyles()
  const {  picture: currentPic, setPicture }=useAuth()
  const [imageSrc, setImageSrc] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      setImageSrc(imageDataUrl)
    }
  }

  const onDelete= async()=>{
    setIsLoading(true)
    try {
      await Axios.post(
        '/prod/user/update/logo',
        { base64_logo: null },
      )
      setIsLoading(false)
      setPicture(null)
      setImageSrc(null)
      setValue({ url: null, data: null })
      onClose()
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }
 
  return (
    <Modal costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <div className={classes.flex}>
          <div className={classes.text}>
            <Text size="subnote" color={colors.accent} weight='bold'>{ imageSrc ? 'Profile photo' :'Add photo'}</Text>
          </div>
          <div className={classes.icon}>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              disableRipple={true}
              style={{ backgroundColor: 'transparent' }}
              classes={{ root: classes.closeIcon }}
              onClick={imageSrc ? ()=> setImageSrc(null) : onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.divider}></div>
        {imageSrc ? 
          <CropImageInput imageSrc={imageSrc} setValue={setValue} setImageSrc={setImageSrc} onClose={onClose}/>
          :
          <ProfileAvatar change  picture={picture} alt={alt} isModal={true} />
        }
        {!imageSrc && 
        <>
          <Divider className="divider" variant="middle" style={{ width: '100%' }} />
          <div className={classes.buttonWrapper}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={onFileChange}
            />
            <label htmlFor="contained-button-file">
              <PrimaryButton
                className={classes.button}
                startIcon={
                  <Icon classes={{ root: classes.IconRoot }}>
                    <img  alt='addCamera' src={addcamera}/>
                  </Icon>
                }
                component="span"
              >
               Add Photo
              </PrimaryButton>
            </label>
            <PrimaryButton
              className={classes.button}
              onClick={onDelete}
              // loading={isLoading}
              disabled={!currentPic||isLoading}
              startIcon={   <Icon classes={{ root: classes.IconRoot }}>
                <img className={classes.img} style={ !currentPic ||isLoading?  { opacity: 0.5 } : null} alt='delete' src={deleteBin}/>
              </Icon>}
            >
               Remove
            </PrimaryButton>
          </div>
        </>
        }
      </div>
    </Modal>
  )
}

function readFile(file) {

  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export default PictureModal
