/* eslint-disable max-len */
/* eslint-disable promise/always-return */
import React, { useEffect, useState } from 'react'
import { Text } from '../../../Components'
import { colors } from '../../../Themes'
import { useTheme } from '@material-ui/core/styles'
import { IconButton, useMediaQuery } from '@material-ui/core'
import { SecondaryOutlinedButton2 } from '../../../Components/Button/Button'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Axios } from '../../../Config'
import { useAuth } from '../../../Global'
import { BackIcon, errorIcon } from '../../../Themes/Images'
import { useStyles, selectedStyles } from './StoreProductDetails.styles'
import AddOnBoughtModal from '../../../Components/AddOnBoughtModal'
import XEROModal from '../../../Components/XEROModal'
import AnnualDateRangePicker from '../../../Components/AnnualDatePicker/AnnualDatePicker'
import { SelectInput } from '../../../Components/Input'
import { useWindowDimensions } from '../../../Utils'


function formatDate(date = new Date()) {
  const year = date?.toLocaleString('default', { year: 'numeric' })
  const month = date?.toLocaleString('default', {
    month: '2-digit',
  })
  const day = date?.toLocaleString('default', { day: '2-digit' })

  return [year, month, day].join('-')
}

function addOneYear(date) {
  // date.setFullYear(date.getFullYear() + 1)
  // return date
  const dateCopy = new Date(date)

  dateCopy.setFullYear(dateCopy.getFullYear() + 1)
  dateCopy.setDate(dateCopy.getDate() - 1)

  return dateCopy
}

const timeZones = Intl.supportedValuesOf('timeZone')
const output = timeZones.map(x => {
  return ({ value: x, label: x })
})

const StoreProductDetails = () => {
  const classes = useStyles()
  const history = useHistory()
  const { state } = useLocation()
  const { productId } = useParams()
  const theme = useTheme()
  const { userAttributes, getUpdatedUser } = useAuth()
  const [isLoading, setLoading] = useState(false)
  const [isDisabled, setDisabled] = useState(false)
  const [error, setError] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isXero, SetIsXero] = useState(false)
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1))
  const yearFrom = addOneYear(startDate)
  const [endDate, setEndDate] = useState(yearFrom)
  const [value, setValue] = useState('')
  const { width } = useWindowDimensions()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))

  const startDateFormatted = formatDate(startDate)
  const endDateFormatted = formatDate(yearFrom)
  const timeZoneFormatted = value.value


  const handleChange = (value) => {
    setValue(value)
  }

  const handleOpenXero = () => {
    SetIsXero(true)
   
  }

  const handleCloseXero = () => {
    SetIsXero(false)
   
  }

  const redirectToInfo = () => {
    history.push({ pathname: '/settings/payment-information', 
      state: {
        selectedTab: 1
      } })
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    history.push('/store')
  }

  // const isAllowed = startDate.getTime() <= endDate.getTime()

  useEffect(() => {
    if (
      (timeZoneFormatted === undefined ||
            (startDateFormatted === '' || startDateFormatted === '--') || endDateFormatted === '' || endDateFormatted === '--') && 
            (state?.name === 'Overview Annual Report' || state?.name === 'Full Annual Report')
    ) 
    {
      setDisabled(true)
    } else {
      setDisabled(false)
    }

  }, [timeZoneFormatted, startDateFormatted, endDateFormatted, state])

  const annualReportBody =  {
    productId: +productId,
    startDate: startDateFormatted,
    endDate: endDateFormatted,
    timezone: timeZoneFormatted
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      await Axios.post('/prod/generateProductPayLink',

        annualReportBody
        
      ).then((response) => {
        window.Paddle.Checkout.open({
          override: response?.data.data.productPayLink,
          email: userAttributes?.email,
          // passthrough: JSON.stringify({
          //   username: user,
          //   email: userAttributes?.email,
          //   sub: userAttributes?.sub,
          //   'auth_time': '16177s14966',
          // }),
          successCallback: function (data) {
            redirectToInfo()
          },
          closeCallback: function (data) {
            setStartDate(new Date(new Date().getFullYear(), 0, 1))
            setEndDate(new Date(new Date().getFullYear(), 11, 31))
            setValue('')
          },
          
          
        })
      })
      setLoading(false)
      setDisabled(true)
      setError('')
      setStartDate(new Date(new Date().getFullYear(), 0, 1))
      setEndDate(new Date(new Date().getFullYear(), 11, 31))
      setValue('')
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }


  const onSubmitBuyProduct = async () => {
    setLoading(true)
    try {
      await Axios.post('/prod/buyProduct',
        {
          productId: +productId,
        }
      ).then((response) => {
        getUpdatedUser()
        showModal()
      }
      )
      setLoading(false)
      setError('')
      setDisabled(true)
      // toast.custom((t) => (
      //   <SuccessToast t={t} message='The add-on was bought successfully!'/>
      // ))
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  return (
    <div className={classes.container} >
      <div className={classes.header} >
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
         >
          
          {!matches && 
          <>
            <BackIcon classes={{ root: classes.closeIcon }}  onClick={() =>   history.goBack()}/>
            <p className={classes.back}>Back</p>
          </>}
        </IconButton>
        {state?.isOneOffCharge ? <Text size='h5' color={colors.accent} weight='bold' className={classes.headerText} >Purchase - {state?.name}</Text> : 
          <Text size='h5' color={colors.accent} weight='bold' className={classes.headerText} >Add-on - {state?.name}</Text>}
      </div>
      <div className={classes.line} />
      <div className={classes.container2}>
        <div className={classes.innerDiv} >
          <div className={classes.smallDiv}>
            <Text color={colors.primary} size='body' weight='bold'>{state?.name}</Text>
          </div>
          <div className={classes.smallDiv}>
            <Text color={colors.grayish} size='footnote' weight='regular'>{state?.description}</Text>
          </div>
          <div className={classes.imgDiv}>
            <img style={{ objectFit: 'contain' }}  src={state?.icon} alt='car' height='250px' width='100%' />
          </div>
        </div>
        <div className={classes.verticalLine}></div>
        <div className={classes.innerDiv2} >
          <div className={classes.smallDiv}>
            <Text color={colors.primary} size='body' weight='bold'>Payment details</Text>
          </div>
          <div className={classes.line3} />
          <div className={classes.priceDiv}>
            <Text color={colors.primary} size='footnote' weight='regular'>{state?.paymentType === 'month'? 'Monthly Fee': state?.isOneOffCharge === true ? 'Purchase Fee' : 'Annual Fee'}</Text>
            <Text color={colors.primary} size='footnote' weight='regular'>${state?.basePrice}</Text>
          </div>
          <div className={(state?.name === 'Overview Annual Report' || state?.name === 'Full Annual Report') ? classes.taxDivAnnual : classes.taxDiv}>
            <Text color={colors.primary} size='footnote' weight='regular'>Tax </Text>
            <Text color={colors.primary} size='footnote' weight='regular'>$0</Text>
          </div>
          {(state?.name === 'Overview Annual Report' || state?.name === 'Full Annual Report') &&
          <div className={classes.line4} />
          }
          {(state?.name === 'Overview Annual Report' || state?.name === 'Full Annual Report') &&
          <div className={classes.pickAnnualReport}>
            <Text color={colors.primary} size='footnote' weight='medium'>When does your year start?</Text>
          </div>}
          {(state?.name === 'Overview Annual Report' || state?.name === 'Full Annual Report') && 
          <>
            <div className={classes.dateRangePicker}>
              <AnnualDateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={yearFrom}
                setEndDate={setEndDate}
                callBack={() => null}
                isMobile={width < 600}
                isAnnual={true}
                disabled={true}
              />
            </div>
            {/* {!isAllowed && 
            <div className={classes.errorDivAllowed}>
              <img className={classes.errorICon} src={errorIcon} />
              <Text className={classes.errorText} color={colors.secondary} size='footnote'>End date cannot be smaller than the start date!</Text>
            </div>
            } */}
            <div style={{ display: 'flex' }}>
              <SelectInput
                value={value}
                selectCostumStyles={selectedStyles}
                label="Timezone"
                placeholder="Timezone"
                options={output}
                onChange={(value) => handleChange(value, 'pms')}
              />
            </div>
          </>
          }
          <div className={classes.line2} />
          {state?.isOneOffCharge ?
            <div className={classes.smallDiv3}>
              <SecondaryOutlinedButton2
                loading={isLoading}
                disabled={isDisabled || isLoading}
                style={{
                  backgroundColor: (isLoading || isDisabled) ? colors.disabled : colors.primary,
                  color: colors.white,
                }}
                onClick={() => onSubmit()}
                className={classes.cancelButton}
              > <Text color={colors.white} style={{ fontWeight: 600 }} size='small'>Proceed to payment</Text>
              </SecondaryOutlinedButton2>
            </div> : 
            <div className={classes.smallDiv3}>
              <SecondaryOutlinedButton2
                disabled={isDisabled || isLoading}
                loading={isLoading}
                style={{
                  backgroundColor: (isLoading || isDisabled) ? colors.disabled : colors.primary,
                  color: colors.white,
                }}
                onClick={state?.name === 'XERO integration' ? () => handleOpenXero() : () => onSubmitBuyProduct()}
                className={classes.cancelButton}
              > <Text color={colors.white} style={{ fontWeight: 600 }} size='small'>Proceed to payment</Text>
              </SecondaryOutlinedButton2>
              {error &&
          <div className={classes.errorDiv}>
            <img className={classes.errorICon} src={errorIcon} />
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{error.response.data.error.message}</Text>
          </div>
              }
            </div> }
        </div>
      </div>
      <XEROModal
        isVisible={isXero}
        cancel={handleCloseXero} 
      />
      <AddOnBoughtModal
        isVisible={isModalOpen}
        cancel={handleCloseModal}
        isManualFile={state?.name === 'Manual file integration'}
        onSuccess={() => getUpdatedUser()}
      />
    </div>
  )
}
export default StoreProductDetails