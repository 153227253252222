import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const stepperModalStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: colors.white,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    padding: 20,
    paddingLeft: 50,
    paddingRight: 50,
    outline: 'none',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 320,
      paddingLeft: 20,
      paddingRight: 20
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 550,
    },
  },
  backdrop: {
    backgroundColor: colors.primaryRGBA(0.5),
  },
  noBlur: {
    backgroundColor: colors.primaryRGBA(0.5),
  },
  firstTitle: {
    fontSize: 25,
    color: colors.accent,
    marginBottom: 22,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textAlign: 'center'
  },
  helperMessage: {
    fontSize: 15,
    marginBottom: 22,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    textAlign: 'center'
  },
  lastItemDescription: { 
    position: 'absolute', 
    marginBottom: '-70px', 
    marginLeft: '-60px',
  },
  itemDescription: {
    position: 'absolute', 
    marginBottom: '-70px'
  },
  line: {
    display: 'flex', 
    flex: 1, 
    alignItems: 'center'
  },
  flex: {
    display: 'flex', 
  },
  divider: {
    height: 1,
    backgroundColor: '#E6E6E6', 
    width: '100%',
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center'
  }
}))

export default stepperModalStyles