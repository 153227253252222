import React, { useState, useEffect } from 'react'
import { Text, TextInput } from '../../../Components'
import useStyles from './ExplanationModal.style'
import { SecondaryOutlinedButton3 } from '../../../Components/Button/Button'
import { AmazingIcon, BadIcon, FeedbackAILogo, OkayIcon } from '../../../Themes/Images'
import { IconButton, Modal } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import Modal1 from '../../../Components/Modal/Modal'
import SucessModal from '../../../Components/SuccessModal/SuccessModal'
import { Info } from '../../../Themes/Images'
import { useChat } from '../../../Global/Chat'
import MarkdownViewer from '../../../Components/MarkdownToHTML'
import TypingEffect from '../../../Components/TypingEffect'


function ExplanationModal({ isOpen, onCancel, questionId }) {
    // console.log('🚀 ~ ExplanationModal ~ questionId:', questionId)
    const classes = useStyles()
    const [value, setValue] = useState('')
    const [isDisabled, setDisabled] = useState(true)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState()
    const [isLoading, setLoading] = useState(false)


    const { explainQuestion, explanation } = useChat()

    useEffect(() => {
        if (questionId) {
            explainQuestion(questionId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const LoadingAnimation = ({ classes }) => {
        const { loadingWord } = useChat()
        return (
            <div className={classes.loading}>
                <div className={classes.receivedMessageTyping}>
                    <TypingEffect word={loadingWord} />
                </div>
            </div>
        )
    }

    const handleClose = () => {
        setSuccess(false)
        setValue('')
        setError()
        setDisabled(true)
        setLoading(false)
        onCancel()
    }

    const explainModal = (
        <div className={classes.ModalWrapper}>
            <div className={classes.header}>
                <div className={classes.detailsHeader}>
                    <Info />
                    <Text size='subnote'>Details</Text>
                </div>
                <IconButton
                    disableFocusRipple
                    centerRipple={true}
                    disableRipple={true}
                    style={{ backgroundColor: 'transparent', padding: 0 }}
                    classes={{ root: classes.iconButtonClose }}
                    onClick={handleClose}
                >
                    <ClearIcon classes={{ root: classes.iconButtonClose }} />
                </IconButton>
            </div>
            <div className={classes.firstPart}>
                {explanation === '' ? <LoadingAnimation classes={classes} /> : <MarkdownViewer markdownText={explanation} textKey={'explanation'} />}
                {/* 
            <div style={{ height: 25 }}></div> */}
            </div>
        </div>
    )
    return (
        <Modal1 opened={isOpen} onClose={handleClose} type="noBlur" costumizedPaperStyles={success ? classes.paperCostum2 : classes.paperCostum} variant='explanation'>
            {success ? <SucessModal isSmart={false} onCancel={onCancel} /> :
                explainModal}

        </Modal1>
    )
}

export default ExplanationModal
