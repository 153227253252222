import React, { useState } from 'react'
import { Modal, Text, TextInput } from '..'
import { colors } from '../../Themes'
import { Active, TrialEnd, locked } from '../../Themes/Images'
import { SecondaryOutlinedButton } from '../Button/Button'
import { useAuth } from '../../Global'
import { Axios } from '../../Config'
import { IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import useStyles from './InactiveToActiveModal.styles'

function InactiveToActiveModal({ isVisible }) {
  const classes = useStyles()
  const { getUpdatedUser } = useAuth()
  const [isLoading, setIsloading] = useState(false)


  const onSubmit= async ()=>{
    setIsloading(true)
    try {
      await Axios.post('prod/user/reactivate',
      )
      await getUpdatedUser()
      setIsloading(false)
    } catch (error) {
      setIsloading(false)
    }
  }

  return (
    <Modal costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <div className={classes.subModal}>
          <div className={classes.text}>
            <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >Welcome back!</Text>
          </div>
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.iconButtonClose }}
            onClick={() => onSubmit()}
          >
            <ClearIcon classes={{ root: classes.closeIcon }} />
          </IconButton>
        </div>
        <div style={{ height: 1, backgroundColor: '#EBEBEB', width: '100%', display: 'flex', marginBottom: 25  }} />
        <Active />
        <Text size='subnote' color={colors.primary} className={classes.spaceText}>You are now active. We are updating your data, 
        but since it has passed some time since your last login, it may take up to 2 hours to have the dashboard fully updated.</Text>
      </div>
      <div className={classes.ModalWrapper}>
        <div style={{ height: 1, backgroundColor: '#EBEBEB', width: '100%', display: 'flex', marginTop: 5, marginBottom: 25  }} />
        <div className={classes.buttonsWrapper}>
          <SecondaryOutlinedButton
            onClick={()=>onSubmit()}
            className={classes.secondButton}
            loading={isLoading}
            style={{ backgroundColor: isLoading ? colors.disabled : colors.primary, border: isLoading ? colors.disabled : colors.primary }}
          >
         Explore Dataplayer
          </SecondaryOutlinedButton>
        </div>
      </div>
    </Modal>
  )
}

export default InactiveToActiveModal