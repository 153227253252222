import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'
import { border, fontSize } from '@material-ui/system'

const useStylesTextInput = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'stretch',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '30ch',
  },
  input: {
    flexGrow: 1,
    borderWidth: 10,
    borderColor: colors.accent,
    // minWidth: 280,
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
}))

const textInputStyles = {
root: {
    borderRadius: 5,
    color: colors.primary,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
    // [theme.breakpoints.down(760)]: {
    //   fontSize: 14
    // },
    backgroundColor: colors.background,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    },
    '&$focused': {
      backgroundColor: colors.background,
    },
    '&$disabled': {
      color: colors.primaryRGBA(0.5),
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 16,
      backgroundColor: colors.background,
      border: 'none'
    },
    '& .MuiFilledInput-inputHiddenLabel': {
      paddingTop: '19px !important'
    },
  },
  underline: {
    '&:after': {
      border: '1px solid ' + colors.primary,
      top: 0,
      borderRadius: 5,
      transform: 'scaleX(1)',
      opacity: 0,
    },
    '&$focused:after': {
      transform: 'scaleX(1)',
      opacity: 1,
    },
    '&:before': {
      borderWidth: 0,
    },
    '&:hover:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
    '&$disabled:before': {
      borderWidth: 0,
      pointerEvents: 'none',
      border: 'nonw'
    },
  },
  focused: {},
  disabled: {},
}

const textInputStyles2 = {
  root: {
    borderRadius: 5,
    color: colors.primary,
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 16,
    backgroundColor: colors.white,
    border: '1px solid #EAEAEA ',
    height: 50,
    '& .MuiFilledInput-inputHiddenLabel': {
      paddingTop: '22px !important'
    },
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&$focused': {
      backgroundColor: colors.white,
    },
    '&$disabled': {
      color: colors.primaryRGBA(0.5),
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 16,
      backgroundColor: colors.background,
      border: 'none'
    },
    // '& .MuiInputLabel-filled': {
    //   zIndex: 1,
    //   transform: 'translate(12px, 16px) scale(1)',
    //   pointerEvents: 'none',
    // }
  },

  underline: {
    '&:after': {
      border: '1px solid #EAEAEA ',
      top: 0,
      borderRadius: 5,
      transform: 'scaleX(1)',
      opacity: 0,
    },
    '&$focused:after': {
      transform: 'scaleX(1)',
      opacity: 1,
    },
    '&:before': {
      borderWidth: 0,
    },
    '&:hover:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
    '&$disabled:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
  },
  focused: {},
  disabled: {},
}

export { useStylesTextInput, textInputStyles, textInputStyles2 }