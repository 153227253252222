import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'




const useCStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  listRoot: {
    marginTop: 25,
    textAlign: 'center'
  },
  listRootFile: {
    marginTop: 25,
    textAlign: 'center',
    width: '100%'
  },
  listItemRoot: {
    border: '1px solid' + colors.gray,
    borderRadius: 10,
    justifyContent: 'space-between',
    position: 'relative',
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: 12
  },
  paidBadge: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '1px 12px',
    backgroundColor: colors.green,
    color: colors.white,
    fontSize: 14,
    fontFamily: 'Roboto',
    borderBottomLeftRadius: 10,
    borderTopRightRadius: 10
  
  },
  notPaidBadge: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '1px 12px',
    backgroundColor: '#818181',
    color: colors.white,
    fontSize: 14,
    fontFamily: 'Roboto',
    borderBottomLeftRadius: 10,
    borderTopRightRadius: 10
  },
  firstWrapper: {
    flex: '0 0 50%',
    display: 'flex',
    flexDirection: 'column'
  },
  secondWrapper: {
    flex: '0 0 40%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  secondWrapperFile: {
    flex: '0 0 40%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    gap: 5,
    flexDirection: 'column'
  },
  iconButton: {
    paddingRight: 0
  },
  copyIcon: {
    width: 20,
    height: 20
  },
  space: {
    marginBottom: 6
  },
  paymendId: {
    color: colors.accent,
    fontSize: 16,
    fontWeight: 400,
  },
  margin: {
    marginTop: 32 
  }
}))

export { useCStyles, useStyles }