import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const StepBeforeDowngradeModalStyle = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 25px',
      maxWidth: 320,
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: 10,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    width: '250px',
    backgroundColor: colors.white,
    color: colors.primary,
    border: '1px solid #cccccc',
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondButton: {
    width: '250px',
    backgroundColor: colors.primary,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primaryHover,
      color: colors.white,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  border: {
    borderTop: '1px solid #EBEBEB',
    marginBottom: 30,
    width: '100%'
  },
  border2: {
    borderTop: '1px solid #EBEBEB',
    marginBottom: 30,
    marginTop: 10,
    width: '100%'
  },
  text2: {
    textAlign: 'center',
    marginBottom: 30,
    // paddingInline: 50
  },
  text3: {
    textAlign: 'center',
    marginBottom: 30
  },
  text4: {
    textAlign: 'center',
    marginBottom: 10,
    lineHeight: 1.4,
    // paddingInline: 50
  },
  iconButtonClose: {
    position: 'absolute',
    right: 0,
    top: -5,
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
}))

export default StepBeforeDowngradeModalStyle