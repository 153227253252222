import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  arrow: {
    backgroundColor: colors.secondary, 
    borderRadius: '50%',
    justifyContent: 'center', 
    alignItems: 'center',
    padding: 0
  }
}))

export default useStyles