/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React from 'react'
import { Text } from '..'
import { IconButton } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import { email } from '../../Themes/Images'
import successModalStyles from './SuccessModal.styles'

function SucessModal({ onCancel, isSmart }) {
  const classes = successModalStyles()

  return (
    <div className={classes.ModalWrapper}>
      <div className={classes.subModal}>
        <img src={email} alt='email' height="80px" width='120px' className={classes.img} />
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => onCancel()}
        >
          <ClearIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
      </div>
      <div className={isSmart ? classes.text2 : classes.text}>
        <Text size='h4' color={colors.accent} weight='bold' className={classes.spaceText2}>{isSmart ? 'Thank you!' : 'Your request was sent successfully!'}</Text>
      </div>
      <div className={classes.text}>
        <Text size={isSmart ? 'subnote' : 'h6'} color={colors.primary} weight={isSmart ? 'regular' : 'bold'} className={classes.spaceText2}>{isSmart ? 'We will reach out soon with details on the next steps.' : 'Our support team will reach out soon.'}</Text>
      </div>
    </div>
  )
}

export default SucessModal