/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Amplify from 'aws-amplify'
import { amplifyConfig } from './Config'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Wrapper, Login, ForgotPassword, NotFound, Signup, WrapperSecondary } from './Screens'
import { Loader } from './Components'
import { AuthProvider, useAuth, MenuProvider, SubscriptionProvider } from './Global'
import { themesOptions } from './Themes'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './App.css'
import 'react-datepicker/dist/react-datepicker.css'
import Layout from './Components/Layout'
import ReferalPopUp from './Components/ReferalCard'
import Verify from './Screens/VerifyCode/VerifyCode'
import { ChatProvider } from './Global/Chat'
import ChatHistoryProvider from './Global/ChatHistoryMenu'
import ForcedNewPassword from './Screens/NewPassword/ForcedNewPassword'
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
    }
  }
})
Amplify.configure(amplifyConfig)

// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       main: '#98235C',
//     },
//     secondary: {
//       main: '#EC4252',
//     },
//   },
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 760,
//       md: 960,
//       lg: 1280,
//       xl: 1920,
//     },
//   },
//   overrides: {
//     MuiInputBase: {
//       input: {
//         '&:-webkit-autofill': {
//           transitionDelay: '9999s',
//           transitionProperty: 'background-color, color',
//         },
//       },
//     },
//   },
// })

const theme = createTheme(themesOptions)


function UnAuthenticatedContent() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/verify" component={Verify} />
      <Route exact path="/reset-password" component={ForgotPassword} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/change-password" component={ForcedNewPassword}/>

      <Redirect
        to="/login"
        path={['/dashboard', '/', '/account', '/settings']}
      />
      <Route component={NotFound} />
    </Switch>
  )
}

function AppContent() {
  const { isLoading, isAuthenticated, roles } = useAuth()

  if (isLoading) {
    return <Loader />
  }

  if (isAuthenticated && roles.includes('secondary')) {
    return (
      <MenuProvider>
        <ChatHistoryProvider>
        <SubscriptionProvider>
          <Layout />
          <WrapperSecondary />
        </SubscriptionProvider>
        </ChatHistoryProvider>
      </MenuProvider>
    )
  }

  if (isAuthenticated) {
   
    return (
      <MenuProvider>
        <ChatHistoryProvider>
        <SubscriptionProvider>
          <Layout />
          <Wrapper />
          {/* {opened === true && <ReferalPopUp cancel={close} />} */}
        </SubscriptionProvider>
        </ChatHistoryProvider>
      </MenuProvider>
    )
  }

  return <UnAuthenticatedContent />
}

function App() {
  // window.addEventListener('appinstalled', () => {
  //   console.log('Thank you for installing our app!')
  // })
  
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ChatProvider>
        <ThemeProvider theme={theme}>
          
          <Router  >
            
            <Toaster
              style={{
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.16)'
              }}
              containerStyle={{
                bottom: 70,
                right: 20,
              }}
              position="bottom-right"
            />
            
            
            <AppContent />
          </Router>
        </ThemeProvider>
        </ChatProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
