import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background,
    borderRadius: 5,
    margin: 10,
    textTransform: 'none',
    color: colors.primary,
    height: 190,
    minWidth: 190,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    },
  },
  icon: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  text: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    marginBottom: 0,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
  },
  desc: {
    textAlign: 'center',
    padding: 10,
    color: colors.primary,
  }
}))


const useSettingItemStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background,
    borderRadius: 5,
    margin: '6px 0',
    padding: 10,
    textTransform: 'none',
    color: colors.primary,
    maxWidth: 600,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    },
  },
  icon: {
    width: 40,
    height: 40,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginRight: 15
    },
  },
  img: {
    maxWidth: ' 100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  text: {
    display: 'flex',
    flex: 6,
    flexDirection: 'column'
  },
  title: {
    marginBottom: 0,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
  },
  desc: {
    padding: '10px 0 0',
    color: colors.primary,
  }
}))

export { useStyles, useSettingItemStyles }