import { colors } from '../../Themes'

const styles = {
  root: {
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.primary,
    borderRadius: 5,
    minHeight: 50,
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
  },
}
  
const secondStyles = {
  root: {
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.accent,
    borderRadius: 5,
    minHeight: 50,
    '&:hover': {
      backgroundColor: colors.accentHover,
    },
  },
}
  
const outlinedStyles = {
  root: {
    textTransform: 'none',
    color: colors.primary,
    backgroundColor: colors.background,
    borderWidth: 1,
    borderColor: colors.accent,
    borderRadius: 5,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    },
  },
}
  
const pressableStyles = {
  root: {
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.primary,
    borderRadius: 5,
    minHeight: 50,
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
  },
}

const secondaryOutlinedStyles = {
  root: {
    textTransform: 'none',
    color: colors.primary,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    },
  },
}
  
const secondaryOutlinedStyles2 = {
  root: {
    textTransform: 'none',
    color: colors.primary,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    },
  },
}
const secondaryOutlinedStyles3 = {
  root: {
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.purple,
    borderWidth: 1,
    borderColor: colors.purple,
    borderRadius: 5,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.primary,
      borderColor: colors.primary,
    },
  },
}
  
const secondaryOutlinedStyles4 = {
  root: {
    textTransform: 'none',
    color: colors.primary,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.white,
    borderRadius: 5,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.grayish,
      color: colors.primary,
    },
  },
}
  
const secondaryOutlinedStyles5 = {
  root: {
    textTransform: 'none',
    color: colors.primary,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.white,
    borderRadius: 5,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.grayish,
      color: colors.primary,
    },
  },
}


export { styles, secondStyles, outlinedStyles, pressableStyles, secondaryOutlinedStyles, secondaryOutlinedStyles2, secondaryOutlinedStyles3,
  secondaryOutlinedStyles4, secondaryOutlinedStyles5 }