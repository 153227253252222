import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex', 
    flexDirection: 'column', 
    width: '100%'
  },
  line: {
    border: `1px solid ${colors.borderGray}`,
    marginBottom: 30,
    marginTop: 30
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  chooseDateText: {
    marginTop: 10,
    marginBottom: 10
  },
  cardsWrapper: { 
    display: 'flex', 
    flexWrap: 'wrap', 
    gap: 16 
  },
  loaderWrapper: {
    display: 'flex',
    height: '30vh',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export default useStyles