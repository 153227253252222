import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    margin: 0,
  },
  image: {
    margin: 'auto',
    width: '70%',
    height: '70%',
  },
  cardsContainer: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    flexShrink: 0,
  },
  cards: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  subHeader: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  subHeader1: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 13
  },
  successDiv: {
    display: 'flex', 
    height: 60, 
    borderRadius: 5, 
    width: '100%'
  },
  infoDiv: {
    minWidth: 60,
    background: colors.primary, 
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderTopRightRadius: 0, 
    borderBottomRightRadius: 0
  },
  inReviewText: {
    display: 'flex',
    justifyContent: 'flex-start', 
    alignItems: 'center', 
    marginLeft: 20, 
    width: '95%'
  },
  requestChange: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1215)]: {
      flexDirection: 'column',
      gap: 20
    },
    [theme.breakpoints.down(550)]: {
      flexDirection: 'column',
      gap: 20
    },
  },
  refresh: {
    display: 'flex',
    gap: 22,
  },
  dateAndRefresh: { 
    display: 'flex', 
    justifyContent: 'center',
    gap: 22,
    [theme.breakpoints.down(1215)]: {
      justifyContent: 'start',
    },
  },
  refreshButton: { 
    display: 'flex',
    gap: 10,
    alignItems: 'center', 
    cursor: 'pointer' 
  },
  requestChangeButton: {
    width: 220,
    [theme.breakpoints.between(761, 1150)]: {
      width: 220
    },
    [theme.breakpoints.down(550)]: {
      width: '100%'
    },
  },
  smallWrapper: {
    display: 'flex', 
    flexDirection: 'column',
    gap: 10, 
    width: '100%'
  }
}))

export default useStyles