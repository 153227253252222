import React, { useState, useEffect } from 'react'
import Text from '../Text'

const TypingEffect = ({ word }) => {
  const [dots, setDots] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(dots => {
        switch (dots) {
        case '':
          return '.'
        case '.':
          return '..'
        case '..':
          return '...'
        default:
          return ''
        }
      })
    }, 500) // Change the interval duration as needed

    return () => clearInterval(interval)
  }, [])

  return (
    <Text size='footnote'>
     {word} {dots}
    </Text>
  )
}

export default TypingEffect