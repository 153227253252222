import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 25px',
      maxWidth: 320,
    },
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0 0',
    width: '100%',
    gap: 10,
     
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      // marginBottom: 0,
    },
  },
  firstButton: {
    backgroundColor: colors.primary,
    fontSize: 14,
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
    '&$focused': {
      backgroundColor: colors.primaryHover,
    },
    color: colors.white,
    flex: 1,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginRight: 7.5,
      marginLeft: 30,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  secondButton: {
    fontSize: 14,
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white
    },
    '&$focused': {
      backgroundColor: colors.grayHover,
      color: colors.white
    },
    color: colors.primary,
    flex: 1,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('sm')]: {
      // marginLeft: 7.5,
      marginTop: 0
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      minWidth: 550,
    },
  },
  formControlLabel: {
    color: colors.primary,
  },
  iconButtonClose: {
    position: 'absolute',
    right: 0,
    top: -15
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  divider: {
    display: 'flex',
    height: 1,
    width: '100%', 
    backgroundColor: '#E6E6E6'
  },
  dividerFirst: {
    display: 'flex',
    height: 1,
    width: '100%', 
    backgroundColor: '#CECECE',
    marginBottom: 25,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    padding: '32px',
    backgroundColor: colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '32px 15px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px',
    },
  },
  containerFirst: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    padding: '32px 14% 0%',
    backgroundColor: '#F4F4F4',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 15px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px',
    },
  },
  close: {
    display: 'flex', 
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 15,
    [theme.breakpoints.down(760)]: {
      alignSelf: 'flex-start',
      position: 'sticky',
      top: 90,
      zIndex: 1300,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '0 0 26px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  headerFirst: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '0 0 31px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  back: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    color: colors.primary,
    marginLeft: 5
  },
  headerText: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.accent,
    [theme.breakpoints.down(760)]: {
      marginTop: 20
    },
  },
  iconButtonCloseThird: {
    position: 'absolute',
    left: 5,
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
      position: 'relative',
      left: 0,
      marginBottom: 15
    },
  },
  line: {
    borderBottomColor: ' #C9C9C9',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1
  },
  verifyWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    paddingTop: 120,
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      paddingTop: 48,
    },
  },
  animationContainer: {
    flex: 1,
    display: 'flex',
    marginTop: -100,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cardContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardRoot: {
    minWidth: 480,
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      minWidth: '80%',
    },
  },
  cardTitle: {
    padding: '15px 32px ',
    backgroundColor: colors.primary
  },
  MuiCardContentRoot: {
    padding: '48px 32px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 16px',
    },
    '&:last-child': {
      paddingBottom: 48
    }
  },
  continueButton: {
    maxWidth: '45%',
    marginTop: 17,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  closeFirst: {
    display: 'flex', 
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 15,
    [theme.breakpoints.down(760)]: {
      alignSelf: 'flex-start',
      position: 'sticky',
      top: 90,
      zIndex: 1300,
    },
  },
  backFirst: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    color: colors.primary,
    marginLeft: 5
  },
  headerTextFirst: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.accent,
    [theme.breakpoints.down(760)]: {
      marginTop: 20
    },
  },
  iconButtonCloseFirst: {
    position: 'absolute',
    left: 5,
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
      position: 'relative',
      left: 0,
      marginBottom: 15
    },
  },
  deleteAccountDescription: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    padding: '24px 40px',
    backgroundColor: colors.white,
    borderRadius: 5,
   
  },
  deleteReasons: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginBottom: '5px',
  },
  svg: {
    display: 'flex',
    flex: 1,
    maxWidth: 300,
    [theme.breakpoints.down(1400)]: {
      display: 'none'
    },
  },
  innerContainer: {
    display: 'flex', 
    flex: 1,  
    justifyContent: 'space-between'
  }
}))

export default useStyles