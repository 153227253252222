/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import './AnnualDatePicker.css'
import { Calendar } from '../../Themes/Images'
import { Text } from '..'
import { useStyles, useSimpleDatePickerStyles } from './AnnualDatePicker.styles'

function AnnualDateRangePicker({ startDate, setStartDate, endDate, setEndDate, callBack, isMobile, isAnnual, disabled }) {
  const classes = useStyles()

  const CostumStartInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className='firstInputContainerDatePickerAnnual' onClick={onClick} ref={ref}>
        <Text size="footnote" color='grey'>
          {value}
        </Text>
        <Calendar className='calendarIcon'  />
      </div>
    ),
  )

  const CostumEndInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className={classes.datePickerEndInputContainer}  onClick={onClick} ref={ref}>
        <Text size="footnote" color='grey'>
          {value}
        </Text>
      </div>
    ),
  )

  const renderDatePicker = () => {
    if (isMobile) {

      return (
        <>
          <div className="firstInput">
            <DatePicker
              customInput={<CostumStartInput />}
              selected={startDate}
              dateFormat="dd-MM-yyyy"
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onCalendarClose={() => callBack()}
              withPortal={isMobile}
              className={classes.datePickerStartInputContainer}
            />
          </div>
          {isAnnual && <div style={{ width: 20, backgroundColor: 'white' }}></div>}
          <div className="firstInput">
            <DatePicker
              customInput={<CostumEndInput />}
              selected={endDate}
              dateFormat="dd-MM-yyyy"
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              onCalendarClose={() => callBack()}
              withPortal={isMobile}
              className={classes.datePickerEndInputContainer}
              disabled={disabled}
            />
          </div>
        </>
      )
    } 
    
    return (
      <>
        <div className="firstInput">
          <DatePicker
            selected={startDate}
            dateFormat="dd-MM-yyyy"
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onCalendarClose={() => callBack()}
            className={classes.datePickerStartInputContainer}
          />
        </div>
        {isAnnual && <div style={{ width: 20, backgroundColor: 'white' }}></div>}
        <div className="firstInput">
          <DatePicker
            selected={endDate}
            dateFormat="dd-MM-yyyy"
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className={classes.datePickerEndInputContainer}
            onCalendarClose={() => callBack()}
            disabled={disabled}
          />
        </div>
        <Calendar className={classes.calendarIcon} />
      </>
    )
  }

  return (
    <div className={classes.datePickerWrapper} >
      {renderDatePicker()}
    </div>
  )
}

AnnualDateRangePicker.defaultProps = {
  isMobile: false
}

function SimpleDatePicker({ date, setDate }){
  const classes=useSimpleDatePickerStyles()

  return (
    <div className={classes.datePickerWrapper} >
      <DatePicker
        selected={date}
        dateFormat="dd-MM-yyyy"
        onChange={date => setDate(date)}
        selectsStart
        startDate={date}
        className={classes.datePickerInputContainer}
      />
      <Calendar className={classes.calendarIcon} />
    </div>

  )
}

SimpleDatePicker.defaultProps = {
}

export default AnnualDateRangePicker
export { SimpleDatePicker }