/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react'
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core'
import { colors } from '../../Themes'
import { PrimaryButton } from '../Button/Button'
import { useAuth, useSubscription } from '../../Global'
import useStyles from './PriceCards.styles'
import Text from '../Text'
import { Free, Giga, Mega, Tera } from '../../Themes/Images'
import useCancellationDate from '../../Utils/CancellationDate'
let classNames = require('classnames')

const CARDS_ITEMS = {
  Mega: {
    items: ['Last 25 months plus ongoing data', 'One admin user'],
    desc: ['For single-site practices'],
    price1: ['49'],
    price2: ['471'],
    priceAUDMonth: ['75'],
    priceAUDYear: ['720'],
    priceNZDMonth: ['81'],
    priceNZDYear: ['778'],
    priceEURMonth: ['45'],
    priceEURYear: ['432'],
    priceGBPMonth: ['39'],
    priceGBPYear: ['375'],
    img: <Mega />,
  },
  Giga: {
    items: ['Last 37 months plus ongoing data', 'One admin user'],
    desc: ['For multiple-site practices'],
    price1: ['99'],
    price2: ['951'],
    priceAUDMonth: ['151'],
    priceAUDYear: ['1450'],
    priceNZDMonth: ['163'],
    priceNZDYear: ['1565'],
    priceEURMonth: ['91'],
    priceEURYear: ['874'],
    priceGBPMonth: ['78'],
    priceGBPYear: ['749'],
    img: <Giga />,
  }
}

function FreeCard({ value, className, classNameCardContent, signValue, onSelect, loading, plans }) {
  const classes = useStyles()
  const { data_loading, is_trialing } = useAuth()
  const { plan } = useSubscription()

  const RenderHeader = () => {

    return (
      <div className={classes.cardHeaderCostum}>
        <div className={classes.topImg3}>
          <div className={classes.imgInside3}>
            <Free />
          </div>
        </div>
        <Text size='h5' weight='bold' className={classes.planName} >Free</Text>
      </div>
    )
  }

  return (
    <>
      <Card elevation={0} className={classNames(classes.root, className)}>
        <CardHeader
          component={RenderHeader}
        />
        <CardContent className={classNames(classes.cardContent, classNameCardContent)}>
          <div className={classes.priceFree}>
            <Text size='h5' weight='bold' className={classes.price} >{signValue === undefined ? '$' : signValue}0</Text>
            {value === 0 ?
              <Text size='footnote' className={classes.type}>/month</Text>
              :
              <Text size='footnote' className={classes.type}>/year</Text>}
          </div>
        </CardContent>
        {/* {plan?.length > 0 && 
        <CardActions className={classes.teraCard} disableSpacing>
          
          <PrimaryButton
            style={{ backgroundColor: (data_loading || loading) && colors.disabled, border: (data_loading || loading) && `1px solid ${colors.disabled}` }}
            classes={{ root: classes.contactUsButton }}
            disabled={data_loading}
            loading={loading}
            onClick={onSelect}
          >
            {plans === 'Mega' || plans === 'Giga' ? 'Switch' : 'Upgrade'}
          
          </PrimaryButton>
        </CardActions>
        } */}

        
      </Card>
    </>
  )
}

function CurrentCard({ id, value, className, classNameCardContent, onSelect, loading, plans }) {
  const classes = useStyles()
  const { data_loading, cancellationEffectiveDate } = useAuth()
  const { plan } = useSubscription()

  const { day, month, year } = useCancellationDate(cancellationEffectiveDate)

  const RenderHeader = () => {

    return (
      <div className={classes.cardHeaderCostum}>
        <div className={classes.topImg4}>
          <div className={classes.imgInside4}>
            {CARDS_ITEMS[plan?.[0].name]?.img}
          </div>
        </div>
        <div className={classes.currentPlanName}>
          <Text size='h5' weight='bold' className={classes.planName} >{plan?.[0]?.name}</Text>
        </div>
        {CARDS_ITEMS[plan?.[0].name]?.desc.map((item, index) => {
          return <Text size='small' color='#5C5C61'key={index} className={classes.text2}>{item}</Text>
        })}
      </div>
    )
  }

  return (
    <>
      <Card elevation={0} className={classNames(classes.rootOffer, className)}>
        <CardHeader
          component={RenderHeader}
        />
        <CardContent className={classNames(classes.cardContent2, classNameCardContent)}>
          <div className={classes.active}>
            <Text size='subnote' weight='medium' color={colors.accent}>Your plan</Text>
          </div>
          
        </CardContent>
        <CardActions className={classes.activeSection} disableSpacing>
          {cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined ? 
            <Text style={{ textAlign: 'center' }} size='footnote'>You will switch to the Free plan on <Text size='footnote' weight='medium'>{day} {month} {year}.</Text>
            </Text>
            : 
            <PrimaryButton
              style={{ backgroundColor: (data_loading || loading) && colors.disabled, border: (data_loading || loading) && `1px solid ${colors.disabled}` }}
              classes={{ root: classes.activeButton }}
              disabled={data_loading}
              loading={loading}
              onClick={onSelect}
            >
            Cancel subscription
          
            </PrimaryButton>
          }
         
        </CardActions>
      </Card>
    </>
  )
}

function TeraCard({ className, classNameCardContent }) {
  const classes = useStyles()

  const RenderHeader = () => {

    return (
      <div className={classes.cardHeaderCostum}>
        <div className={classes.topImg}>
          <div className={classes.imgInsideTera}>
            <Tera />
          </div>
        </div>
        <Text size='h5' weight='bold' className={classes.planName} >Tera</Text>
      </div>
    )
  }

  return (
    <>
      <Card elevation={0} className={classNames(classes.root, className)}>
        <CardHeader
          component={RenderHeader}
        />
        <CardContent className={classNames(classes.cardContent, classNameCardContent)}>
          <Text size='h5' weight='bold' className={classes.price} >Custom</Text>
        </CardContent>
        <CardActions className={classes.teraCard} disableSpacing>
          <PrimaryButton
            classes={{ root: classes.contactUsButton }}
            onClick={() => {
              let d = document
              let s = d.createElement('script')
              s.type = 'text/javascript'
              s.id = 'zsiqscript'
              s.defer = true
              s.src = 'https://salesiq.zoho.eu/widget'
              let t = d.getElementById('zohoChat')
              t.parentNode.insertBefore(s, t)
            }}
          >
            Contact Us
          </PrimaryButton>
        </CardActions>
      </Card>
    </>
  )
}

function DynamicCard({ recurring_price, name, value, onSelect, signValue, priceId }) {
  const [offer] = useState(name === 'Giga')
  const classes = useStyles()
  const { data_loading, cancellationEffectiveDate } = useAuth()
  const { plan } = useSubscription()

  const RenderHeader = () => {

    return (
      <div className={classes.cardHeaderCostum}>
        <div className={classes.topImg}>
          <div className={classes.imgInside2}>
            {CARDS_ITEMS[name]?.img}
          </div>
        </div>
        <div className={classes.currentPlanName}>
          <p className={classes.planName} >{name}</p>
        </div>
        {CARDS_ITEMS[name]?.desc.map((item, index) => {
          return <Text size='small' color='#5C5C61' className={classes.text2} key={index} >{item}</Text>
        })}
      </div>
    )
  }

  return (
    <>
      <Card elevation={0} className={classes.root}>
        <CardHeader
          component={RenderHeader}
        />
        {offer && <div className={classes.bestOffer}>
          <Text size='footnote' color={colors.white} weight='regular'>Best offer</Text> 
        </div> }
        {value === 0 ? <CardContent className={classes.cardContent}>
          {(priceId === 1 || priceId === undefined) && 
            CARDS_ITEMS[name]?.price1.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue === undefined ? '$' : signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/month</Text>
              </div>
            })
          }
          {priceId === 2 && 
            CARDS_ITEMS[name]?.priceAUDMonth.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/month</Text>
              </div>
            })
          }
          {priceId === 4 && 
            CARDS_ITEMS[name]?.priceNZDMonth.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/month</Text>
              </div>
            })
          }
          {priceId === 5 && 
            CARDS_ITEMS[name]?.priceEURMonth.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/month</Text>
              </div>
            })
          }
          {priceId === 3 && 
            CARDS_ITEMS[name]?.priceGBPMonth.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/month</Text>
              </div>
            })
          }
          
        </CardContent> :
          <CardContent className={classes.cardContent}>
            {(priceId === 1 || priceId === undefined) && 
            CARDS_ITEMS[name]?.price2.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue === undefined ? '$' : signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/year</Text>
              </div>
            })
            }
            {priceId === 2 && 
            CARDS_ITEMS[name]?.priceAUDYear.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/year</Text>
              </div>
            })
            }
            {priceId === 4 && 
            CARDS_ITEMS[name]?.priceNZDYear.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/year</Text>
              </div>
            })
            }
            {priceId === 5 && 
            CARDS_ITEMS[name]?.priceEURYear.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/year</Text>
              </div>
            })
            }
            {priceId === 3 && 
            CARDS_ITEMS[name]?.priceGBPYear.map((item, index) => {
              return <div key={index} className={classes.priceFree}>
                <Text size='h5' weight='bold' className={classes.price} >{signValue }{item}</Text>
                <Text size='footnote' className={classes.price} >/year</Text>
              </div>
            })
            }
          </CardContent>}
        <CardActions className={classes.teraCard} disableSpacing>
          <PrimaryButton
            style={{ backgroundColor: data_loading || (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) && colors.background, 
              border: data_loading || (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) && `1px solid ${colors.background}` }}
            classes={{ root: classes.contactUsButton }}
            disabled={data_loading || (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined)}
            onClick={onSelect}
          >
            {/* {buttonContent} */}
            Upgrade
          </PrimaryButton>
        </CardActions>
      </Card>
    </>
  )
}

export { FreeCard, CurrentCard, TeraCard, DynamicCard }