
import { colors } from '../../Themes'
import { makeStyles } from '@material-ui/core/styles'

const badgeStyles = (theme) => ({
  badge: {
    backgroundColor: colors.secondary,
    color: colors.secondary,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: ' 0 8px 0 0 ',
    },
    '& .MuiAvatar-colorDefault': {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },
  large: {
    width: 48,
    height: 48,
    cursor: 'pointer',
    backgroundColor: colors.primary
  },
  large2: {
    width: 55,
    height: 55,
    cursor: 'pointer',
    backgroundColor: colors.primary,
  },
  chat: {
    width: 48,
    height: 48,
    backgroundColor: colors.primary,
    [theme.breakpoints.down(760)]: {
      width: 36,
      height: 36,
    },
  }
}))

export  { badgeStyles, useStyles }