import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const getMoreInfoModalStyles = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    marginTop: 50
  },
  fullNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  fullNameInput: {
    flex: '0 0 48%'
  },
  input_false: {
    marginTop: 8
  },
  input_true: {
    border: '1px solid ' + colors.secondary,
    borderRadius: 5,
    marginTop: 8,
    marginBottom: 5
  },
  numberInput: {
    maxWidth: '100%'
  },
  spaceText: {
    margin: '18px 0 40px 0'
  },
  spaceTittle: {
    marginTop: 16
  },
  errorInput: {
    color: colors.secondary,
    marginBottom: 8
  },
  button: {
    width: '100%',
    marginTop: 32,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cancelButton: {
    flex: '0 0 40%',
    backgroundColor: colors.white,
    color: colors.primary,
    marginTop: 8,
    '&:hover': {
      backgroundColor: colors.grayHover,
    },
  },
}))

export default getMoreInfoModalStyles