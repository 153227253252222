/* eslint-disable react/no-unescaped-entities */
/* eslint-disable indent */
import React from 'react'
import { Card, IconButton } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import CloseIcon from '@material-ui/icons/Close'
import { Text, SecondaryButton } from '..'
import { colors } from '../../Themes'
import useStyles from './CostumerFeedbackInput.styles'

function CostumerFeedbackInput({ onClose, onOpenCostumerFeedbackModal }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <div className={classes.headerWrapper} >
          <Text size="subnote" weight='bold' color={colors.primary} >Help us improve Dataplayer! </Text>
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.closeIcon }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
          <Text size="footnote" weight='regular' color={colors.primary} className={classes.text}>Build a better experience by sharing your feedback.</Text>
     <SecondaryButton
     className={classes.button}
     onClick={onOpenCostumerFeedbackModal}
     >
     Give feedback
     </SecondaryButton>
      </CardContent>
    </Card>
  )
}

export default CostumerFeedbackInput
