import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const ManualFileUploadStyles = makeStyles((theme) => ({
  drop: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px dashed #314155',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '32px',
    gap: 10,
    [theme.breakpoints.down(600)]: {
      padding: '32px 15px',
    },
        
  },
  dropDragging: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px dashed #314155',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '32px',
    gap: 10,
    background: 'rgba(49, 65, 85, 0.05)',
    [theme.breakpoints.down(600)]: {
      padding: '32px 15px',
    },
        
  },
  fileUploaded: {
    width: '100%',
    display: 'flex',
    border: '1px solid #cecece',
    borderRadius: 5,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '20px 32px',
    gap: 10,
    position: 'relative',
    [theme.breakpoints.down(600)]: {
      padding: '20px 15px',
    },
        
  },
  afterDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 32px',
    gap: 10,
    [theme.breakpoints.down(400)]: {
      padding: '20px 15px',
    },
  },
  textUpload: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  text: {
    display: 'flex',
    flexDirection: 'column', 
    textAlign: 'center',
    gap: 10
  },
  done: {
    display: 'flex',
    gap: 10
  },
  buttonDiv: {
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center',
    gap: 10,
    paddingTop: 10,
    [theme.breakpoints.down(600)]: {
      justifyContent: 'center',
      flexDirection: 'column'
    },
  },
  button: {
    width: 220,
 
    [theme.breakpoints.down(550)]: {
      width: '100%'
    },
  },
  cancel: {
    width: 220,
    background: colors.white,
    color: colors.primary,
    border: `1px solid ${colors.primary}`,
    [theme.breakpoints.down(600)]: {
      width: '100%'
    },
    '&:hover': {
      background: colors.white,
      color: colors.primary,
      border: `1px solid ${colors.primary}`,
    },
  },
  errorICon: {
    paddingRight: 10,
  },
  error: {
    display: 'flex',
    alignItems: 'center'
  },
  forMobile: {
    display: 'flex',
    gap: 10, 
    alignItems: 'center',
    [theme.breakpoints.down(760)]: {
      flexDirection: 'column',
      alignItems: 'start',
      gap: 5, 
     
    },
  },
  wrapper: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between',
    width: '100%', 
    alignItems: 'center',
    gap: 20,
    marginBlock: -8,
    [theme.breakpoints.down(760)]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  textWrapper: {
    display: 'flex', 
    flexDirection: 'column',
    gap: 10,
    justifyContent: ' flex-start'
  }
}))

const useStyles = makeStyles((theme) => ({
  root: {
    width: '85%',
    borderRadius: 29, 
    background: '#D9D9D9',
    position: 'absolute',
    bottom: 35,
    left: 85,
    '& .MuiLinearProgress-bar': {
      backgroundColor: 'linear-gradient(90deg, #901F5D 0%, rgba(238, 67, 82, 0.97) 98.67%)'
    },
    [theme.breakpoints.down(1200)]: {
      width: '80%'
    },
    [theme.breakpoints.down(1000)]: {
      width: '70%'
    },
    [theme.breakpoints.down(760)]: {
      width: '80%'
    },
    [theme.breakpoints.down(630)]: {
      width: '75%'
    },
    [theme.breakpoints.down(600)]: {
      width: '75%',
      left: 68,
    },
    [theme.breakpoints.down(500)]: {
      width: '70%'
    },
    [theme.breakpoints.down(400)]: {
      width: '65%'
    },
  },
}))

export  { ManualFileUploadStyles, useStyles }