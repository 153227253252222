import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    backgroundColor: colors.white,
    color: colors.white,
    marginBottom: 48,
    marginInline: 12,
    boxShadow: 'none',
    border: '1px solid ' + colors.gray,
    borderRadius: 5,
    position: 'fixed',
    bottom: 0,
    right: 0
  },
  headerWrapper: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    marginBottom: 8
  },
  cardContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    whiteSpace: 'normal',
  },
  closeIconButton: {
    color: colors.white,
    padding: 0
  },
  linkTo: {
    color: colors.accent,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'left',
    textDecoration: 'underline'
  },
  iconButtonRoot: {
    padding: 0,
    marginTop: 12,
  },
  closeIcon: {
    padding: 0,
    color: colors.primary,
    position: 'absolute',
    right: 0,
  },
  icon: {
    color: colors.primary,
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  labelRoot: {
    justifyContent: 'flex-start'
  },
  button: {
    maxWidth: 180,
    minHeight: 50,
  },
  text: {
    margin: '4px 0 24px'
  }
}))

export default useStyles