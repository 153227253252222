import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const useStylesPass = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    alignSelf: 'stretch',
  },
  root2: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 0,
    alignSelf: 'stretch',
    marginTop: 10
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  input: {
    flexGrow: 1,
    borderWidth: 10,
    borderColor: colors.accent,
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
}))

const inputStylesPass = {
  root: {
    borderRadius: 5,
    color: colors.primary,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 18,
    backgroundColor: colors.background,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    },
    '&$focused': {
      backgroundColor: colors.background,
    },
    '&$disabled': {
      color: colors.primary,
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 18,
      backgroundColor: colors.background,
    },
  },
  underline: {
    '&:after': {
      border: '1px solid ' + colors.primary,
      top: 0,
      borderRadius: 5,
      transform: 'scaleX(1)',
      opacity: 0,
    },
    '&$focused:after': {
      transform: 'scaleX(1)',
      opacity: 1,
    },
    '&:before': {
      borderWidth: 0,
    },
    '&:hover:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
    '&$disabled:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
  },
  focused: {},
  disabled: {},
}

export { useStylesPass, inputStylesPass }