/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { SuccessToast } from '../Notification/Notification'
import { Modal, PrimaryButton, Text, TextInput } from '..'
import { colors } from '../../Themes'
import { Axios } from '../../Config'
import { Divider } from '@material-ui/core'
import CostumerFeedbackRadioButton from '../CostumerFeedbackRadioButton/CostumerFeedbackRadioButton'
import customerModalFeedbackStyles from './CostumerModalFeedback.styles'

function CostumerModalFeedback({ isVisible, cancel }) {
  const classes = customerModalFeedbackStyles()
  const [values, setValues] = useState({
    reason: ''
  })
  const [errors, setErrors] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [value, setValue] = useState('0')

  const handleChangeRadioButtons = (event) => {
    setValue(event.target.value)
  }

  const handleChange = (value, name) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
    delete errors[name]
  }

  const onSubmit= async ()=>{
    setLoading(true)
    try {
      await Axios.post('prod/user/feedback',
        {
          rate: parseInt(value),
          rate_description: values.reason
        }
      )
      setLoading(false)
      setValues({
        reason: ''
      })
      setValue('0')
      cancel()
      toast.custom((t) => (
        <SuccessToast t={t} message='Feedback successfully sent!'/>
      ))
      setErrors({})
    } catch (error) {
      setLoading(false)
    }
  }

  const onCancel=()=>{
    setValues({
      reason: ''
    })
    setValue('0')
    setErrors({})
    cancel()
  }

  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <Text size='h5' color={colors.primary} weight='bold' className={classes.spaceTittle} >Help us improve Dataplayer</Text>
        <Divider light style={{ margin: '32px 0 16px' }} />
        <Text size='subnote' color={colors.primary} className={classes.spaceText}>How likely are you to recommend Dataplayer to a friend or colleague?</Text>
        <CostumerFeedbackRadioButton
          value={value}
          handleChange={handleChangeRadioButtons}
        />
        <Text size='subnote' color={colors.primary} className={classes.spaceText2}>Is there anything else you would like to add?  <span style={{ opacity: 0.5 }}>(optional)</span></Text>
        <TextInput
          name='reason'
          costumizedInputStyles={classes.input}
          label="The more the better"
          value={values.reason}
          setValue={(value) => handleChange(value, 'reason')}
          multiline={true}
          rows={4}
        />
        {errors?.reason &&
                    <p className={classes.errorInput}>{errors?.reason}</p>
        }
        <div className={classes.buttonsWrapper}>
          <PrimaryButton
            className={classes.cancelButton}
            onClick={onCancel}
          > Show this later
          </PrimaryButton>
          <PrimaryButton
            className={classes.nextButton}
            disabled={Boolean( value === '0') ||isLoading}
            loading={isLoading}
            style={{
              backgroundColor: Boolean( value === '0') ||isLoading ? colors.disabled : colors.primary,
              color: colors.white,
            }}
            onClick={onSubmit}
          >
                       Send
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}



export default CostumerModalFeedback
