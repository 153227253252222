/* eslint-disable promise/always-return */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React from 'react'
import { Modal, PrimaryButton, Text } from '..'
import { IconButton } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import { tick } from '../../Themes/Images'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Lottie from 'react-lottie'
import AddOnBoughtModalStyles from './AddOnBoughtModal.styles'

const LOCK_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: tick,
  rendererSettings: {
    className: 'loginAnim',
  },
}

function AddOnBoughtModal({ isVisible, cancel, isManualFile, onSuccess }) {
  const classes = AddOnBoughtModalStyles()
  const history = useHistory()

  const redirectToInfo = () => {
    history.push({ pathname: '/settings/payment-information', 
      state: {
        selectedTab: 1
      } })
  }

  const redirectToFileUpload = () => {
    history.push('/settings/uploadFile')
    onSuccess()
  }

  const onCancel = () => {
    cancel()
  }
  
  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => onCancel()}
        >
          <ClearIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
        <div className={classes.text3}>
          <Lottie options={LOCK_OPTIONS} height={200} width={400} />
        </div>
        <div className={classes.text2}>
          <Text size='h3' color={colors.accent} weight='bold'>Add-on was succesfully purchased!</Text>
        </div>
        {!isManualFile && 
         <div className={classes.text2}>
           <Text size='subnote' color={colors.primary}>You can check the payment details here</Text>
         </div>
        }
        <div className={isManualFile ? classes.buttonsWrapperFile : classes.buttonsWrapper}>
          <PrimaryButton
            className={isManualFile ? classes.fileButton : classes.nextButton}
            onClick={() => redirectToInfo()}
          >
            Payment details
          </PrimaryButton>
          {isManualFile && 
           <PrimaryButton
             className={classes.nextButton}
             onClick={() => redirectToFileUpload()}
           >
           Upload file
           </PrimaryButton>
          }
        </div>
      </div>
    </Modal>
  )
}

export default AddOnBoughtModal  
