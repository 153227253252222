import React from 'react'
import { PrimaryButton, Text } from '..'
import { ManualFileUploadStyles, useStyles } from './ManualFileUploadHeader.styles'
import { UploadManual } from '../../Themes/Images'
import { colors } from '../../Themes'
import { useAuth } from '../../Global'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

const ManualFileHeader = () => {
  const classes = ManualFileUploadStyles()

  const {  setIsModalOpen, activeFileTypesCount, userUploadedFileTypeCount, userUploadedFileType, fileUploadActive } = useAuth()

  const hasReview = userUploadedFileType.find((user) => user?.status === 'IN REVIEW')

  const isDisabled = activeFileTypesCount === userUploadedFileTypeCount

  if (!fileUploadActive || fileUploadActive === null || fileUploadActive === undefined ) {
    return <Redirect push to="/dashboard"/>
  }


  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.textWrapper}>
          <Text size='subnote' weight='bold'>Upload your file</Text>
          <Text size='footnote' weight='regular'>Uploading files allows Dataplayer to access and integrate external data sources.   
          </Text>

        </div>
        <PrimaryButton disabled={isDisabled}
          onClick={() => setIsModalOpen(true)} 
          className={classes.button}
          style={{
            backgroundColor: isDisabled ? colors.disabled : colors.primary,
            color: colors.white,
          }}>
          <UploadManual width={24} height={24} style={{ marginRight: 10 }} />Upload
        </PrimaryButton>
      </div>
    </>
  )
}

export default ManualFileHeader