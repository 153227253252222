const SELECT_OPTIONS = [
  { value: '$',  label: 'USD', id: 1 },
  { value: 'A$',  label: 'AUD', id: 2 },
  { value: '£', label: 'GBP', id: 3 },
  { value: 'NZ$',  label: 'NZD', id: 4 },
  { value: '€', label: 'EUR', id: 5 },
]

const megaMonth = (priceId, signValue) => {
  if (priceId === undefined || priceId === 1) {
    return (
      `${signValue === undefined ? '$' : signValue}49`
    )
  }
  if (priceId === 2) {
    return (
      `${signValue}75`
    )
  }
  if (priceId === 4) {
    return (
      `${signValue}81`
    )
  }
  if (priceId === 5) {
    return (
      `${signValue}45`
    )
  }

  if (priceId === 3) {
    return (
      `${signValue}39`
    )
  }
}

const gigaMonth = (priceId, signValue) => {
  if (priceId === undefined || priceId === 1) {
    return (
      `${signValue === undefined ? '$' : signValue}99`
    )
  }
  if (priceId === 2) {
    return (
      `${signValue}151`
    )
  }
  if (priceId === 4) {
    return (
      `${signValue}163`
    )
  }
  if (priceId === 5) {
    return (
      `${signValue}91`
    )
  }

  if (priceId === 3) {
    return (
      `${signValue}78`
    )
  }
}

const megaYear = (priceId, signValue) => {
  if (priceId === undefined || priceId === 1) {
    return (
      `${signValue === undefined ? '$' : signValue}471`
    )
  }
  if (priceId === 2) {
    return (
      `${signValue}720`
    )
  }
  if (priceId === 4) {
    return (
      `${signValue}778`
    )
  }
  if (priceId === 5) {
    return (
      `${signValue}432`
    )
  }

  if (priceId === 3) {
    return (
      `${signValue}375`
    )
  }
}

const gigaYear = (priceId, signValue) => {
  if (priceId === undefined || priceId === 1) {
    return (
      `${signValue === undefined ? '$' : signValue}951`
    )
  }
  if (priceId === 2) {
    return (
      `${signValue}1450`
    )
  }
  if (priceId === 4) {
    return (
      `${signValue}1565`
    )
  }
  if (priceId === 5) {
    return (
      `${signValue}874`
    )
  }

  if (priceId === 3) {
    return (
      `${signValue}749`
    )
  }
}

export { SELECT_OPTIONS, megaMonth, megaYear, gigaMonth, gigaYear }