import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PrimaryButton, Text } from '../../Components'
import { useDropzone } from 'react-dropzone'
import { uploadFileStyles, useStyles } from './UploadFile.styles'
import { Axios } from '../../Config'
import { afterFileUpload, doneUpload, errorIcon, uploadFile } from '../../Themes/Images'
import { colors } from '../../Themes'
import axios from 'axios'
import { LinearProgress } from '@material-ui/core'
import toast from 'react-hot-toast'
import { ErrorToast, SuccessToast } from '../../Components/Notification/Notification'
import { useAuth } from '../../Global'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

function containsWhitespace(str) {
  return /\s/.test(str)
}
  
function isRar(fileName) {
  return fileName?.includes('.zip')
}

const UploadFile = () => {
  const classes = uploadFileStyles()
  const linearClasses = useStyles()

  const [preview, setPreview] = useState(null)
  const [myFile, setMyFile] = useState(null)
  const [url, setUrl] = useState('')
  const [formDt, setFormDt] = useState()
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [upload, setUpload] = useState(false)
  const controllerRef = useRef()
  const { given_name } = useAuth() 

  let size = myFile?.size / 1000000
  if (myFile?.size  < 1000000) {
    size = (myFile?.size / 1000000).toFixed(4)
  }
  else {
    size = (myFile?.size / 1000000).toFixed(0)
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  
  const onDrop = useCallback((acceptedFiles) => {
    const file = new FileReader
    
    file.onload = function() {
      setPreview(file.result)
    }
    
    file.readAsDataURL(acceptedFiles[0])
    setMyFile(acceptedFiles[0])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myFile])

  const cancelSubmit = () => { 
    setMyFile(null)
    controllerRef?.current?.abort()
  }
  
  

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    // accept: 
    //     {
    //       'application/zip': [],
    //     },
        
  })

  const properSubmit = async (e) => {
    e?.preventDefault()
    setLoading(true)
    setUpload(false)
    controllerRef.current = new AbortController()
    try {
      if ( typeof myFile === 'undefined' ) return

      const formData = new FormData()
  
      formData.append('file', myFile, 'halaxy_exported_data.zip')

      setFormDt(formData)
      
      const data = await Axios.get(`/prod/halaxy/getPresignedURL?fileName=${myFile?.name}`,
      )
      setUrl(data?.data?.data?.signedURL)
      
      await axios( {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          let percent = 0
          percent = Math.floor((loaded * 100) / total)
          
          if (progress <= 100) {
            setProgress(percent) 
          }
        },
        method: 'put',
        // eslint-disable-next-line max-len
        url: data?.data?.data?.signedURL,
        data: myFile,
        signal: controllerRef?.current?.signal
      })
      setLoading(false)
      setUpload(true)
      setProgress(0)
      setTimeout(function() {
        toast.custom((t) => (
          <SuccessToast t={t} message='File uploaded successfully!'/>
        ))
        cancelSubmit()
        setUpload(false)
      }, 500)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setProgress(0)
      setUpload(false)
      if (error.name === 'CanceledError') {
        toast.custom((t) => (
          <ErrorToast t={t} message='You canceled the upload'/>
        ))
      } else {
        toast.custom((t) => (
          <ErrorToast t={t} message='Something went wrong!'/>
        ))
      }
    }
  }

  if (given_name !== 'Halaxy') {
    return <Redirect push to='/dashboard' />
  }

  return (
    <form id='my-form' style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Text size='subnote' weight='bold'>Upload your file</Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <div className={classes.dropDragging}>
              <img style={{ objectFit: 'contain' }} src={uploadFile} alt='upload file' />
              <div style={{ marginTop: 10 }}>
                <Text size='footnote'>Drag and drop file, or <Text size='footnote' weight='bold' color={colors.accent}>browse</Text></Text>
              </div>
              <Text color={colors.textGray} size='small'>File type must be ZIP</Text>
            </div>
            :
            myFile?.name && containsWhitespace(myFile?.name) === false ?
              <div className={classes.fileUploaded}>
                <div className={classes.afterDiv}>
                  <div style={{ display: 'flex', gap: 20 }}>
                    <img style={{ objectFit: 'contain' }} src={afterFileUpload} alt='upload after file' />
                    <div className={classes.textUpload}>
                      <Text size='footnote'>{myFile?.name}</Text>
                      <Text color={colors.textGray} size='small'>{size} MB</Text>
                    </div>
                  </div>
                  {upload && 
                     <div style={{ display: 'flex', gap: 10 }}>
                       <img src={doneUpload} alt='checked' style={{ objectFit: 'contain' }} />
                       <Text size='footnote' color='#12C730'>Done</Text>
                     </div>
                  }
                </div>
                {progress > 0 &&  
                <div style={{ width: '100%' }}>
                  <LinearProgress className={linearClasses.root} variant="determinate" value={progress} />
                </div>
                }
              </div> 
              :
              <div className={classes.drop}>
                <img style={{ objectFit: 'contain' }} src={uploadFile} alt='upload file' />
                <div className={classes.text}>
                  <Text size='footnote'>Drag and drop file, or <Text size='footnote' weight='bold' color={colors.accent}>browse</Text></Text>
                  <Text color={colors.textGray} size='small'>File type must be ZIP</Text>
                </div>
              </div> 
        }
      </div>
      {myFile && !isRar(myFile?.name ) && <div className={classes.error}>
        <img className={classes.errorICon} src={errorIcon} />
        <Text size='footnote' color='rgba(238, 67, 82, 0.97)'>The file format is not correct. Make sure to upload a ZIP file.</Text>
      </div>
      }
      {myFile && containsWhitespace(myFile?.name) && <div className={classes.error}>
        <img className={classes.errorICon} src={errorIcon} />
        <Text size='footnote' color='rgba(238, 67, 82, 0.97)'>Make sure the zip file name doesn&apos;t have any spaces</Text>
      </div>
      }
      {myFile?.name && containsWhitespace(myFile?.name) === false && (
        <div className={classes.buttonDiv}>
          <PrimaryButton onClick={() => cancelSubmit()} className={classes.cancel}>Cancel</PrimaryButton>
          <PrimaryButton style={{ backgroundColor: loading || !isRar(myFile?.name) ? colors.disabled : colors.primary, color: !isRar(myFile?.name) && colors.white }} 
            className={classes.button} disabled={loading || !isRar(myFile?.name)} loading={loading} 
            onClick={() => properSubmit()}>Upload</PrimaryButton>
        </div>
      )}
    </form>
  )
}

export default UploadFile