import { makeStyles } from '@material-ui/core'

const FromPaidToFreeModalStyle = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 25px',
      maxWidth: 320,
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  nextButton: {
    width: '50%',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
    },
  },
  text2: {
    textAlign: 'center',
    marginBottom: 30
  },
  text3: {
    textAlign: 'center',
  },
  iconButtonClose: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
}))

export default FromPaidToFreeModalStyle