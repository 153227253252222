/* eslint-disable no-useless-escape */
import React, { useEffect } from 'react'
import referalEnterUpgradeStyles from './ReferalEnterUpgrade.styles'
import Text from '../Text'
import { PrimaryButton } from '../Button/Button'
import { colors } from '../../Themes'
import { CheckImg, errorIcon, info, targetsCheck } from '../../Themes/Images'
import { TextInput } from '../Input'
import { InputAdornment } from '@material-ui/core'

function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return specialChars.test(str)
}

  
const ReferalEnterUpgrade = ({ success, referralClicked, value, handleChange, handleOnBlur, message, referralEnteredSuccess, verifyCode, isDisabled, setDisabled, isLoading, inputDisabled }) => {
  const classes = referalEnterUpgradeStyles()

  useEffect(() => {
    if (
      value !== '' && value.length === 8 && !containsSpecialChars(value)
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [value, setDisabled ])

  let content =    
  <>
    <div className={classes.text}>
      <Text size='footnote'>If someone has suggested Dataplayer to you and you have a referral code, proceed here to get your <Text color={colors.lightRed} size='footnote' weight='medium'>free month.</Text></Text>
    </div>
    <PrimaryButton
      className={classes.button}
      onClick={referralClicked}
    >
      <Text color={colors.white} size='footnote'>I am referred</Text>
    </PrimaryButton>
  </>
  
  if (success) {
    content = <>
      <div className={classes.text}>
        <Text size='subnote'>Enter referral code here and then choose your plan.</Text>
        {/* <div className={classes.afterSuccessSubNote}>
          <img className={classes.info} width={20} height={20} src={info} />
          <Text size='footnote'>
            <Text className={classes.note} size='footnote'>NOTE:  </Text> 
            This is not a discount code
          </Text>
        </div> */}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.inputDiv}>
          <TextInput
            name='code'
            costumizedInputStyles={classes.input}
            label='Code'
            value={value}
            disabled={inputDisabled}
            onChange={handleChange}
            onBlur={handleOnBlur}
            endAdornment={
              message && referralEnteredSuccess && 
              <InputAdornment
                className={classes.alignedPlaceHolder}
                position="end"
              >
                <div className={classes.errorDiv}>
                  <img className={classes.errorICon} src={targetsCheck} />
                  <Text className={classes.errorText} color={colors.green} size='footnote'>{message}</Text>
                </div>
              </InputAdornment>
            }
          />
          {(message && referralEnteredSuccess) ? null : 
            <PrimaryButton 
              className={classes.applyButton}
              disabled={isDisabled || isLoading}
              loading={isLoading}
              style={{
                backgroundColor: isDisabled || isLoading ? colors.disabled : colors.primary,
                color: colors.white,
              }}
              onClick={verifyCode}>
              <Text color={colors.white} size='footnote'>Apply</Text>
            </PrimaryButton>
          }
        
        
        </div>
        {message && !referralEnteredSuccess &&
          <div className={classes.errorDiv}>
            <img className={classes.errorICon} src={errorIcon} />
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{message}</Text>
          </div>
        }
        {/* {message && referralEnteredSuccess &&
          <div className={classes.errorDiv}>
            <img className={classes.errorICon} src={targetsCheck} />
            <Text className={classes.errorText} color={colors.green} size='footnote'>{message}</Text>
          </div>
        } */}
      </div>
    </>
  }

  return (
    <div className={classes.bigContainer}>
      <div className={success ? classes.wrapperSuccess : classes.wrapper}>
        {content}
      </div>
      <div className={classes.choosePlanContainer}>
        <div className={classes.choosePlanText}>
          <Text size='h6' weight='bold'>Choose your plan</Text>
        </div>
        <div className={classes.divider} />
      </div>
    </div>
  )
}

export default ReferalEnterUpgrade