import React from 'react'
import useDashboardTargetStyles from './TargetDashboard.styles'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Text } from '../../Components'
import TargetsDashboard from '../../Components/TargetsDashboard/TargetsDashboard'

const TargetDashboard = ({ setTavValue }) => {
  const classes = useDashboardTargetStyles()
  const history = useHistory()
  
  const redirectToTargets = () => {
    setTavValue(1)
  }

  return (
    <div className={classes.container}>
      <div onClick={() => redirectToTargets()} className={classes.redirect}>
        <Text style={{ textDecoration: 'underline' }} size='footnote'>Set targets for your business</Text>
      </div>
      <TargetsDashboard />
    </div>
  )
}

export default TargetDashboard