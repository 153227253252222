/* eslint-disable max-len */
const TOOLTIPS = {
  CHRIS: {
    title: 'BODYTONIC ACCOUNT',
    desc: 'These are all the explanations for the graphs included in your account. We will update it regularly with each new addition.',
    tooltips: [
      {
        title: 'Business Metrics',
        desc: `<h1 class="bullet">&bull;</h1> <i class="lineItem">Invoiced Revenue</i>
       Get the sum of all invoices created rounded to 3 digits after the comma.
       You can click the graph and then click 'Drill down from month' to see revenue grouped by businesses or click 'Drill down from Businesses' to see revenue grouped by 'Weeks'.
       
       <h1 class="bullet">&bull;</h1> <i class="lineItem">Related to Targets</i>
        <b>Now</b>- Sum of all invoices until now based on time periods.
        <b>Target</b> - Sum of Target value based on time periods.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Standalone PAV</i>
        <b>PAV</b>- total patients that arrived in appointments / new patients for each practitioner.
         Can be filtered by  Year, Month, Week, Group Services.

         <h1 class="bullet">&bull;</h1> <i class="lineItem">Rolling PAV 2020 - 2021 ( Filtered by Month / Drill down from Interval)</i>
         <b>Rolling PAV</b>- total patients that arrived in appointments for the last 3 months / new patients for the last 3 months  for each practitioner
         Can be filtered by Year, Month, Practitioner         

         <h1 class="bullet">&bull;</h1> <i class="lineItem">Did Not Arrive & Late Notice Cancellations</i>
         For each practitioner count all the individual appointments where the patient did not arrive.
         Can be filtered by <i> Year</i>,<i> Month</i>, <i>Week</i>, <i>Practitioner</i>, <i>Category</i>, <i>Group Services.</i>

         <h1 class="bullet">&bull;</h1> <i class="lineItem">Did Not Arrive Patients</i>
         Count the names of all patients that didn't arrive at their individual appointments.
         Can be filtered by Specific Date ( Start_time - End_time),  <i> Year</i>,<i> Month</i>, <i>Week</i>, <i>Practitioner</i>, <i>Category</i>, <i>Group Services.</i>

         <h1 class="bullet">&bull;</h1> <i class="lineItem">Bookings Data</i>
         Can be filtered by <i> Business</i>, <i> Year</i>,  <i>Month</i>,  <i>Week</i>,  <i>Quarter</i>,  <i>Practitioner</i>.
          
          <b>Booked</b> - All the appointments you have created. 
          <b>Arrived</b> - All your appointments where the patient arrived.
          <b>Not arrived</b> - All your appointments where patients did not arrive or show up. 
          <b>Cancelled</b> - All your cancelled appointments.
          <b>Waiting List</b> - All your future appointments (where appointment start time > than your current timestamp).

        <h1 class="bullet">&bull;</h1> <i class="lineItem">The Busy Hours of Practitioners</i>
          For each Practitioner get the difference between appointment start and appointment end as '<i>Busy Hours</i>'.
         Can be filtered by <i> Year</i>,<i> Month</i>, <i>Week</i>.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Treatment Notes</i>
          For each practitioner, count all the treatment notes that have drafts as Opened and the difference: number of patients arrived -  number of treatment notes created as Not Created.
         Can be filtered by <i> Year</i>,<i> Month</i>, <i>Practitioner</i>, <i>Group Services.</i>.
        `,
      },
      {
        title: 'My Performance',
        desc: `<h1 class="bullet">&bull;</h1> <i class="lineItem">Total number of appointments</i>
        For each month count all the appointments from individual appointments that have started in that month and group by two last years.
        Can be filtered by <i> Year</i>,<i> Quarters</i>,<i> Month</i>, <i>Business</i>.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Online vs In-House appointments</i>
        For each year count all the appointments from individual appointments that have started and separate by ‘Online Booked’ if that appointment was created online by patients/clients, or ‘In-House Booked’. This figure is on a year base default, click on graph and then select ‘Drill down from Year’ to see data compared on a monthly basis, and then you can again drill down from month to see data compared by business, or drill up to yearly basis again.
        Can be filtered by <i> Year</i>,<i> Business</i>,<i> Month</i>, <i>Quarter</i>.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Online vs In-House bookings </i>
        For each year count all the bookings based on their creation date and separate by ‘Online Booked’ if that appointment was created online by patients/clients, or ‘In-House Booked’. This figure is on a year base default, click on graph and then select ‘Drill down from Year’ to see data compared on a monthly basis, and then you can again drill down from month to see data compared by business, or drill up to yearly basis again.
        Can be filtered by <i> Year</i>,<i> Business</i>,<i> Month</i>, <i>Quarter</i>.

       <h1 class="bullet">&bull;</h1> <i class="lineItem">Total number of appointments (for all years)</i>
        For each year count all the appointments from individual appointments that have started and group by the business.
        Can be filtered by <i> Year</i>,<i> Business</i>,<i> Month</i>, <i>Quarter</i>.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Arrived</i>
         Count all the appointments that patients have arrived from individual appointments.
         Can be filtered by <i> Year</i>,<i> Business</i>,<i> Month</i>, <i>Quarter</i>.
        
         <h1 class="bullet">&bull;</h1> <i class="lineItem">Your revenue through the years.</i>
         Get the sum of all invoices created, (both opened and closed status invoices and that are related to all the individual appointments made), rounded to 3 digits after the comma for each year and group by the business.        
          Can be filtered by <i> Year</i>,<i> Business</i>,<i> Month</i>, <i>Quarter</i>.

         <h1 class="bullet">&bull;</h1> <i class="lineItem">Cancelled</i>
         For each year count all the appointments that are cancelled and group by the business.
         Can be filtered by <i> Year</i>,<i> Business</i>,<i> Month</i>, <i>Quarter</i>.

         <h1 class="bullet">&bull;</h1> <i class="lineItem">New Patients</i>
         For each year count all the patients only when they have their first appointment on individual appointments.
         Can be filtered by <i> Year</i>,<i> Business</i>,<i> Month</i>, <i>Quarter</i>.

         <h1 class="bullet">&bull;</h1> <i class="lineItem">Total Patients</i>
         For each year count all the patients that have an individual appointment.
         Can be filtered by <i> Year</i>,<i> Business</i>,<i> Month</i>, <i>Quarter</i>.
    
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Engagement Map Ages</i>
        <b>Patients</b>- ( sum of specific age group/number of all patients ) as Percentage
        <b>Revenue </b>- ( sum of  net_amount from specific age group /  total net_amount ) as Percentage.

        `,
      },
      {
        title: 'Daily Track',
        desc: ` <h1 class="bullet">&bull;</h1> <i class="lineItem">Last Two Days Revenue</i>
        Sum of all invoices for the last two days.
        Can be filtered by <i>Business</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Last Two Days Bookings</i>
        Count all the appointments created on the last two days.
        Can be filtered by <i>Business</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Last Two Days Appointments</i>
        Count all the appointments started on the last two days.
        Can be filtered by <i>Business</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Revenue In Periods</i>
        Sum of all invoices based on different time periods.
        Can be filtered by <i>Period</i>.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Bookings In Periods</i>
        Count all the appointments created on different time periods.
        Can be filtered by <i>Period</i>.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Appointments In Periods</i>
        Count all the appointments started at different time periods
        Can be filtered by <i>Period</i>, <i>Business</i>.
        `,
      },
      {
        title: 'Internal',
        desc: ` <h1 class="bullet">&bull;</h1> <i class="lineItem">Revenue by Practitioner</i>
        For the Bodytonic clinic, Canada Water @ the Dock Offices London we calculate the sum of invoices for each practitioner based on group services
        Can be filtered by <i>Period</i>, <i>Practitioner</i>, <i>Group Services</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Average Revenue Per Client By Practitioner</i>
        For each practitioner, we calculate the average revenue per client as the sum of invoices amount  / all invoices
        Can be filtered by <i>Period</i>, <i>Practitioner</i>, <i>Group Services</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">The Proceeds Practitioners Deliver</i>
        For each Practitioner get the sum of the amount of the invoice.
        Can be filtered by <i>Period</i>, <i>Practitioner</i>, <i>Group Services</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Practitioner's Invoice Status</i>
        For each practitioner count the invoices with status = open as  <b>'Open'</b>, when status = open credit count as <b>'Open Credit'</b>
        Can be filtered by <i>Period</i>, <i>Practitioner</i>, <i>Group Services</i>.

        `,
      },
      {
        title: 'Specific',
        desc: ` <h1 class="bullet">&bull;</h1> <i class="lineItem">Standalone PAV (grouped Weekly )</i>
        Can be filtered by <i>Year</i>, <i>Month</i>, <i>Group Services</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Standalone PAV based on Treatment Type</i>
        Can be filtered by <i>Year</i>, <i>Month</i>, <i>Group Services</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Appointments by Treatment Type</i>
        For each practitioner, we count all the appointments made and group them by treatment type..
        Can be filtered by <i>Year</i>, <i>Month</i>.
        `,
      },
      {
        title: 'Additional',
        desc: `<h1 class="bullet">&bull;</h1> <i class="lineItem">Invoice By Distinct Age Groups</i>
        Sum of invoice amounts paid by patients based on their group ages
        Can be filtered by <i>Year</i>, <i>Month</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Practitioners Availability This Week ( Click on the plus sign (<b>+</b>) near the Practitioner name to expand )</i>
        For each practitioner, get working hours as 'Total Work Hours ' from daily availability, get the difference from appointment start and appointment end for individual appointments this week as 'Busy Hours', and  Total work hours - Busy hours as 'Available Hours'
        
        `,
      },
      {
        title: 'Products',
        desc: `<h1 class="bullet">&bull;</h1> <i class="lineItem">Products</i>
        For each product invoice, count how many times it’s been sold as <b> ‘Total No’ </b> and the revenue generated as <b>‘Total Price’</b>.
        Can be filtered by <i>Year</i>, <i>Month</i>, <i>Business</i>, <i>Product</i>.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Products vs Services.</i>
        Get the total revenue generated by services and products.
        Can be filtered by <i>Year</i>, <i>Month</i>, <i>Business</i>, <i>Product</i>.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Products associated with Treatment Types.</i>
        For each product, count the treatment types the product is invoiced with.
        Can be filtered by <i>Year</i>, <i>Month</i>, <i>Business</i>, <i>Product</i>.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Products Sold by Practitioner.</i>
        For each Practitioner, get all the products they have suggested during appointments.
        Can be filtered by <i>Year</i>, <i>Month</i>, <i>Business</i>, <i>Product</i>.
        
        `,
      },
    ],
  },
  KWPC: {
    title: 'Kilmore and Wallan Podiatry Clinic',
    desc: `<b>These are all the explanations for the graphs included in your account</b>. 
        Businesses represent a business or location (clinic). Each Cliniko account can have unlimited businesses. These are typically used for each physical location if there is more than one. 
         `,
    tooltips: [
      {
        title: 'My Track',
        desc: `All graphs here can be filtered by: 
            - <i>Business</i>
            - <i>Year</i>
            - <i>Quarter</i>
            - <i>Month</i>
            
            <h1 class="bullet">&bull;</h1> <i class="lineItem">Revenue KPI</i>
            A sum of all your invoices rounded to 3 digits after the comma. You can see how your practice has been performing throughout the years.
            `,
      },
    ],
  },
  CUSTOMISED: {
    title: 'CUSTOMISED',
    desc: 'Your account was customised from scratch by our team. Reach out to us if you’d like all your tooltips information to be included here, instead of having them besides the graphs. ',
    tooltips: [],
  },
  FREE: {
    title: 'FREE ACCOUNT',
    desc: `Below are all the explanations for the graphs included in your free account, with 12 months worth of data, updated on an hourly basis.

    You can only use the "Month" and "Individual or Group appointment" as Graph Controls in a Free Account.
    The data in the “Restricted Access” graphs is only available to paid accounts.

    Bear in mind, this is just the free account model. Dataplayer offers more range in the variety of data you can access, filters, customisation etc. Upgrade your account now to get the full insights for your practice.
     `,
    tooltips: [
      {
        title: 'Snapshot',
        desc: `<h1 class="bullet">&bull;</h1> <i class="lineItem">Appointments booked by site - Last two days </i>
        
        All your created individual and group appointments in the last 48 hours, specified by your site locations. 
        `,
      },
      {
        title: 'Appointments',
        desc: `<h1 class="bullet">&bull;</h1> <i class="lineItem">Summary of patients that did not arrive per practitioner </i>
        Shows the number of patients that did not arrive for each of your practitioners in their individual appointments.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Details of patients that did not arrive per practitioner</i>
        All the names of the patients that did now show up for their individual appointments. See how many they missed in the value sector.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Appointment information</i>
        
        Definitions:
        <b>Group Appointments</b> - All your group appointments based on their appointment start time.
        <b>Group Appointments Booked</b> -  All your created group appointments.
        <b>Individual Appointments</b> - All your individual appointments based on their appointment 
        start time.
        <b>Individual Appointments Booked </b> - All your created individual appointments. 
        <b>Cancelled</b> - All your individual cancelled appointments.
        <b>Not arrived</b> - All your appointments where patients did not arrive or show up.
        <b>Waiting List</b> -All your future appointments (where appointment start time > than your current timestamp).

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Revenue by appointments type</i>
        Your revenue based on your billable appointments, for each appointment type.
        `,
      },
      {
        title: 'Appointment types',
        desc: `<h1 class="bullet">&bull;</h1> <i class="lineItem">Billable appointments by type </i>
        
        The number of your billable appointments, categorised by appointment type.
        `,
      },
      {
        title: 'Patients',
        desc: `<h1 class="bullet">&bull;</h1> <i class="lineItem">Patients by age and appointment types</i>
        
        This graph shows the number of your patients, grouped by age and appointment type. It offers a proper view of your general demographic and the services they’re most interested in.
        `,
      },
      {
        title: 'Practitioners',
        desc: `<h1 class="bullet">&bull;</h1> <i class="lineItem">Top 3 Practitioners ranked by arrived patients. </i>

        See which practitioners have had the highest number for arrived patients, and their specific value. 
        `,
      },
    ],
  },
  ACHIEVE: {
    title: 'ACHIEVE EXERCISE',
    desc: `These are all the explanations for the graphs included in your account. 
    Businesses represent a business or location (clinic). Each Cliniko account can have unlimited businesses. These are typically used for each physical location if there is more than one.     
     `,
    tooltips: [
      {
        title: 'City Performance',
        desc: `All the graphs in this tab can be filtered by business, year, quarter, month, and city.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Total Number of All Client Bookings</i>
        All appointments made in your practice (individual and group), grouped by Brisbane, Townsville and both. 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Attended | Did Not Arrive but Not Cancelled | Cancelled</i>
        '<b>Attended</b>'- All individual appointments made by patients where they arrived.
        '<b>Did Not Arrive but Not Cancelled</b>' - All individual appointments made by patients where they did not arrive.
        '<b>Cancelled</b>' - All individual appointments that were cancelled by patients.
        `,
      },
      {
        title: 'Staff Performance',
        desc: `All the graphs in this tab can be filtered by business, year, quarter, month, city, and practitioner.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Total Number of All Client Bookings</i>
        All appointments made in your practice (individual and group) grouped by practitioners. 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Attended | Did Not Arrive but Not Cancelled | Cancelled</i>
        '<b>Attended</b>'- All individual appointments made by patients where they arrived.
        '<b>Did Not Arrive but Not Cancelled</b>' - All individual appointments made by patients where they did not arrive.
        '<b>Cancelled</b>' - All individual appointments that were cancelled by patients.
        
        You can see this information for each practitioner by using the “Practitioner” filter.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Compensated vs Pending Payment</i>
        All the invoiced grouped by their status as “open” or “paid”. 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">The Busy Hours of Practitioners</i>
        It shows the hours of each practitioner spent in appointments (their specific duration). 
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Breakdown of client type for each staff member (Pie Chart)</i>
        Percentage of work (held session) from each classification, displayed as a single pie chart.
         
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Breakdown of client type for each staff member</i>
        Percentage of attendance from each practitioner (held compared to total bookings), displayed as a bar graph to 100%. 
        `,
      },
      {
        title: 'Site Performance',
        desc: `All the graphs in this tab can be filtered by business, year, quarter, month, and city.
 
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Total number of all client bookings</i>
        All the individual and group appointments fro each practitioner, grouped by site.
         
        <h1 class="bullet">&bull;</h1> <span class="lineItem">Attended | Did Not Arrive but Not Cancelled | Cancelled</span>
        <i>Pie chart, in percentage.</i>
        
        '<b>Attended</b>'- All individual appointments made by patients where they arrived.
        '<b>Did Not Arrive but Not Cancelled</b>' - All individual appointments made by patients where they did not arrive.
        '<b>Cancelled</b>' - All individual appointments that were cancelled by patients.
        
        In this tab, you can see this information for each site by using the “Business” filter.
        
        <h1 class="bullet">&bull;</h1> <span class="lineItem">Attended | Did Not Arrive but Not Cancelled | Cancelled</span>
        <i>Bar Chart, compare each sight.</i>
         
        '<b>Attended</b>'- All individual appointments made by patients where they arrived.
        '<b>Did Not Arrive but Not Cancelled</b>' - All individual appointments made by patients where they did not arrive.
        '<b>Cancelled</b>' - All individual appointments that were cancelled by patients.
        
        In this tab, you can see this information for each site by using the “Business” filter.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Open Invoices At Each Site</i>
        
        For each site, we count all the invoices with the status “open”. 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Breakdown of Specific Services at Each Site</i>
        All the individual and group appointments grouped by specific services for each site.
        `,
      },
      {
        title: 'Specific Services',
        desc: `All the graphs in this tab can be filtered by business, year, month, type (individual, group, both), category (for appointment types), and city.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Appointment Type Bookings</i>
        You can see the number of appointments for each category (appointment type). Drill down to see the information grouped by year.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Appointment Type Revenue</i>
         
        You can see the sum of your invoices for each appointment type. Drill down to see the information grouped by year
        `,
      },
      {
        title: 'Demographic',
        desc: `All the graphs in this tab can be filtered by year and city.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">No of patients by age groups</i>
        How many patients you have in each age group.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Patients by gender</i>
        How many patients you have grouped by gender, as a number and percentage.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Active patients</i>
        See the number of patients that have had an appointment in the Previous Year, Previous 3 Months and Previous 6 Months.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">New patients vs Returning patients</i>
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Leading cities of invoiced patients</i>
        The sum of all your invoices, grouped by cities of invoiced patients.
        `,
      },
      {
        title: 'Daily Track',
        desc: `All the graphs in this tab can be filtered by time period, business and city.

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Last Two Days Bookings</i>
        All the individual appointments created (booked) in the last two days.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Last Two Days Appointments</i>
        All the individual appointments held in the last two days.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Bookings In Periods</i>
        All the individual appointments created (booked) on the specific selected time period.
        If you don’t apply a filter, the default is for the previous week and current week.
        
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Appointments In Periods</i>
        All the individual appointments started on the specific selected time period.
        If you don’t apply a filter, the default is for the previous week and current week.
        `,
      },
      {
        title: 'My Referrals',
        desc: `The graphs in this tab can be filtered by year and month.
        You can see all the number of patients referred and grouped by referrer source type name.
        You can also see all the doctors referring per business and their total number of referrals.     
        `,
      },
    ],
  },
  MEGA: {
    title: 'MEGA ACCOUNT',
    desc: `Learn how to read your data easily and get the insights that matter. These are all the explanations for the graphs included in your mega account. 
    You currently have 2 years worth of data in your account which gets updated and refreshed on an hourly basis.
    Businesses represent a business or location (clinic). Each Cliniko account can have unlimited businesses. These are typically used for each physical location if there is more than one. 
    `,
    tooltips: [
      {
        title: 'Practice metrics',
        desc: `All graphs here can be filtered by: 
        - <i>Business</i>
        - <i>Year</i>
        - <i>Quarter</i>
        - <i>Month</i>
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Practice Revenue</i>
        How much revenue your clinic has generated over a certain period of time. 
        We calculate it as the sum of all your invoices linked to individual appointments rounded to 3 digits after the comma.
         
         <h1 class="bullet">&bull;</h1> <i class="lineItem">Yearly target</i> 
        <i>How far off you are from reaching your yearly revenue target.</i> 
         <h1 class="bullet">&bull;</h1> <i class="lineItem">Monthly target</i>
        <i>How far off you are from reaching your monthly revenue target</i>. 
         <h1 class="bullet">&bull;</h1> <i class="lineItem">Weekly target</i>
        <i>How far off you are from reaching your weekly revenue target.</i> 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Bookings data </i>
        See how many: "Booked", "Arrived", "Not arrived", "Cancelled" and "Waiting List" individual appointments and how many group "Booked" appointments your clinic(s) has.
         
        Definitions:
        <b>Booked</b> - All the appointments you have created.
        <b>Appointments</b> - All your appointments based on the appointment start time.
        <b>Arrived</b> - All your appointments where the patient arrived.
        <b>Not arrived</b> - All your appointments where patients did not arrive or show up. 
        <b>Cancelled</b> - All your cancelled appointments.
        <b>Waiting List</b> - All your future appointments (where appointment start time > than your current timestamp).
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Number of patients that did not arrive</i>
        Shows the number of patients that did not arrive for each of your practitioners in their individual appointments.
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Patients that did not arrive</i>
        All the names of the patients that did now show up for their individual appointments. See how many they missed in the value sector. This graph can only be filter by Start time and End time. 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Booked, Arrived, Cancelled</i>
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">New patients vs Total patients vs Returning Patients</i>
        <b>New</b> shows all patients that have only had their first appointment ever, based on appointment start time.
        <b>Total</b> shows all patients that have an appointment. 
        Returning shows <b>Total</b> patients minus <b>New</b> patients. 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Your new patients per month KPI</i>
        All your new patients through the months in a line chart. 
        
        `,
      },
      {
        title: 'Daily Track',
        desc: `All the graphs here can be filtered by business. 

        <h1 class="bullet">&bull;</h1> <i class="lineItem">Practice revenue in the last two days</i>
        Sum of all invoices for the last two days.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Number of bookings in the last two days</i>
        All the appointments created on the last two days.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Number of appointments in the last two days</i>
        All the appointments started on the last two days.
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Practice revenue (filter for specific time periods)</i>
        Sum of all invoices for the specific selected time period.
        If you don’t apply a filter, the default is for the last two days. 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Number of bookings (filter for specific time periods)</i>
        All the appointments created on the specific selected time period.
        If you don’t apply a filter, the default is for the last two days. 
         
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Number of appointments (filter for specific time periods)</i>
        All the appointments started on the specific selected time period.
        If you don’t apply a filter, the default is for the last two days. 
         
        The time periods filter includes: 
        - <i>Today</i>
        - <i>Previous Day</i>
        - <i>This Week </i>
        - <i>Previous Week</i>
        - <i>This Month</i>
        - <i>Previous Month</i>
        - <i>This Quarter</i>
        - <i>Previous Quarter</i>
        - <i>This Year</i>
        - <i>Previous Year</i>
        `,
      },
      {
        title: 'Appointment type metrics',
        desc: `All graphs here can be filtered by: 
        - <i>Year</i>
        - <i>Month</i>
        - <i>Appointment Type</i> 
        - <i>Business</i> 
         
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Appointment Type Bookings</i>
        All your appointments for each appointment type grouped by year.
         
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Top 3 Category for total Appointments </i>
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Appointment Type Revenue</i>
        For each Appointment Type, we calculate the sum of all invoices and group them by year. 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Top 3 Category for total Revenue</i> 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Bookings per hour</i>
        All your bookings based on the hours when appointments start. 
        
        <h1 class="bullet">&bull;</h1> <i class="lineItem">Number of new patients per appointment type</i>
         `,
      },
      {
        title: 'Practitioners',
        desc: `<i>Practitioner revenue</i>
        <i>The average revenue per client by a practitioner</i>
        <i>Number of arrived, booked, cancelled and did not arrive per practitioner</i>
        <i>Practitioners and their open invoices</i>
        <i>Practitioners and their paid invoices</i>
        <i>Practitioners Busy Hours This Month</i> 
        <i>Practitioners Available Days</i>
        
        `,
      },
      {
        title: 'Patient demographics',
        desc: `<i>Leading cities based on patients location</i>
        <i>Number of patients and revenue for specific age groups</i>
        <i>Number of patients by gender</i>
        <i>Heat map for cities</i>
        <i>Heat map for age groups</i>
        
        `,
      },
    ],
  },
  GIGA: {
    
  },
}

export default TOOLTIPS
