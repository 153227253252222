import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    backgroundColor: colors.white,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      flexGrow: 1,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
      height: '100%',
      justifyContent: 'center',
    },
  },
  title: {
    color: colors.accent,
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0',
      fontSize: 24
    },
  },
  loginTitle: {
    color: colors.white,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  gradientLogo: {
    height: 100,
    width: 200,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: 150,
    },
  },
  loginButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  loginForm: {
    padding: 20,
    marginBottom: 30,
    display: 'flex',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginBottom: -10,
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      flex: 1,
    },
  },
  loginAnimationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(70deg, ${colors.accent}, ${colors.secondary})`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  loginButton: {
    width: '100%',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  loginWrapper: {
    margin: 'auto',
    flexGrow: 0.7,
    maxWidth: 600,
  },
  forgotPassword: {
    margin: '32px 0',
    color: colors.secondary,
    fontFamily: 'Roboto',
    fontSize: 'larger',
  },
  subLine: {
    borderWidth: '0px 0px 1px',
    borderStyle: 'solid',
    borderColor: colors.gray,
    margin: '35px  0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  input_false: {
    flexGrow: 1,
    borderWidth: 10,
    borderColor: colors.accent,
    marginTop: 15,
    marginBottom: 0,
  
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  input_true: {
    border: '1px solid ' + colors.secondary,
    flexGrow: 1,
    borderRadius: 5,
    // minWidth: 280,
    marginTop: 15,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  errorInput: {
    color: colors.secondary,
    padding: '0 12px',
    fontSize: 16,
  },
  errorInputEnd: {
    color: colors.secondary,
    padding: '0 12px',
    fontSize: 16,
    textAlign: window.innerWidth < 500 ? 'left' :'right'
  },
  tearmsAndCondition: {
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  pmsText: {
    opacity: 0.5,
    padding: '0 15px'
  },
  link: {
    color: colors.accent,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  buttonProgress: {
    position: 'absolute',
  },
  infoWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  infoText: {
    marginRight: 10,
    fontFamily: 'Roboto',
    fontSize: 18,
  },
  visitDataplayerButton: {
    backgroundColor: colors.white,
    color: colors.primary,
    marginTop: 100,
    maxWidth: 400,
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.accent,
    },
    '&$focused': {
      backgroundColor: colors.gray,
    },
    width: '65%',
  },
  visitDataplayerMobileButton: {
    backgroundColor: colors.white,
    color: colors.primary,
    marginTop: 16,
    maxWidth: 400,
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.accent,
    },
    '&$focused': {
      backgroundColor: colors.gray,
    },
    width: '100%',
  },
  visitDataplayerContainer: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 32px 26px',
    backgroundColor: colors.primary
  },
  other: {
    marginTop: window.innerWidth < 500 ? 8 : 0,
    marginBottom: 0,
    marginLeft: window.innerWidth < 500 ? 0 : 15,
    minWidth: 300
  },
  dropwdownWrapper: { 
    display: 'flex', 
    flexDirection: window.innerWidth < 500 ? 'column' : 'row', 
    marginTop: 8 
  }
}))

const selectStyles = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 44,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 18,
    zIndex: 3
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 58,
    flex: 1,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 58,
    borderRadius: 5,
    '&:hover': {
      border: `1px solid ${colors.accentHover}`,
    },
    border: '0px solid lightgray',
    boxShadow: 'none',
  }),
  
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      paddingLeft: 12,
      backgroundColor: colors.background,
      height: 58,
      marginTop: 8,
      color: colors.primary,
      // zIndex: 1000,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
        cursor: 'pointer'
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
}

export  { useStyles, selectStyles }