/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { Text } from '../../Components'
import { colors } from '../../Themes'
import { SecondaryOutlinedButton2 } from '../../Components/Button/Button'
import CodeCustomModal from '../CodeCustomModal/CodeCustomModal'
import { Link } from 'react-router-dom'
import ContactUsModal from '../ContactUsModal/ContactUsModal'
import ContactUsModalCustomisation from '../ContactUsModalCustomisation/ContactUsModalCustomisation'
import { Chip, useMediaQuery, useTheme } from '@material-ui/core'
import useStyles from './AddOnsCard.styles'
import { useAuth } from '../../Global'
import SmartTargetModal from '../SmartTargetModal'

const formatPrice = (num)=> {
  let nr = num.toString()
  let index = nr.indexOf('.') > 0
  if (index) {
    nr = nr.slice(0, (nr.indexOf('.'))+3)  
  }
  let number = Number(nr)
  return number 
}

export default function AddOnsCard({ 
  img, 
  name, 
  price, 
  description, 
  buttonText, 
  buttonHandler, 
  // handleOpenXero,
  isOneOffCharge, 
  staticCard, 
  smallCard, 
  remove, 
  isEligible, 
  customPrice,
  billingType,
  nextPayment,
  paymentType,
  isXero,
  isFree
}) {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isContactUsModalCustomisation, setIsContactUsModalCustomisation] = useState(false)
  const [isSmartTarget, setIsSmartTarget] = useState(false)
  const [isContactUsModal, setIsContactUsModal] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(550))

  const { cancellationEffectiveDate } = useAuth()

  let priceFormatted = price ? formatPrice(price ) : price

  const handleHover = () => {
    setIsHovered(true)
  }
  
  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  
  const handleOpenModal = () => {
    setIsModalOpen(true)
    setIsHovered(false)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setIsHovered(false)
  }

  const string =  String(priceFormatted)

  const pricee = string?.split('-')

  let formattedPrice = customPrice ? 'Custom pricing': price === 'Custom' ? 'Custom pricing' : isOneOffCharge ? `$${priceFormatted?? price}` : !isEligible ? null : `$${priceFormatted?? price}/${paymentType === 'month' ? 'month': 'year'}` 
  let smallCardFormattedPrice =  name === 'Referral Benefits ' ? `-$${ Number(pricee[1])?? price}/${billingType === 'month' ? 'month': 'year'}`   :  `$${priceFormatted?? price}/${billingType === 'month' ? 'month': 'year'}`  

  const handleOpenSmartTargetsModal = () => {
    setIsSmartTarget(true)
    setIsHovered(false)
  }

  const handleCloseSmartTargetsModal = () => {
    setIsSmartTarget(false)
    setIsHovered(false)
  }

  const  handleOpenContactUsCustomisationModal = () => {
    setIsContactUsModalCustomisation(true)
    setIsHovered(false)
  }

  const handleCloseContactUsCustomisationModal = () => {
    setIsContactUsModalCustomisation(false)
    setIsHovered(false)
  }
  
  const handleOpenContactUsModal = () => {
    setIsContactUsModal(true)
    setIsHovered(false)
  }

  const handleCloseContactUsModal = () => {
    setIsContactUsModal(false)
    setIsHovered(false)
  }

  const smallCardActions = (
    <CardActions className={classes.actionsRoot}>
      <div className={classes.smallCardActionWrapper}>
        <div className={classes.smallCardPriceTextWrapper}>
          <Text color={colors.lightRed} weight='medium' size="footnote">{smallCardFormattedPrice  } </Text>
        </div>
        <div className={classes.smallCardActionTextWrapper}>
          <Text size='small' color={colors.textGray} >{billingType === 'month' ? 'Monthly Payment' : 'Annual Payment' }  </Text>
          <Text size='small' color={colors.textGray} > Renews automatically on {nextPayment} </Text>
        </div>
      </div>
      <div className={classes.smallCardLastSection}> 
        <button  onClick={remove} className={classes.removeButton} > <Text size='footnote' weight='medium'> Cancel </Text>  </button>
      </div>
    </CardActions>
  )

  let formattedBuyButton={
    handleButtonClick: null,
    buttonText: '',
    disabled: false
  }

  let { handleButtonClick,  btnText, disabled }= formattedBuyButton

  if (staticCard && name === 'Customisation Request') {

    handleButtonClick = handleOpenContactUsCustomisationModal
    btnText =  buttonText
  }
  else if (staticCard && name === 'Custom Targets') {

    handleButtonClick = handleOpenSmartTargetsModal
    btnText =  buttonText
    disabled= isFree
  }

  else if (staticCard ) {
    handleButtonClick = handleOpenModal
    btnText =  buttonText
  }
  else if (isOneOffCharge && customPrice === false ) {
    handleButtonClick = buttonHandler
    btnText = 'Purchase'
    disabled= !isEligible
  }
  else if (customPrice === true && name === 'Customisations' ) {
    btnText = 'Request'
    disabled=!isEligible
  }
  else if (customPrice === true ) {
    btnText = 'Request'
    disabled=!isEligible
    handleButtonClick=handleOpenContactUsModal 
  }
  // else if (!isOneOffCharge && name === 'XERO integration' ) {
  //   handleButtonClick = buttonHandler
  //   btnText = 'Activate'
  //   // disabled= isXero
  // }
  else if (!isOneOffCharge) {
    handleButtonClick = buttonHandler
    btnText= 'Activate'
    disabled=!isEligible
  }

  const cardActions = (
    <CardActions className={classes.actionsRootStore}>
      <div className={classes.actionsWrapper}>
        <div className={classes.priceTextWrapper}>
          { !staticCard && <Text  color={ isEligible ? colors.lightRed : colors.disabled} weight='medium' size="body">{ formattedPrice  } </Text>}
        </div>
        {/* {!isEligible && 
            <Chip className={classes.chipPrice} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans</Text>} />
        }
        {isEligible && isXero && (name === 'Additional Logins' || name === 'Manual file integration') && (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) &&
             <Chip className={classes.chipPrice} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans</Text>} />
        }
        {isEligible && !isXero && (name === 'Additional Logins' || name === 'Manual file integration' || name === 'XERO integration') && (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) &&
              <Chip className={classes.chipPrice} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans</Text>} />
        } */}
        {/* isXero */}
        {isEligible && isXero && name === 'XERO integration' &&
                <Text
                  weight='medium'
                  size=
                    'footnote'
                  color={colors.primary}
                  className={classes.linkXero}  
                  // to={{
                  //   pathname: '/upgradeplan',
                  // }}
                  onClick={() => {
                    // window.scrollTo({ top: 0, behavior: 'smooth' })
                    remove()
                  }}
                >
                Cancel subscription
                </Text>
        }
        { isEligible && isXero && (name === 'Additional Logins' || name === 'Manual file integration') && (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) &&
          <div className={classes.upgradeNow}>
            {/* <Chip className={classes.chip} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans.</Text>} /> */}
            <Link
              className={classes.link}  
              to={{
                pathname: '/upgradeplan',
              }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
            Upgrade now!
            </Link>
          </div>
        }
        { isEligible && isXero && (name === 'Additional Logins' || name === 'Manual file integration') && (cancellationEffectiveDate === null || cancellationEffectiveDate === undefined) &&
           <div style={{ display: 'flex', flexDirection: 'column' }}>
             <SecondaryOutlinedButton2 
               onClick={handleButtonClick} 
               disabled={disabled}
               className={classes.button} 
             >
               {btnText}
             </SecondaryOutlinedButton2>
           </div>
        }

        {/* !isXero */}
        { isEligible && !isXero && (name === 'Additional Logins' || name === 'Manual file integration' || name === 'XERO integration') && (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) &&
          <div className={classes.upgradeNow}>
            {/* <Chip className={classes.chip} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans.</Text>} /> */}
            <Link
              className={classes.link}  
              to={{
                pathname: '/upgradeplan',
              }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
            Upgrade now!
            </Link>
          </div>
        }
        { isEligible && !isXero && (name === 'Additional Logins' || name === 'Manual file integration' || name === 'XERO integration') && (cancellationEffectiveDate === null || cancellationEffectiveDate === undefined) &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SecondaryOutlinedButton2 
              onClick={handleButtonClick} 
              disabled={disabled}
              className={classes.button} 
            >
              {btnText}
            </SecondaryOutlinedButton2>
          </div>
        }
        { isEligible && (name !== 'Additional Logins' && name !== 'Manual file integration' && name !== 'XERO integration') &&
         <div style={{ display: 'flex', flexDirection: 'column' }}>
           <SecondaryOutlinedButton2 
             onClick={handleButtonClick} 
             disabled={disabled}
             className={classes.button} 
           >
             {btnText}
           </SecondaryOutlinedButton2>
         </div>
        }
        {/* { isEligible && !isXero && (name === 'Additional Logins' || name === 'Manual file integration') && (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) &&
          <div className={classes.upgradeNow}>
            <Chip className={classes.chip} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans.</Text>} />
            <Link
              className={classes.link}  
              to={{
                pathname: '/upgradeplan',
              }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
            Upgrade now!
            </Link>
          </div>
        } */}
        {/* { isEligible && !isXero && name !== 'XERO integration' && (cancellationEffectiveDate === null || cancellationEffectiveDate === undefined) &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SecondaryOutlinedButton2 
              onClick={handleButtonClick} 
              disabled={disabled}
              className={classes.button} 
            >
              {btnText}
            </SecondaryOutlinedButton2>
          </div>
        } */}
        
        
        {/* { isEligible &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SecondaryOutlinedButton2 
              onClick={handleButtonClick} 
              disabled={disabled}
              className={classes.button} 
            >
              {btnText}
            </SecondaryOutlinedButton2>
          </div>
        } */}
        {!isEligible &&  !isMobile &&
          <div className={classes.upgradeNow}>
            {/* <Chip className={classes.chip} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans.</Text>} /> */}
            <Link
              className={classes.link}  
              to={{
                pathname: '/upgradeplan',
              }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
              Upgrade now!
            </Link>
          </div>
        }
        
        <CodeCustomModal
          isVisible={isModalOpen}
          cancel={handleCloseModal}
        />
        <ContactUsModal
          isVisible={isContactUsModal}
          cancel={handleCloseContactUsModal}
          name={name}
        />
        <ContactUsModalCustomisation
          isVisible={isContactUsModalCustomisation}
          cancel={handleCloseContactUsCustomisationModal} 
        />
        <SmartTargetModal
          isVisible={isSmartTarget}
          cancel={handleCloseSmartTargetsModal} 
        />
      </div>
    </CardActions>
  )

  return (
    <Card onMouseEnter={()=>handleHover()}  onMouseLeave={()=> handleMouseLeave()} className={ `${classes.root} ${  classes.hover} ${smallCard && classes.smallCard}`}>
      <CardContent style={{ padding: 0 }}  >
        <div className={classes.contentInnerWrapper}  >
          <div className={ classes.imgWrapper}>
            <img className={classes.img} src={img} />
          </div>
          <div className={classes.contentTextWrapper}>
            <div className={classes.nameWrapper} >
              <Text size='subnote' weight='medium' color={colors.primary}>{name} </Text>
            </div>
            {isEligible && isXero && name === 'XERO integration' &&
              <Chip className={classes.chipActive} label= {<Text weight='bold' style={{ fontSize: '10px', }} color={colors.white} >Active</Text>} />
            }
            {/* {!isEligible && 
            <Chip className={classes.chip} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans.</Text>} />
            }
            {isEligible && isXero && (name === 'Additional Logins' || name === 'Manual file integration') && (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) &&
             <Chip className={classes.chip} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans.</Text>} />
            }
            {isEligible && !isXero && (name === 'Additional Logins' || name === 'Manual file integration' || name === 'XERO integration') && (cancellationEffectiveDate !== null && cancellationEffectiveDate !== undefined) &&
              <Chip className={classes.chip} label= {<Text style={{ fontSize: '10px' }} color={colors.grayish} >Available for Paid Plans.</Text>} />
            } */}
            <div style={{ flex: 3 }} className={classes.descriptionWrapper} >
              <Text size='footnote' className={`${classes.descriptionText} ${ !isHovered && classes.hideDescText}`} color={colors.grayish}>
                {description}
              </Text>
            </div>
          </div>
        </div>
      </CardContent>
      {smallCard ? smallCardActions : cardActions } 
    </Card>
  )
}