import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 280,
    border: `1px solid ${colors.disabled}`,
    borderRadius: '5px',
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 15%',
    boxSizing: 'border-box',
    height: 300,
    marginInline: 7.5,
    position: 'relative',
    overflow: 'visible',
    marginTop: 50,
    // overflowX: 'clip',
    [theme.breakpoints.down('md')]: {
      marginBottom: 15,
      marginRight: 10,
    },
    // [theme.breakpoints.down(1500)]: {
     
    //   marginTop: 50,
    // },
    [theme.breakpoints.down('xs')]: {
      flex: '0 0 100%',
    },
    '&:hover': {
      boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.15)'
    }
  },
  rootOffer: {
    minWidth: 280,
    border: `1px solid ${colors.accent}`,
    borderRadius: '5px',
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 15%',
    boxSizing: 'border-box',
    height: 300,
    marginInline: 7.5,
    position: 'relative',
    overflow: 'visible',
    // overflowX: 'clip',
    marginTop: 50,
    // [theme.breakpoints.down(1500)]: {
     
    //   marginTop: 50,
    // },
    [theme.breakpoints.down('md')]: {
      marginBottom: 25,
    },
    [theme.breakpoints.down('xs')]: {
      flex: '0 0 100%',
    },
    '&:hover': {
      boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.15)'
    }
  },
  cardContent: {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderColor: colors.disabled,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 24px 0px',
  },
  cardContent2: {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderColor: colors.accent,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px 24px 0px',
    position: 'relative'
  },
  cardHeaderCostum: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5px 15px',
    height: '130px !important',
    flexShrink: 0,
    textAlign: 'center'
  
  },
  
  cardSubHeader: {
    backgroundColor: colors.primary,
    color: colors.white,
    textAlign: 'center',
    padding: 5
  },
  text: {
    width: '100%',
    textAlign: 'left',
    padding: '8px 12px',
    backgroundColor: colors.background,
    borderRadius: 5,
    marginBottom: 10
  },
  text2: {
    paddingTop: 10,
    textAlign: 'center',
  },
  bestOffer: {
    clipPath: 'polygon(0% 0%, 100% 0%, 84% 39%, 99% 84%, 9% 84%, 8% 100%, 0 84%)',
    marginLeft: '-9px',
    position: 'absolute',
    backgroundColor: '#EE4352',  
    marginTop: '127px', 
    height: '30px', 
    width: '110px', 
    borderWidth: 0, 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      clipPath: 'polygon(0% 0%, 100% 0%, 90% 39%, 100% 78%, 8% 78%, 7% 100%, 0 78%)',
      height: '35px', 
      width: '150px',
      marginTop: '125px', 
      textAlign: 'center'
    },
  },
  // Header
  planName: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 700,
    color: colors.primary
  },
  price: {
    fontFamily: 'Roboto',
    fontSize: 28,
    fontWeight: 'bold',
    color: colors.primary
  },
  type: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    color: colors.primary,
  },
  offerTitle: {
    alignSelf: 'center',
    backgroundColor: colors.secondary,
    width: '100%',
    color: 'white',
    textAlign: 'center',
    padding: 5
  },
  contactUsButton: {
    alignSelf: 'center',
    width: '90%',
    margin: 'auto',
    color: colors.white,
    backgroundColor: colors.primary,
    border: `1px solid ${colors.primary}`,
    fontSize: 14,
    '&:hover': {
      color: colors.primary,
      backgroundColor: colors.white,
      
    }
  },
  activeButton: {
    alignSelf: 'center',
    width: '90%',
    margin: 'auto',
    color: colors.primary,
    backgroundColor: colors.white,
    border: `1px solid ${colors.primary}`,
    fontSize: 14,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.primary,
      
    }
  },
  offerButton: {
    alignSelf: 'center',
    width: '90%',
    margin: 'auto',
    color: colors.white,
    backgroundColor: colors.secondary,
    border: `1px solid ${colors.secondary}`,
    '&:hover': {
      color: colors.secondary,
      backgroundColor: colors.white,
    }
  },
  topImg2: {
    marginTop: '-130px',
    position: 'absolute',
    width: 90,
    height: 90,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
  },
  imgInside: {
    marginTop: 10,
    marginRight: 5
  },
  topImg: {
    marginTop: '-130px',
    position: 'absolute',
    width: 90,
    height: 90,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
  },
  imgInside2: {
    marginTop: 10,
    marginRight: 4
  },
  imgInsideTera: {
    marginTop: 15,
    marginRight: 4
  },
  topImg3: {
    marginTop: '-150px',
    position: 'absolute',
    width: 90,
    height: 90,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
  },
  imgInside3: {
    marginTop: 15,
    marginRight: 5
  },
  topImg4: {
    marginTop: '-130px',
    position: 'absolute',
    marginLeft: 10,
    width: 90,
    height: 90,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
  },
  imgInside4: {
    marginTop: 10,
    marginRight: 5
  },
  priceFree: {
    display: 'flex', 
    gap: 2
  },
  currentPlanName: {
    display: 'flex', 
    paddingTop: 30
  },
  active: {
    display: 'flex', 
    alignItems: 'end', 
    marginTop: 10,
    // position: 'absolute',
    // top: 105
  },
  teraCard: {
    marginTop: 0,
    padding: '30px 8px' 
  },
  activeSection: {
    marginTop: -1,
    padding: '30px 8px' 
  }
}))

export default useStyles