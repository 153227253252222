import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useCStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  listRoot: {
    marginTop: 25,
    textAlign: 'center',
    width: '100%',
  },
  listItemRoot: {
    border: '1px solid' + colors.gray,
    borderRadius: 5,
    // justifyContent: 'space-between',
    position: 'relative',
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  successBadge: {
    paddingBlock: 5,
    paddingInline: 17,
    backgroundColor: '#11C32F',
    color: colors.white,
    border: '1px solid #11C32F',
    fontSize: 12,
    fontFamily: 'Roboto',
    position: 'absolute',
    top: -1,
    right: -1,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 5,
    textAlign: 'center',
    maxWidth: 80,
   
  },
  errorBadge: {
    paddingBlock: 5,
    paddingInline: 17,
    backgroundColor: '#EE4352',
    color: colors.white,
    border: '1px solid #EE4352',
    fontSize: 12,
    fontFamily: 'Roboto',
    position: 'absolute',
    top: -1,
    right: -1,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 5,
    textAlign: 'center',
    maxWidth: 80
  },
  inReview: {
    paddingBlock: 5,
    paddingInline: 17,
    backgroundColor: '#78A9FF',
    color: colors.white,
    border: '1px solid #78A9FF',
    fontSize: 12,
    fontFamily: 'Roboto',
    textAlign: 'center',
    maxWidth: 80,
    position: 'absolute',
    top: -1,
    right: -1,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  paused: {
    paddingBlock: 5,
    paddingInline: 17,
    backgroundColor: '#AAAAAA',
    color: colors.white,
    border: '1px solid #AAAAAA',
    fontSize: 12,
    fontFamily: 'Roboto',
    position: 'absolute',
    top: -1,
    right: -1,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 5,
    textAlign: 'center',
    maxWidth: 80
  },
  cancelled: {
    paddingBlock: 5,
    paddingInline: 17,
    backgroundColor: '#AAAAAA',
    color: colors.white,
    border: '1px solid #AAAAAA',
    fontSize: 12,
    fontFamily: 'Roboto',
    position: 'absolute',
    top: -1,
    right: -1,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 5,
    textAlign: 'center',
    maxWidth: 80
  },
  firstWrapper: {
    // flex: '0 0 50%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  secondWrapper: {
    // flex: '0 0 40%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    flexDirection: 'column',
    gap: 5
  },
  iconButton: {
    paddingRight: 0
  },
  copyIcon: {
    width: 20,
    height: 20
  },
  space: {
    marginBottom: 6
  },
  paymendId: {
    color: colors.accent,
    fontSize: 16,
    fontWeight: 400,
    cursor: 'pointer'
  },
  margin: {
    marginTop: 32
  },
  update: {
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'flex',
    gap: 10
  },
  noUpdate: {
    visibility: 'hidden'
  },
  border: {
    borderTop: '1px solid #EBEBEB',
    marginTop: 16,
    marginBottom: 12,
    width: '100%'
  },
  border2: {
    borderTop: '1px solid #EBEBEB',
    marginBlock: 16,
    width: '100%'
  },
  linkName: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer'
  },
  showMoreItems: {
    display: 'flex', 
    justifyContent: 'space-between',
    marginBottom: 11
  },
  showMoreItems2: {
    display: 'flex', 
    justifyContent: 'space-between'
  },
  firstItemsWrapper: { 
    display: 'flex', 
    justifyContent: 'space-between', 
    width: '100%' 
  }
}))

const useCStylesList = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60,
    height: '30vh',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

export { useCStyles, useStyles, useCStylesList }