import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    padding: '32px 10%',
    backgroundColor: colors.background,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 15px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px',
    },
  },
  container2: {
    backgroundColor: 'white',
    marginTop: 30,
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'space-evenly',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
        
    },
  },
  innerDiv2: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    // height: 500,
    [theme.breakpoints.down('sm')]: {
      width: '91%',
      marginTop: 30
    },
  },
  innerDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '91%'
    },
  },
  smallDiv: {
    marginBottom: 15
  },
  smallDiv3: {
    marginBottom: 10
  },
  smallDiv4: {
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
      
  },
  priceDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15
  },
  taxDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '56%',
    [theme.breakpoints.between('lg', 'xl')]: {
      marginBottom: '150px',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      marginBottom: '160px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '40px',
    }
  },
  taxDivAnnual: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
    // [theme.breakpoints.between('lg', 'xl')]: {
    //   marginBottom: '150px',
    // },
    // [theme.breakpoints.between('sm', 'lg')]: {
    //   marginBottom: '160px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   marginBottom: '40px',
    // }
  },
  pickAnnualReport: {
    display: 'flex',
    marginBottom: '16px',
  },
  dateRangePicker: {
    display: 'flex', 
    width: '100%', 
    paddingBottom: 10,
  },
  verticalLine: {
    borderRightColor: ' #C9C9C9',
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '0 0 26px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginTop: 20
    },
  },
  back: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    color: colors.primary,
    marginLeft: 5,
  },
  iconButtonClose: {
    position: 'absolute',
    left: 0,
    top: 0,
    // [theme.breakpoints.down(400)]: {
    //   alignSelf: 'flex-start',
    //   top: -20,
    //   left: -10,
        
        
    // },  
  },
  line: {
    borderBottomColor: ' #C9C9C9',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1
  },
  line2: {
    borderBottomColor: ' #C9C9C9',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    marginBottom: 16,
    marginTop: 16
  },
  line3: {
    borderBottomColor: ' #C9C9C9',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    marginBottom: 15
  },
  line4: {
    borderBottomColor: ' #EBEBEB',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    marginBottom: 15
  },
  headerText: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 14
    },
    [theme.breakpoints.down(400)]: {
      marginTop: '20px !important'
    },
  },
  cancelButton: {
    width: '100%',
    backgroundColor: colors.primary,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.primary,
    },
  },
  text: {
    fontStyle: 'italic !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px !important',
    },
  },
  imgDiv: {
    // height: '100%',
    // display: 'flex',
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      height: '80%',
    },
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  errorDiv: {
    display: 'flex', 
    alignItems: 'center' 
  },
  errorDivAllowed: {
    display: 'flex', 
    alignItems: 'center',
    marginBottom: 5
  },
  errorICon: {
    marginTop: 5 
  },
  errorText: {
    marginLeft: 5, 
    marginTop: 5 
  },
}))

const selectedStyles = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 44,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 18,
    zIndex: 3
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 48,
    flex: 1,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 48,
    borderRadius: 5,
    '&:hover': {
      border: `1px solid ${colors.accentHover}`,
    },
    border: '0px solid lightgray',
    boxShadow: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      paddingLeft: 12,
      backgroundColor: colors.background,
      height: 38,
      marginTop: 8,
      color: colors.primary,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
        cursor: 'pointer'
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
  indicatorSeparator: (styles) => ({ display: 'none' })
}

export  { useStyles, selectedStyles }