import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    paddingBottom: 20,
     
    marginTop: -40,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  planInformationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    margin: '5px 0 20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  
  },
  planInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  upgradeDowngrade: {
    display: 'flex', 
    flexDirection: 'column', 
    gap: 24
  },
  downgradeLink: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  button: {
    minWidth: 150,
    width: 330,
    alignSelf: 'center',
    fontSize: 14,
    '&:hover': {
      backgroundColor: colors.primaryHover
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
      alignSelf: 'flex-start',
      width: '50%'
  
    },
  },
  manageButton: {
    minWidth: 210,
    width: 330,
    alignSelf: 'center',
    marginTop: 20,
    fontSize: 14,
    '&:hover': {
      backgroundColor: colors.primaryHover
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
      alignSelf: 'flex-start',
      width: '50%',
  
    },
  },
  textSpace: {
    marginTop: 6
  },
  line: {
    borderBottomColor: ' #C9C9C9',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    marginBottom: 20
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  chooseDateText: {
    marginTop: 10,
    marginBottom: 10
  },
}))

export default useStyles