import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'
import { margin, maxWidth, width } from '@material-ui/system'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  explanationModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100vh'
  },
  chatMenuModal: {
    position: 'absolute',
    right: 20,
    top: 130
  },
  explanationPaper: {
    height: '100%',
    padding: 0,
    maxWidth: 550,
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      minWidth: 550,
    },
    [theme.breakpoints.down(960)]: {
      width: '100% !important',
      maxWidth: '100% !important',
      overflow: 'scroll',
    },
  },
  imageModal: {
    // padding: 'none',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '600px !important',
      marginInline: 20
    }
  },
  imagePaper: {
    maxWidth: 900
  },
  paper: {
    backgroundColor: colors.white,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    padding: 20,
    outline: 'none',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 550,
    },
  },
  paperImage: {
    backgroundColor: colors.white,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    padding: 20,
    outline: 'none',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 450,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 1000,
    },
  },
  backdrop: {
    backgroundColor: colors.primaryRGBA(0.5),
  },
  noBlur: {
    backgroundColor: colors.primaryRGBA(0.5),
  },
}))

export default useStyles