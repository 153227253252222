/* eslint-disable promise/always-return */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, Password, PrimaryButton, Text, TextInput } from '../../Components'
import { FormControl, FormControlLabel, Radio, RadioGroup, IconButton, Card, CardContent, useMediaQuery } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ClearIcon from '@material-ui/icons/Clear'
import { colors } from '../../Themes'
import useStyles from './DeletePlan.styles'
import { DeleteAccountSvg, loginAnimation, DeletePlans } from '../../Themes/Images'
import Loading from 'react-lottie'
import { useAuth } from '../../Global'
import { Auth } from 'aws-amplify'
import { Axios } from '../../Config'
import { useTheme } from '@material-ui/core/styles'

const FORM_CONTROL = [
  {
    key: 1,
    value: 'The reports were not helpful to me. The feature I will specify below was missing.',
    control: <Radio color="primary" />,
    label: <Text size='footnote' color={colors.primary}>The reports were not helpful to me. The feature I will specify below was missing.</Text>,
  },
  {
    key: 2,
    value: 'I will stay on the free plan and evaluate further another time.',
    control: <Radio color="primary" />,
    label: <Text size='footnote' color={colors.primary}>I will stay on the free plan and evaluate further another time.</Text>,
  },
  {
    key: 3,
    value: 'The pricing is not within my budgeting limit.',
    control: <Radio color="primary" />,
    label: <Text size='footnote' color={colors.primary}>The pricing is not within my budgeting limit.</Text>
  },
  {
    key: 4,
    value: 'I am going with another solution.',
    control: <Radio color="primary" />,
    label: <Text size='footnote' color={colors.primary}>I am going with another solution.</Text>,
  }
  , 
  {
    key: 5,
    value: 'Other',
    control: <Radio color="primary" />,
    label: <Text size='footnote' color={colors.primary}>Other.</Text>,
  }
]

const SECURITY_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loginAnimation,
  rendererSettings: {
    className: 'loginAnim',
  },
}

const SCREEN_CONTENT = {
  firstStep: 'firstStep',
  secondStep: 'secondStep',
}

const Header = () => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const isMobile = useMediaQuery(theme.breakpoints.down(760))

  return (
    <>
      {isMobile ? 
        null 
        : 
        <div className={classes.close}>
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.iconButtonClose }}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon classes={{ root: classes.closeIcon }} />
            <p className={classes.back}>Back</p>
          </IconButton>
        </div>
      }
      <div className={classes.headerFirst} >
        {isMobile ? 
          null 
          :  
          <div className={classes.closeFirst}>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              disableRipple={true}
              style={{ backgroundColor: 'transparent' }}
              classes={{ root: classes.iconButtonCloseFirst }}
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
              <p className={classes.backFirst}>Back</p>
            </IconButton>
          </div>
        }
        <p className={classes.headerTextFirst} >Cancel your subscription plan</p>
      </div>
    </>
  )
}


const FirstStep = ({ goToVerify, value, setValue, input, setInput }) => {
  
  const classes = useStyles()
  
  const handleChange = (event) => {
    setValue(event.target.value)
  }
 
  
  return (
    <div className={classes.containerFirst}>
      <Header />
      <div className={classes.dividerFirst} />
      <div className={classes.deleteAccountDescription}>
        <div className={classes.innerContainer}>
          <div className={classes.deleteReasons} >
            <span style={{ marginBottom: 10 }} className="deleteAccountLabel">
          Before you cancel your plan, we&apos;d really appreciate your feedback. <br></br> Why did you decide to cancel your plan?
            </span>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="reason"
                name="reasons"
                value={value}
                onChange={handleChange}
              >
                {FORM_CONTROL.map((item) => {
                  return (
                    <FormControlLabel key={item.key} value={item.value} control={item.control} label={item.label} classes={{ root: classes.formControlLabel }} />
                  )
                })}
              </RadioGroup>
            </FormControl>
            <Text size="footnote" color={colors.primary} className="deleteAccountParagraph"> * More details on the selected reason (or others) are welcome:</Text>
  
            <TextInput
              label='Give us your feedback - the more the better.'
              value={input}
              setValue={setInput}
              multiline={true}
              rows={4}
              className="inputttt"
            />
            <Text size="footnote" color={colors.primary} >Now it’s really time to say goodbye! </Text>
        
            <PrimaryButton
              className="submitButton"
              onClick={goToVerify}
              disabled={!value || input.trim().length === 0}
              style={{
                backgroundColor: input === '' && !input ? colors.disabled : colors.primary,
                color: colors.white,
                maxWidth: 250,
                marginTop: 15
              }}
            >
          Cancel plan
            </PrimaryButton>
          </div>
          <div className={classes.svg}>
            <DeletePlans style={{ objectFit: 'contain' }} />
          </div>
        </div>
      </div>
    </div>
  )
}

const SecondStep = ({ goBack, password, setPassword, email, verifyPassword, loading, error, setInput, setValue, isVisible }) => {
  const classes = useStyles()
  
  return (
    <div className={classes.container} >
      <div className={classes.header} >
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonCloseThird }}
          onClick={() => {
            setInput('')
            setValue('')
            goBack(SCREEN_CONTENT.firstStep)
          }}
        >
          <ArrowBackIcon classes={{ root: classes.closeIcon }} />
          <p className={classes.back}>Back</p>
  
        </IconButton>
        <p className={classes.headerText} >Verify account</p>
      </div>
      <div className={classes.line} />
      <div className={classes.verifyWrapper} >
        <div className={classes.animationContainer}>
          <Loading options={SECURITY_OPTIONS} height={600} />
        </div>
        <div className={classes.cardContainer}>
          <Card className={classes.cardRoot} variant="outlined">
            <div className={classes.cardTitle}>
              <Text size='footnote' color={colors.white}  >For your own security, verify your account.</Text>
            </div>
            <CardContent classes={{ root: classes.MuiCardContentRoot }} >
              <Text size='h5' color={colors.accent} weight="bold" style={{ marginBottom: 29 }} >Enter your password</Text>
              <Text size="footnote" color={colors.primary} weight="light" style={{ marginBottom: 6 }} >Account</Text>
              <Text size="footnote" color={colors.primary} weight="regular">{email}</Text>
              <Password
                label="Password"
                value={password}
                setValue={setPassword}
              />
              {Boolean(error) && <span className="loginError" style={{ textAlign: 'start', color: colors.secondary, width: 365, lineHeight: 1.5 }}>{error}</span>}
              <PrimaryButton
                classes={{ root: classes.continueButton }}
                style={
                  password === '' || loading
                    ? { backgroundColor: colors.disabled, minHeight: 50 }
                    : { backgroundColor: colors.primary, minHeight: 50 }
                }
                disabled={password === '' || loading}
                loading={loading}
                onClick={verifyPassword}
              >
                  Continue
              </PrimaryButton>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

const DeletePlanSteps = {
  firstStep: FirstStep,
  secondStep: SecondStep,
}

const DeletePlan = () => {
  const classes = useStyles()
  const { user, userAttributes, getUpdatedUser } = useAuth()
  const [deleteStatus, setDeleteStatus] = useState(SCREEN_CONTENT.firstStep)
  const DeleteSteps = DeletePlanSteps[deleteStatus]
  const [loading, setLoading] = useState(false)
  const [opened, setOpened] = useState(false)
  const [value, setValue] = useState('')
  const [input, setInput] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const history = useHistory()

  const navigate = (url) => history.push(url)
  
  
  const verifyPassword = () => {
    setLoading(true)
    Auth.signIn(user, password)
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setDeleteStatus(SCREEN_CONTENT.firstStep)
        // {(is_trialing || free) ?  showReferralModal() : checkout()}
        setOpened(true)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setError('The password you entered did not match our records. Please double-check it and try again.')
      })
  }
  
  const cancelPlan = async () => {
    setLoading(true)
    try {
      await Axios.post('/prod/user/setupfreeaccount',
        {
          cancelReason: `${value} ${input}`
        }
      )
      await getUpdatedUser()
      setLoading(false)
      navigate('/dashboard')
      //   showModal()
    } catch (error) {
      setLoading(false)
    }
  
  }
  
  // const handleOpen = () => {
  //   setOpened(true)
  // }
  
  const handleClose = () => {
    setOpened(false)
    setValue('')
    setInput('')
  }
  
  const submitReason = () => {
    setOpened(true)
  }
  
  return (
    <div className="deleteAccountContainer">
      <DeleteSteps
        onNextClick={() => setDeleteStatus(SCREEN_CONTENT.secondStep)}
        
        value={value}
        setValue={setValue}
        input={input}
        setInput={setInput}
        goToVerify={() => setDeleteStatus(SCREEN_CONTENT.secondStep)}
  
        goBack={() => setDeleteStatus(SCREEN_CONTENT.firstStep)}
        verifyPassword={verifyPassword}
        password={password}
        setPassword={setPassword}
        email={userAttributes?.email}
        loading={loading}
        error={error}
        // openModal={handleOpen}
      />
      <Modal costumizedPaperStyles={classes.paperCostum} opened={opened} handleClose={handleClose}>
          
        <div className={classes.modalContent}>
          <div className={classes.subModal}>
            <div className={classes.text}>
              <Text style={{ alignItems: 'center' }} size='h5' color={colors.accent} weight='bold' >Cancel subscription</Text>
            </div>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              disableRipple={true}
              style={{ backgroundColor: 'transparent' }}
              classes={{ root: classes.iconButtonClose }}
              onClick={() => handleClose()}
            >
              <ClearIcon classes={{ root: classes.closeIcon }} />
            </IconButton>
          </div>
            
          <div className={classes.divider} style={{ marginTop: 20, marginBottom: 30 }} />
          <DeleteAccountSvg />
            
          <Text size='subnote' className="modalDescription">
              Are you sure you want to cancel your plan subscription?
          </Text>
          <div className={classes.divider} style={{ marginTop: 10, marginBottom: 0 }} />
          <div className={classes.modalButtons}>
            <PrimaryButton
              onClick={() => handleClose()}
              className={classes.secondButton}
              style={{ border: '1px solid #cccccc' }}
            >
                Go back
            </PrimaryButton>
            <PrimaryButton
              onClick={cancelPlan}
              className={classes.firstButton}
              loading={loading}
              style={{ backgroundColor: loading ? colors.disabled : colors.primary }}
            >
                Yes, cancel it
            </PrimaryButton>
  
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DeletePlan