/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { ReportCard, SecondaryButton, SecondLoader, SuccessNotify, Text } from '../../Components'
import { dayCalendar, monthlyCalendar, undrawEmail, weeklyCalendar } from '../../Themes/Images'
import { ReportEmailInput, ReportsCheckBox } from '../../Components/Reports'
import { Axios } from '../../Config'
import { colors } from '../../Themes'
import {  useMutation } from 'react-query'
import { useAuth, useSubscription } from '../../Global'
import './Reports.css'
import { useMediaQuery } from '@material-ui/core'
import useStyles from './Reports.styles'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

const DAILYDEFAULTSTATE={
  report_subscription: true,
  share_report_emails: [
  ]
}

const MONTHLYDEFAULTSTATE={
  report_subscription: true,
  share_report_emails: [
  ]
}
const WEEKLYDEFAULTSTATE={
  report_subscription: true,
  share_report_emails: [
  ]
}

const selectedState={
  index: 0,
  name: 'daily'
}




function UpdateReport(body) {
  return Axios.post('/prod/reports/config', body)
}

const EMAIL_LIMIT={
  'FREE': 1,
  'MEGA': 3,
  'GIGA': 5,
  'ACHIEVE': 10,
  'CHRIS': 10,
  'CUSTOMISED': 10,
}
function Reports() {
  const classes=useStyles()
  const { tooltips, userAttributes, is_trialing, plan: globalPlan, given_name }=useAuth()
  const { subscription, plan }=useSubscription()
  const [reports, setReports] = useState({})
  const [dailyReport, setDaylyReport] = useState(DAILYDEFAULTSTATE)
  const [weeklyReport, setweeklyReport] = useState(MONTHLYDEFAULTSTATE)
  const [monthlyReport, setMonthlyReport] = useState(WEEKLYDEFAULTSTATE)
  const [selectedReport, setSelectedReport] = useState(selectedState)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const theme = useTheme()


  // const free = plan?.length === 0
  const free = globalPlan === 'free'

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // const [emailLimit]=useState(EMAIL_LIMIT[plan?.[0]?.name || 'Free'] )
  const [emailLimit]=useState(EMAIL_LIMIT[tooltips] )

  const {  mutate, isLoading: isLoadingMutation } = useMutation(body=> UpdateReport(body), {
    mutationKey: 'updateReports',
    onSuccess: async ()=> {
      await   getReports()
      setIsSuccess(true)

    } 
  })

  const [disabledButtons, setDisabledButtuns] = useState({
    dailyButtonDisabled: true,
    weeklyButtonDisabled: true,
    monthlyButtonDisabled: true,

  })

  const getReports=async ()=>{
    try {
      const { data } = await Axios.get(
        '/prod/reports/config',
      )
      setReports(data?.data)
      if (data?.data){
        if (data?.data.daily){
          setDaylyReport({
            report_subscription: data?.data.daily?.report_subscription,
            share_report_emails: data?.data.daily?.share_report_emails
          })
        }
        if (data?.data.weekly){
          setweeklyReport({
            report_subscription: data?.data.weekly?.report_subscription,
            share_report_emails: data?.data.weekly?.share_report_emails
          })
        }
        if (data?.data.monthly){
          setMonthlyReport({
            report_subscription: data?.data.monthly?.report_subscription,
            share_report_emails: data?.data.monthly?.share_report_emails
          })
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

    }
  }


  useEffect(() => {
    getReports()
  }, [])


  useEffect(() => {
    if (!isEmpty(reports)){
      if ( (reports?.daily?.share_report_emails?.toString() !== dailyReport?.share_report_emails?.toString()) || reports?.daily?.share_report_emails?.length !== dailyReport?.share_report_emails?.length 
      || reports?.daily?.report_subscription !== dailyReport?.report_subscription  ){
        setDisabledButtuns((prev)=>({
          ...prev,
          dailyButtonDisabled: false
        }))
      } else {
        setDisabledButtuns((prev)=>({
          ...prev,
          dailyButtonDisabled: true
        }))
      }
      if ( (reports?.weekly?.share_report_emails?.toString() !== weeklyReport?.share_report_emails?.toString()) || reports?.weekly?.share_report_emails?.length !== weeklyReport?.share_report_emails?.length
       || reports?.weekly?.report_subscription !== weeklyReport?.report_subscription ){
        setDisabledButtuns((prev)=>({
          ...prev,
          weeklyButtonDisabled: false
        }))
      } else {
        setDisabledButtuns((prev)=>({
          ...prev,
          weeklyButtonDisabled: true
        }))
      }
      if ( (reports?.monthly?.share_report_emails?.toString() !== monthlyReport?.share_report_emails?.toString()) || reports?.monthly?.share_report_emails?.length !== monthlyReport?.share_report_emails?.length
       || reports?.monthly?.report_subscription !== monthlyReport?.report_subscription ){
        setDisabledButtuns((prev)=>({
          ...prev,
          monthlyButtonDisabled: false
        }))
      } else {
        setDisabledButtuns((prev)=>({
          ...prev,
          monthlyButtonDisabled: true
        }))
      }

    }
  }, [dailyReport, weeklyReport, monthlyReport])


  const onUpdate= async ()=>{
    let body

    if (selectedReport.name==='daily'){
      body={
        report_timeframe: selectedReport.name,
        report_subscription: dailyReport.report_subscription,
        share_report_emails: dailyReport.share_report_emails
      }
    }
    if (selectedReport.name==='weekly'){
      body={
        report_timeframe: selectedReport.name,
        report_subscription: weeklyReport.report_subscription,
        share_report_emails: weeklyReport.share_report_emails
      }
    }
    if (selectedReport.name==='monthly'){
      body={
        report_timeframe: selectedReport.name,
        report_subscription: monthlyReport.report_subscription,
        share_report_emails: monthlyReport.share_report_emails
      }
    }
    mutate(body)
  }

 

  const onReportSubChange=(event, type)=>{
    if (type==='daily'){
      setDaylyReport({
        ...dailyReport,
        report_subscription: event.target.checked
      })
    }
    if (type==='weekly'){
      setweeklyReport({
        ...weeklyReport,
        report_subscription: event.target.checked
      })
    }
    if (type==='monthly'){
      setMonthlyReport({
        ...monthlyReport,
        report_subscription: event.target.checked
      })
    }
  }


  if (isLoading){
    return <SecondLoader/>
  }

  if (given_name === 'Halaxy') {
    return <Redirect push to='/dashboard' />
  }

  return (
    <div className={classes.container}>
      <Text size="subnote" weight='bold' className={classes.mainTittle}>Choose an email report and modify its settings:</Text>   
      <div className={classes.containertWrapper}>
        <div className={classes.firstWrapper}>
          { !isEmpty(subscription)  &&  <Text size="subnote" className={classes.space}> Select the report to edit the settings : </Text>  }   
          <div className={classes.cardWrapper}>
            {reports['daily'] &&
                 <ReportCard
                   key={0}
                   img={dayCalendar}
                   tittle="Daily Report"
                   selected={selectedReport.index===0}
                   onClick={()=>setSelectedReport({
                     index: 0,
                     name: 'daily'
                   })}
                 />
            }
            {reports['weekly'] &&
                 <ReportCard
                   key={1}
                   img={weeklyCalendar}
                   tittle="Weekly Report"
                   selected={selectedReport.index===1}
                   onClick={()=>setSelectedReport({
                     index: 1,
                     name: 'weekly'
                   })}
                   disabled={(free && (is_trialing===false || is_trialing===undefined)) || plan?.[0]?.name === 'Mega'}
                   free={free}
                 />
            }
            {reports['monthly'] &&
                 <ReportCard
                   key={2}
                   img={monthlyCalendar}
                   tittle="Monthly Report"
                   selected={selectedReport.index===2}
                   onClick={()=>setSelectedReport({
                     index: 2,
                     name: 'monthly'
                   })}
                   disabled={(free && (is_trialing===false || is_trialing===undefined))}
                   free={free}
                 />
            }

          </div>
          <div className={classes.divider} />
        </div>
        <div className={classes.gap}>
          <div className={classes.columnFlex}>
            {selectedReport.index===0 && 
            <>
              <ReportsCheckBox
                checked={dailyReport.report_subscription}
                tittle={<Text size='footnote'>Send this report to my main email ({userAttributes?.email}).</Text>}
                handleChange={(event)=>{onReportSubChange(event, 'daily')}}
              />
              <Text size="subnote"  style={{ margin: '15px 0 10px', fontWeight: '500' }}>Type other email addresses you would like the chosen report to be sent to:</Text>
              <ReportEmailInput
                values={dailyReport}
                setValues={setDaylyReport}
                limit={emailLimit}

              />
              <Text size='footnote' color={colors.primary} style={{ margin: '8px 0 50px', opacity: 0.5 }}>  <b>Note</b>: Your current plan limits the number of email addresses to {emailLimit}.</Text>
              <SecondaryButton
                onClick={onUpdate}
                className={classes.button}
                disabled={isLoadingMutation||disabledButtons.dailyButtonDisabled}
                loading={isLoadingMutation}
                style={ isLoadingMutation||disabledButtons.dailyButtonDisabled ? { backgroundColor: colors.disabled, color: colors.white } :
                  { backgroundColor: colors.primary, color: colors.white }
                }
              >
              Update
              </SecondaryButton>
            </>

            }
            {selectedReport.index===1 &&
          <>
            <ReportsCheckBox
              handleChange={(event)=>{onReportSubChange(event, 'weekly')}}
              checked={weeklyReport.report_subscription}
              tittle={<Text size='footnote'>Send this report to my main email ({userAttributes?.email}).</Text>}
            />
            <Text size="subnote" weight='medium'  style={{ margin: '15px 0 10px' }}>Type other email addresses you would like the chosen report to be sent to:</Text>
            <ReportEmailInput
              values={weeklyReport}
              setValues={setweeklyReport}
              limit={emailLimit}

            />
            <Text size='footnote' color={colors.primary} style={{ margin: '8px 0 50px', opacity: 0.5 }}>  <b>Note</b>: Your current plan limits the number of email addresses to {emailLimit}.</Text>

            <SecondaryButton
              onClick={onUpdate}
              className={classes.button}
              disabled={isLoadingMutation ||disabledButtons.weeklyButtonDisabled}
              loading={isLoadingMutation}
              style={ isLoadingMutation ||disabledButtons.weeklyButtonDisabled ? { backgroundColor: colors.disabled, color: colors.white } :
                { backgroundColor: colors.primary, color: colors.white }
              }
            >
              Update
            </SecondaryButton>
          </>
            }
            {selectedReport.index===2 &&
          <>
            <ReportsCheckBox
              handleChange={(event)=>{onReportSubChange(event, 'monthly')}}
              checked={monthlyReport.report_subscription}
              tittle={<Text size='footnote'>Send this report to my main email ({userAttributes?.email}).</Text>}
            />
            <Text size="subnote"  style={{ margin: '15px 0 10px', fontWeight: '500' }}>Type other email addresses you would like the chosen report to be sent to:</Text>
            <ReportEmailInput
              values={monthlyReport}
              setValues={setMonthlyReport}
              limit={emailLimit}
            />
            <Text size='footnote' color={colors.primary} style={{ margin: '8px 0 50px', opacity: 0.5 }}>  <b>Note</b>: Your current plan limits the number of email addresses to {emailLimit}.</Text>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <SecondaryButton
                onClick={onUpdate}
                className={classes.button}
                style={ isLoadingMutation||disabledButtons.monthlyButtonDisabled ? { backgroundColor: colors.disabled, color: colors.white,  width: isMobile ? '100%' : 350 } :
                  { backgroundColor: colors.primary, color: colors.white,  width: isMobile ? '100%' : 350 }
                }
                disabled={isLoadingMutation||disabledButtons.monthlyButtonDisabled}
                loading={isLoadingMutation}
              >
              Update
              </SecondaryButton>
            </div>
          </>
            }
         
          </div>
          <div className={classes.imageWrapper}>
            <img src={undrawEmail} />
          </div>
        </div>
      </div>
      <SuccessNotify open={isSuccess} setOpen={setIsSuccess} message="Report settings saved successfully" /> 
    </div>
    
  )
}

export default Reports
