/* eslint-disable max-len */
import React, {  useRef } from 'react'
import ReactCodeInput from 'react-code-input'
import { PrimaryButton, Text } from '..'
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { colors } from '../../Themes'
import { useStyles } from './ForgotPasswordPin.styles'

const ForgotPasswordPin = (
  {
    error,
    email,
    goFirstStep,
    goNewPasswordStep,
    pin,
    setPin,
    onForgotPassword,
  }
) => {
  const classes = useStyles()
  let codeRef = useRef()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(484))
  const isMobile2 = useMediaQuery(theme.breakpoints.between(420, 484))
  const isMobile3 = useMediaQuery(theme.breakpoints.down(420))

  const clearInputCodeInput = () => {
    if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus()
    codeRef.current.state.input[0] = ''
    codeRef.current.state.input[1] = ''
    codeRef.current.state.input[2] = ''
    codeRef.current.state.input[3] = ''
    codeRef.current.state.input[4] = ''
    codeRef.current.state.input[5] = ''
  }

  return (
    <div className={classes.container}>
      <IconButton
        disableFocusRipple
        centerRipple={true}
        disableRipple={true}
        style={{ backgroundColor: 'transparent' }}
        classes={{ label: classes.iconLabel, root: classes.iconRoot }}
        onClick={goFirstStep}
      >
        <ArrowBackIcon />
        <p style={{ marginLeft: 5, fontWeight: 'bold' }}>Back</p>
      </IconButton>
      <Text size="h5" color={colors.accent} weight="bold" >PIN Verification</Text>
      <Text size="footnote" color={colors.primary} className={classes.space} > Account recovery <br /> We have sent a PIN code to <b>{email}</b> <br />Check your email address and enter the correct PIN to finalise your account recovery.
      </Text>
      <div className={classes.reactCodeContainer}>
        <div className={classes.underReactCodeContainer}>
          <ReactCodeInput
            ref={codeRef}
            value={pin}
            onChange={
              (res) => {
                setPin(res)
              }
            }
            type='number'
            fields={6}
            inputStyle={{
              flex: '0 1 12%',
              maxWidth: isMobile2 ? 45 : isMobile3 ? 31 : 50,
              height: isMobile2 ? 45 : isMobile3 ? 31 : 50,
              fontFamily: 'monospace',
              marginRight: isMobile ? 5 : 16,
              MozAppearance: 'textfield',
              borderRadius: '5px',
              fontSize: isMobile ? 16 : 32,
              textAlign: 'center',
              backgroundColor: '#F5F5F5',
              color: colors.primary,
              border: '1px solid #F5F5F5',
            }}
            // {...codeProps}
          />
          {Boolean(error) && (
            <span className="ConfirmPasswordError" style={{ maxWidth: '100%', marginTop: 12 }}>{error}</span>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span className={classes.resendCode} onClick={() => {
            onForgotPassword('resendCode')
            clearInputCodeInput()
          }
          }> Resend code </span>
        </div>
      </div>
      <PrimaryButton
        disabled={Boolean(error) || pin.length < 6}
        className={classes.continueButton}
        style={{
          backgroundColor: Boolean(error) || pin.length < 6 ? colors.disabled : colors.primary,
          color: colors.white,
        }}
        onClick={goNewPasswordStep}
      >
        Continue
      </PrimaryButton>
    </div>
  )
}

export default ForgotPasswordPin