const amplifyConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_NEW_USER_POOL_ID,
    clientId: process.env.REACT_APP_COGNITO_NEW_CLIENT_ID,
    userPoolWebClientId: process.env.REACT_APP_NEW_WEB_CLIENT_ID,
    region: process.env.REACT_APP_NEW_COGNITO_REGION,
    // identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    cognitoAuthenticatedLogins: process.env.REACT_APP_COGNITO_AUTHENTICATED_LOGINS,
    roleSessionName: process.env.REACT_APP_ROLE_SESSION_NAME,
    apiGatewayUrl: process.env.REACT_APP_API_GATEWAY_URL,
    authenticationFlowType: process.env.REACT_APP_FLOWTYPE
  },
  cloudfront: process.env.REACT_APP_CLOUDFRONT,
};

export default amplifyConfig;
