import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { cellStyles, tableRowStyles, useStylesTable } from '../PaymentList/PaymentsTable/PaymentsTable.styles'
import Text from '../Text'

const useStylesSkeleton = makeStyles({
  root2: {
    background: '#c9c9c9',
    borderRadius: 5
  }
})

const StyledTableCell = withStyles((theme) => (cellStyles))(TableCell)

const StyledTableRow = withStyles((theme) => (tableRowStyles))(TableRow)

const TableItem = ({ purchase, classesSkeleton }) => (
  <StyledTableRow>
    <StyledTableCell component="th" scope="row">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={120} height={20} />
    </StyledTableCell>
    {purchase && <StyledTableCell component="th" scope="row">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={120} height={20} />
    </StyledTableCell>
    }
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={120} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
    <StyledTableCell>
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={30} height={20} />
    </StyledTableCell>
  </StyledTableRow>
)

export default function SkeletonTable({ purchase }) {
  const classes = useStylesTable()
  const classesSkeleton = useStylesSkeleton()

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow className={classes.tableFont}>
            <StyledTableCell>Date</StyledTableCell>
            {purchase &&  <StyledTableCell align="left">Purchase</StyledTableCell>}
            <StyledTableCell align="left">Payment ID</StyledTableCell>
            <StyledTableCell align="left">Amount</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableItem classesSkeleton={classesSkeleton} purchase={purchase} />
          <TableItem classesSkeleton={classesSkeleton} purchase={purchase} />
          <TableItem classesSkeleton={classesSkeleton} purchase={purchase} />
          <TableItem classesSkeleton={classesSkeleton} purchase={purchase} />
          <TableItem classesSkeleton={classesSkeleton} purchase={purchase} />
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TableItemForFile = ({ classesSkeleton }) => (
  <StyledTableRow>
    <StyledTableCell component="th" scope="row">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={120} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={120} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={150} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
  </StyledTableRow>
)

const TableItemForFileHistory = ({ classesSkeleton }) => (
  <StyledTableRow>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
    <StyledTableCell align="left">
      <Skeleton classes={{ root: classesSkeleton.root2 }} width={80} height={20} />
    </StyledTableCell>
  </StyledTableRow>
)

export function SkeletonTableForFile() {
  const classes = useStylesTable()
  const classesSkeleton = useStylesSkeleton()

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow className={classes.tableFont}>
            <StyledTableCell><Text size='footnote' weight='bold'>File Name</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Template Name</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Upload Date</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Last Update</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Type</Text></StyledTableCell>
            <StyledTableCell align="center"><Text size='footnote' weight='bold'>Status</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Actions</Text></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableItemForFile classesSkeleton={classesSkeleton} />
          <TableItemForFile classesSkeleton={classesSkeleton} />
          <TableItemForFile classesSkeleton={classesSkeleton} />
          <TableItemForFile classesSkeleton={classesSkeleton} />
          <TableItemForFile classesSkeleton={classesSkeleton} />
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function SkeletonTableForFileHistory() {
  const classes = useStylesTable()
  const classesSkeleton = useStylesSkeleton()

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow className={classes.tableFont}>
            <StyledTableCell><Text size='footnote' weight='bold'>File Name</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Template Name</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Upload Date</Text></StyledTableCell>
            <StyledTableCell align="left"><Text size='footnote' weight='bold'>Type</Text></StyledTableCell>
            <StyledTableCell align="center"><Text size='footnote' weight='bold'>Status</Text></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableItemForFileHistory classesSkeleton={classesSkeleton} />
          <TableItemForFileHistory classesSkeleton={classesSkeleton} />
          <TableItemForFileHistory classesSkeleton={classesSkeleton} />
          <TableItemForFileHistory classesSkeleton={classesSkeleton} />
          <TableItemForFileHistory classesSkeleton={classesSkeleton} />
        </TableBody>
      </Table>
    </TableContainer>
  )
}