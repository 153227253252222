import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const requestFileChangeModalStyles = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    // paddingBottom: 0,
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
      width: '80%'
    },
  },
  paperCostum2: {
    padding: '48px 10px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  subText: {
    marginTop: 5,
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    [theme.breakpoints.down(760)]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  subText2: {
    marginTop: 0,
    marginBottom: 10
  },
  spaceText: {
    margin: '22px 0 15px 0'
  },
  spaceText2: {
    margin: '17px 0 0px 0',
    alignSelf: 'start'
  },
  spaceTittle: {
    marginTop: 16,
    alignSelf: 'center'
  },
  underTitle: {
    alignSelf: 'start'
  },
  buttonsWrapper: {
    display: 'flex',
    gap: 10,
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  buttonsWrapper2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
   
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  cancelButton: {
    width: '50%',
    backgroundColor: colors.white,
    border: '1px solid #cccccc',
    height: 50,
    color: colors.primary,
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white,
      border: `1px solid ${colors.grayHover}`,
    },
  },
  cancelButton2: {
    width: '50%',
    backgroundColor: colors.white,
    border: '1px solid #cccccc',
    height: 50,
    color: colors.primary,
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white,
      border: `1px solid ${colors.grayHover}`,
    },
  },
  nextButton: {
    width: '50%',
    height: 50,
  },
  text: {
    marginBottom: 32,
    textAlign: 'center',
  },
  text2: {
    marginBottom: 25
  },
  iconButtonClose: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  errorDiv: {
    display: 'flex', 
    alignItems: 'center' 
  },
  errorText: {
    marginLeft: 5, 
    marginTop: 5 
  },
  dropwdownWrapper: { 
    display: 'flex', 
    flexDirection: window.innerWidth < 500 ? 'column' : 'row', 
    marginTop: 16,
    fontSize: 18,
    color: 'hsl(0, 0%, 50%)'
  },
  border: {
    borderTop: '1px solid #EBEBEB',
    marginBottom: 30,
    width: '100%'
  },
  border2: {
    borderTop: '1px solid #EBEBEB',
    marginTop: 0,
    width: '100%'
  },
  border3: {
    borderTop: '1px solid #EBEBEB',
    marginTop: 17,
    width: '100%'
  },
  border4: {
    borderTop: '1px solid #EBEBEB',
    marginTop: 0,
    width: '100%',
    marginBottom: 24
  },
  root: {
    '& .MuiStep-horizontal': {
      paddingInline: window.innerWidth < 500 && 2
    },
    '& .MuiStepper-root': {
      paddingInline: 0,
      paddingBottom: 24,
      paddingTop: 22
    },
    '& .MuiStepIcon-root': {
      color: colors.white,
      // width: 30,
      // height: 30,
      border: `1px solid ${colors.primary }`,
      borderRadius: '50%'
    },
    '& .MuiStepIcon-active': {
      color: colors.primary,
      // width: 30,
      // height: 30,
      '& .MuiStepIcon-text': {
        fill: colors.white
      },
    },
    '& .MuiStepIcon-completed': {
      color: colors.primary,
      // width: 30,
      // height: 30,
    },
    '& .MuiStepIcon-text': {
      fill: colors.primary
    },
  },
  spaceText3: {
    margin: '10px 0 0px 0'
  },
  text3: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
  text4: {
    marginTop: 10,
    marginBottom: 20,
    textAlign: 'center',
  },
  img: {
    objectFit: 'contain'
  },
  thirdStepWrapper: {
    display: 'flex', 
    flexDirection: 'column',
    gap: 20, 
    alignItems: 'center', 
    justifyContent: 'center'
  }
}))

const selectedStyles = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 44,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 18,
    zIndex: 3
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 58,
    flex: 1,
    color: 'hsl(0, 0%, 50%)'
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 58,
    borderRadius: 5,
    '&:hover': {
      border: `1px solid ${colors.accentHover}`,
    },
    border: '0px solid lightgray',
    boxShadow: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      paddingLeft: 12,
      backgroundColor: colors.background,
      height: 38,
      marginTop: 8,
      color: colors.primary,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
        cursor: 'pointer'
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
  indicatorSeparator: (styles) => ({ display: 'none' })
}

export { requestFileChangeModalStyles, selectedStyles } 