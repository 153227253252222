import React from 'react'
import { PrimaryButton } from '..'
import Lottie from 'react-lottie'
import { useHistory } from 'react-router'
import { lock } from '../../Themes/Images'
import useStyles from './NoPermission.styles'
import { Link } from '@material-ui/core'

const LOCK_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: lock,
  rendererSettings: {
    className: 'loginAnim',
  },
}

function NoPermission() {
  const history=useHistory()
  const classes = useStyles()
  return (
    <div className={classes.targetsContainer}>
      <div className={classes.TargetsFreeAccountMessage}>
        <Lottie options={LOCK_OPTIONS} height={200} />
        <p>Targets are not available for users on the Free Plan.</p>
        <p>
        Upgrade to get access to this and more reports for your practice.{' '}
        </p>
        <PrimaryButton
          classes={{ root: classes.compareButton }}
          onClick={() =>history.push('/upgradeplan')}
          type="submit"
        >
            Upgrade now
        </PrimaryButton>
        <Link target='_blank' href='https://dataplayer.io/pricing'>Check Pricing Plans</Link>
      </div>
    </div>
  )
}

export default NoPermission
