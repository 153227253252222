import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  containerTarget: {
    flex: 1,
    paddingBottom: 20,
     
    marginTop: -40,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  isActive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EE4352',
    minWidth: 120,
    minHeight: 48,
    borderRadius: 5,
    cursor: 'pointer',
    [theme.breakpoints.down(450)]: {
      minWidth: 100,
    },
    [theme.breakpoints.down(380)]: {
      minWidth: 80,
    },
  },
  isNotActive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background,
    minWidth: 120,
    minHeight: 48,
    borderRadius: 5,
    cursor: 'pointer',
    [theme.breakpoints.down(450)]: {
      minWidth: 100,
    },
    [theme.breakpoints.down(380)]: {
      minWidth: 80,
    },
  },
  periodPicker: {
    display: 'flex',
    gap: 16,
    paddingTop: 16
  },
  addValues: {
    paddingTop: 26,
    display: 'flex'
  },
  targetsContainer: {
    flex: 1,
    // paddingTop: 5,
    // paddingBottom: 10,
    // paddingLeft: '15px ',
    // paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  tragetsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  inputContent: {
    flex: 1,
  },
  title: {
    color: colors.accent,
    marginTop: '8px !important',
    marginBottom: '8px !important'
  },
  mainTitle: {
    color: colors.primary,
    marginBottom: '8px !important',
    lineHeight: 1.4
  },
  targetsPicture: {
    width: 500,
    height: 350,
    display: 'flex', 
    justifyContent: 'flex-end',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  input: {
    flexGrow: 1,
    borderWidth: 5,
    borderColor: colors.accent,
    // minWidth: 280,
    marginTop: 15,
    marginBottom: 15,
    maxWidth: '430px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  textField: {
    width: '25ch',
  },
  
  saveButton: {
    marginRight: 15,
    width: '100% !important',
    color: colors.white,
    marginTop: 30,
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      width: 250,
      alignSelf: 'flex-end',
    },
  },
  alignedPlaceHolder: {
    color: colors.primary,
  },
  
  successContent: {
    maxHeight: 50,
    paddingRight: 5,
    backgroundColor: ' rgba(51, 170, 51, 0.2)',
    display: 'flex',
    borderRadius: 10,
    alignItems: 'center',
    margin: ' 15px 0 ',
    justifyContent: 'space-between',
    maxWidth: '50%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.only('md')]: {
      width: '50%',
    },
  },
  
  successText: {
    fontFamily: 'Roboto',
    color: '#70BF42',
  
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
    },
  },
  successCloseImg: {
    maxHeight: '1.5rem',
    marginRight: 15,
    cursor: 'pointer',
  
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    },
  },
  errorContent: {
    maxWidth: '50%',
    height: 50,
    paddingRight: 5,
    backgroundColor: ' rgb(255,0,0,0.2)',
    display: 'flex',
    borderRadius: 10,
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      alignSelf: 'flex-end',
    },
  },
  
  errorInput: {
    color: colors.secondary,
    padding: '0 12px',
    fontSize: 16,
  },
  info: {
    flexDirection: 'row',
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid #ebebeb',
    padding: '18px 16px',
    maxWidth: 720
  },
  link: {
    marginLeft: 5,
    textDecoration: 'underline',
    color: colors.primary
  },
  img: {
    marginRight: 16
  },
  targetImg: {
    width: '80%', 
    height: 'auto', 
    objectFit: 'contain' 
  },
  adornment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  marginTop: {
    marginTop: 10
  },
  titleDiv: {
    display: 'flex', 
    maxWidth: '55% !important',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100% !important',
    },
  },
  divider: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottom: 1,
    margin: '15px 0 25px',
    borderColor: colors.grayHover,
  },
  container: {
    display: 'flex', 
    justifyContent: 'flex-start',
    paddingTop: 15
  },
  aboveGrid: {
    display: 'flex',
    flexDirection: 'column',
    width: '65% !important',
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
  },
  grid: {
    maxWidth: 750 
  }
}))

export default useStyles