import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const useStyles5 = makeStyles((theme) => ({
  input: {
    maxWidth: 450,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  jobTitleInput: {
    marginTop: 0,
    marginBottom: 10,
    maxWidth: 450,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  margin: {
    [theme.breakpoints.up('md')]: {
      marginTop: -12
    },
  },
  // Change email message
  changeEmailWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
    },
  },
  changeEmailLink: {
    textDecoration: 'underline',
    marginTop: 12,
    cursor: 'pointer',
  },
  // /Not Verifyed email message
  notVerifiedEmailMessageWrapper: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
  },
  notVerifiedNumberMessageWrapper: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
  },
  notVerifiedEmailButton: {
    display: 'flex',
    paddingTop: 45,
    marginLeft: 30,
    cursor: 'pointer',
    color: colors.white,
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      paddingTop: 0,
      marginLeft: 0,
    },
  },
  notVerifiedEmailButtonPhoneVerified: {
    display: 'flex',
    paddingTop: 100,
    marginLeft: 30,
    cursor: 'pointer',
    color: colors.white,
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      paddingTop: 0,
      marginLeft: 0,
    },
  },
  notVerifiedNumberButton: {
    display: 'flex',
    paddingTop: 25,
    marginLeft: 30,
    cursor: 'pointer',
    color: colors.white,
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      paddingTop: 0,
      marginLeft: 0,
    },
  },
  errorInput: {
    color: colors.secondary,
    padding: '0 12px',
    fontSize: 16,
  },
  successEmail: {
    display: 'inline-block', 
    padding: '12px 0'
  },
  requestSent: {
    color: colors.primary, 
    marginLeft: 12
  },
  changeEmail: {
    display: 'inline-block', 
    paddingTop: 55,
    marginLeft: 30,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px !important',
      marginLeft: '0px !important',
    },
  },
  changeEmailPhoneVerified: {
    display: 'inline-block', 
    paddingTop: 100,
    marginLeft: 30,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px !important',
      marginLeft: '0px !important',
    },
  }
}))

export default useStyles5