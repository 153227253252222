/* eslint-disable promise/always-return */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React from 'react'
import { Modal, Text } from '..'
import { IconButton, useMediaQuery } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import TooltipmModal from './TooltipMobileModal.styles'

function TooltipMobileModal({ isVisible, cancel }) {
  const classes = TooltipmModal()

  const onCancel = () => {
    cancel()
  }
  
  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <div className={classes.subModal}>
          <div className={classes.text}>
            <Text size='subnote' weight='bold' color={colors.purple}>Invite your colleagues to use Dataplayer</Text>
          </div>
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.iconButtonClose }}
            onClick={() => onCancel()}
          >
            <ClearIcon classes={{ root: classes.closeIcon }} />
          </IconButton>
        </div>
        <div className={classes.divider}>
        </div>
        <div className={classes.listContainer}>
          <ul className={classes.paddingLeft}>
            <li className={classes.listItem}><Text color={colors.primary} size='footnote'>When they upgrade, they can use your referral code to get 1 free month.</Text></li>
            <li className={classes.listItem}><Text color={colors.primary} size='footnote'>Each time your referral code is used, you get 15 to 30 free days too.</Text></li>
            <li className={classes.lineHeight}><Text color={colors.primary} size='footnote'>Share Dataplayer, grow your practice together!</Text></li>
          </ul>
        </div>
      </div>
    </Modal>
  )
}

export default TooltipMobileModal  