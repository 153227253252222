
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { tabStyles, tabsStyles, useStyles } from './TabsForProfile.style'
import Text from '../Text'

const StyledTabs = withStyles(tabsStyles)((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles(tabStyles)((props) => <Tab disableRipple {...props} />)

export default function TabsForAddons({ value, handleChange }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <StyledTabs
        value={value} 
        onChange={handleChange}
        aria-label="targets"    
      >
        <StyledTab label={<Text size='subnote' weight='medium'>Profile</Text>}/>
        <StyledTab label={<Text size='subnote' weight='medium'>Clinic information</Text>}/>
      </StyledTabs>
    </div>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          { children }
        </>
      )}
    </div>
  )
}
  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export { TabPanel }