/* eslint-disable promise/always-return */
import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import { emailSent } from '../../Themes/Images'
import { colors } from '../../Themes'
import Axios from '../../Config/Axios'
import { PrimaryButton } from '../../Components'
import { useAuth } from '../../Global'
import useStyles from './UpgradeSubscription.styles'

function UpgradeSubscription() {
  const classes = useStyles()
  const history = useHistory()
  const { setUpgradeSuccess } = useAuth()

  const onConfirm = () => {
    Axios({
      method: 'POST',
      url: 'https://66mc5xv9fb.execute-api.eu-central-1.amazonaws.com/prod/paddle/subscriptions',
    }).then(
      (res) => {
        setUpgradeSuccess(true)
        history.push('/dashboard')
      }).catch(e => console.log(e))
  }

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={emailSent} className={classes.img} />
      </div>
      <div className={classes.messageWrapper}>
        <div className={classes.message}>
          <p style={{ color: colors.accent, fontSize: 32, fontWeight: 'bold', fontFamily: 'Roboto' }} >Congratulations!</p>
          <br />
          <br />
          <p className={classes.text} >You’re just a step away from unlocking all the premium features Dataplayer has to offer. <br /> <br />
                Click on the button below to confirm this request and one of our customer representatives will assist you promptly, check your email! .</p>
          <PrimaryButton
            classes={{ root: classes.button }}
            onClick={onConfirm}
          >
            Confirm Request
          </PrimaryButton>
          <a
            className={classes.link}
            href="https://dashboard.dataplayer.io/"
          >
            <ArrowBackIcon classes={{ root: classes.rootBackIcon }} />
            <span className="logoutDrawer">Back to Dashboard</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default UpgradeSubscription
