/* eslint-disable no-useless-escape */
import React, { useState } from 'react'

function validateSignup(input) {
  let errors = {}
  let reg = /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/
  switch (input.key) {
  case 'username': {
    if (!input.value) {
      errors.username = 'Please fill out the required information.'
    } else if (/[^a-zA-Z0-9]/.test(input.value)){
      errors.username = 'Username shouldn\'t contain special characters'
    }
      
    return errors
  }
  case 'clinicName': {
    if (!input.value) {
      errors.clinicName = 'Please fill out the required information.'
    }
    return errors
  }
  case 'other': {
    if (!input.value) {
      errors.other = 'Please fill out the required information.'
    }
    return errors
  }
  case 'pms': {
    if (!input.value) {
      errors.pms = 'Please fill out the required information.'
    }
    return errors
  }
  case 'email': {
    if (!input.value) {
      errors.email = 'Please fill out the required information.'
    } else if (
      !reg.test(input.value)
    ) {
      errors.email = 'Please enter a valid email address.'
    }
    return errors
  }
  case 'phone_number': {
    if (!input.value) {
      errors.phone_number = 'Please fill out the required information.'
    }
    return errors
  }

  default:
    return errors
  }
}

const useFormSignup = (callback, setValues) => {
  const [errors, setErrors] = useState({})

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    if (Object.keys(errors).length === 0) {
      callback()
    }
  }

  const handleChange = (value, name) => {
    setValues((prev) => {
      if (name==='pms' && prev?.other !== ''){
        return ({
          ...prev,
          [name]: value,
          other: ''
        })
      }
      if (name==='pms' && prev?.other === ''){
        delete errors['other']
      }
      return ({
        ...prev,
        [name]: value,
      })
    })
    delete errors[name]

    // if (isFocused) {
    // const input = {
    //   key: name,
    //   value,
    // };
    // const validateError = validate(input);
    // setErrors((prev) => ({
    //   ...prev,
    //   [name]: "",
    // }));
    // }
  }

  const handleOnBlur = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const input = {
      key: name,
      value,
    }
    const validateError = validateSignup(input)
    setErrors({
      ...errors,
      ...validateError,
    })
  }

  return {
    handleChange,
    handleSubmit,
    handleOnBlur,
    errors,
    setErrors,
  }
}



function validateMoreInfo(input) {
  let errors = {}
  let reg = /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/
  switch (input.key) {
  case 'firstname': {
    if (!input.value) {
      errors.firstname = 'Please fill out the required information.'
    } else if (/[^a-zA-Z0-9_ ]/.test(input.value)){
      errors.firstname = 'First name shouldn\'t contain special characters.'
    } else if (!/^([^0-9]*)$/.test(input.value)){
      errors.firstname = 'First name shouldn\'t contain numbers.'

    }
      
    return errors
  }
  case 'lastname': {
    if (!input.value) {
      errors.lastname = 'Please fill out the required information.'
    } else if (/[^a-zA-Z0-9_ ]/.test(input.value)){
      errors.lastname = 'Last name shouldn\'t contain special characters.'
    }
    else if (!/^([^0-9]*)$/.test(input.value)){
      errors.lastname = 'Last name shouldn\'t contain numbers.'

    }
    return errors
  }
  case 'role': {
    if (!input.value) {
      errors.role = 'Please fill out the required information.'
    } else if (!/^([^0-9]*)$/.test(input.value)){
      errors.role = 'Job title shouldn\'t contain numbers.'

    }
    //  else if (/[^a-zA-Z0-9]/.test(input.value)){
    //   errors.role = 'Job title  shouldn\'t contain special characters.'
    // }
    return errors
  }
  case 'numberOfEmployees': {
    if (!input.value) {
      errors.numberOfEmployees = 'Please fill out the required information.'
    } else if (input.value<0){
      errors.numberOfEmployees = 'Your input can\'t have a negative value.'
    }
    return errors
  }

  default:
    return errors
  }
}

const useMoreInfoValidation = (callback, setValues) => {
  const [errors, setErrors] = useState({})

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    if (Object.keys(errors).length === 0) {
      callback()
    }
  }

  const handleChange = (value, name) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
    delete errors[name]

    // if (isFocused) {
    // const input = {
    //   key: name,
    //   value,
    // };
    // const validateError = validate(input);
    // setErrors((prev) => ({
    //   ...prev,
    //   [name]: "",
    // }));
    // }
  }

  const handleOnBlur = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const input = {
      key: name,
      value,
    }
    const validateError = validateMoreInfo(input)
    setErrors({
      ...errors,
      ...validateError,
    })
  }

  return {
    handleChange,
    handleSubmit,
    handleOnBlur,
    errors,
    setErrors,
  }
}

export { useFormSignup, useMoreInfoValidation }
