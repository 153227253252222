import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useDashboardTargetStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: 16
  },
  redirect: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    border: `1px solid ${colors.gray}`,
    paddingBlock: 12,
    cursor: 'pointer'
  }
}))

export default useDashboardTargetStyles