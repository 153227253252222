import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column-reverse',
      padding: '30px 0'
    },
  },
  imgContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  img: {
    width: '100%',
    maxHeight: '600px',
    objectFit: 'contain',
  
  },
  messageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
  
    },
  },
  message: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.drawer}`,
    borderRadius: 10,
    maxWidth: '70%',
    padding: '54px 60px 47px ',
    boxShadow: '5px 10px 18px ' + colors.gray,
    [theme.breakpoints.down('sm')]: {
      padding: '54px 20px 47px',
      maxWidth: '80%',
  
    },
  },
  text: {
    marginBottom: 40,
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  button: {
    maxWidth: 250,
    backgroundColor: colors.primary,
    color: colors.white,
    '&:hover': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: colors.primary,
      border: '1px solid rgba(152, 35, 92, 1)',
      backgroundColor: colors.white,
      color: colors.primary,
    },
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: colors.primary,
    alignItems: 'center',
    marginTop: 32
  }
}))

export default useStyles