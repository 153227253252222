import React, { useState } from 'react'
import ScrollToTop from 'react-scroll-to-top'
import CollapseTooltips from '../../Components/Collapse'
import { TERMS } from '../../Global'
import '../../Terms.css'
import { colors } from '../../Themes'
import { ArrowUpIcon } from '../../Themes/Images'
import { Pressable } from '../../Components'
import useStyles from './TermsAndConditions.styles'

function TermsAndConditions() {
  const classes = useStyles()
  const [expandedItem, setExpandedItem] = useState(null)
  const expand = (key) => {
    setExpandedItem((prev) => {
      if (prev === key) {
        return -1
      }
      return key
    })
  }
  // const x = 3
  return (
    <div className="c1 c16">
      <div>
        <p className="c3 c4"><span className="c11"></span></p>
      </div>
      <p className="c3"><span className="c5 c1">Terms and conditions for our product.</span></p>
      <p className="c3 c4"><span className="c5 c1"></span></p>
      <p className="c6 c3 c1"><span className="c2 c8">This version of these terms is dated January 2021.</span></p>
      <p className="c15 c1"><span className="c2 c8">These terms of service outline the rules and regulations for the use of
      </span><span className="c2 c8">the Dataplayer software application (&ldquo;Service&rdquo;) and any other services of
      Tegeria Ltd. (the company behind Dataplayer). </span><span className="c2 c8">By accessing this website we assume you
        accept these terms and conditions. Do not continue to use Dataplayer if you do not agree to take all of the terms
      and conditions stated on this page.</span></p>
      <p className="c1 c15"><span className="c5 c1">Our company information.</span></p>
      <p className="c3 c1"><span className="c0 c1">Company: Tegeria Ltd</span></p>
      <p className="c1 c3"><span className="c0 c1">L82210014D<br />Address: Blv Zogu I, Qendra e Biznesit Tirana, kati 11, 1001,
      Albania</span></p>
      <p className="c3"><span className="c0 c1">Product: Dataplayer</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      {TERMS.map((item, index) => {
        return (
          <CollapseTooltips
            isExpanded={index === expandedItem}
            expand={() => expand(index)}
            key={index}
            title={item.title}
            description={item.desc}
            definitions={item.definitions}
            number={index + 1}
          />
        )
      })}
      {/* <p className="c3"><span className="c5 c1">Accessing the service.</span></p>
      <p className="c3 c4"><span className="c5 c1"></span></p>
      <p className="c3 c1 c6"><span className="c2 c1">You </span><span className="c0 c1">must be a registered user to access the
      Service. You must provide accurate, up-to-date and complete information when you register, and are solely
      responsible for keeping your password secure. You will be solely responsible and liable for any activity that
      occurs under your user name.</span></p>
      <p className="c6 c3 c1"><span className="c0 c1">You must use all reasonable endeavours to prevent any unauthorized access to,
      or use of, the Service and you must notify Tegeria. Ltd immediately in the event of any unauthorized access or
      use.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c6 c3 c1"><span className="c1 c14">Information.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c3"><span className="c0 c1">&quot;Information&quot; as herein referred to is all such information that has been
      or shall be disclosed in writing or is disclosed electronically, orally or physically.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c3"><span className="c0 c1">Without limiting the generality thereof, Information shall include information and
      data relating to management reports, management accounts, budgets, business plans, business strategies, sales,
      prospective customers, suppliers, pricing methods, technical standards, curriculum standards, legal disputes,
      secret processes, trade secrets, patents, patent applications, techniques, sets, login details, software, source
      code, algorithms, flow charts, data flow patterns, learning models, and including specifically but not by way of
      limitation any information that could give any commercial advantage to partners or competitors of Tegeria. </span>
      </p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c3"><span className="c1 c5">Confidentiality. </span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c3"><span className="c0 c1">The Recipient (you) shall keep confidential the Information it receives from Tegeria
      and shall neither disclose to any third party nor use the Information it receives from Tegeria without the prior
      written consent.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c3"><span className="c0 c1">To secure the confidentiality of information you shall: </span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c3 c13"><span className="c0 c1">(a) undertake not to use, reproduce or store any disclosed Information in an
      externally accessible computer or electronic information retrieval system or transmit it outside of its principal
      place of business. </span></p>
      <p className="c3 c13"><span className="c0 c1">(b) allow access to disclosed Information exclusively to those employees of the
      recipient who have a reasonable need to see and use it for the purpose of evaluation by the recipient and to
      ensure that those employees are bound by legally enforceable obligations of secrecy to Tegeria.</span></p>
      <p className="c3 c13"><span className="c0 c1">(c) take all reasonable steps to ensure that disclosed Information does not come
      into the possession of third parties who are not authorised to receive it. </span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c3"><span className="c5 c1">Intellectual and industrial property.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c3"><span className="c0 c1">All intellectual and industrial property rights in Tegeria&#39;s Information are and
      will remain vested in Tegeria and all your &nbsp;intellectual and industrial property rights are and will remain
      yours and neither party shall have or acquire any copyright in or apply for or obtain any patent design industrial
      design trademark service mark or other similar protection in respect of disclosed Information given to it by the
      other. </span></p>
      <p className="c3 c4 c13"><span className="c0 c1"></span></p>
      <p className="c3"><span className="c0 c1">It is agreed that no rights or licence under any patent application, patent or any
      other intellectual property right is conveyed by this Agreement by implication or otherwise.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c3"><span className="c0 c1">The use and disclosure of Information transmitted hereunder shall be subject to the
      Laws of Albania and the parties submit to the jurisdiction of the Albanian Courts. </span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c6 c3 c1"><span className="c5 c1">Service cancellation.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c6 c3 c1"><span className="c0 c1">You are solely responsible for the cancellation of your account. You can
      cancel your account at any time through the settings/subscription area of the application. Email or phone requests
      to cancel your account are not considered a cancellation.</span></p>
      <p className="c6 c3 c1"><span className="c2 c1">All of your content and data will be deleted </span><span
        className="c2 c1">{x}</span><span className="c0 c1">&nbsp;days after
        cancellation or termination of your account. This information will not be able to be recovered. We recommend you
        use the export functionality of the Service prior to cancellation to retain any information you may
      require.</span></p>
      <p className="c6 c3 c1"><span className="c2 c1">Failure to pay your subscription fees will result in your account being
      suspended. Accounts are suspended for a maximum of </span><span className="c2 c1">{x} </span><span className="c0 c1">days before the account may be terminated by us. We are not
        responsible for any loss you suffer as a result of such suspension or termination. A suspended account can be
      reactivated by supplying valid payment credentials and resuming your subscription.</span></p>
      <p className="c6 c3 c1"><span className="c0 c1">If you cancel your account before the end of your currently paid month, the
      Service will end immediately and you will not be charged again. You will not be entitled to a refund.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c6 c3 c1"><span className="c5 c1">Account Termination.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c6 c3 c1"><span className="c1 c2">Tegeria Ltd in its sole discretion has the right to suspend or terminate your
      account and refuse any and all current or future use of the Service, for any reason, at any such time. Such
      termination can result in the deactivation or deletion of your account. Tegeria Ltd. reserves the right to refuse
      service to anyone for any reason at any time.</span></p>
      <p className="c6 c3 c1"><span className="c2">Tegeria </span><span className="c0">Ltd. will not be liable for any delay or failure
      to perform any of its obligations in respect of the Service if such delay or failure is due to an event which is
      beyond its control.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c6 c3 c1"><span className="c5">Terms of service update.</span></p>
      <p className="c3 c4"><span className="c0 c1"></span></p>
      <p className="c6 c3 c1"><span className="c2">Tegeria Ltd may modify the Service, or any other services, at any time, for any
      reason, and without notice. We also may modify these Terms of Service at any time but, if we do so, we will notify
      you of the modified Terms of Service and give you a reasonable period of time to notify us that you do not agree
      to the modified Terms of Service and cease using the service.</span></p>
      <p className="c6 c3 c1 c4"><span className="c0"></span></p>
      <p className="c4 c7"><span className="c1 c10"></span></p> */}


      <Pressable>
        <ScrollToTop
          smooth
          top={50}
          component={<ArrowUpIcon className="arrowUp" />}
          color={colors.white}
          className={classes.arrow}
        // className="scrollToTop"
        />
      </Pressable>
    </div>
  )
}

export default TermsAndConditions
