
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Text, TextInput } from '..'
import { IconButton } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import { Axios } from '../../Config'
import { PrimaryButton, SecondaryOutlinedButton3 } from '../Button/Button'
import { SelectInput } from '../Input'
import SucessModal from '../SuccessModal/SuccessModal'
import { xeroStyles, selectedStyles } from './XEROModal.styles'
import { useLocation, useHistory, Link, useParams } from 'react-router-dom'
import AddOnBoughtModal from '../AddOnBoughtModal'

const SELECT_OPTIONS = [
  { value: 'uk',  label: 'UK' },
  // { value: 'Custom Email Reports', label: 'Custom Email Reports' },
  { value: 'nz', label: 'NZ' },
  { value: 'au', label: 'AU' },
]

function XEROModal({ isVisible, cancel }) {
  const classes = xeroStyles()
  // const { state } = useLocation()
  const { productId } = useParams()
  const history = useHistory()
  const [firstValue, setFirstValue] = useState('')
  const [secondValue, setSecondValue] = useState('')
  const [dropdownValues, setDropdownValues] = useState('')
  const [isDisabled, setDisabled] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState()
  const [isLoading, setLoading] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    history.push('/store')
  }

  useEffect(() => {
    if (
      firstValue.length === 0 || secondValue.length === 0 || dropdownValues === ''
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [firstValue, secondValue, dropdownValues])

  // const onSubmitBuyProduct = async () => {
  //   setLoading(true)
  //   try {
  //     await Axios.post('/prod/buyProduct',
  //       {
  //         productId: +productId,
  //       }
  //     ).then((response) => 
  //       showModal()
  //     )
  //     setLoading(false)
  //     setError('')
  //     setDisabled(true)
  //     // toast.custom((t) => (
  //     //   <SuccessToast t={t} message='The add-on was bought successfully!'/>
  //     // ))
  //   } catch (error) {
  //     setError(error)
  //     setLoading(false)
  //   }
  // }

  const onSubmit = async (category, firstValue, secondValue) => {
    setLoading(true)
    try {
      await Axios.post('/prod/user/startXeroLoading',
        {
          region: category,
          clientId: firstValue,
          clientSecret: secondValue
        }
      ).then((response) => console.log(response)
      )
      setSuccess(true)
      
      setDisabled(true)
    }
    catch (error) {
      setError(error)
      setLoading(false)
    }
    try {
      await Axios.post('/prod/buyProduct',
        {
          productId: +productId,
        // eslint-disable-next-line promise/always-return
        }).then(() => {
        // cancel()
        // showModal()
      }
      
        
      )
      cancel()
      showModal()
      setLoading(false)
      setFirstValue('')
      setSecondValue('')
      setDropdownValues('')
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }
  
  const onCancel = () => {
    setSuccess(false)
    setFirstValue('')
    setSecondValue('')
    setDropdownValues('')
    setError()
    setDisabled(true)
    setLoading(false)
    cancel()
    
  }

  const handleChange = (value) => {
    setDropdownValues(value)
  }

  const onChangeFirst = (e) => {
    setFirstValue(e.target.value)
    setError()
  }
  const onChangeSecond = (e) => {
    setSecondValue(e.target.value)
    setError()
  }

  const contactUsModalCustomisation = (
    <div className={classes.ModalWrapper}>
      <div className={classes.subModal}>
        <div className={classes.text}>
          <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >XERO integration</Text>
        </div>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => onCancel()}
        >
          <ClearIcon classes={{ root: classes.iconButtonClose }} />
        </IconButton>
      </div>
      <div className={classes.border} />
      <div className={classes.text2}>
        <Text size='footnote' color={colors.primary} className={classes.spaceText2}>To connect Dataplayer to your XERO account, please follow these&nbsp; 
          <a href='https://dataplayer.zohodesk.eu/portal/en-gb/kb/articles/connect-dataplayer-to-your-xero-account-27-3-2024' target='_blank' rel="noopener noreferrer" className={classes.link}>steps</a>.
        </Text>
      </div>
     
      {/* <div className={classes.subText}>
        <Text size='subnote' color={colors.primary} weight='bold' className={classes.underTitle} >Details:</Text>
      </div> */}
      <div className={classes.subText2}>
        <Text size='small' color={colors.primary} weight='medium' className={classes.underTitle} >Enter your Client ID:</Text>
      </div>
      <div className={classes.inputForm}>
        <TextInput
          name='id'
          costumizedInputStyles={classes.input}
          label='Client ID'
          value={firstValue}
          onChange={onChangeFirst}
        />
      </div>
      {/* {error &&
          <div className={classes.errorDiv}>
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{error.response.data.error.message}</Text>
          </div>
      } */}
      <div className={classes.subText2}>
        <Text size='small' color={colors.primary} weight='medium' className={classes.underTitle} >Enter your Client Secret:</Text>
      </div>
      <div className={classes.inputForm}>
        <TextInput
          name='secret'
          costumizedInputStyles={classes.input}
          label='Client secret'
          value={secondValue}
          onChange={onChangeSecond}
        />
      </div>
      <div className={classes.subText2}>
        <Text size='small' color={colors.primary} weight='medium' className={classes.underTitle} >Select your XERO region:</Text>
      </div>
      <div className={classes.dropwdownWrapper}>
        <SelectInput
          value={dropdownValues}
          selectCostumStyles={selectedStyles}
          label="Requests"
          placeholder="Select"
          options={SELECT_OPTIONS}
          onChange={(val) => handleChange(val)}
          account
        />
      </div>
      {error &&
          <div className={classes.errorDiv}>
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{error?.response?.data?.error?.message}</Text>
          </div>
      }
      <div className={classes.blog}>
        <Text size='small' color={colors.grayish}>If you need assistance, please reach out through <Text className={classes.chatButton} size='small' color={colors.grayish} onClick={() => {
          let d = document
          let s = d.createElement('script')
          s.type = 'text/javascript'
          s.id = 'zsiqscript'
          s.defer = true
          s.src = 'https://salesiq.zoho.eu/widget'
          let t = d.getElementById('zohoChat')
          t.parentNode.insertBefore(s, t)
        }}>chat</Text>
          &nbsp;or email <a style={{ color: colors.grayish }} className={classes.chatButton} href='mailto:support@dataplayer.io'>support@dataplayer.io</a>
        </Text>
        {/* <div className={classes.chatButton} onClick={() => {
          let d = document
          let s = d.createElement('script')
          s.type = 'text/javascript'
          s.id = 'zsiqscript'
          s.defer = true
          s.src = 'https://salesiq.zoho.eu/widget'
          let t = d.getElementById('zohoChat')
          t.parentNode.insertBefore(s, t)
        }}>
          <Text size='small' weight='medium' color={colors.grayish}>chat</Text>
          
        </div> */}
        {/* <Text size='small' weight='medium' color={colors.grayish}>or email <a className={classes.chatButton} href='mailto:support@dataplayer.io'></a>support@dataplayer.io</Text> */}
      </div>
      
      <div className={classes.border2} />
      <div className={classes.buttonsWrapper}>
        <SecondaryOutlinedButton3
          className={classes.cancelButton}
          onClick={onCancel}
        > Cancel
        </SecondaryOutlinedButton3>
        <SecondaryOutlinedButton3
          className={classes.nextButton}
          disabled={isDisabled || isLoading}
          loading={isLoading}
          style={{
            backgroundColor: isDisabled || isLoading ? colors.disabled : colors.primary,
            color: colors.white,
          }}
          onClick={() => onSubmit(dropdownValues.value, firstValue, secondValue)}
        >
          Confirm
        </SecondaryOutlinedButton3>
      </div>
    </div>
  )

  
  return (
    <>
      {success &&  
      <AddOnBoughtModal
        isVisible={isModalOpen}
        cancel={handleCloseModal}
      />}
      <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>

        {contactUsModalCustomisation}

      
      </Modal>
    </>
  )
}



export default XEROModal

