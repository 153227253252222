import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../Themes'

const useStyles = makeStyles((theme) => ({
  paperCostum: {
    display: 'flex',
    padding: '24px',
    maxWidth: 450,
    position: 'relative',
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  image: {
    display: 'flex',
    justifyContent: 'center'
  },
  spaceText: {
    margin: '18px 0 40px 0'
  },
  spaceTittle: {
    marginTop: 16
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: 30,
    marginTop: 7,
    [theme.breakpoints.down('sm')]: {
      gap: 10
    },
  },
  button: {
    minWidth: 150,
    backgroundColor: colors.background,
    color: colors.primary,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12
    },
    [theme.breakpoints.down(500)]: {
      minWidth: 100,
    },
  },
  input: {
    display: 'none',
  },
  closeIcon: {
    padding: '12px 0',
    position: 'absolute', 
    top: 20, 
    right: 20 
  },
  iconAdd: {
    width: '2em',
    height: '1.5em'
  },
  IconRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    width: 25,
  },
  img: {
    width: 'auto',
    height: 25
  },
  text: {
    display: 'flex', 
    justifyContent: 'center', 
    marginTop: 15 
  },
  icon: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  flex: {
    display: 'flex' 
  },
  divider: {
    display: 'flex', 
    height: 1,
    width: '100%',
    backgroundColor: '#E6E6E6',
    marginTop: 15 
  }
}))

export default useStyles