import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    backgroundColor: colors.white,
    color: colors.white,
    marginBottom: 24,
    marginInline: 25,
    boxShadow: 'none',
    border: '1px solid ' + colors.gray,
    borderRadius: 5
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8
  },
  cardContentRoot: {
    display: 'flex',
    padding: '18px 12px',
    whiteSpace: 'normal',
    '&:last-child': {
      paddingBottom: 12
    }
  },
  linkTo: {
    color: colors.accent,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'left',
    textDecoration: 'underline'
  },
  iconButtonRoot: {
    padding: 0,
    marginTop: 12,
  
  },
  closeIcon: {
    padding: 0,
    color: colors.primary,
    position: 'absolute',
    right: 0,
  },
  icon: {
    color: colors.primary,
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  labelRoot: {
    justifyContent: 'flex-start'
  },
  imgContainer: {
    position: 'relative',
    marginRight: 20,
    maxWidth: 56,
    maxHeight: 61,
  },
  img: {
    maxWidth: 56,
    maxHeight: 61,
    objectFit: 'contain',
  },
  centerImgText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }
}))

export default useStyles