import React, { useState } from 'react'
import Loading from 'react-lottie'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@material-ui/core'
import { colors } from '../../../Themes'
import { empty, CopyIcon } from '../../../Themes/Images'
import { Pressable, Text } from '../..'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { cellStyles, tableRowStyles, useCStylesTable, useStylesTable } from './PaymentsTable.styles'

const EMPTY = {
  loop: true,
  autoplay: true,
  animationData: empty,
  rendererSettings: {
    className: 'loading',
  },
}

function CircularIndeterminate() {
  const classes = useCStylesTable()

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

const CURRENCY = {
  USD: '$',
}

const StyledTableCell = withStyles((theme) => (cellStyles))(TableCell)

const StyledTableRow = withStyles((theme) => (tableRowStyles))(TableRow)

export default function PaymentsTable({ payments, onLoadMore, showMore, openReceipt, loading }) {
  const classes = useStylesTable()
  const [isCopied, setIsCopied] = useState(null)

  if (loading) {
    return (
      <CircularIndeterminate />
    )
  }

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        {showMore && <caption className={classes.caption} onClick={onLoadMore} ><Text size='footnote' color={colors.accent} className={classes.showMore} > Show More</Text> </caption>}
        <TableHead>
          <TableRow className={classes.tableFont}>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="left">Payment ID</StyledTableCell>
            <StyledTableCell align="left">Amount</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.length > 0 && payments.map((payment, index) =>
            (
              <StyledTableRow key={payment?.id}>
                <StyledTableCell component="th" scope="row">
                  <Text 
                    size='footnote' 
                    weight="medium">
                    {moment(payment?.payout_date).format('LL')}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text 
                    size='footnote' 
                    weight="medium" 
                    color={payment?.is_paid === 1 ? colors.accent : colors.primary} 
                    className={payment?.is_paid === 1 ? classes.paymentId : null} 
                    onClick={() => openReceipt(payment?.receipt_url)} >  
                    {payment?.id}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text size='footnote'  >
                US  {CURRENCY?.[payment?.currency]}{payment?.amount}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {payment?.is_paid === 1 ?
                    <Text size='footnote' className={classes.statusPaid}>
                    Paid
                    </Text> :
                    <Text size='footnote' className={classes.statusFailed} >
                    Not paid
                    </Text>
                  }
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip key={payment?.subscription_id} open={index === isCopied} title="Receipt url copied to clipboard" placement="top" arrow>
                    <CopyToClipboard
                      onCopy={() => {
                        setIsCopied(index)
                        setTimeout(() => { setIsCopied(null) }, 3000)
                      }} text={payment?.receipt_url}>
                      <Pressable classes={{
                        root: classes.copyBase
                      }}
                      disabled={payment?.is_paid === 0}
                      >
                        <CopyIcon className="copyIcon" />
                      </Pressable>
                    </CopyToClipboard>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {
        payments.length === 0 &&
        <div className="loadingContainer">
          <Text size='footnote' color={colors.grey} className={classes.margin} >No items found</Text>
          <Loading options={EMPTY} height={180} />
        </div>
      }
    </TableContainer>
  )
}