import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { Text }  from '../..'
import useStyles3 from './ReportsCheckBox.styles'

function ReportsCheckBox({ tittle, checked, handleChange, key }) {
  const classes=useStyles3()

  return (
    <div className={classes.container}>
      <Checkbox
        key={key}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <Text size='subnote' >
        {tittle}
      </Text>
    </div>
  )
}

export default ReportsCheckBox
