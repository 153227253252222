/* eslint-disable promise/always-return */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import { Modal, Text, TextInput } from '..'
import { IconButton } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import { Axios } from '../../Config'
import { SecondaryOutlinedButton3, SecondaryOutlinedButton4 } from '../Button/Button'
import { useAuth } from '../../Global'
import { errorIcon } from '../../Themes/Images'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import codeCustomModalStyles from './CodeCustomModal.styles'

function validate(input) {
  let errors = {}
  switch (input.key) {
  case 'code': {
    if (!input.value) {
      errors.code = 'Please fill out the required information.'
    }
    return errors
  }
  default:
    return errors
  }
}

function CodeCustomModal({ isVisible, cancel }) {
  const classes = codeCustomModalStyles()
  const [code, setCode] = useState('')
  const [isDisabled, setDisabled] = useState(true)
  const [errors, setErrors] = useState({})
  const [error, setError] = useState()
  const [isLoading, setLoading] = useState(false)
  const { userAttributes } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (
      code !== '' && !isNaN(code)
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [code])

  const handleOnBlur = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const input = {
      key: name,
      value,
    }
    const validateError = validate(input)
    setErrors({
      ...errors,
      ...validateError,
    })
  }

  const handleChange = (e, name) => {
    setCode(e.target.value)
    delete errors[name]
    setError('')
  }

  const redirectToInfo = () => {
    history.push({ pathname: '/settings/payment-information', 
      state: {
        selectedTab: 1
      } })
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      await Axios.post('/prod/generateProductPayLink',
        {
          productId: +code,
        }
      ).then((response) => {
        onCancel()
        window.Paddle.Checkout.open({
          override: response?.data.data.productPayLink,
          email: userAttributes?.email,
          successCallback: function (data) {
            redirectToInfo()
          }
        })
      })
      setLoading(false)
      setCode('')
      setErrors({})
      setDisabled(true)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  const onCancel = () => {
    setCode('')
    setError()
    setErrors({})
    setDisabled(true)
    cancel()
  }
  
  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <div className={classes.subModal}>
          <div className={classes.text}>
            <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >Enter customisation code</Text>
          </div>
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.iconButtonClose }}
            onClick={() => onCancel()}
          >
            <ClearIcon classes={{ root: classes.iconButtonClose }} />
          </IconButton>
        </div>
        <div className={classes.text2}>
          <Text size='subnote' color={colors.primary} className={classes.spaceText2}>Enter here the customisation code to get the payment details:</Text>
        </div>
        <TextInput
          name='code'
          costumizedInputStyles={classes.input}
          label='Code'
          value={code}
          onChange={(value) => handleChange(value, 'code')}
          onBlur={handleOnBlur}
        />
        {error &&
          <div className={classes.errorDiv}>
            <img className={classes.errorICon} src={errorIcon} />
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>This customisation code is invalid.</Text>
          </div>
        }
        {errors &&
          <div className={classes.errorDiv}>
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{errors.code}</Text>
          </div>
        }
        <div className={classes.buttonsWrapper}>
          <SecondaryOutlinedButton4
            className={classes.cancelButton}
            onClick={onCancel}
          >  Cancel
          </SecondaryOutlinedButton4>
          <SecondaryOutlinedButton3
            className={classes.nextButton}
            disabled={isDisabled || isLoading}
            loading={isLoading}
            style={{
              backgroundColor: isDisabled || isLoading ? colors.disabled : colors.primary,
              color: colors.white,
            }}
            onClick={() => onSubmit()}
          >
            Proceed to payment
          </SecondaryOutlinedButton3>
        </div>
      </div>
    </Modal>
  )
}

export default CodeCustomModal  
