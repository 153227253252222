/* eslint-disable promise/always-return */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth as AmplifyAuth } from 'aws-amplify'

import { Axios, AxiosChat } from '../Config'
import { useMediaQuery } from '@material-ui/core'

const Auth = createContext()

function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    user: '',
    picture: '',
    userAttributes: '',
    data_loading: '',
    tooltips: '',
    website_locked: '',
    is_trialing: undefined,
    plan: '',
    roles: [],
    token: undefined,
    isAuthenticated: false,
    isLoading: true,
    referralCode: '',
    aiAssistant: false

  })
  // When user is not from cliniko
  const [shouldLogin, setShouldLogin] = useState(true)
  // When user send a email for upgrade for the message at dashboard
  const [upgradeSucces, setUpgradeSuccess] = useState(false)
  const [loginValues, setLoginValues] = useState({
    isLoginIn: false,
    loginError: null
  })

  const [userAcc, setUserAcc] = useState()

  const [authCodeValues, setAUthCodeValues] = useState({
    isCodeIn: false,
    codeError: null
  })

  const [changePassValues, setChangePassValues] = useState({
    isPassIn: false,
    passError: null
  })


  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [fileToEdit, setFileToEdit] = useState(false)
  const [isPassChanged, setIsPassChanged] = useState(false)
  const [isRequestFileChangeModalOpen, setIsRequestFileChangeModalOpen] = useState(false)
  const [referralCodeUpgrade, setReferralCodeUpgrade] = useState('')
  const list = []


  const validateReferralCode = async (setLoading, setErrors, setDisabled, setMessage, setReferralEnteredSuccess, setButtonDisabled) => {
    setLoading(true)
    try {
      await Axios.post('/prod/validateReferralCode',
        {
          referralCode: referralCodeUpgrade,
        }
      )
      setLoading(false)
      setReferralEnteredSuccess(true)
      setButtonDisabled(true)
      setErrors({})
      setMessage('Code applied.')
      setDisabled(false)
    } catch (error) {
      setReferralEnteredSuccess(false)
      setMessage(error?.response?.data?.error?.message)
      setLoading(false)
      setDisabled(false)
      setButtonDisabled(false)
    }

  }

  const handleFileUpdate = (file) => {
    setFileToEdit(file)
    setIsUpdateModalOpen(true)
  }

  const logout = () => {
    setLoginValues((prev) => ({
      ...prev,
      isLoginIn: false,
    }))

    setAUthCodeValues((prev) => {
      return {
        ...prev,
        isCodeIn: false

      }
    })
    AmplifyAuth.signOut()
      .then((res) => {
        setAuth({
          user: undefined,
          picture: undefined,
          userAttributes: undefined,
          data_loading: '',
          tooltips: '',
          website_locked: '',
          isAuthenticated: false,
          isLoading: false,
          referralCode: '',

        })
        setReferralCodeUpgrade('')
      })
      .catch(() => { })
  }

  // useEffect(() => { console.log(auth.aiAssistant) }, [auth])

  const login = async (username, password, success, successPass) => {
    setLoginValues((prev) => {
      return {
        ...prev,
        isLoginIn: true
      }
    })

    try {
      const user = await AmplifyAuth.signIn(username, password)
      // console.log('🚀 ~ login ~ user:', user)
      setUserAcc(user)
      // console.log(user)
      // const { 'custom:enabledMFA': enabledMFA } = user?.attributes
      // Check for MFA requirements
      if (user?.challengeName === 'CUSTOM_CHALLENGE') {  
        // function that sends user to mfa screen
        success()  
        
      }
      // Check for forced pass change requirements
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {  
        // function that sends user to change pass screen
        successPass() 
        setIsPassChanged(true)
        
      }
      let { data: costumUserAtrributes } = await Axios.get('/prod/user')
      // console.log('🚀 ~ login ~ costumUserAtrributes:', costumUserAtrributes)
      setAuth({
        user: user?.username,
        given_name: user?.attributes?.given_name,
        picture: user?.attributes?.picture,
        token: user?.signInUserSession?.idToken?.jwtToken,
        userAttributes: user?.attributes,
        data_loading: costumUserAtrributes?.data_loading,
        tooltips: costumUserAtrributes?.tooltips,
        website_locked: costumUserAtrributes?.website_locked,
        dashboard_id: costumUserAtrributes?.dashboard_id,
        targetsDashboardId: costumUserAtrributes?.targetsDashboardId,
        is_trialing: costumUserAtrributes?.trialing_data?.is_trialing,
        end_of_trial_date: costumUserAtrributes?.trialing_data?.end_of_trial_date,
        plan: costumUserAtrributes?.plan,
        roles: user?.signInUserSession.accessToken.payload['cognito:groups'] || [],
        isAuthenticated: true,
        isLoading: false,
        referralCode: costumUserAtrributes?.referralCode,
        showFreeTrialModal: costumUserAtrributes?.showFreeTrialModal,
        account_state: costumUserAtrributes?.account_state,
        active_dash: costumUserAtrributes?.active_dash,
        subscription_id: costumUserAtrributes?.subscription_id,
        xeroDashboard: costumUserAtrributes?.xeroDashboard,
        xeroDataLoading: costumUserAtrributes?.xeroDataLoading,
        xeroDashboardId: costumUserAtrributes?.xeroDashboardId,
        cancellationEffectiveDate: costumUserAtrributes?.cancellationEffectiveDate,
        referralCodeOneTimeUsage: costumUserAtrributes?.referralCodeOneTimeUsage,
        additional_dashboards: costumUserAtrributes?.additional_dashboards,
        enabledMFA: user?.attributes?.enabledMFA,
        activeFileTypesCount: costumUserAtrributes?.activeFileTypesCount,
        userUploadedFileTypeCount: costumUserAtrributes?.userUploadedFileTypeCount,
        userUploadedFileType: costumUserAtrributes?.userUploadedFileType,
        fileUploadVerifying: costumUserAtrributes?.fileUploadVerifying,
        fileUploadStatus: costumUserAtrributes?.fileUploadStatus,
        aiAssistant: costumUserAtrributes?.aiAssistant,
        currency: costumUserAtrributes?.currency,
        financial_year_start_date: costumUserAtrributes?.financial_year_start_date,
        financial_year_end_date: costumUserAtrributes?.financial_year_end_date,
        timezone: costumUserAtrributes?.timezone
      })
      setLoginValues((prev) => {
        return {
          ...prev,
          isLoginIn: false
        }
      })

    } catch (error) {
      // console.log('🚀 ~ login ~ error:', error)
      if (!navigator.onLine) {
        setLoginValues({
          isLoginIn: false,
          loginError: ' You are offline. Please double-check and try again. '
        })
      }
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        user: '',
        picture: '',
        userAttributes: '',
        data_loading: '',
        tooltips: '',
        website_locked: '',
        roles: [],
        token: undefined,
        referralCode: '',
        aiAssistant: false,
      })
      setLoginValues({
        isLoginIn: false,
        loginError: ' The username and/or password you entered did not match our records. Please double-check and try again. '
      })

    }
  }

  const forceChangePassword = async (userAcc, newPassword) => {
    setChangePassValues((prev) => {
      return {
        ...prev,
        isPassIn: true
      }
    })
    try {
      const data = await AmplifyAuth.completeNewPassword(userAcc, newPassword)
      let { data: costumUserAtrributes } = await Axios.get('/prod/user')
      setAuth({
        user: data?.username,
        picture: data?.attributes?.picture,
        token: data?.signInUserSession?.idToken?.jwtToken,
        userAttributes: data?.attributes,
        roles: data?.signInUserSession.accessToken.payload['cognito:groups'] || [],
        isAuthenticated: true,
        isLoading: false,
        data_loading: costumUserAtrributes?.data_loading,
        tooltips: costumUserAtrributes?.tooltips,
        website_locked: costumUserAtrributes?.website_locked,
        dashboard_id: costumUserAtrributes?.dashboard_id,
        targetsDashboardId: costumUserAtrributes?.targetsDashboardId,
        is_trialing: costumUserAtrributes?.trialing_data?.is_trialing,
        end_of_trial_date: costumUserAtrributes?.trialing_data?.end_of_trial_date,
        plan: costumUserAtrributes?.plan,
        referralCode: costumUserAtrributes?.referralCode,
        showFreeTrialModal: costumUserAtrributes?.showFreeTrialModal,
        account_state: costumUserAtrributes?.account_state,
        active_dash: costumUserAtrributes?.active_dash,
        subscription_id: costumUserAtrributes?.subscription_id,
        xeroDashboard: costumUserAtrributes?.xeroDashboard,
        xeroDataLoading: costumUserAtrributes?.xeroDataLoading,
        xeroDashboardId: costumUserAtrributes?.xeroDashboardId,
        cancellationEffectiveDate: costumUserAtrributes?.cancellationEffectiveDate,
        referralCodeOneTimeUsage: costumUserAtrributes?.referralCodeOneTimeUsage,
        fileUploadActive: costumUserAtrributes?.fileUploadActive,
        additional_dashboards: costumUserAtrributes?.additional_dashboards,
        aiAssistant: costumUserAtrributes?.aiAssistant,
        currency: costumUserAtrributes?.currency,
        financial_year_start_date: costumUserAtrributes?.financial_year_start_date,
        financial_year_end_date: costumUserAtrributes?.financial_year_end_date,
        timezone: costumUserAtrributes?.timezone
      })
      setChangePassValues((prev) => {
        return {
          ...prev,
          isPassIn: false
        }
      })
    } catch (error) {
      if (!navigator.onLine) {
        setChangePassValues({
          isPassIn: false,
          passError: ' You are offline. Please double-check and try again. '
        })
      }
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        user: '',
        picture: '',
        userAttributes: '',
        data_loading: '',
        tooltips: '',
        website_locked: '',
        roles: [],
        token: undefined,
        referralCode: '',
        aiAssistant: false,
      })
      setChangePassValues({
        isPassIn: false,
        passError: 'There is a problem changing the password, please try again.'
      })
    }
  }

  const verify = async (code, errorInput) => {
    setAUthCodeValues((prev) => {
      return {
        ...prev,
        isCodeIn: true

      }
    })
    try {
      const data = await AmplifyAuth.sendCustomChallengeAnswer(userAcc, code)
      let { data: costumUserAtrributes } = await Axios.get('/prod/user')
      setAuth({
        user: data?.username,
        picture: data?.attributes?.picture,
        token: data?.signInUserSession?.idToken?.jwtToken,
        userAttributes: data?.attributes,
        roles: data?.signInUserSession.accessToken.payload['cognito:groups'] || [],
        isAuthenticated: true,
        isLoading: false,
        data_loading: costumUserAtrributes?.data_loading,
        tooltips: costumUserAtrributes?.tooltips,
        website_locked: costumUserAtrributes?.website_locked,
        dashboard_id: costumUserAtrributes?.dashboard_id,
        targetsDashboardId: costumUserAtrributes?.targetsDashboardId,
        is_trialing: costumUserAtrributes?.trialing_data?.is_trialing,
        end_of_trial_date: costumUserAtrributes?.trialing_data?.end_of_trial_date,
        plan: costumUserAtrributes?.plan,
        referralCode: costumUserAtrributes?.referralCode,
        showFreeTrialModal: costumUserAtrributes?.showFreeTrialModal,
        account_state: costumUserAtrributes?.account_state,
        active_dash: costumUserAtrributes?.active_dash,
        subscription_id: costumUserAtrributes?.subscription_id,
        xeroDashboard: costumUserAtrributes?.xeroDashboard,
        xeroDataLoading: costumUserAtrributes?.xeroDataLoading,
        xeroDashboardId: costumUserAtrributes?.xeroDashboardId,
        cancellationEffectiveDate: costumUserAtrributes?.cancellationEffectiveDate,
        referralCodeOneTimeUsage: costumUserAtrributes?.referralCodeOneTimeUsage,
        fileUploadActive: costumUserAtrributes?.fileUploadActive,
        additional_dashboards: costumUserAtrributes?.additional_dashboards,
        aiAssistant: costumUserAtrributes?.aiAssistant,
        currency: costumUserAtrributes?.currency,
        financial_year_start_date: costumUserAtrributes?.financial_year_start_date,
        financial_year_end_date: costumUserAtrributes?.financial_year_end_date,
        timezone: costumUserAtrributes?.timezone
      })
    } catch (error) {
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        user: '',
        picture: '',
        userAttributes: '',
        data_loading: '',
        tooltips: '',
        website_locked: '',
        roles: [],
        token: undefined,
        referralCode: '',
        aiAssistant: false,
      })
      setAUthCodeValues({
        isCodeIn: false,
        codeError: 'The code you entered did not match our records. Please double-check and try again.'
      })
      errorInput()
    }

  }

  const getAuthenticatedUser = async (userData) => {
    if (!auth.isAuthenticated) {
      try {
        let data
        if (userData) {
          data = userData
        } else {
          data = await AmplifyAuth.currentAuthenticatedUser({ bypassCache: true })
        }
        let { data: costumUserAtrributes } = await Axios.get('/prod/user')
        setAuth({
          user: data?.username,
          given_name: data?.attributes?.given_name,
          picture: data?.attributes?.picture,
          token: data?.signInUserSession?.idToken?.jwtToken,
          userAttributes: data?.attributes,
          data_loading: costumUserAtrributes?.data_loading,
          tooltips: costumUserAtrributes?.tooltips,
          website_locked: costumUserAtrributes?.website_locked,
          dashboard_id: costumUserAtrributes?.dashboard_id,
          targetsDashboardId: costumUserAtrributes?.targetsDashboardId,
          is_trialing: costumUserAtrributes?.trialing_data?.is_trialing,
          end_of_trial_date: costumUserAtrributes?.trialing_data?.end_of_trial_date,
          plan: costumUserAtrributes?.plan,
          roles: data?.signInUserSession.accessToken.payload['cognito:groups'] || [],
          isAuthenticated: true,
          isLoading: false,
          referralCode: costumUserAtrributes?.referralCode,
          showFreeTrialModal: costumUserAtrributes?.showFreeTrialModal,
          account_state: costumUserAtrributes?.account_state,
          active_dash: costumUserAtrributes?.active_dash,
          subscription_id: costumUserAtrributes?.subscription_id,
          xeroDashboard: costumUserAtrributes?.xeroDashboard,
          xeroDataLoading: costumUserAtrributes?.xeroDataLoading,
          xeroDashboardId: costumUserAtrributes?.xeroDashboardId,
          cancellationEffectiveDate: costumUserAtrributes?.cancellationEffectiveDate,
          referralCodeOneTimeUsage: costumUserAtrributes?.referralCodeOneTimeUsage,
          fileUploadActive: costumUserAtrributes?.fileUploadActive,
          additional_dashboards: costumUserAtrributes?.additional_dashboards,
          activeFileTypesCount: costumUserAtrributes?.activeFileTypesCount,
          userUploadedFileTypeCount: costumUserAtrributes?.userUploadedFileTypeCount,
          userUploadedFileType: costumUserAtrributes?.userUploadedFileType,
          fileUploadVerifying: costumUserAtrributes?.fileUploadVerifying,
          fileUploadStatus: costumUserAtrributes?.fileUploadStatus,
          aiAssistant: costumUserAtrributes?.aiAssistant,
          currency: costumUserAtrributes?.currency,
          financial_year_start_date: costumUserAtrributes?.financial_year_start_date,
          financial_year_end_date: costumUserAtrributes?.financial_year_end_date,
          timezone: costumUserAtrributes?.timezone
        })
      } catch (error) {
        setAuth({
          isAuthenticated: false,
          isLoading: false,
          user: '',
          picture: '',
          userAttributes: '',
          data_loading: '',
          tooltips: '',
          website_locked: '',
          roles: [],
          token: undefined,
          referralCode: '',

        })
      }
    }
  }

  const getUpdatedUser = async () => {
    try {
      let data = await AmplifyAuth.currentAuthenticatedUser({ bypassCache: true })
      let { data: costumUserAtrributes } = await Axios.get('/prod/user')
      // console.log('🚀 ~ getUpdatedUser ~ costumUserAtrributes:', costumUserAtrributes)

      setAuth({
        user: data?.username,
        given_name: data?.attributes?.given_name,
        picture: data?.attributes?.picture,
        token: data?.signInUserSession?.idToken?.jwtToken,
        userAttributes: data?.attributes,
        data_loading: costumUserAtrributes?.data_loading,
        tooltips: costumUserAtrributes?.tooltips,
        website_locked: costumUserAtrributes?.website_locked,
        dashboard_id: costumUserAtrributes?.dashboard_id,
        targetsDashboardId: costumUserAtrributes?.targetsDashboardId,
        is_trialing: costumUserAtrributes?.trialing_data?.is_trialing,
        end_of_trial_date: costumUserAtrributes?.trialing_data?.end_of_trial_date,
        plan: costumUserAtrributes?.plan,
        roles: data?.signInUserSession.accessToken.payload['cognito:groups'] || [],
        isAuthenticated: true,
        isLoading: false,
        referralCode: costumUserAtrributes?.referralCode,
        showFreeTrialModal: costumUserAtrributes?.showFreeTrialModal,
        account_state: costumUserAtrributes?.account_state,
        active_dash: costumUserAtrributes?.active_dash,
        subscription_id: costumUserAtrributes?.subscription_id,
        xeroDashboard: costumUserAtrributes?.xeroDashboard,
        xeroDataLoading: costumUserAtrributes?.xeroDataLoading,
        xeroDashboardId: costumUserAtrributes?.xeroDashboardId,
        cancellationEffectiveDate: costumUserAtrributes?.cancellationEffectiveDate,
        referralCodeOneTimeUsage: costumUserAtrributes?.referralCodeOneTimeUsage,
        fileUploadActive: costumUserAtrributes?.fileUploadActive,
        additional_dashboards: costumUserAtrributes.additional_dashboards,
        activeFileTypesCount: costumUserAtrributes?.activeFileTypesCount,
        userUploadedFileTypeCount: costumUserAtrributes?.userUploadedFileTypeCount,
        userUploadedFileType: costumUserAtrributes?.userUploadedFileType,
        fileUploadVerifying: costumUserAtrributes?.fileUploadVerifying,
        fileUploadStatus: costumUserAtrributes?.fileUploadStatus,
        aiAssistant: costumUserAtrributes?.aiAssistant,
        currency: costumUserAtrributes?.currency,
        financial_year_start_date: costumUserAtrributes?.financial_year_start_date,
        financial_year_end_date: costumUserAtrributes?.financial_year_end_date,
        timezone: costumUserAtrributes?.timezone

      })
    } catch (error) {
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        user: '',
        picture: '',
        userAttributes: '',
        data_loading: '',
        tooltips: '',
        website_locked: '',
        roles: [],
        token: undefined,
        referralCode: '',

      })
    }
  }

  const setPicture = (imageUrl) => {
    setAuth({ ...auth, picture: imageUrl })
  }

  useEffect(() => {
    getAuthenticatedUser()
  }, [])

  return (
    <Auth.Provider
      value={{
        ...auth,
        ...loginValues,
        setLoginValues,
        ...authCodeValues,
        setAUthCodeValues,
        ...changePassValues,
        setChangePassValues,
        login,
        logout,
        getAuthenticatedUser,
        getUpdatedUser,
        setPicture,
        shouldLogin,
        setShouldLogin,
        upgradeSucces,
        setUpgradeSuccess,
        verify,
        userAcc,
        isModalOpen,
        setIsModalOpen,
        isUpdateModalOpen,
        setIsUpdateModalOpen,
        fileToEdit,
        setFileToEdit,
        handleFileUpdate,
        isRequestFileChangeModalOpen,
        setIsRequestFileChangeModalOpen,
        referralCodeUpgrade,
        setReferralCodeUpgrade,
        validateReferralCode,
        isPassChanged,
        forceChangePassword
      }}
    >
      {children}
    </Auth.Provider>
  )
}
AuthProvider.propTypes = {
  children: PropTypes.any,
}

function useAuth() {
  const context = useContext(Auth)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export { useAuth }
export default AuthProvider