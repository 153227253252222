import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  verticalLine: {
    borderRightColor: ' #C9C9C9',
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    width: 1,
    height: '30px',
  },
  container: {
    backgroundColor: '#901F5D', 
    color: 'white', 
    zIndex: 1300,
    paddingTop: 10,
    paddingBottom: 10,
    paddingInline: 30, 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky', 
    top: 0,
    [theme.breakpoints.down(760)]: {
      paddingInline: 15, 
    },
  },
  iconContainer: {
    display: 'flex', 
    justifyContent: 'space-between',
    gap: 20, 
    alignItems: 'center',
    [theme.breakpoints.down(760)]: {
      gap: 0, 
    },
  },
  icon: {
    width: '24px',
    height: 'auto',
    cursor: 'pointer', 
    objectFit: 'contain' 
  },
  root: {
    '& .MuiBadge-badge': {
      backgroundColor: colors.white,
      color: colors.accent
    }
  },
  button: {
    width: '130px',
    minHeight: 42,
    borderRadius: '5px',
    fontSize: '14px',
    fontWeight: 500,
    backgroundColor: colors.white,
    color: '#901F5D',
    paddingTop: 8,
    '&:hover': {
      transition: 'all 0.3s ease-in',
      backgroundColor: '#901F5D',
      color: colors.white,
      border: '1px solid white'
    },
  },
  buttonDiv: {
    marginRight: 12
  }
}))

export default useStyles