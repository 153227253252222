/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import Axios from '../Config/Axios'
import { useAuth } from './Auth'

const Subscription = createContext()

function GetSubscription(refreshSubId, user) {
  return useQuery(
    ['subscription', refreshSubId + user],
    async () => {
      const { data } = await Axios.get(
        '/prod/user/subscription',
      )
      return data?.response?.[0]
    },
    {
      retry: false,

    }
  )
}


function GetPlans(currentPlan) {
  return useQuery(
    ['plans', currentPlan],
    async () => {
      const { data } = await Axios.get(
        '/prod/user/subscription/plans',
        { params: { plan: currentPlan } }
      )
      return data?.response
    },
    {
      retry: false,
      enabled: !!currentPlan
    }
  )
}

function GetPlan(refreshPlanId) {
  return useQuery(
    ['plan', refreshPlanId],
    async () => {
      const { data } = await Axios.get(
        '/prod/user/subscription/plans',
        { params: { type: 'current' } }
      )
      return data?.response
    },
    {
      retry: false,

    }
  )
}


function SubscriptionProvider({ children }) {
  const { user } = useAuth()
  const [refreshSubId, setRefreshSubId] = useState(0)
  const [refreshPlanId, setRefreshPlanId] = useState(0)
  const [priceValues, setPriceValues] = useState('')
  const refetchSubscription = () => setRefreshSubId(prev => prev + 1)
  const refetchPlan = () => setRefreshPlanId(prev => prev + 1)

  const { data: subscription = {}, isLoading: isLoadingSub } = GetSubscription(refreshSubId, user)
  const { data: plans = [], isLoading: isLoadingPlans } = GetPlans(subscription?.plan_id || 'free')
  const { data: plan = [], isLoading: isLoadingPlan } = GetPlan(refreshPlanId)

  return (
    <Subscription.Provider
      value={{
        subscription,
        refetchSubscription,
        isLoadingSub,
        plans,
        isLoadingPlans,
        plan,
        refetchPlan,
        isLoadingPlan, priceValues, setPriceValues
      }}
    >
      {children}
    </Subscription.Provider>
  )
}
SubscriptionProvider.propTypes = {
  children: PropTypes.any,
}

function useSubscription() {
  const context = useContext(Subscription)
  if (context === undefined) {
    throw new Error('useSub must be used within an SubProvider')
  }
  return context
}

export { useSubscription }
export default SubscriptionProvider
