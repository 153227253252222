import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    backgroundColor: colors.white,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      flexGrow: 1,
  
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
      height: '100%',
    },
  },
  loginTitle: {
    color: colors.white,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  image: {
    height: '100%',
    width: '100%',
    margin: 'auto',
  },
  description: {
    fontWeight: 'light',
    color: '#5C5C5C',
    marginBottom: 15,
    lineHeight: 1.4
  },
  gradientLogo: {
    height: 100,
    width: 200,
  },
  iconButton: {
    margin: 10,
    color: colors.secondary,
    '&:hover': {
      backgroundColor: colors.transparent,
    },
  },
  rootBackIcon: {
    fill: colors.secondary,
    width: 28,
    height: 28,
  },
  loginForm: {
    display: 'flex',
    padding: 20,
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      // width: "100%",
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      flex: 1
    },
  },
  loginButton: {
    width: '230px',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      // marginBottom: 10,
      width: '100%',
      marginBottom: 30,
  
    },
  },
  loginAnimationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(70deg,rgb(152, 35, 92), rgb(238, 67, 82))',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'none',
  
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  content: {
    margin: 'auto',
    flexGrow: .7,
    maxWidth: 500,
  },
  loginButtons: {
    display: 'flex',
    marginTop: 30,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
  
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
}))

export default useStyles