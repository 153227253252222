/*eslint-disable */
const TERMS = [
 
  {
    title: 'Accessing the service.',
    desc: `You must be a registered user to access the Service. You must provide accurate, up-to-date and complete information when you register, and are solely responsible for keeping your password secure. You will be solely responsible and liable for any activity that occurs under your user name.
    
    You must use all reasonable endeavours to prevent any unauthorized access to, or use of, the Service and you must notify Tegeria. Ltd immediately in the event of any unauthorized access or use.
      `,
   
  },
  {
    title: 'Information.',
    desc: ` <i>Information </i>  as herein referred to is all such information that has been or shall be disclosed in writing or is disclosed electronically, orally or physically.

    Without limiting the generality thereof, Information shall include information and data relating to management reports,  management accounts, budgets, business plans, business strategies, sales, prospective customers, suppliers, pricing methods,  technical standards, curriculum standards, legal disputes, secret processes, trade secrets, patents, patent applications, techniques, sets, login details, software, source code, algorithms, flow charts, data flow patterns, learning models, and including specifically but not by way of limitation any information that could give any commercial advantage to partners or competitors of Tegeria.
      `,
   
  },
  {
    title: 'Confidentiality.',
    desc: `The Recipient (you) shall keep confidential the Information it receives from Tegeria and shall neither disclose to any third party nor use the Information it receives from Tegeria without the prior written consent.
    
    To secure the confidentiality of Information you shall:
    
     <h1 class="bullet">&bull;</h1> undertake not to use, reproduce or store any disclosed Information in an externally accessible computer or electronic 
     information retrieval system or transmit it outside of its principal place of business.

     <h1 class="bullet">&bull;</h1> allow access to disclosed Information exclusively to those employees of the recipient who have a reasonable need to 
     see and use it for the purpose of evaluation by the recipient and to ensure that those employees are bound by legally 
     enforceable obligations of secrecy to Tegeria.

     <h1 class="bullet">&bull;</h1> take all reasonable steps to ensure that disclosed Information does not come into the possession of third parties 
     who are not authorised to receive it.
      `,
   
  },
  {
    title: 'Intellectual and industrial property.',
    desc: `All intellectual and industrial property rights in Tegeria's Information are and will remain vested in Tegeria and all your intellectual  and industrial property rights are and will remain yours and neither party shall have or acquire any copyright in or apply for or  obtain any patent design industrial design trademark service mark or other similar protection in respect of disclosed Information  given to it by the other
    
    It is agreed that no rights or licence under any patent application, patent or any other intellectual property right is conveyed by this Agreement by implication or otherwise
    
    The use and disclosure of Information transmitted hereunder shall be subject to the Laws of Albania and the parties submit to the jurisdiction of the Albanian Courts.

      `,
   
  },
  {
    title: 'Service cancellation.',
    desc: `You are solely responsible for the cancellation of your account. You can cancel your account at any time through the settings/ subscription area of the application. Email or phone requests to cancel your account are not considered a cancellation.
    
    All of your content and data will be deleted 3 days after cancellation or termination of your account. This information will not be  able to be recovered. We recommend you use the export functionality of the Service prior to cancellation to retain any information  you may require.
    
    Failure to pay your subscription fees will result in your account being suspended. Accounts are suspended for a maximum of 3 days before the account may be terminated by us. We are not responsible for any loss you suffer as a result of such suspension or  termination. A suspended account can be reactivated by supplying valid payment credentials and resuming your subscription.
    
    If you cancel your account before the end of your currently paid month, the Service will end immediately and you will not be 
    charged again. You will not be entitled to a refund.

      `,
   
  },
  {
    title: 'Account Termination.',
    desc: `Tegeria Ltd in its sole discretion has the right to suspend or terminate your account and refuse any and all current or future use of  the Service, for any reason, at any such time. Such termination can result in the deactivation or deletion of your account. Tegeria Ltd. reserves the right to refuse service to anyone for any reason at any time.
    
    Tegeria Ltd. will not be liable for any delay or failure to perform any of its obligations in respect of the Service if such delay or  failure is due to an event which is beyond its control.

      `,
   
  },
  {
    title: 'Terms of service update.',
    desc: `Tegeria. Ltd. may modify the Service, or any other services, at any time, for any reason, and without notice. We also may modify these Terms of Service at any time but, if we do so, we will notify you of the modified Terms of Service and give you a reasonable  period of time to notify us that you do not agree to the modified Terms of Service and cease using the service.  `,
   
  },
  {
    title: 'Inactive accounts.',
    desc: `The accounts that are on the “Free” plan, on which there have been no logins for 60 days, will pass to an inactive status. Inactive accounts won’t update the data and the dashboard, and won’t receive any email reports. An account can become active again when the user logs in again and all functionalities will be active. 
    The user will be notified 2 weeks before the account passes to inactive status.`,
   
  },
]
  
export default TERMS
  