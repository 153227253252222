import { makeStyles } from '@material-ui/core'

const TooltipmModal = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 25px',
      maxWidth: 320,
    },
  },
  paperCostum5: {
    padding: '24px ',
    width: 450,
    borderRadius: 5,
    position: 'absolute',
    bottom: 40,
    right: 40,
    background: 'white',
    color: 'black',
    zIndex: 2000,
    border: '1px solid black',
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
      maxWidth: 350,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 25px',
      width: 230,
      right: 0
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  text: {
    marginBottom: 20,
    textAlign: 'center',
  },
  iconButtonClose: {
    position: 'absolute',
    right: -10,
    top: -15,
    [theme.breakpoints.down(400)]: {
      right: -30,
      top: -25,
    },
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  divider: {
    height: 1,
    backgroundColor: '#EBEBEB'
  },
  listContainer: {
    display: 'flex', 
    flexDirection: 'column',
    paddingTop: '5px' 
  },
  paddingLeft: {
    paddingLeft: 10 
  },
  listItem: {
    marginBottom: 10, 
    lineHeight: 1.4 
  },
  lineHeight: {
    ineHeight: 1.4
  }
}))

export default TooltipmModal