import React, { useCallback, useRef, useState } from 'react'
import { Text } from '..'
import { useDropzone } from 'react-dropzone'
import { ManualFileUploadStyles, useStyles } from './ManualFileUpload.styles'
import { afterFileUpload, errorIcon, uploadFile } from '../../Themes/Images'
import { colors } from '../../Themes'
import { IconButton, LinearProgress } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

const ManualFileUpload = ({ fileToUpload, setFileToUpload, cancelSubmit, isCsv, containsWhitespace, progress, isLoading }) => {
  const classes = ManualFileUploadStyles()
  const linearClasses = useStyles()
  const [preview, setPreview] = useState(null)
  const controllerRef = useRef()
  const [fileChosen, setFileChosen] = useState(false)


  let size = fileToUpload?.size / 1000000
  if (fileToUpload?.size  < 1000000) {
    size = (fileToUpload?.size / 1000000).toFixed(4)
  }
  else {
    size = (fileToUpload?.size / 1000000).toFixed(0)
  }
  
  const onDrop = useCallback((acceptedFiles) => {
    const file = new FileReader
    
    file.onload = function() {
      setPreview(fileToUpload?.result)
    }
    
    file.readAsDataURL(acceptedFiles[0])
    setFileToUpload(acceptedFiles[0])
    setFileChosen(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToUpload])

  const cancelFileSubmit = () => { 
    cancelSubmit()
    setFileChosen(false)
    controllerRef?.current?.abort()
  }

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: 
        {
          'text/csv': [],
        },
        
  })

  const AfterUpload = () => (
    <div className={classes.uploadWithErrors}>
      <div className={classes.fileUploaded}>
        {!isLoading && 
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.iconButtonClose }}
            onClick={() => cancelFileSubmit()}
          >
            <ClearIcon classes={{ root: classes.iconButtonClose }} />
          </IconButton>
        }
        {/* <div className={classes.afterDiv}> */}
        <div className={classes.fileInfoContainer}>
          <img className={classes.fileImg} src={afterFileUpload} alt='upload after file' />
          <div className={classes.textUpload}>
            <Text size='footnote'>{fileToUpload?.name}</Text>
            {/* <Text color={colors.textGray} size='small'>{size} MB</Text> */}
          </div>
        </div>
        {/* </div> */}
        {progress > 0 &&  
                <div style={{ width: '100%', marginTop: 20 }}>
                  <LinearProgress className={linearClasses.root} variant="determinate" value={progress} />
                </div>
        }
      </div> 
      {fileToUpload && !isCsv && <div className={classes.error}>
        <img className={classes.errorICon} src={errorIcon} />
        <Text size='footnote' color='rgba(238, 67, 82, 0.97)'>The file format is not correct. Make sure to upload a .csv file.</Text>
      </div>
      }
      {fileToUpload && containsWhitespace && <div className={classes.error}>
        <img className={classes.errorICon} src={errorIcon} />
        <Text size='footnote' color='rgba(238, 67, 82, 0.97)'>Make sure the file name doesn&apos;t contain any spaces.</Text>
      </div>
      }
    </div>
  )

  if (fileToUpload?.name && fileChosen) {
    return <AfterUpload />
  }

  return (
    <form id='my-form' className={classes.form}>
      <div className={classes.formItems} {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <div className={classes.dropDragging}>
              <img style={{ objectFit: 'contain' }} src={uploadFile} alt='upload file' />
              <div style={{ marginTop: 10 }}>
                <Text size='footnote'>Drag and drop file, or <Text size='footnote' weight='bold' color={colors.accent}>browse</Text></Text>
              </div>
              <Text color={colors.textGray} size='small'>File type must be .csv</Text>
            </div>
            :
            // fileToUpload?.name && fileChosen ?
            //   <AfterUpload />
            //   :
            <div className={classes.drop}>
              <img style={{ objectFit: 'contain' }} src={uploadFile} alt='upload file' />
              <div className={classes.text}>
                <Text size='footnote'>Drag and drop file, or <Text size='footnote' weight='bold' color={colors.accent}>browse</Text></Text>
                <Text color={colors.textGray} size='small'>File type must be .csv</Text>
              </div>
            </div> 
        }
      </div>
    </form>
  )
}

export default ManualFileUpload