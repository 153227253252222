import { colors } from '../../../Themes'

const selectStyles2 = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 60,
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 60,
    maxWidth: 450,
    maxHeight: 200
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 60,
    margin: '15px 0',
    height: 60,
    borderRadius: 5,
    '&:hover': {
      border: state.isFocused ?  `1px solid ${colors.primary}` :  '0px solid lightgray',
    },
    border: state.isFocused ? `1px solid ${colors.primary}` :  '0px solid lightgray',
    boxShadow: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: colors.background,
      color: colors.primary,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
}
  
const selectStyles3 = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 60,
    maxHeight: 100
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    minHeight: 60,
    maxWidth: '100%',
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 60,
    margin: '0px 0',
    borderRadius: 5,
    '&:hover': {
      border: state.isFocused ?  `1px solid ${colors.primary}` :  '0px solid lightgray',
    },
    border: state.isFocused ? `1px solid ${colors.primary}` :  '0px solid lightgray',
    boxShadow: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: colors.background,
      color: colors.primary,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
}

export { selectStyles2, selectStyles3 }