import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center'
  },
  img: {
    maxWidth: 600,
    maxHeight: 400,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  spaceText: {
    margin: '18px 0 20px 0',
    maxWidth: 600,
    lineHeight: 1.4
  },
  spaceTittle: {
    marginTop: 16
  },
  divider: {
    height: 1, 
    backgroundColor: '#EBEBEB', 
    width: '100%',
    display: 'flex', 
    marginTop: 15,
    marginBottom: 25
  },
  buttonsWrapper: {
    display: 'flex',
    gap: 10,
    width: '100%',
    justifyContent: 'center'
  },
  button: {
    width: '250px',
    backgroundColor: colors.white,
    color: colors.primary,
    border: '1px solid #cccccc',
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondButton: {
    width: '250px',
    backgroundColor: colors.primary,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primaryHover,
      color: colors.white,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  logOutButton: {
    marginTop: 16,
    cursor: 'pointer',
    '&:hover': {
      color: colors.primaryHover + '!important'
    }
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    marginBottom: 30
  },
  iconButtonClose: {
    position: 'absolute',
    right: 10,
    top: 10
  },  
}))

export default useStyles