import React from 'react'
import clsx from 'clsx'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import useStyles from './Modal.styles'

function ImageModal({ opened, handleClose, children, costumizedPaperStyles, type }) {
  const classes = useStyles()
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={opened}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ classes: { root: clsx(classes.backdrop, classes[type]) } }}
    >
      <Fade in={opened}>
        <div className={clsx(classes.paperImage, costumizedPaperStyles)}>
          {children}
        </div>
      </Fade>
    </Modal>
  )
}

export default ImageModal