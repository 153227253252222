/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Loading from 'react-lottie'
import { withStyles, useTheme } from '@material-ui/core/styles'
import { Table, TableBody, TableFooter, TablePagination, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { colors } from '../../Themes'
import { empty, PrimaryBin } from '../../Themes/Images'
import {  Text } from '..'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SecondaryButton } from '../Button/Button'
import { cellStyles, rowStyles, useCStyles, useStyles, useStyles1 } from './ManuallyDataEnterTable.styles'

const EMPTY = {
  loop: true,
  autoplay: true,
  animationData: empty,
  rendererSettings: {
    className: 'loading',
  },
}

function TablePaginationActions({ page, onChangePage, hasPrevious, more }) {
  const classes = useStyles1()
  const theme = useTheme()

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1)
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={handleBackButtonClick} disabled={!hasPrevious} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!more}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  more: PropTypes.bool.isRequired,

}

function CircularIndeterminate() {
  const classes = useCStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

const StyledTableCell = withStyles((theme) => (cellStyles))(TableCell)

const StyledTableRow = withStyles((theme) => (rowStyles))(TableRow)

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideCallback(ref, callback) {
  useEffect(() => {
    /**
       * Alert if clicked on outside of element
       */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default function ManuallyDataTable({ 
  data,
  onLoadMore, 
  showMore, 
  loading,
  onDelete,
  isDeleteLoading,
  current_page,
  per_page,
  setPaginationValues,
  previus,
  more
}) {
  const classes = useStyles()
  const wrapperRef = useRef(null)
  const [slectedItem, setSelctedItem] = useState(null)

  useOutsideCallback(wrapperRef, ()=>setSelctedItem(null))

  const onSelect = (key) => {
    setSelctedItem((prev) => {
      return key
    })
  }

  const handleChangePage = (event, newPage) => {
    setPaginationValues((prev) => {
      const next = {
        ...prev,
        current_page: newPage,
      }
      return next
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginationValues((prev) => {
      const next = {
        ...prev,
        per_page: parseInt(event.target.value, 10),
        current_page: 1,

      }
      return next
    })
  }

  if (loading) {
    return (
      <CircularIndeterminate />
    )
  }

  return (
    <TableContainer ref={wrapperRef} component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        {showMore && <caption className={classes.caption} onClick={onLoadMore} ><Text size='footnote' color={colors.accent} className={classes.showMore} > Show More</Text> </caption>}
        <TableHead>
          <TableRow className={classes.tableFont}>
            <StyledTableCell align="left">Date added</StyledTableCell>
            <StyledTableCell align="left">Date created</StyledTableCell>
            <StyledTableCell align="left">NPS score</StyledTableCell>
            <StyledTableCell align="left">Actions</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.length > 0 && data.map((item, index) =>
            (
              <StyledTableRow key={item?.id}>
                <StyledTableCell component="th" scope="row">
                  <Text 
                    size='footnote' 
                    weight="regular" 
                    color={ colors.primary} 
                  >  
                    {moment(item?.added_at).format('LL')}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text 
                    size='footnote' 
                    weight="regular" 
                    color={ colors.primary} 
                  >  
                    {moment(item?.created_at).format('LL')}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text size='footnote'>
                    {item?.score}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {slectedItem===item?.id ?
                    <SecondaryButton
                      className={classes.confrimDeleteButton} 
                      onClick={()=>onDelete(slectedItem)}
                      disabled={isDeleteLoading}
                    >
                      Confirm
                    </SecondaryButton>
                    :
                    <IconButton onClick={()=>onSelect(item?.id)}>
                      <PrimaryBin/>
                    </IconButton>
                  }
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
        {data.length>0 && 
       <TableFooter>
         <StyledTableRow key={'footer'}>
           <TablePagination
             rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
             colSpan={4}
             count={data.length}
             rowsPerPage={per_page}
             page={current_page}
             SelectProps={{
               inputProps: { 'aria-label': 'rows per page' },
               native: true,
             }}
             onChangePage={handleChangePage}
             onRowsPerPageChange={handleChangeRowsPerPage}
             labelDisplayedRows={	({ from, to, count }) =>('')}
             labelRowsPerPage="Rows per page"
             ActionsComponent={
               (subProps) => 
                 <TablePaginationActions
                   {...subProps} 
                   hasPrevious={previus!==null}
                   more={more}
                 />}
           />
         </StyledTableRow>
       </TableFooter>
        } 
      </Table>
      {
        data.length === 0 &&
        <div className="loadingContainer">
          <Text size='footnote' color={colors.grey} className={classes.margin}>No items found</Text>
          <Loading options={EMPTY} height={180} />
        </div>
      }
    </TableContainer>
  )
}