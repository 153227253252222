import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    maxWidth: 800,
    [theme.breakpoints.up('sm')]: {
      padding: '0px'
    },
  },
  iconRoot: {
    color: colors.primary,
    paddingLeft: 0,
    marginBottom: 10
  },
  iconLabel: {
    justifyContent: 'flex-start',
    color: colors.primary
  },
  space: {
    marginTop: 20,
    marginBottom: 40,
    lineHeight: 1.4
  },
  resendCode: {
    color: colors.primary,
    textDecoration: 'underline',
    textAlign: 'start',
    cursor: 'pointer',
    marginTop: 25,
  },
  continueButton: {
    marginTop: 25,
    width: '480px',
    alignSelf: 'flex-start',
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  }
}))

export { useStyles }