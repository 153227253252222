import React from 'react'
import { differentPracticeManagementImg } from '../../Themes/Images'
import { colors } from '../../Themes'
import { PrimaryButton } from '../../Components'
import useStyles from './DifferentPracticeManagement.styles'

function DifferentPracticeManagement({ value }) {
  const classes=useStyles()

  if (value === 'Gensolve') {
    return (
      <div className={classes.container}>
        <div className={classes.imgContainer}>
          <img src={differentPracticeManagementImg} className={classes.img}/>
        </div>
        <div   className={classes.messageWrapper}>
          <div className={classes.message}>
            <p size="h3" color={colors.accent}  className={classes.text} >Thank you for creating an account.<br/>
              <br/>
            Our team will reach out to guide you on the next steps for the integration!
              <br/>
            </p>
            <PrimaryButton 
              classes={{ root: classes.button }}
              onClick={()=>window.location.href=
                       'https://dataplayer.io/'
              }
            >
                       Explore website
            </PrimaryButton>
          </div>
        </div>
         
      </div>
    )
  }
  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={differentPracticeManagementImg} className={classes.img}/>
      </div>
      <div   className={classes.messageWrapper}>
        <div className={classes.message}>
          <p size="h3" color={colors.accent}  className={classes.text} >It seems like we’re not connected to that practice 
            management software. <br/>
          <br/>
            We’re in the process of adding other data sources.
          <br/>
          <br/>
            One of our customer service representatives will  reach out to advise how we can help you.</p>
          <PrimaryButton 
            classes={{ root: classes.button }}
            onClick={()=>window.location.href=
                     'https://dataplayer.io/'
            }
          >
                     Return to  dataplayer
          </PrimaryButton>
        </div>
      </div>
       
    </div>
  )
}

export default DifferentPracticeManagement
