/* eslint-disable indent */

import React from 'react'
import clsx from 'clsx'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { colors } from '../../Themes'
import Text from '../Text'
import stepperModalStyles from './StepperModal.styles'

const Timeline = ({ current, items }) => { 
  const classes = stepperModalStyles()

  const itemWrapper = (isActive)=>(
    { 
      minWidth: 32,
      height: 32, 
      backgroundColor: isActive ? '#EE4352' : '#E6E6E6', 
      borderRadius: '50%', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }
  )

  const line = (isHorizontalFilled)=>({ 
    height: 2, 
    width: '100%', 
    backgroundColor: isHorizontalFilled ? '#EE4352' : '#E6E6E6'
  })

    const renderPoint = ( item, isActive, isHorizontalFilled,  isLast)=> { 
        const { stepNumber, description } = item

        if (isLast){

            return (
                <div style={itemWrapper(isActive)} >
                    <Text size='subnote' color={colors.white}>{stepNumber}</Text>
                    <Text size='footnote' className={classes.lastItemDescription}>{description}</Text>
                </div>
            )
        }
        
        return (
            <div className={classes.line}>
              <div style={itemWrapper(isActive)}>
              <Text size='subnote' color={colors.white}>{stepNumber}</Text>
              </div>
              <div style={line(isHorizontalFilled)}></div>
              <Text size='footnote' className={classes.itemDescription} >{description}</Text>
            </div>
        )
    }

  return (
    <div className={classes.flex}>
        {items?.map((item, index)=>{
            return renderPoint(item, current>=index, current>index, index===items?.length-1)
        })}
    </div>
  )
}

function StepperModal({ isVisible, current, elements, handleClose, costumizedPaperStyles, timeLine, type, onSuccessAPI, onSuccessInfo }) {
  const Element = elements[current]
  const classes = stepperModalStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isVisible}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ classes: { root: clsx(classes.backdrop, classes[type]) } }}
    >
      <Fade in={isVisible}>
        <div className={clsx(classes.paper, costumizedPaperStyles)}>
        <p className={classes.firstTitle}>Hi, welcome to Dataplayer!</p>
        <p className={classes.helperMessage}>
          {' '}
          To set up your account, we need a few more details.
        </p>
        <div className={classes.divider} />
          <Timeline
            current={current}
            items={timeLine}
          />
          {/* {elements[current]} */}
          <Element
            onSuccessSave={onSuccessAPI}
            onSuccessInfo={onSuccessInfo}
          />
        </div>
      </Fade>
    </Modal>
  )
}

export default StepperModal
