/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import Loading from 'react-lottie'
import Axios from '../../Config/Axios'
import { Auth } from 'aws-amplify'
import { HelpIcon, HelpSupport, email, loading, loginAnimation, receipt } from '../../Themes/Images'
import { useTheme } from '@material-ui/core/styles'
import { IconButton, Card, CardContent, CardActions, useMediaQuery } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { colors } from '../../Themes'
import { useHistory } from 'react-router'
import { useAuth, useSubscription } from '../../Global'
import PriceTabs from '../../Components/PriceTabs/PriceTabs'
import { CurrentCard, DynamicCard, FreeCard, TeraCard } from '../../Components/PriceCards/PriceCards'
import { PrimaryButton, Modal, Password, Text, Loader } from '../../Components'
import shouldRouteshow from '../../Utils/RotuesForCostumizedUsers'
import ReferalCodeModal from '../../Components/ReferalCodeModal/ReferalCodeModal'
import useStyles from './UpgradePlan.styles'
import FromPaidToFreeModal from '../../Components/FromPaidToFreeModal'
import StepBeforeDowngradeModal from '../../Components/StepBeforeDowngradeModal'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
const DataTableUpgrade = React.lazy(() => import('../../Components/DataTableUpgrade'))
import { CSSTransition } from 'react-transition-group'
import PriceTabsMobileTable from '../../Components/PriceTabsMobileTable'
import { SELECT_OPTIONS } from '../../Utils/useCurrency'
import ReferalEnterUpgrade from '../../Components/ReferalEnterUpgrade'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const LOADING_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    className: 'loading',
  },
}

const SECURITY_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loginAnimation,
  rendererSettings: {
    className: 'loginAnim',
  },
}

function filterPlans(items, type) {
  if (items.length > 0) {
    return items.filter(plan => plan?.billing_type === type)
  }
  return []
}

const SCREEN_CONTENT = {
  firstStep: 'firstStep',
  secondStep: 'secondStep',
}

const SuccessModal = (
  { isVisible,
    isUpgrade,
    isFree,
    goBackToDashboard, 
    goToPaymentInformation,
    isLoading 
  }
) => {
  const classes = useStyles()

  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.costumizedPaperStyles} opened={isVisible}  >
      <div className={classes.modalContent} >
        <div className={classes.modalImg} >
          <img className={classes.img} src={email} alt='' />
        </div>
        {isLoading ? <p className={classes.modalTittle} > Your new dashboard is still loading. </p> : <p className={classes.modalTittle} > Your upgrade was successful! </p>}
        <p className={classes.desc} >Depending on how many records your clinic has, loading <br />
            your new dashboard may take up to {isFree && !isUpgrade?<b>30 minutes.</b> :<b>6 hours.</b> }   <br /><br />
            When everything is ready, we will notify you via email. In the <br />
            meantime, you can continue using your previous plan.</p>
        {isLoading ?
          <PrimaryButton
            classes={{ root: classes.goBackButton }}
            onClick={goBackToDashboard}
          >
            Go back to the dashboard
          </PrimaryButton>
          :
          <PrimaryButton
            classes={{ root: classes.goBackButton }}
            onClick={goToPaymentInformation}
          >
            Go to the dashboard
          </PrimaryButton>
        }
      </div>
    </Modal>
  )
}

const ConfirmModal = ({ onUpgradePlan, goBack, confrimModal, selectedPlan, isLoadingUpgrade }) => {
  const classes = useStyles()

  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.costumizedPaperStyles} opened={confrimModal}  >
      <div className={classes.modalContent} >
        <div >
          <img className={classes.img} src={receipt} alt='' />
        </div>
        <p className={classes.modalTittle} style={{ margin: 0 }} >  Confirm  subscription  </p>
        <p className={classes.desc}  style={{ textAlign: 'center', }}>Are you sure you want to subscribe to the {selectedPlan?.billing_type==='month'?'monthly':'yearly'} <b style={{ fontSize: 20 }}>{selectedPlan?.name}</b>  plan for   <b style={{ fontSize: 20 }}>${selectedPlan?.recurring_price?.USD}</b> ?</p>
        <div className={classes.modalButtons}>
          <PrimaryButton
            classes={{ root: classes.secondButton }}
            style={{ border: '1px solid #cccccc' }}
            onClick={goBack}
          >
            Cancel 
          </PrimaryButton>
          <PrimaryButton
            classes={{ root: classes.firstButton }}
            onClick={onUpgradePlan}
            loading={isLoadingUpgrade}
            style={
              isLoadingUpgrade
                ? { backgroundColor: colors.disabled, minHeight: 50 }
                : {  minHeight: 50 }
            }
            disabled={isLoadingUpgrade}
          >
            Subscribe
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}


const FirstStep = ({ tabValue, handleTabChange, plans, handleSelectedPlan, showDowngradeModal, handleOnBlur, referralEnteredSuccess, setReferralEnteredSuccess }) => {
  const { plan } = useSubscription()
  const isGiga = plan?.[0]?.name === 'Giga'
  const isMega = plan?.[0]?.name === 'Mega'
  const { is_trialing, plan: globalPlan, referralCodeUpgrade, setReferralCodeUpgrade, validateReferralCode, referralCodeOneTimeUsage } = useAuth()
  const free = globalPlan === 'free'
  const initialValue = ''
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const ref = useRef(null)
  const isMobile = useMediaQuery(theme.breakpoints.down(760))
  const isMobileTable = useMediaQuery(theme.breakpoints.down(760))
  const [mobileTableValue, setMobileTableValue] = useState(0)
  const [priceValues, setPriceValues] = useState('')

  const [referredClicked, setReferredClicked] = useState(false)
  const [referralLoading, setReferralLoading] = useState(false)
  const [referralErrors, setReferralErrors] = useState({})
  const [referralDisabled, setReferralDisabled] = useState(false)
  const [referralBUttonDisabled, setReferralBUttonDisabled] = useState(false)
  const [message, setMessage] = useState()
  // const [referralEnteredSuccess, setReferralEnteredSuccess] = useState(false)

  const location = useLocation()

  useEffect(() => {
    setReferralCodeUpgrade('')
  }, [location])

  const handleChangeReferralCode = (e, name) => {
    setReferralCodeUpgrade(e.target.value)
    delete referralErrors[name]
    setMessage('')
  }
  

  const handleChange = (value) => {
    setPriceValues(value)
  }

  const handleChangeMobileTable = (event, newValue) => {
    setMobileTableValue(newValue)

  }

  const redirectToCancelPlan = () => {
    history.push('/cancelPlan' )
  }

  const handleShow = useCallback(() => {
    setShow(!show)
  }, [show])

  const showLess = (
    <div>
      <Text style={{ cursor: 'pointer', textDecoration: 'underline' }} color='#EE4352' size='body'>Show less details</Text>
      <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg' alt='arrowUp' style={{ marginLeft: 10, transform: 'rotateX(0.5turn)', fill: '#ee4352', height: 15, width: 15, objectFit: 'contain' }} />
    </div>
  )

  const showMore = (
    <div>
      <Text style={{ cursor: 'pointer', textDecoration: 'underline' }} color='#EE4352' size='body'>Show more details</Text>
      <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg' alt='arrowDown' style={{ marginLeft: 10, fill: '#ee4352', height: 15, width: 15, objectFit: 'contain' }} />
    </div>
  )

  const linkName = show ? showLess : showMore

  const createData = (name, type ) => {
    return { name, type }
  }

  const checkImage = 
  <div >
    <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/check.svg' alt='check' className={classes.check} />
  </div>
  
  const text = (title) => (
    <Text size='body' color={colors.primary} weight="regular">{title}</Text>
  )
  
  const text2 = (title) => (
    <Text size='body' color={colors.primary} weight="bold">{title}</Text>
  )

  const text5 = (title, subTitle) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text size='body' color={colors.primary} weight="bold">{title}</Text>
      <Text className={classes.text7} >{subTitle}</Text>
    </div>
  )

  const rows = [
    createData(text2('Hourly data refresh'), checkImage),
    createData(text2('Unlimited customer support'), checkImage),
    createData(text2('Data sources'), text('Single data source')),
    createData(text2('Email reports'), text('Daily reports')),
    createData(text2('Automatic reports to'), text('1 email address')),
    createData(text2('Multiple filters'), checkImage),
  ]


  const rows2 = [
    createData(text2('Hourly data refresh'), checkImage),
    createData(text2('Customisations'), text('Up to 3 custom graphs')),
    createData(text2('Unlimited customer support'), checkImage),
    createData(text2('Data sources'), text('Single data source')),
    createData(text2('Email reports'), text('Daily and monthly reports')),
    createData(text2('Automatic reports to'), text('3 email addresses')),
    createData(text2('Multiple filters'), checkImage),
    createData(text2('Default targets'), checkImage),
  ]

  const rows3 = [
    createData(text2('Hourly data refresh'), checkImage),
    createData(text2('Customisations'), text('Up to 10 custom graphs')),
    createData(text2('Unlimited customer support'), checkImage),
    createData(text2('Data sources'), text('Single data source + add-ons')),
    createData(text2('Email reports'), text('Daily, weekly and monthly reports')),
    createData(text2('Automatic reports to'), text('5 email addresses')),
    createData(text2('Multiple filters'), checkImage),
    createData(text2('Default targets'), checkImage),
    createData(text5('Action shortcuts', '*Available for Cliniko users only.'), checkImage),
  ]
  
  const rows4 = [
    createData(text2('Hourly data refresh'), checkImage),
    createData(text2('Customisations'), text('Unlimited')),
    createData(text2('Unlimited customer support'), checkImage),
    createData(text2('Data sources'), text('Multiple data sources')),
    createData(text2('Email reports'), text('Daily, weekly, monthly, quarterly & annual reports')),
    createData(text2('Automatic reports to'), text('10 email addresses')),
    createData(text2('Multiple filters'), checkImage),
    createData(text2('Default targets'), checkImage),
    createData(text2('Manual file integration'), checkImage),
    createData(text5('Action shortcuts', '*Available for Cliniko users only.'), checkImage),
  ]

  const MobileTable = ({ rowName }) => {
    return (
      <div className={[classes.flexColumn]}>
        <TableContainer className={classes.tableBorder} >
          <Table className={classes.table} aria-label="simple table" >
            <TableBody>
              {rowName.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.text2} component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.text2} align="center">{row.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  let content = <></>
  if (free || is_trialing) {
    content =  
    <>
      <CSSTransition in={mobileTableValue === 0} timeout={9000} classNames="second">
        <div hidden={mobileTableValue !== 0}>
          {mobileTableValue === 0 && <MobileTable rowName={rows}  />}</div>
      </CSSTransition>
      <CSSTransition in={mobileTableValue === 1} timeout={9000} classNames="second">
        <div hidden={mobileTableValue !== 1}>
          {mobileTableValue === 1  && <MobileTable rowName={rows2}  />}</div>
      </CSSTransition>
      <CSSTransition in={mobileTableValue === 2} timeout={9000} classNames="second">
        <div hidden={mobileTableValue !== 2}>
          {mobileTableValue === 2 && <MobileTable rowName={rows3} />}</div>
      </CSSTransition>
      <CSSTransition in={mobileTableValue === 3} timeout={9000} classNames="second">
        <div hidden={mobileTableValue !== 3}>
          {mobileTableValue === 3 && <MobileTable rowName={rows4} />}</div>
      </CSSTransition>
    </>
  }

  if (isMega) {
    content = 
    <>
      <CSSTransition in={mobileTableValue === 0} timeout={9000} classNames="second">
        <div hidden={mobileTableValue !== 0}>
          {mobileTableValue === 0  && <MobileTable rowName={rows2}  />}</div>
      </CSSTransition>
      <CSSTransition in={mobileTableValue === 1} timeout={9000} classNames="second">
        <div hidden={mobileTableValue !== 1}>
          {mobileTableValue === 1 && <MobileTable rowName={rows3} />}</div>
      </CSSTransition>
      <CSSTransition in={mobileTableValue === 2} timeout={9000} classNames="second">
        <div hidden={mobileTableValue !== 2}>
          {mobileTableValue === 2 && <MobileTable rowName={rows4} />}</div>
      </CSSTransition>
    </>
  }

  if (isGiga) {
    content = 
    <>
      <CSSTransition in={mobileTableValue === 0} timeout={9000} classNames="second">
        <div hidden={mobileTableValue !== 0}>
          {mobileTableValue === 0 && <MobileTable rowName={rows3} />}</div>
      </CSSTransition>
      <CSSTransition in={mobileTableValue === 1} timeout={9000} classNames="second">
        <div hidden={mobileTableValue !== 1}>
          {mobileTableValue === 1 && <MobileTable rowName={rows4} />}</div>
      </CSSTransition>
    </>
  }


  const onSubmit= async ()=>{
    redirectToCancelPlan()
    // setLoading(true)
    // try {
    //   await Axios.post('/prod/user/setupfreeaccount',
    //   )
    //   await getUpdatedUser()
    //   setLoading(false)
    //   showModal()
    // } catch (error) {
    //   setLoading(false)
    // }
  }

  const isGigaCondition = (plan?.[0]?.name === 'Giga' && (plan?.[0]?.billing_type === 'month' && tabValue === 0)) || (plan?.[0]?.name === 'Giga' && plan?.[0]?.billing_type === 'year')
  const isGigaConditionMonthly = (plan?.[0]?.name === 'Giga' && (plan?.[0]?.billing_type === 'month' && tabValue === 1)) || (plan?.[0]?.name === 'Mega' && plan?.[0]?.billing_type === 'year')
  const isGigaYearly = plan?.[0]?.name === 'Giga' && plan?.[0]?.billing_type === 'year'


  return (
    <div className={classes.container} >
      {isMobile ? null : <div className={classes.close}>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => history.goBack()}
        >
          <ArrowBackIcon classes={{ root: classes.closeIcon }} />
          <p className={classes.back}>Back</p>
        </IconButton>
      </div>}
      <div className={classes.header} >
        {isMobile ? null :  <div className={classes.close}>
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.iconButtonClose }}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon classes={{ root: classes.closeIcon }} />
            <p className={classes.back}>Back</p>
          </IconButton>
        </div> }
        <p className={classes.headerText} >Upgrade your subscription plan</p>
      </div>
      <div className={classes.line} />
      {/* <div className={classes.cardsContainer} > */}
      {/* <p className={classes.planForYouText} >Empower your practice with additional features.</p> */}
     
      <div className={isGigaCondition ? classes.cardsWrapperGigaCondition :isGigaConditionMonthly ? classes.gigaMonthlyTab2 : isGigaYearly ? classes.gigaYearly : classes.cardsWrapper} >
        {(free|| is_trialing) && referralCodeOneTimeUsage === undefined &&
          <ReferalEnterUpgrade 
            value={referralCodeUpgrade} 
            success={referredClicked} 
            referralClicked={() => setReferredClicked(true)} 
            message={message} 
            referralEnteredSuccess={referralEnteredSuccess} 
            handleChange={(value) => handleChangeReferralCode(value, 'code')} 
            handleOnBlur={handleOnBlur}
            isDisabled={referralDisabled || referralBUttonDisabled}
            setDisabled={setReferralDisabled}
            isLoading={referralLoading}
            verifyCode={() => validateReferralCode(setReferralLoading, setReferralErrors, setReferralDisabled, setMessage, setReferralEnteredSuccess, setReferralBUttonDisabled)}
            inputDisabled={referralBUttonDisabled}
          />
        }
      
        <PriceTabs value={tabValue} handleChange={handleTabChange} onChange={handleChange} SELECT_OPTIONS={SELECT_OPTIONS} priceValues={priceValues} />
        {/* <FreeCard plans={plan?.[0]?.name} onSelect={onSubmit} loading={loading} value={tabValue} /> */}
        
        {plan.length > 0 ?
          <CurrentCard plans={plan?.[0]?.name} onSelect={onSubmit} loading={loading} />
          :
          <FreeCard value={tabValue} signValue={priceValues?.value} />
        }
        {/* {plan?.length > 0 && <CurrentCard />} */}
        {tabValue === 0 &&
          filterPlans(plans, 'month').map((plan) => {
            return <DynamicCard key={plan?.id} value={tabValue} onSelect={() => handleSelectedPlan({ ...plan })} signValue={priceValues?.value} priceId={priceValues?.id} {...plan} />
          })
        }
        {tabValue === 1 &&
          filterPlans(plans, 'year').map((plan) => {
            return <DynamicCard key={plan?.id} value={tabValue} onSelect={() => handleSelectedPlan({ ...plan })} signValue={priceValues?.value} priceId={priceValues?.id} {...plan} />
          })
        }
       
        <TeraCard value={tabValue} />
      </div>
      {!isMobileTable && <div className={classes.linkName} onClick={() => handleShow()} >
        <Text style={{ cursor: 'pointer', textDecoration: 'underline' }} color='#EE4352' size='body'>{linkName}</Text></div>}
      {isMobileTable ? 
        <div ref={ref} className={classes.flexColumn}>
          <PriceTabsMobileTable isMega={isMega} value2={tabValue} value={mobileTableValue} handleChange={handleChangeMobileTable} isFree={free} isTrialing={is_trialing} isGiga={isGiga} signValue={priceValues?.value} priceId={priceValues?.id} />
          <Text className={classes.textTitleTableMobile} size='h3' color={colors.primary} weight='bold'>Feature / Plan</Text>
          {content}
        </div>
        :
        <div ref={ref} id={show ? 'table' : undefined}  className={classes.tableDiv}>
          {show && <React.Suspense fallback={<Loader />}><DataTableUpgrade value={tabValue} free={free} isGiga={isGiga} isTrialing={is_trialing} signValue={priceValues?.value} priceId={priceValues?.id} /></React.Suspense>}           
        </div>}
      {/* </div> */}
      <div className={classes.footerContainer} >
        <div className={classes.footer}  onClick={() => {
          let d = document
          let s = d.createElement('script')
          s.type = 'text/javascript'
          s.id = 'zsiqscript'
          s.defer = true
          s.src = 'https://salesiq.zoho.eu/widget'
          let t = d.getElementById('zohoChat')
          t.parentNode.insertBefore(s, t)
        }}>
          <Text weight='regular' color={colors.background} size='subnote'>Did not find what you are looking for?&nbsp;
            <Text className={classes.chatLink} weight='bold' color={colors.background} size='subnote'>Chat with us
            </Text>
          </Text>
          <HelpSupport  style={{ width: 24, height: 24, marginLeft: 8 }}/>
        </div>
        <div className={classes.links} >
          <a className={classes.link} target="_blank" rel="noopener noreferrer" href="https://www.dataplayer.io/security" > Privacy Policy</a> |
          <a className={classes.link} target="_blank" rel="noopener noreferrer" href="https://www.dataplayer.io/terms" >Terms & Conditions</a>
        </div>
      </div>
    </div>
  )
}

const SecondStep = ({ setUpgradeStatus, password, setPassword, email, verifyPassword, loading, error, setSelectedPlan, isVisible }) => {
  const classes = useStyles()

  return (
    <div className={classes.container} >
      <div className={classes.header} >
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => {
            setSelectedPlan('')
            setUpgradeStatus(SCREEN_CONTENT.firstStep)
          }}
        >
          <ArrowBackIcon classes={{ root: classes.closeIcon }} />
          <p className={classes.back}>Back</p>

        </IconButton>
        <p className={classes.headerText} >Verify account</p>
      </div>
      <div className={classes.line} />
      <div className={classes.verifyWrapper} >
        <div className={classes.animationContainer}>
          <Loading options={SECURITY_OPTIONS} height={600} />
        </div>
        <div className={classes.cardContainer}>
          <Card className={classes.cardRoot} variant="outlined">
            <div className={classes.cardTitle}>
              <Text size='footnote' color={colors.white}  >For your own security, verify your account.</Text>
            </div>
            <CardContent classes={{ root: classes.MuiCardContentRoot }} >
              <Text size='h5' color={colors.accent} weight="bold" style={{ marginBottom: 29 }} >Enter your password</Text>
              <Text size="footnote" color={colors.primary} weight="light" style={{ marginBottom: 6 }} >Account</Text>
              <Text size="footnote" color={colors.primary} weight="regular">{email}</Text>
              <Password
                label="Password"
                value={password}
                setValue={setPassword}
              />
              {Boolean(error) && <span className="loginError" style={{ textAlign: 'start', color: colors.secondary, width: 365, lineHeight: 1.5 }}>{error}</span>}
              <PrimaryButton
                classes={{ root: classes.continueButton }}
                style={
                  password === '' || loading
                    ? { backgroundColor: colors.disabled, minHeight: 50 }
                    : { backgroundColor: colors.primary, minHeight: 50 }
                }
                disabled={password === '' || loading}
                loading={loading}
                onClick={verifyPassword}
              >
                Continue
              </PrimaryButton>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

const UpgradePlanSteps = {
  firstStep: FirstStep,
  secondStep: SecondStep,
}

function UpgradePlan() {
  const history = useHistory()
  const { user, userAttributes, data_loading, tooltips, referralCodeUpgrade } = useAuth()
  const { subscription, plans, isLoadingPlans, plan } = useSubscription()
  const shouldShowRoutes = shouldRouteshow(tooltips)
  const Paddle = window.Paddle
  const [upgradeStatus, setUpgradeStatus] = useState(SCREEN_CONTENT.firstStep)
  const UpgradeSteps = UpgradePlanSteps[upgradeStatus]
  const [tabValue, setTabValue] = useState(0)
  const [checkoutSuccess, setCheckoutSuccess] = useState(false)
  const [confrimModal, setConfirmModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sowDowngradeModal, setShowDowngradeModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [isLoadingUpgrade, setIsLoadingUpgrade] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const [refCode, setRefCode] = useState('')
  const { is_trialing, plan: Global, referralCodeOneTimeUsage, setReferralCodeUpgrade }=useAuth()
  const [errors, setErrors] = useState({})
  const [isDisabled, setDisabled] = useState(true)
  const [error, setError] = useState('')
  const [referralEnteredSuccess, setReferralEnteredSuccess] = useState(false)

  const free = plan?.length === 0

  // const handleChange = (e, name) => {
  //   setRefCode(e.target.value)
  //   delete errors[name]
  //   setError('')
  // }

  // const showReferralModal = () => {
  //   setIsModalOpen(true)
  // }

  const showFromPaidToFree = () => {
    setShowModal(true)
  }

  // const cancelFromPaidToFree = () => {
  //   setShowModal(false)
  // }

  const showDowngradeModal = () => {
    setShowDowngradeModal(true)
  }

  const cancelShowDowngradeModal = () => {
    setShowDowngradeModal(false)
  }

  // const handleCloseModal = () => {
  //   setIsModalOpen(false)
  //   setRefCode('')
  //   setError()
  //   setErrors({})
  //   setDisabled(true)
  // }

  // to clear error
  useEffect(() => {
    if (error) {
      setError(undefined)
    }
  }, [password])

  const onUpgradePlan = () => {
    setIsLoadingUpgrade(true)
    Axios.post('prod/user/subscription/upgrade', {
      plan_id: selectedPlan?.id
    }).then(res => {
      setConfirmModal(false)
      setIsLoadingUpgrade(false)
      setCheckoutSuccess(true)

    }).catch(err => {
      setIsLoadingUpgrade(false)
      console.log(err)
    })
  }

  const checkout = () => {
    if (plan.length > 0) {
      setConfirmModal(true)
    } else {
      setIsModalOpen(false)
      Paddle.Checkout.open({
        product: selectedPlan?.id,
        email: userAttributes?.email,
        passthrough: JSON.stringify({
          username: user,
          email: userAttributes?.email,
          sub: userAttributes?.sub,
          'auth_time': '16177s14966',
        }),
        successCallback: function (data) {
          setCheckoutSuccess(true)
        }
      })
    }
  }

  const checkoutWithCode = async () => {
    if (plan.length > 0) {
      setConfirmModal(true)
    } else {
      // setLoading(true)
      // try {
      //   await Axios.post('/prod/validateReferralCode',
      //     {
      //       referralCode: refCode,
      //     }
      //   ).then((response) => {
      // handleCloseModal()
      Paddle.Checkout.open({
        product: selectedPlan?.id,
        email: userAttributes?.email,
        passthrough: JSON.stringify({
          username: user,
          referralCode: referralCodeUpgrade,
          email: userAttributes?.email,
          sub: userAttributes?.sub,
          'auth_time': '16177s14966',
        }),
        successCallback: function (data) {
          setCheckoutSuccess(true)
          setReferralCodeUpgrade('')
        }
      })
        
      setLoading(false)
      setErrors({})
      setDisabled(true)
      // } catch (error) {
      //   setError(error)
      //   setLoading(false)
      // }
    }
  }

  function validate(input) {
    let errors = {}
    switch (input.key) {
    case 'code': {
      if (!input.value) {
        errors.code = 'Please fill out the required information.'
      }
      return errors
    }
    default:
      return errors
    }
  }

  // const handleOnBlur = (e) => {
  //   e.preventDefault()
  //   const { name, value } = e.target
  //   const input = {
  //     key: name,
  //     value,
  //   }
  //   const validateError = validate(input)
  //   setErrors({
  //     ...errors,
  //     ...validateError,
  //   })
  // }

  const handleOnBlurReferralCode = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const input = {
      key: name,
      value,
    }
    const validateError = validate(input)
    setReferralEnteredSuccess({
      ...errors,
      ...validateError,
    })
  }

  const handleSelectedPlan = (payload) => {
    setSelectedPlan(payload)
    setUpgradeStatus(SCREEN_CONTENT.secondStep)
  }

  const verifyPassword = () => {
    setLoading(true)
    Auth.signIn(user, password)
      .then(() => {
        setUpgradeStatus(SCREEN_CONTENT.firstStep)
        {(is_trialing || free) && referralCodeOneTimeUsage === undefined && referralEnteredSuccess ?  checkoutWithCode() : checkout()}
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setError('The password you entered did not match our records. Please double-check it and try again.')
      })
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const goBackToDashboard = () => {
    history.push('/dashboard')
  }

  // const goToPaymentInformation = () => {
  //   history.push('/dashboard', 
  //   )}

  return (
    <>
      { isLoadingPlans ?
        <div className="loadingContainer">
          <Loading options={LOADING_OPTIONS} height={180} />
        </div>
        :
        <UpgradeSteps
          // First Step
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          plan={plan}
          plans={plans}
          checkoutSuccess={checkoutSuccess}
          goBackToDashboard={goBackToDashboard}
          checkout={checkout}
          setUpgradeStatus={setUpgradeStatus}
          showDowngradeModal={showDowngradeModal}
          handleOnBlur={handleOnBlurReferralCode}
          setReferralEnteredSuccess={setReferralEnteredSuccess}
          referralEnteredSuccess={referralEnteredSuccess}
          // Second Step
          password={password}
          setPassword={setPassword}
          email={userAttributes?.email}
          handleSelectedPlan={handleSelectedPlan}
          verifyPassword={verifyPassword}
          setSelectedPlan={setSelectedPlan}
          loading={loading}
          error={error}
          showModal={showFromPaidToFree}
        />
      }

      <SuccessModal
        isVisible={checkoutSuccess || data_loading}
        isLoading={data_loading}
        isFree={Object.keys(subscription).length === 0}
        isUpgrade={checkoutSuccess}
        goBackToDashboard={goBackToDashboard}
        goToPaymentInformation={goBackToDashboard}
      />
      <ConfirmModal
        selectedPlan={selectedPlan}
        goBack={() => {
          setSelectedPlan('')
          setConfirmModal(false)
        }
        }
        confrimModal={confrimModal}
        onUpgradePlan={onUpgradePlan}
        isLoadingUpgrade={isLoadingUpgrade}
      />
      {/* <ReferalCodeModal isVisible={isModalOpen}
        onCancel={handleCloseModal} checkout={checkout}
        checkoutWithCode={checkoutWithCode} handleChange={(value) => handleChange(value, 'code')} value={refCode} 
        error={error} handleOnBlur={handleOnBlur} isDisabled={isDisabled} isLoading={loading} setDisabled={setDisabled}
      /> */}
      {/* <FromPaidToFreeModal isVisible={showModal} cancel={cancelFromPaidToFree} /> */}
      {/* <StepBeforeDowngradeModal plan={plan?.[0]?.name} isVisible={sowDowngradeModal} cancel={cancelShowDowngradeModal} /> */}
    </>
  )
}

export default UpgradePlan