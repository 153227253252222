/* eslint-disable react/no-unescaped-entities */
/* eslint-disable indent */
import React from 'react'
import { Card, IconButton } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import WarningIcon from '@material-ui/icons/Warning'
import CloseIcon from '@material-ui/icons/Close'
import { Text } from '..'
import { colors } from '../../Themes'
import { useHistory } from 'react-router'
import useStyles from './VerifyEmailCardDrawer.styles'

function VerifyEmailCardDrawer({ onClose }) {
  const classes = useStyles()
  const history=useHistory()

  return (
    <Card className={classes.root}>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <div className={classes.headerWrapper} >
          <WarningIcon color="error" />
          <Text size="subnote" weight='medium'  color={colors.primary} className={classes.title}>Verify Email </Text>
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.closeIcon }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.text}>
          <Text size="footnote" weight='regular' color={colors.primary}>To get your practice reports and other important communications, please verify your email address.</Text>
        </div>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          classes={{ root: classes.iconButtonRoot, label: classes.labelRoot }}
          style={{ backgroundColor: 'transparent' }}
          onClick={() => history.push('/settings/account')}
        >
          <p className={classes.linkTo}>Proceed</p>
        </IconButton>
      </CardContent>
    </Card>
  )
}

export default VerifyEmailCardDrawer
