import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'
import { DRAWER_WIDTH } from '../../Themes/Constants'
import { display } from '@material-ui/system'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
  drawerOpen: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: colors.white,
    position: 'absolute',
    bottom: 64,
    left: DRAWER_WIDTH - 20,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 1250,
    '&:hover': {
      backgroundColor: colors.background,
    },
  },
  drawerClose: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: colors.white,
    position: 'absolute',
    bottom: 64,
    left: 52.5,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1250,
    '&:hover': {
      backgroundColor: colors.background,
    },
  },
  iconButton: {
    marginRight: 10,
  },
  iconButtonMobile: {
    marginRight: 10,
    marginLeft: 10,
  },
  buttonDiv: {
    paddingInline: 25,
    marginBottom: 24
  },
  button: {
    width: '100%',
    minHeight: 48,
    borderRadius: '5px',
    fontSize: '14px',
    fontWeight: 500,
    backgroundColor: '#901F5D',
    color: colors.white,
    '&:hover': {
      backgroundColor: '#901F5D',
      color: colors.white,
    },
  },
  divider: {
    height: 1,
    marginTop: 10,
    backgroundColor: '#E6E6E6',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  menuCompanyInfo: {
    display: 'flex',
    // flexDirection: 'column',
    margin: '0 10px 0px 25px',
    marginBottom: 30,
    alignItems: 'center'
  },
  menuCompanyInfo1: {
    display: 'flex',
    justifyContent: 'center',
    // flexDirection: 'column',
    marginBottom: 30,
    // justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: 25,
    },
  },
  companyLogo: {
    minWidth: 53,
    maxWidth: 100,
    height: 100,
    objectFit: 'contain',
    cursor: 'pointer'
  },
  text: {
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  text1: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // Modal
  paperCostum: {
    padding: '50px 70px',

    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },

  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 10
  },

  inputContainer: {
    margin: '15px 0',
  },
  firstTitle: {
    fontSize: 25,
    color: colors.accent,
    marginBottom: 22,
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  dateRangePickerDivContainer: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 16,
    justifyContent: 'space-between',
    [theme.breakpoints.down(760)]: {
      flexDirection: 'column',
      gap: 8
    },
  },

  helperMessage: {
    fontSize: 15,
    marginBottom: 22,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },

  input_true: {
    border: '1px solid ' + colors.secondary,
    flexGrow: 1,
    borderRadius: 5,
    // minWidth: 280,
    marginTop: 15,
    marginBottom: 5,

    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  errorInput: {
    color: colors.secondary,
    padding: '0 12px',
    fontSize: 16,
    marginBottom: 10,
  },
  // Modal Confirm
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 22,
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      marginTop: 20,

    },
  },
  cancelButton: {
    flex: '0 0 49%',
    backgroundColor: colors.white,
    color: colors.primary,
    fontWeight: '400',
    '&:hover': {
      backgroundColor: colors.grayHover,

    },
  },
  nextButton: {
    flex: '0 0 49%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
    },
  },
  specifyReason: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 8
    },
  },
  otherInput: {
    flex: 1.7
  },
  marketingInput: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  onboardTitle: {
    fontFamily: 'Roboto',
    fontWeight: '700',
  },
  onboardTitleRegion: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    marginBottom: 8
  },
  onboardTitleCurrency: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    marginTop: 20,
    marginBottom: 8
  },
  onboardTitleNote: {
    padding: 10,
    paddingLeft: 0,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginBottom: 10
  },
  integrationNote: {
    padding: 10,
    border: `1px solid ${colors.disabled}`,
    borderRadius: 5,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  noteImage: {
    height: 20,
    width: 21,
    marginRight: 13,
  },
  noteText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  discoverDP: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 8
  },
  discoverDPText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    display: 'block'
  },
  discoverDPTextOpt: {
    display: 'block',
    marginLeft: 8,
    color: '#999999'
  },
}))

const selectStyles = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    flex: 1,
    // zIndex: 100,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 58,
    // margin: '15px 0',
    borderRadius: 5,
    '&:hover': {
      border: `1px solid ${colors.accentHover}`,
    },
    border: '0px solid lightgray',
    boxShadow: 'none',
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: colors.background,
      color: colors.primary,
      // zIndex: 1000,

      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
}

export { useStyles, selectStyles }