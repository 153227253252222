/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Text, TextInput } from '../../Components'
import { useAuth } from '../../Global'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { colors } from '../../Themes'
import { PrimaryButton } from '../../Components/Button/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useStyles from './VerifyCode.styles'
import { GradientLogo, loginAnimation } from '../../Themes/Images'
import Loading from 'react-lottie'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const LOADING_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loginAnimation,
  rendererSettings: {
    className: 'loginAnim',
  },
}

function Verify() {
  const classes = useStyles()
  const { isAuthenticated, isCodeIn, codeError, setAUthCodeValues, verify, login: LoginGlobal, isLoginIn } = useAuth()
  const [code, setCode] = useState('')
  const [disabled, setDisabled] = useState(false)
  // const [isMfaCodeIn, setIsMfaCodeIn] = useState(false)
  const { state } = useLocation()

  const history = useHistory()

  const keyPress = (e) => {
    e.preventDefault()
    verifyCode()
    // setCode('')
  }

  // const redirectToPass = () => history.push('/generatePass')
  const toVerify = () => history.push('/verify')

  const login = async () => {
    await LoginGlobal(state?.username.trim(), state?.password.trim())
  }

  const toLogin = () => history.push('/login')

  const verifyCode = async () => {
    await verify(code.trim(), toLogin)
    // setIsMfaCodeIn(true)
  }

  const handleChange = (e) => {
    setCode(e.target.value.toUpperCase())

  }


  useEffect(() => {
    if (
      code
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    code

  ])


  useEffect(() => {
    if (codeError) {
      setAUthCodeValues((prev) => {
        return {
          ...prev,
          codeError: null
        }
      })
    }
  }, [code])

  if (isAuthenticated) {
    return <Redirect
      to={{
        pathname: '/dashboard',
        state: { fromLogin: true }
      }}
    />
  }

  return (
    <>
      <div className={classes.loginContainer}>
        <div className={classes.loginAnimationContainer}>
          {/* <Text size='h2' color={colors.white} weight='bold'>INTELLIGENT INSIGHTS</Text> */}
          <Loading options={LOADING_OPTIONS} height={500} />
        </div>
        <form className={classes.loginForm} onSubmit={keyPress}>
          <div className={classes.loginWrapper}>
            <GradientLogo className={classes.gradientLogo} />

            <h1 style={{ color: colors.accent }}>Two Factor Authentication</h1>
            <Text size='footnote'>We've sent a verification code to your email address. Enter it below.</Text>
            <TextInput
              name='code'
              costumizedInputStyles={classes.input}
              label='Code'
              value={code.toUpperCase()}
              onChange={(value) => handleChange(value)}
            //   onBlur={handleOnBlur}
            />
            <div className={classes.loginButtons}>
              <div style={{ display: 'flex', width: '50%' }}>
                <Link
                  style={{
                    display: 'flex',
                    textDecoration: 'none',
                    color: colors.black,
                    alignItems: 'center',
                    justifyContent: 'center',

                  }}
                  to="/login"
                >
                  <ArrowBackIcon classes={{ root: classes.rootBackIcon }} />
                  <span style={{ fontWeight: 'bold', color: colors.accent }} className="logoutDrawer">Back</span>
                </Link>
              </div>
              <div style={{ display: 'flex', width: '50%' }}>
                <PrimaryButton
                  type="submit"
                  disabled={disabled || isCodeIn}
                  loading={isCodeIn}
                  style={
                    disabled
                      ? { backgroundColor: colors.disabled, color: colors.white, minHeight: 50 }
                      : { backgroundColor: colors.primary, minHeight: 50 }
                  }
                  className={classes.loginButton}
                >
                  Next
                </PrimaryButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default Verify
