import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  Contrainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    padding: '20px 24px',
    backgroundColor: colors.secondary,
    borderRadius: 10,
    [theme.breakpoints.up('sm')]: {
      margin: '30px 16px 0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '15px 15px 0',
    },
  },
  text: {
    fontFamily: 'Roboto',
    color: colors.white,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  icon: {
    color: colors.white,
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  iconButtonRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: ' 15px 0 0',
    },
  },
  linkTo: {
    color: colors.white,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 18
  },
  closeIcon: {
    color: colors.white,
  },
  iconButtonClose: {
    position: 'absolute',
    right: 15,
    padding: 0,
  },
  codeContainer: {
    display: 'flex',
    padding: '0px 7px 0px 19px',
    borderRadius: 10,
    backgroundColor: '#EE717C',
    alignItems: 'center'
  },
  copyIcon: {
    width: 20,
    height: 20
  },
  button: {
    marginLeft: 'auto',
    marginRight: 32,
    backgroundColor: colors.white,
    color: colors.secondary,
    minWidth: 150,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    }
  }
}))

export default useStyles