import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  datePickerWrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%'
  },
  calendarIcon: {
    position: 'absolute',
    left: 18,
    top: 9
  },
  datePickerStartInputContainer: {
    position: 'relative',
    padding: 15,
    backgroundColor: `${colors.white} !important`,
    border: `1px solid ${colors.primary} !important`,
    textAlign: 'center',
    borderRadius: 5,
    // borderTopRightRadius: 0,
    // borderBottomRightRadius: 0,
    color: 'grey',
    width: '89%',
    [theme.breakpoints.down(1600)]: {
      width: '87%',
    },
    [theme.breakpoints.down(1300)]: {
      width: '83%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '80%',
    },
    [theme.breakpoints.down(960)]: {
      width: '91%',
    },
    [theme.breakpoints.down(800)]: {
      width: '91%',
    },
    [theme.breakpoints.down(740)]: {
      width: '90%',
    },
    [theme.breakpoints.down(680)]: {
      width: '88%',
    },
    [theme.breakpoints.down(500)]: {
      width: '85%',
    },
    [theme.breakpoints.down(440)]: {
      width: '81%',
    },
    [theme.breakpoints.down(400)]: {
      width: '78%',
    },
    [theme.breakpoints.down(360)]: {
      width: '78%',
    },
    [theme.breakpoints.down(345)]: {
      width: '76%',
    },
    [theme.breakpoints.down(330)]: {
      width: '68%',
    },
    '&:focus': {
      backgroundColor: `${colors.white} !important`,
      border: `1px solid ${colors.primary} !important`,
      borderRadius: 5,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
  datePickerEndInputContainer: {
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'center',
    borderRadius: 5,
    color: 'grey',
    width: '89%',
    [theme.breakpoints.down(150)]: {
      width: '87%',
    },
    [theme.breakpoints.down(1300)]: {
      width: '83%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '80%',
    },
    [theme.breakpoints.down(960)]: {
      width: '91%',
    },
    [theme.breakpoints.down(800)]: {
      width: '91%',
    },
    [theme.breakpoints.down(740)]: {
      width: '90%',
    },
    [theme.breakpoints.down(680)]: {
      width: '88%',
    },
    [theme.breakpoints.down(500)]: {
      width: '85%',
    },
    [theme.breakpoints.down(440)]: {
      width: '81%',
    },
    [theme.breakpoints.down(400)]: {
      width: '78%',
    },
    [theme.breakpoints.down(360)]: {
      width: '78%',
    },
    [theme.breakpoints.down(345)]: {
      width: '76%',
    },
    [theme.breakpoints.down(330)]: {
      width: '68%',
    },
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 5,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
}))


const useSimpleDatePickerStyles= makeStyles((theme) => ({
  datePickerWrapper: {
    display: 'flex',
    position: 'relative',
    marginTop: 12,
    marginBottom: 25
  },
  calendarIcon: {
    position: 'absolute',
    left: 18,
    top: 9
  },
  datePickerInputContainer: {
    position: 'relative',
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'center',
    borderRadius: 10,
    color: 'grey',
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 10,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
}))

export { useStyles, useSimpleDatePickerStyles }