import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    padding: '32px 10%',
    backgroundColor: colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '32px 15px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px',
    },
    [theme.breakpoints.up(1920)]: {
      padding: '32px 15%',
    },
    [theme.breakpoints.up(2200)]: {
      padding: '32px 20%',
    },
    [theme.breakpoints.up(2500)]: {
      padding: '32px 25%',
    },
  },
  close: {
    display: 'flex', 
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 15,
    [theme.breakpoints.down(760)]: {
      alignSelf: 'flex-start',
      position: 'sticky',
      top: 90,
      zIndex: 1300,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '0 0 26px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  back: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    color: colors.primary,
    marginLeft: 5
  },
  headerText: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.accent,
    [theme.breakpoints.down(760)]: {
      marginTop: 20
    },
  },
  iconButtonClose: {
    position: 'absolute',
    left: 5,
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
      position: 'relative',
      left: 0,
      marginBottom: 15
    },
  },
  cardsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: 'green',
  
  },
  planForYouText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 20,
    alignSelf: 'center',
    margin: '30px 0 ',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
  },
  cardsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flex: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    marginTop: 0,
    [theme.breakpoints.down(1500)]: {
      maxWidth: 750,
      marginInline: 'auto'
    },
  },
  cardsWrapperGigaCondition: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flex: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    marginTop: 30,
    marginInline: 'auto',
    paddingInline: '23%',
    [theme.breakpoints.down(1500)]: {
      maxWidth: 650,
      marginInline: 'auto',
      paddingInline: '0%',
    },
  },
  gigaYearly: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flex: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    marginTop: 30,
    marginInline: 'auto',
    paddingInline: '20%',
    [theme.breakpoints.down(1500)]: {
      maxWidth: 750,
      marginInline: 'auto',
      paddingInline: '0%',
    },
  },
  gigaMonthlyTab2: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flex: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    marginTop: 30,
    marginInline: 'auto',
    paddingInline: '13%',
    [theme.breakpoints.down(1500)]: {
      maxWidth: 650,
      marginInline: 'auto',
      paddingInline: '0%',
    },
    [theme.breakpoints.down(1100)]: {
      marginInline: 'auto',
      paddingInline: '7%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down(760)]: {
      marginInline: 'auto',
      paddingInline: '0%',
      maxWidth: '100%',
    },
  },
  // Footer styles
  footer: {
    width: '100%',
    height: 83,
    display: 'flex',
    backgroundColor: colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    marginBottom: 16

  },
  chatLink: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  footerContainer: {
    display: 'flex',
    // flex: 1,
    flexDirection: 'column',
    padding: '0px 0 0',
    flexShrink: 0,
  },
  footerText: {
    fontSize: 22,
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: colors.primary,
    alignSelf: 'center',
  },
  itemWrapper: {
    display: 'flex',
    margin: '30px 0',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    flexShrink: 0,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0',
    },
  },
  footerItem: {
    display: 'flex',
    flexDirection: 'column',
    height: 140,
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',
    },
  },
  footerSubText: {
    lineHeight: '1.5em',
    width: '20em',      /* height is 2x line-height, so two lines will display */
    overflow: 'hidden'
  },
  button: {
    maxWidth: 180,
    color: colors.primary,
    backgroundColor: 'transparent',
    border: `1px solid ${colors.primary}`,
    '&:hover': {
      color: colors.white,
    },
  },
  
  links: {
    alignSelf: 'flex-end',
    padding: '00px 0 20px'
  },
  link: {
    color: colors.primary,
    textDecoration: 'none',
    marginInline: 10,
    '&:hover': {
      textDecoration: 'underline',
      color: colors.primaryHover,
    }
  },
  // Modal
  costumizedPaperStyles: {
    position: 'relative',
    padding: '24px',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  modalImg: {
    height: 100
  },
  img: {
    height: '100%'
  },
  modalTittle: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 28,
    color: colors.accent,
    margin: '30px 0 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      margin: '15px 0 0',
    },
  },
  desc: {
    fontFamily: 'Roboto',
    fontSize: 18,
    color: colors.primary,
    margin: '32px 0',
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0 20px',
      fontSize: 16,
    },
  },
  goBackButton: {
    width: '70%'
  },
  // Second Step
  verifyWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    flexShrink: 0,
    boxSizing: 'border-box',
    paddingTop: 120,
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      paddingTop: 48,
    },
  },
  animationContainer: {
    flex: 1,
    display: 'flex',
    marginTop: -100,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cardContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardRoot: {
    minWidth: 480,
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      minWidth: '80%',
    },
  },
  cardTitle: {
    padding: '15px 32px ',
    backgroundColor: colors.primary
  },
  MuiCardContentRoot: {
    padding: '48px 32px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 16px',
    },
    '&:last-child': {
      paddingBottom: 48
    }
  },
  continueButton: {
    maxWidth: '45%',
    marginTop: 17,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  MuiCardActionsRoot: {
    position: 'relative',
    padding: '20px 32px 50px',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  cardLine: {
    position: 'absolute',
    top: 0,
    width: '85%',
    borderBottomColor: ' #C9C9C9',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  // Confirm modal
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  firstButton: {
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
    '&$focused': {
      backgroundColor: colors.primaryHover,
    },
    color: colors.white,
    flex: 1,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 30,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
    },
  },
  secondButton: {
    backgroundColor: colors.white,
    color: colors.primary,
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white
    },
    '&$focused': {
      backgroundColor: colors.gray,
      color: colors.white
    },
    flex: 1,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    },
  },
  linkName: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 32
  },
  flexColumn: {
    display: 'flex', 
    flexDirection: 'column',
  },
  table: {
    minWidth: 200,
    marginTop: '10px',
    marginBottom: '10px',
    borderTop: '1px solid #EDEDED',
    borderBottom: '1px solid #EDEDED',
    '& .MuiTableCell-root': {
      borderRight: '1px solid #EDEDED',
      width: '50%'
    },
  },
  tableBorder: {
    borderRight: '1px solid #EDEDED', 
    borderRadius: '5px',
  },
  text2: {
    color: '#293E57 !important', 
    fontSize: '18px !important'
  },
  textTitleTableMobile: {
    textAlign: 'center'
  },
}))

export default useStyles