import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import React from 'react'
import useStyles from './PhoneNumberInput.styles'

const PhoneNumberInput = ({ value, setValue, errorNumber, onBlur, signup, name }) => {
  const classes = useStyles()
  return (
    <div className={signup ? classes.inputSignUp : classes.input}>
      <PhoneInput
        defaultCountry={!value && 'AU'}
        countryCallingCodeEditable={false}
        international
        id={name}
        name={name}
        placeholder="Phone number"
        error={errorNumber ? errorNumber : ''}
        value={value ? value : ''}
        onBlur={onBlur}
        onChange={setValue}
        autoComplete='true' />
    </div>
  )
}

export default PhoneNumberInput