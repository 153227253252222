import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import FilledInput from '@material-ui/core/FilledInput'
import Text from '../../Text'
import { inputStyles, useStyles } from './NumberInput.styles'

const Input = withStyles(inputStyles)((props) => <FilledInput {...props} />)

function NumberInput({
  label,
  value,
  type,
  disabled,
  setValue,
  min,
  max,
  pattern,
  onChange,
  error,
  costumizedInputStyles,
  hiddenLabel,
  inputLabelStyle,
  ...rest
}) {
  const classes = useStyles()

  const handleChange = (event) => {
    let value = event.target.value
    setValue(value)
  }

  return (
    <div className={classes.root}>
      <FormControl  
        className={clsx(classes['input' + error], costumizedInputStyles)}
        variant="filled"
        hiddenLabel={hiddenLabel}  
      >
        { !hiddenLabel && 
          <InputLabel
            style={inputLabelStyle}
            htmlFor="input"
            className={disabled ? 'passwordLabelDisabled' : 'passwordLabel'}
          >
            {<Text size='subnote' color='hsl(0, 0%, 50%)'>{label}</Text>}
          </InputLabel>}
        <Input
          id="textInput"
          type={type}
          value={value}
          autoComplete="on"
          disableUnderline={error}
          onChange={onChange ? onChange : handleChange}
          disabled={disabled}
          inputProps={{ min: 0 }}
          {...rest}
        />
      </FormControl>
    </div>
  )
}

NumberInput.defaultProps = {
  type: 'number',
  disabled: false,
  pattern: /^[0-9]*$/,
  max: 9999999999999999,
  error: false,
}

export default NumberInput
