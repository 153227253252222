export const SELECT_OPTIONS = [
    { 'value': 'Pacific/Midway', 'label': 'Midway Island, Samoa (GMT-11)' },
    { 'value': 'Pacific/Honolulu', 'label': 'Hawaii (GMT-10)' },
    { 'value': 'America/Juneau', 'label': 'Alaska (GMT-9)' },
    { 'value': 'America/Boise', 'label': 'Mountain Time (GMT-7)' },
    { 'value': 'America/Dawson', 'label': 'Dawson, Yukon (GMT-7)' },
    { 'value': 'America/Chihuahua', 'label': 'Chihuahua, La Paz, Mazatlan (GMT-7)' },
    { 'value': 'America/Phoenix', 'label': 'Arizona (GMT-7)' },
    { 'value': 'America/Chicago', 'label': 'Central Time (GMT-6)' },
    { 'value': 'America/Regina', 'label': 'Saskatchewan (GMT-6)' },
    { 'value': 'America/Mexico_City', 'label': 'Guadalajara, Mexico City, Monterrey (GMT-6)' },
    { 'value': 'America/Belize', 'label': 'Central America (GMT-6)' },
    { 'value': 'America/Detroit', 'label': 'Eastern Time (GMT-5)' },
    { 'value': 'America/Bogota', 'label': 'Bogota, Lima, Quito (GMT-5)' },
    { 'value': 'America/Caracas', 'label': 'Caracas, La Paz (GMT-4)' },
    { 'value': 'America/Santiago', 'label': 'Santiago (GMT-3)' },
    { 'value': 'America/St_Johns', 'label': 'Newfoundland and Labrador (GMT-3:30)' },
    { 'value': 'America/Sao_Paulo', 'label': 'Brasilia (GMT-3)' },
    { 'value': 'America/Tijuana', 'label': 'Tijuana (GMT-8)' },
    { 'value': 'America/Montevideo', 'label': 'Montevideo (GMT-3)' },
    { 'value': 'America/Argentina/Buenos_Aires', 'label': 'Buenos Aires, Georgetown (GMT-3)' },
    { 'value': 'America/Godthab', 'label': 'Greenland (GMT-3)' },
    { 'value': 'America/Los_Angeles', 'label': 'Pacific Time (GMT-8)' },
    { 'value': 'Atlantic/Azores', 'label': 'Azores (GMT-1)' },
    { 'value': 'Atlantic/Cape_Verde', 'label': 'Cape Verde Islands (GMT-1)' },
    { 'value': 'GMT', 'label': 'UTC (GMT+0)' },
    { 'value': 'Europe/London', 'label': 'Edinburgh, London (GMT+0)' },
    { 'value': 'Europe/Dublin', 'label': 'Dublin (GMT+0)' },
    { 'value': 'Europe/Lisbon', 'label': 'Lisbon (GMT+0)' },
    { 'value': 'Africa/Casablanca', 'label': 'Casablanca, Monrovia (GMT+0)' },
    { 'value': 'Atlantic/Canary', 'label': 'Canary Islands (GMT+0)' },
    { 'value': 'Europe/Belgrade', 'label': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague (GMT+1)' },
    { 'value': 'Europe/Sarajevo', 'label': 'Sarajevo, Skopje, Warsaw, Zagreb (GMT+1)' },
    { 'value': 'Europe/Brussels', 'label': 'Brussels, Copenhagen, Madrid, Paris (GMT+1)' },
    { 'value': 'Europe/Amsterdam', 'label': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (GMT+1)' },
    { 'value': 'Africa/Algiers', 'label': 'West Central Africa (GMT+1)' },
    { 'value': 'Europe/Bucharest', 'label': 'Bucharest (GMT+2)' },
    { 'value': 'Africa/Cairo', 'label': 'Cairo (GMT+2)' },
    { 'value': 'Europe/Helsinki', 'label': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (GMT+2)' },
    { 'value': 'Europe/Athens', 'label': 'Athens (GMT+2)' },
    { 'value': 'Asia/Jerusalem', 'label': 'Jerusalem (GMT+2)' },
    { 'value': 'Africa/Harare', 'label': 'Harare, Pretoria (GMT+2)' },
    { 'value': 'Europe/Moscow', 'label': 'Istanbul, Minsk, Moscow, St. Petersburg, Volgograd (GMT+3)' },
    { 'value': 'Asia/Kuwait', 'label': 'Kuwait, Riyadh (GMT+3)' },
    { 'value': 'Africa/Nairobi', 'label': 'Nairobi (GMT+3)' },
    { 'value': 'Asia/Baghdad', 'label': 'Baghdad (GMT+3)' },
    { 'value': 'Asia/Tehran', 'label': 'Tehran (GMT+3:30)' },
    { 'value': 'Asia/Dubai', 'label': 'Abu Dhabi, Muscat (GMT+4)' },
    { 'value': 'Asia/Baku', 'label': 'Baku, Tbilisi, Yerevan (GMT+4)' },
    { 'value': 'Asia/Kabul', 'label': 'Kabul (GMT+4:30)' },
    { 'value': 'Asia/Yekaterinburg', 'label': 'Ekaterinburg (GMT+5)' },
    { 'value': 'Asia/Karachi', 'label': 'Islamabad, Karachi, Tashkent (GMT+5)' },
    { 'value': 'Asia/Kolkata', 'label': 'Chennai, Kolkata, Mumbai, New Delhi (GMT+5:30)' },
    { 'value': 'Asia/Kathmandu', 'label': 'Kathmandu (GMT+5:45)' },
    { 'value': 'Asia/Dhaka', 'label': 'Astana, Dhaka (GMT+6)' },
    { 'value': 'Asia/Colombo', 'label': 'Sri Jayawardenepura (GMT+5:30)' },
    { 'value': 'Asia/Almaty', 'label': 'Almaty, Novosibirsk (GMT+6)' },
    { 'value': 'Asia/Rangoon', 'label': 'Yangon Rangoon (GMT+6:30)' },
    { 'value': 'Asia/Bangkok', 'label': 'Bangkok, Hanoi, Jakarta (GMT+7)' },
    { 'value': 'Asia/Krasnoyarsk', 'label': 'Krasnoyarsk (GMT+7)' },
    { 'value': 'Asia/Shanghai', 'label': 'Beijing, Chongqing, Hong Kong SAR, Urumqi (GMT+8)' },
    { 'value': 'Asia/Kuala_Lumpur', 'label': 'Kuala Lumpur, Singapore (GMT+8)' },
    { 'value': 'Asia/Taipei', 'label': 'Taipei (GMT+8)' },
    { 'value': 'Australia/Perth', 'label': 'Perth (GMT+8)' },
    { 'value': 'Asia/Irkutsk', 'label': 'Irkutsk, Ulaanbaatar (GMT+8)' },
    { 'value': 'Asia/Seoul', 'label': 'Seoul (GMT+9)' },
    { 'value': 'Asia/Tokyo', 'label': 'Osaka, Sapporo, Tokyo (GMT+9)' },
    { 'value': 'Asia/Yakutsk', 'label': 'Yakutsk (GMT+9)' },
    { 'value': 'Australia/Darwin', 'label': 'Darwin (GMT+9:30)' },
    { 'value': 'Australia/Adelaide', 'label': 'Adelaide (GMT+9:30)' },
    { 'value': 'Australia/Sydney', 'label': 'Canberra, Melbourne, Sydney (GMT+10)' },
    { 'value': 'Australia/Brisbane', 'label': 'Brisbane (GMT+10)' },
    { 'value': 'Australia/Hobart', 'label': 'Hobart (GMT+10)' },
    { 'value': 'Asia/Vladivostok', 'label': 'Vladivostok (GMT+10)' },
    { 'value': 'Pacific/Guam', 'label': 'Guam, Port Moresby (GMT+10)' },
    { 'value': 'Asia/Magadan', 'label': 'Magadan, Solomon Islands, New Caledonia (GMT+11)' },
    { 'value': 'Asia/Kamchatka', 'label': 'Kamchatka, Marshall Islands (GMT+12)' },
    { 'value': 'Pacific/Fiji', 'label': 'Fiji Islands (GMT+12)' },
    { 'value': 'Pacific/Auckland', 'label': 'Auckland, Wellington (GMT+13)' },
    { 'value': 'Pacific/Tongatapu', 'label': 'Nuku\'alofa (GMT+13)' }
]

export const CURRENCY_OPTIONS = [
    { value: 'USD', label: 'USD - United States Dollar ', id: 1 },
    { value: 'JPY', label: 'JPY - Japanese Yen', id: 2 },
    { value: 'GBP', label: 'GBP - Pound sterling', id: 3 },
    { value: 'KRW', label: 'KRW - South Korean won', id: 4 },
    { value: 'EUR', label: 'EUR - Euro', id: 5 },
    { value: 'DKK', label: 'DKK - Danish Krone', id: 6 },
    { value: 'TWD', label: 'TWD - New Taiwan dollar ', id: 7 },
    { value: 'INR', label: 'INR - Indian rupee', id: 8 }
]

export const MARKETING_OPTIONS = [
    { value: 'Search Engine (Google, Bing etc.)', label: 'Search Engine (Google, Bing etc.)' },
    { value: 'Recommended by a friend or colleague', label: 'Recommended by a friend or colleague' },
    { value: 'Listed as integration on Cliniko/Nookal', label: 'Listed as integration on Cliniko/Nookal' },
    { value: 'Our social media', label: 'Our social media' },
    { value: 'Communities/Groups', label: 'Communities/Groups' },
    { value: 'Articles', label: 'Articles' },
    { value: 'Other', label: 'Other' },
]
