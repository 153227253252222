/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { TextInput, Password, PrimaryButton, Text } from '../../Components'
import Loading from 'react-lottie'
import { loginAnimation, GradientLogo, loadingDataplayer, ChangedSuccessfully } from '../../Themes/Images'
import { useAuth } from '../../Global'
import { Link, Redirect } from 'react-router-dom'
import { colors } from '../../Themes'
import { useWindowDimensions } from '../../Utils'
import useStyles from './Login.styles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const LOADING_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loginAnimation,
  rendererSettings: {
    className: 'loginAnim',
  },
}

const VisitDataplayer = () => {
  const classes = useStyles()
  return (
    <div className={classes.visitDataplayerContainer}>
      <Text weight="medium" color={colors.white} > Want to learn more ?</Text>
      <PrimaryButton
        className={classes.visitDataplayerMobileButton}
        onClick={() => window.open('https://dataplayer.io/')}
      >
        Visit Dataplayer
      </PrimaryButton>
    </div>
  )
}
const Expire = ({ delay, children }) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false)
    }, delay)
    return () => clearTimeout(timer)
  }, [delay])

  return visible ? <div>{children}</div> : <div />
}

function Login() {
  const classes = useStyles()
  const { width } = useWindowDimensions()
  const { isAuthenticated, login: LoginGlobal, isLoginIn, loginError, setLoginValues, codeError } = useAuth()
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [resetOffline, setResetOffline] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const keyPress = (e) => {
    e.preventDefault()
    login()
  }

  // const redirectToPass = () => history.push('/generatePass')

  const history = useHistory()

  const toVerify = () => history.push({
    pathname: '/verify',
    state: { username, password }
  })

  const toChangePass = () => history.push({
    pathname: '/change-password',
  })

  const login = () => {
    LoginGlobal(username.trim(), password.trim(), toVerify, toChangePass)
  }

  useEffect(() => {
    if (
      username &&
      password
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    username,
    password,
  ])


  useEffect(() => {
    if (loginError) {
      setLoginValues((prev) => {
        return {
          ...prev,
          loginError: null
        }
      })
    }
  }, [username, password])

  // if (isAuthenticated) {
  //   return <Redirect 
  //     to={{
  //       pathname: '/dashboard',
  //       state: { fromLogin: true }
  //     }}
  //   />
  // }

  return (
    <>
      <div className={classes.loginContainer}>
        <div className={classes.loginAnimationContainer}>
          <h1 className={classes.loginTitle}>INTELLIGENT INSIGHTS</h1>
          <Loading options={LOADING_OPTIONS} height={500} />
          <PrimaryButton
            className={classes.visitDataplayerButton}
            onClick={() => window.open('https://dataplayer.io/')}
          >
            Visit Dataplayer
          </PrimaryButton>
        </div>
        <form className={classes.loginForm} onSubmit={keyPress}>
          <div className={classes.loginWrapper}>
            <GradientLogo className={classes.gradientLogo} />
            <h1 style={{ color: colors.accent }}>Log in</h1>
            <TextInput
              inputLabelStyle={{ fontSize: 16 }}
              label="Username"
              value={username}
              setValue={setUserName}
            />
            <Text size='small' color='#5C5C5C' weight="light" style={{ marginTop: '-7px', marginBottom: 5, display: 'block' }} > <b>Note: </b>Please enter your username rather than your email address.</Text>
            <Password label="Password" value={password} setValue={setPassword} />
            {Boolean(loginError) && <span className="loginError">{loginError}</span>}
            {Boolean(codeError) && <Expire delay='5000'><span className="loginError">{codeError}</span></Expire>}
            <div className={classes.loginButtons}>
              <div className={classes.infoWrapper} >
                <p className={classes.infoText}>
                  Don't have an account yet?
                </p>
                <Link className={classes.forgotPassword} to="/signup">
                  <span style={{ fontWeight: 'bold' }}>Get started</span>
                </Link>
              </div>
              {resetOffline &&
                <div style={{ display: 'flex', alignItems: 'left', width: '100%', marginTop: 8 }}>
                  <Text size='footnote' color='#EC4252'>You are offline. Please double-check and try again.</Text>
                </div>
              }
              <div className={classes.infoWrapper} >
                <p className={classes.infoText}>
                  Forgot password?
                </p>

                {navigator.onLine ?
                  <Link className={classes.forgotPassword} to="/reset-password">
                    <span style={{ fontWeight: 'bold' }}>Reset it now</span>
                  </Link>
                  :
                  <div onClick={() => setResetOffline(true)}>
                    <Text size='subnote' color='#EC4252'>
                      <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Reset it now</span>
                    </Text>
                  </div>
                }

              </div>
              <p className={classes.byLoginText} >
                By logging in to Dataplayer, you agree to our  <a style={{ color: colors.primary, fontSize: 18 }} target="_blank" rel="noopener noreferrer" href="https://www.dataplayer.io/terms" >Terms & Conditions. </a>
              </p>
              <PrimaryButton
                type="submit"
                // disabled={Object.keys(errors).length !== 0}
                disabled={disabled || isLoginIn}
                loading={isLoginIn}
                style={
                  disabled || isLoginIn
                    ? { backgroundColor: colors.disabled, color: colors.white, minHeight: 50 }
                    : { backgroundColor: colors.primary, minHeight: 50 }
                }
                className={classes.loginButton}
              >
                Log in
              </PrimaryButton>
            </div>
          </div>
        </form>
      </div>
      {width < 960 && <VisitDataplayer />
      }
    </>
  )
}

export default Login
