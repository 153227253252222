/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

const DEFAULT_DRAWER_CONFIG = {
  isExpanded: true,
  isVisible: false,
  isFullScreen: false,
}

const ChatHistory = createContext()

function ChatHistoryProvider({ children }) {
  const current = window.location.pathname
  const [chatHistory, setChatHistory] = useState(() => {
    const chatConfig = JSON.parse(
      localStorage.getItem('chatMenu') || JSON.stringify(DEFAULT_DRAWER_CONFIG)
    )
    return {
      ...chatConfig,
      selectedItem: current,
    }
  })

  const toggleFullScreen = () => {
    setChatHistory((prev) => {
      const next = {
        ...prev,
        isFullScreen: !prev.isFullScreen,
      }
      localStorage.setItem('chatMenu', JSON.stringify(next))
      return next
    })
  }

  const toggleDrawer = () => {
    setChatHistory((prev) => {
      const next = {
        ...prev,
        isVisible: !prev.isVisible,
      }
      localStorage.setItem('chatMenu', JSON.stringify(next))
      return next
    })
  }

  const toggleDrawerExpansion = () => {
    setChatHistory((prev) => {
      const next = {
        ...prev,
        isExpanded: !prev.isExpanded,
      }
      localStorage.setItem('chatMenu', JSON.stringify(next))
      return next
    })
  }
  const toggleDrawerOnHelpCenterExpansion = () => {
    setChatHistory((prev) => {
      const next = {
        ...prev,
        isExpanded: true
      }
      localStorage.setItem('chatMenu', JSON.stringify(next))
      return next
    })
  }
  const setSelectedRoute = (selectedItem) => {
    setChatHistory((prev) => {
      const next = {
        ...prev,
        selectedItem,
      }
      localStorage.setItem('chatMenu', JSON.stringify(next))
      return next
    })
  }

  return (
    <ChatHistory.Provider
      value={{
        ...chatHistory,
        toggleFullScreen,
        toggleDrawer,
        toggleDrawerExpansion,
        toggleDrawerOnHelpCenterExpansion,
        setSelectedRoute,
      }}
    >
      {children}
    </ChatHistory.Provider>
  )
}

function useChatMenu() {
  const context = useContext(ChatHistory)
  if (context === undefined) {
    throw new Error('useChatHistory must be used within an MenuProvider')
  }
  return context
}

export { useChatMenu }
export default ChatHistoryProvider
