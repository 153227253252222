
import {
  SettingsImage,
  settingAccount,
  termsAccount,
  changePasswordgAccount,
  deleteAccount,
  targetsAccount,
  paymentAccount,
  upgradeIcon,
  reports
} from '../Themes/Images'
export const SETTINGS_ITEMS = [
  {
    title: 'My Account',
    Icon: settingAccount,
    description: 'Update account and practice information.',
    to: '/settings/account',
  },
  // {
  //   title: 'Email Reports',
  //   Icon: reports,
  //   description: 'Setup reports emailed from Dataplayer.',
  //   to: '/settings/report',
  // },
  // {
  //   title: 'Targets and KPIs',
  //   Icon: targetsAccount,
  //   description: 'Set clinic targets here.',
  //   to: '/settings/targets',
  // },
  
  {
    title: 'Payment Information',
    Icon: paymentAccount,
    description: 'Check all the details on payments, subscription and active or past add-ons.',
    to: '/settings/payment-information',
  },
  {
    title: 'Upgrade Plan',
    Icon: upgradeIcon,
    description: 'Choose one of our plans to upgrade to.',
    to: '/upgradeplan',
  },
    
  {
    title: 'Change Password',
    Icon: changePasswordgAccount,
    description: 'Reset account password.',
    to: '/settings/change-password',
  },
  {
    title: 'Terms and Conditions',
    Icon: termsAccount,
    description: 'Review the terms and conditions for using Dataplayer.',
    to: '/settings/terms&conditions',
  },
  {
    title: 'Delete Account',
    Icon: deleteAccount,
    description: 'Request to delete your account and all data in it forever.',
    to: '/settings/delete-account',
  },
]
  
export const SETTINGS_ITEMS_FOR_FREE_USER = [
  {
    title: 'My Account',
    Icon: settingAccount,
    description: 'Update account and practice information.',
    to: '/settings/account',
  },
  // {
  //   title: 'Email Reports',
  //   Icon: reports,
  //   description: 'Setup reports emailed from Dataplayer.',
  //   to: '/settings/report',
  // },
  // {
  //   title: 'Targets and KPIs',
  //   Icon: targetsAccount,
  //   description: 'Set clinic targets here.',
  //   to: '/settings/targets',
  // },
  {
    title: 'Payment Information',
    Icon: paymentAccount,
    description: 'Check all the details on payments, subscription and active or past add-ons.',
    to: '/settings/payment-information',
  },
  {
    title: 'Upgrade Plan',
    Icon: upgradeIcon,
    description: 'Choose one of our plans to upgrade to.',
    to: '/upgradeplan',
  },
    
  {
    title: 'Change Password',
    Icon: changePasswordgAccount,
    description: 'Reset account password.',
    to: '/settings/change-password',
  },
  {
    title: 'Terms and Conditions',
    Icon: termsAccount,
    description: 'Review the terms and conditions for using Dataplayer.',
    to: '/settings/terms&conditions',
  },
  {
    title: 'Delete Account',
    Icon: deleteAccount,
    description: 'Request to delete your account and all data in it forever.',
    to: '/settings/delete-account',
  },
]