import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const useStylesSlider = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
    display: 'flex',
    alignItems: 'flex-end',
    marginInline: 12,
    marginTop: 10
  },
  margin: {
    height: theme.spacing(3),
  },
  input: {
    marginLeft: theme.spacing(3),
    marginTop: 15
  },
}))
  
const prettoSliderStyles = {
  root: {
    color: colors.accent,
    height: 5,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
}

export { useStylesSlider, prettoSliderStyles }