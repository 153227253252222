/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable promise/always-return */
import React, { useState, useEffect } from 'react'
import { PrimaryButton, Password, ForgotPasswordPin, NewPassword, SuccessNotify, Text } from '../../Components'
import { useTheme } from '@material-ui/core/styles'
import { SupportImage } from '../../Themes/Images'
import { useAuth } from '../../Global'
import { colors } from '../../Themes'
import { Auth } from 'aws-amplify'
import { CSSTransition } from 'react-transition-group'
import { useMediaQuery } from '@material-ui/core'
import useStyles from './ChangePassword.styles'

const SCREEN_CONTENT = {
  changePasswordStep: 'changePasswordStep',
  forgotPasswordPinStep: 'forgotPasswordPinStep',
  newPasswordStep: 'newPasswordStep',
}

function ChangePassword({ onForgotPassword, error: forgotError }) {
  const classes = useStyles()
  const { logout } = useAuth()
  const [newPassword, setNewPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('1050'))

  const changePassword = () => {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    if (confirmPassword !== newPassword) {
      setError('Passwords do not match!')
    } else if (!regex.test(newPassword)) {
      setError(
        'Password should contain at least 8 characters, 1 upper case, 1 lower case  and 1 special character!'
      )
    } else {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword, newPassword)
        })
        .then((data) => {
          logout()
        })
        .catch((err) => {
          setError('Old password is not correct!')
        })
    }
  }

  useEffect(() => {
    setError(false)
    if (newPassword !== '' && confirmPassword !== '' && oldPassword !== '') {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [newPassword, oldPassword, confirmPassword])

  return (
    <div className="passwordSection">
      <Text style={{ marginBottom: 5 }} size='subnote' className="profileUsername">Change your password:</Text>
      <Password
        costumizedInputStyles={classes.passwordInput}
        costumizedInputRootStyles={classes.passwordRootInput}
        label='Old password'
        value={oldPassword}
        setValue={setOldPassword}
      />
      <Password
        costumizedInputStyles={classes.passwordInput}
        costumizedInputRootStyles={classes.passwordRootInput}
        label='New password'
        value={newPassword}
        setValue={setNewPassword}
      />
      <Password
        costumizedInputRootStyles={classes.passwordRootInput}
        label='Confirm password'
        value={confirmPassword}
        setValue={setConfirmPassword}
      />
      {Boolean(error) && (
        <span className="ConfirmPasswordError" style={{ maxWidth: isMobile ? '100%' : '430px', marginBottom: 10 }}>{error}</span>
      )}
      <Text size='footnote' style={{ lineHeight: 1.4, maxWidth: isMobile ? '100%' : '430px' }} className="resetPasswordInfo">After you confirm changing your password, your account will be logged out of any device you were primarily logged in.</Text>
      <PrimaryButton
        disabled={disabled}
        className={classes.changePasswordButton}
        style={{
          backgroundColor: disabled ? colors.disabled : colors.primary,
          color: colors.white,
          marginTop: 15,
          
        }}
        onClick={changePassword}
      >
        Change Password
      </PrimaryButton>
      <Text color={colors.accent} weight='bold' size='subnote' className="accentTitle" style={{ cursor: 'pointer' }} onClick={onForgotPassword}>Did you forget your password?</Text>
      {Boolean(forgotError) && (
        <span className="ConfirmPasswordError" style={{ maxWidth: '80%', marginBottom: 10 }}>{forgotError}</span>
      )}
    </div>
  )
}

const CHANGE_PASSWORD_STEPS = {
  changePasswordStep: ChangePassword,
  forgotPasswordPinStep: ForgotPasswordPin,
  newPasswordStep: NewPassword
}

const ChangeAndForgotPassword = () => {
  const classes = useStyles()
  const { userAttributes, user, logout } = useAuth()
  const [changeAndForgotStatus, setChangeAndForgotStatus] = useState(SCREEN_CONTENT.changePasswordStep)
  const ChangeAndForgotSteps = CHANGE_PASSWORD_STEPS[changeAndForgotStatus]
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [pin, setPin] = useState('')
  const [error, setError] = useState()
  const [isResendCode, setResendCode] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setError(false)
    if (newPassword !== '' && confirmPassword !== '') {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [newPassword, confirmPassword, pin])

  const sendEmail = (sendCase) => {
    Auth.forgotPassword(user)
      .then((res) => {
        if (sendCase==='resendCode'){
          setResendCode(true)
        }
        setChangeAndForgotStatus(SCREEN_CONTENT.forgotPasswordPinStep)
      })
      .catch((err) => {
        if (err?.name==='LimitExceededException'){
          setError(err.message)
          if (sendCase==='resendCode'){
            setResendCode(true)
          }
        }
      })
    setPin('')
  }

  const submitChanges = async () => {
    setLoading(true)
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/

    if (newPassword === '' || confirmPassword === '') {
      setError('Hang on, both fields need to be filled out!')
      setLoading(false)

    } else if (newPassword !== confirmPassword) {
      setError('The passwords do not match.')
      setLoading(false)

    }  else if (!regex.test(newPassword)) {
      setError(
        'Password should contain at least 8 characters, 1 upper case, 1 lower case and 1 special character!'
      )
      setLoading(false)

    } else {

      try {
        await  Auth.forgotPasswordSubmit(user, pin, newPassword)
        logout()
        setSuccess(true)
      } catch (error) {
        if (error?.name==='LimitExceededException'){
          setError(error.message)
          setChangeAndForgotStatus(SCREEN_CONTENT.forgotPasswordPinStep)
        } else {
          setChangeAndForgotStatus(SCREEN_CONTENT.forgotPasswordPinStep)
          setError('The PIN you inserted doesn’t match the one we sent. Please check the value and try again.')
        }
        setLoading(false)
      }
    }
  }

  const moveToChangePassword = () => {
    setError(false)
    setResendCode(false)
    setPin('')
    setChangeAndForgotStatus(SCREEN_CONTENT.changePasswordStep)
  }

  const moveToNewPasswordStep = () => {
    setError(false)
    setResendCode(false)
    setChangeAndForgotStatus(SCREEN_CONTENT.newPasswordStep)
  }
  const moveToPinStep = () => {
    setError(false)
    setResendCode(false)
    setChangeAndForgotStatus(SCREEN_CONTENT.forgotPasswordPinStep)
  }

  return (
    <>
      <div className={classes.passSubcontainer}>
        <CSSTransition
          classNames='changePasswordAnim'
          timeout={300}
          in={Boolean(changeAndForgotStatus === 'forgotPasswordPinStep')}
        >
          <ChangeAndForgotSteps
            changeAndForgotStatus={changeAndForgotStatus}
            onForgotPassword={sendEmail}
            goFirstStep={moveToChangePassword}
            email={userAttributes?.email}
            pin={pin}
            setPin={setPin}
            goNewPasswordStep={moveToNewPasswordStep}
            disabled={disabled}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            goToPinStep={moveToPinStep}
            onSubmit={submitChanges}
            isResendCode={isResendCode}
            error={error}
            loading={loading}
          />
        </CSSTransition>

        <div className={classes.imageContainer}>
          <SupportImage className={classes.image} />
        </div>
      </div>
      <SuccessNotify type="green" open={success} setOpen={setSuccess} message="Password updated successfully" />
    </>
  )


}

export default ChangeAndForgotPassword
