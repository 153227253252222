import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const cellStyles = {
  head: {
    backgroundColor: colors.white,
    color: colors.primary,
    fontSize: 18
  },
  body: {
    fontSize: 16,
    color: colors.primary,
    padding: '24px 16px'
  },
}

const tableRowStyles = {
  root: {
    backgroundColor: colors.white,
  },
}

const useCStylesTable = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

const useStylesTable = makeStyles({
  table: {
    minWidth: 700,
  },
  container: {
    marginTop: 25,
    boxShadow: 'none'
  },
  showMore: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    color: colors.accent,
    cursor: 'pointer'
  },
  paymentId: {
    color: colors.accent,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  statusPaid: {
    padding: '4px 15px',
    backgroundColor: 'transparent',
    color: '#12C730 !important ',
    border: '1px solid #12C730',
    borderRadius: 20
  },
  statusFailed: {
    padding: '4px 15px',
    backgroundColor: 'transparent',
    color: '#818181 !important ',
    border: '1px solid #818181',
    borderRadius: 20
  },
  copyBase: {
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.white,
    borderRadius: 10,
    flexShrink: 0,
    '&:hover': {
      backgroundColor: colors.grayHover,
    },
  },
  afterCopied: {
    position: 'absolute',
    width: 250,
    top: -20,
  },
  caption: {
    textAlign: 'center' 
  },
  tableFont: {
    fontSize: 18
  },
  margin: {
    marginTop: 32
  }
})

export { cellStyles, tableRowStyles, useCStylesTable, useStylesTable }