/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable promise/always-return */
import React, { useEffect, useState } from 'react'
import { Grid, Icon, InputAdornment, useMediaQuery, useTheme } from '@material-ui/core'
import {
  targetsImg,
  info
} from '../../Themes/Images'
import { PrimaryButton, NoPermission,  SecondLoader, Text, TextInput, TabPanel } from '../../Components'
import { colors } from '../../Themes'
import Axios from '../../Config/Axios'
import { useAuth, useSubscription } from '../../Global'
import LoadingModal from '../../Components/LoadingModal'
import { toast } from 'react-hot-toast'
import { ErrorToast, SuccessToast } from '../../Components/Notification/Notification'
import useStyles from './Targets.styles'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import TabsForTargets from '../../Components/TabsForTargets'
import TargetDashboard from '../TargetDashboard'

function validate(values) {
  let errors = {}
  let formIsValid = true

  // if (!values.revenue) {
  //   formIsValid = false
  //   errors.revenue = 'Year financial target  is required'
  // } 
  if (values.revenue < 0) {
    formIsValid = false

    errors.revenue = 'Your input can\'t have a negative value'
  }
  // Appointments
  // if (!values.appointments) {
  //   formIsValid = false

  //   errors.appointments = 'Year appointments  is required'
  // } 
  if (values.appointments < 0) {
    formIsValid = false

    errors.appointments = 'Your input can\'t have a negative value'
  } else if (!/^\d+$/.test(values.appointments)) {
    formIsValid = false

    errors.appointments =
      'The correct format can\'t include decimal numbers, you might want to check that again'
  }
  // percentage
  // if (!values.dna) {
  //   formIsValid = false

  //   errors.dna = 'Percentage DNA  is required'
  // } 
  if (values.dna < 0) {
    formIsValid = false

    errors.dna = 'Your input can\'t have a negative value'
  }
  else if (!/^\d+$/.test(values.dna)) {
    formIsValid = false

    errors.dna =
      'The correct format can\'t include decimal numbers, you might want to check that again'
  }
  // cancellations
  // if (!values.cancellations) {
  //   formIsValid = false

  //   errors.cancellations = 'Percentage cancelled  is required'
  // } 
  if (values.cancellations < 0) {
    formIsValid = false

    errors.cancellations = 'Your input can\'t have a negative value'
  }
  else if (!/^\d+$/.test(values.cancellations)) {
    formIsValid = false

    errors.cancellations =
      'The correct format can\'t include decimal numbers, you might want to check that again'
  }

  // patients
  // if (!values.patients) {
  //   formIsValid = false

  //   errors.patients = 'Patients is required'
  // } 
  if (values.patients < 0) {
    formIsValid = false

    errors.patients = 'Your input can\'t have a negative value'

  }
  else if (!/^\d+$/.test(values.patients)) {
    formIsValid = false

    errors.patients =
      'The correct format can\'t include decimal numbers, you might want to check that again'
  }

  // newPatients
  // if (!values.new_patients) {
  //   formIsValid = false

  //   errors.new_patients = 'New patients is required'
  // } 
  if (values.new_patients < 0) {
    formIsValid = false

    errors.new_patients = 'Your input can\'t have a negative value'

  }
  else if (!/^\d+$/.test(values.new_patients)) {
    formIsValid = false

    errors.new_patients =
      'The correct format can\'t include decimal numbers, you might want to check that again'
  }
  return { errors, formIsValid }
}

const useForm = (callback, validate, values, setValues) => {

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = (event) => {
    if (event) event.preventDefault()

    if (validate(values)?.formIsValid) {
      callback()
    } else {
      setErrors(validate(values)?.errors)
    }
  }

  const handleChange = (event) => {
    event.persist()
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }
    ))
    setErrors(
      validate({ ...values, [event.target.name]: event.target.value })?.errors
    )
  }

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
    setIsSubmitting,
  }
}

const useForm2 = (callback, validate, values2, setValues2) => {

  const [errors2, setErrors2] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit2 = (event) => {
    if (event) event.preventDefault()
    if (validate(values2)?.formIsValid) {
      callback()
    } else {
      setErrors2(validate(values2)?.errors)
    }
  }

  const handleChange2 = (event) => {
    event.persist()
    setValues2((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }
    ))
    setErrors2(
      validate({ ...values2, [event.target.name]: event.target.value })?.errors
    )
  }

  return {
    handleChange2,
    handleSubmit2,
    values2,
    errors2,
    isSubmitting,
    setIsSubmitting,
  }
}

const useForm3 = (callback, validate, values3, setValues3) => {

  const [errors3, setErrors3] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit3 = (event) => {
    if (event) event.preventDefault()
    if (validate(values3)?.formIsValid) {
      callback()
    } else {
      setErrors3(validate(values3)?.errors)
    }
  }

  const handleChange3 = (event) => {
    event.persist()
    setValues3((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }
    ))
    setErrors3(
      validate({ ...values3, [event.target.name]: event.target.value })?.errors
    )
  }

  return {
    handleChange3,
    handleSubmit3,
    values3,
    errors3,
    isSubmitting,
    setIsSubmitting,
  }
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

const periodItems = [
  {
    title: 'monthly',
    id: 1
  },
  {
    title: 'quarterly',
    id: 2
  },
  {
    title: 'annually',
    id: 3
  }
]


function Targets() {
  const classes = useStyles()
  const { data_loading, is_trialing } = useAuth()
  const { subscription, plan }=useSubscription()
  const [monthlyTargets, setMonthlyTargets] = useState({})
  const [quarterlyTargets, setQuarterlyTargets] = useState(undefined || {})
  const [yearlyTargets, setYearlyTargets] = useState(undefined || {})
  const [currentTargets, setCurrentTargets] = useState({})
  const [disabled, setDisabled] = useState(false)
  const [disabled2, setDisabled2] = useState(false)
  const [disabled3, setDisabled3] = useState(false)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [isLoadingUpdate2, setIsLoadingUpdate2] = useState(false)
  const [isLoadingUpdate3, setIsLoadingUpdate3] = useState(false)
  const theme = useTheme()
  const isMobile2 = useMediaQuery(theme.breakpoints.down('sm'))

  const [tabValue, setTabValue] = useState(0)

  const [period, setPeriod] = useState('monthly')

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  const PeriodPicker = ({ title, id }) => {
    const isActive = period?.includes(title)

    return (
      <div key={id} className={isActive ? classes.isActive : classes.isNotActive} onClick={() => setPeriod(title)}>
        <Text color={isActive ? '#fff' : colors.primary} weight='normal' size='footnote'>{title}</Text>
      </div>
    )
  }

  useEffect(() => {
    if (!data_loading) getCurrentTarget()
  }, [data_loading])

  const getCurrentTarget = async () => {
    setIsLoading(true)
    try {
      const response = await Axios.get(
        '/prod/user/targets'
      )
      setMonthlyTargets(response?.data?.data?.monthly)
      setQuarterlyTargets(response?.data?.data?.quarterly)
      setYearlyTargets(response?.data?.data?.yearly)
      setIsLoading(false)
      setCurrentTargets(response?.data?.data)
    } catch (e) {
      setIsLoading(false)
      setError(true)
    }
  }

  const onUpdateTarget = () => {
    const options = {
      headers: { 'Content-Type': 'application/json' },
    }
    let body = {}
    if (plan?.[0]?.name === 'Giga'  || is_trialing) {
      body = {
        dnaTarget: parseFloat(monthlyTargets?.dna),
        cancellationTarget: parseFloat(monthlyTargets?.cancellations),
        revenueTarget: parseFloat(monthlyTargets?.revenue),
        appointmentsTarget: parseFloat(monthlyTargets?.appointments),
        patientsTarget: parseFloat(monthlyTargets?.patients),
        newPatientsTarget: parseFloat(monthlyTargets?.new_patients),
        timeframe: period
      }
    }
    else {
      body = {
        dnaTarget: parseFloat(monthlyTargets?.dna),
        cancellationTarget: parseFloat(monthlyTargets?.cancellations),
        revenueTarget: parseFloat(monthlyTargets?.revenue),
        appointmentsTarget: parseFloat(monthlyTargets?.appointments),
        timeframe: period
      }
    }
    setIsLoadingUpdate(true)
    Axios.post(
      '/prod/user/targets',
      body,
      options
    )
      .then((res) => {
        getCurrentTarget()
        setDisabled(true)
        setIsLoadingUpdate(false)
        toast.custom((t) => (
          <SuccessToast t={t} message='Your targets have been saved successfully!'/>
        ))
        setCurrentTargets((prev) => ({
          monthly: {
            dna: parseFloat(monthlyTargets?.dna),
            cancellations: parseFloat(monthlyTargets?.cancellations),
            revenue: parseFloat(monthlyTargets?.revenue),
            appointments: parseFloat(monthlyTargets?.appointments),
            patients: parseFloat(monthlyTargets?.patients),
            new_patients: parseFloat(monthlyTargets?.new_patients)
          },
          quarterly: {
            dna: parseFloat(quarterlyTargets?.dna),
            cancellations: parseFloat(quarterlyTargets?.cancellations),
            revenue: parseFloat(quarterlyTargets?.revenue),
            appointments: parseFloat(quarterlyTargets?.appointments),
            patients: parseFloat(quarterlyTargets?.patients),
            new_patients: parseFloat(quarterlyTargets?.new_patients)
          },
          yearly: {
            dna: parseFloat(yearlyTargets?.dna),
            cancellations: parseFloat(yearlyTargets?.cancellations),
            revenue: parseFloat(yearlyTargets?.revenue),
            appointments: parseFloat(yearlyTargets?.appointments),
            patients: parseFloat(yearlyTargets?.patients),
            new_patients: parseFloat(yearlyTargets?.new_patients)
          }
        }))

      })
      .catch((e) => {
        setError(true)
        toast.custom((t) => (
          <ErrorToast t={t} message='Something went wrong. Try again!'/>
        ))
      })
  }

  const onUpdateTarget3 = () => {
    const options = {
      headers: { 'Content-Type': 'application/json' },
    }
    let body = {}
    if (plan?.[0]?.name === 'Giga' || is_trialing) {
      body = {
        dnaTarget: parseFloat(yearlyTargets?.dna),
        cancellationTarget: parseFloat(yearlyTargets?.cancellations),
        revenueTarget: parseFloat(yearlyTargets?.revenue),
        appointmentsTarget: parseFloat(yearlyTargets?.appointments),
        patientsTarget: parseFloat(yearlyTargets?.patients),
        newPatientsTarget: parseFloat(yearlyTargets?.new_patients),
        timeframe: period
      }
    }
    else {
      body = {
        dnaTarget: parseFloat(yearlyTargets?.dna),
        cancellationTarget: parseFloat(yearlyTargets?.cancellations),
        revenueTarget: parseFloat(yearlyTargets?.revenue),
        appointmentsTarget: parseFloat(yearlyTargets?.appointments),
        timeframe: period
      }
    }
    setIsLoadingUpdate3(true)
    Axios.post(
      '/prod/user/targets',
      body,
      options
    )
      .then((res) => {
        getCurrentTarget()
        setDisabled3(true)
        setIsLoadingUpdate3(false)
        toast.custom((t) => (
          <SuccessToast t={t} message='Your targets have been saved successfully!'/>
        ))
        setCurrentTargets((prev) => ({
          monthly: {
            dna: parseFloat(monthlyTargets?.dna),
            cancellations: parseFloat(monthlyTargets?.cancellations),
            revenue: parseFloat(monthlyTargets?.revenue),
            appointments: parseFloat(monthlyTargets?.appointments),
            patients: parseFloat(monthlyTargets?.patients),
            new_patients: parseFloat(monthlyTargets?.new_patients)
          },
          quarterly: {
            dna: parseFloat(quarterlyTargets?.dna),
            cancellations: parseFloat(quarterlyTargets?.cancellations),
            revenue: parseFloat(quarterlyTargets?.revenue),
            appointments: parseFloat(quarterlyTargets?.appointments),
            patients: parseFloat(quarterlyTargets?.patients),
            new_patients: parseFloat(quarterlyTargets?.new_patients)
          },
          yearly: {
            dna: parseFloat(yearlyTargets?.dna),
            cancellations: parseFloat(yearlyTargets?.cancellations),
            revenue: parseFloat(yearlyTargets?.revenue),
            appointments: parseFloat(yearlyTargets?.appointments),
            patients: parseFloat(yearlyTargets?.patients),
            new_patients: parseFloat(yearlyTargets?.new_patients)
          }
        }))

      })
      .catch((e) => {
        setError(true)
        toast.custom((t) => (
          <ErrorToast t={t} message='Something went wrong. Try again!'/>
        ))
      })
  }

  const onUpdateTarget2 = () => {
    const options = {
      headers: { 'Content-Type': 'application/json' },
    }
    let body = {}
    if (plan?.[0]?.name === 'Giga' || is_trialing) {
      body = {
        dnaTarget: parseFloat(quarterlyTargets?.dna),
        cancellationTarget: parseFloat(quarterlyTargets?.cancellations),
        revenueTarget: parseFloat(quarterlyTargets?.revenue),
        appointmentsTarget: parseFloat(quarterlyTargets?.appointments),
        patientsTarget: parseFloat(quarterlyTargets?.patients),
        newPatientsTarget: parseFloat(quarterlyTargets?.new_patients),
        timeframe: period
      }
    }
    else {
      body = {
        dnaTarget: parseFloat(quarterlyTargets?.dna),
        cancellationTarget: parseFloat(quarterlyTargets?.cancellations),
        revenueTarget: parseFloat(quarterlyTargets?.revenue),
        appointmentsTarget: parseFloat(quarterlyTargets?.appointments),
        timeframe: period
      }
    }
    setIsLoadingUpdate2(true)
    Axios.post(
      '/prod/user/targets',
      body,
      options
    )
      .then((res) => {
        getCurrentTarget()
        setDisabled2(true)
        setIsLoadingUpdate2(false)
        toast.custom((t) => (
          <SuccessToast t={t} message='Your targets have been saved successfully!'/>
        ))
        setCurrentTargets((prev) => ({
          monthly: {
            dna: parseFloat(monthlyTargets?.dna),
            cancellations: parseFloat(monthlyTargets?.cancellations),
            revenue: parseFloat(monthlyTargets?.revenue),
            appointments: parseFloat(monthlyTargets?.appointments),
            patients: parseFloat(monthlyTargets?.patients),
            new_patients: parseFloat(monthlyTargets?.new_patients)
          },
          quarterly: {
            dna: parseFloat(quarterlyTargets?.dna),
            cancellations: parseFloat(quarterlyTargets?.cancellations),
            revenue: parseFloat(quarterlyTargets?.revenue),
            appointments: parseFloat(quarterlyTargets?.appointments),
            patients: parseFloat(quarterlyTargets?.patients),
            new_patients: parseFloat(quarterlyTargets?.new_patients)
          },
          yearly: {
            dna: parseFloat(yearlyTargets?.dna),
            cancellations: parseFloat(yearlyTargets?.cancellations),
            revenue: parseFloat(yearlyTargets?.revenue),
            appointments: parseFloat(yearlyTargets?.appointments),
            patients: parseFloat(yearlyTargets?.patients),
            new_patients: parseFloat(yearlyTargets?.new_patients)
          }
        }))
      })
      .catch((e) => {
        setError(true)
        toast.custom((t) => (
          <ErrorToast t={t} message='Something went wrong. Try again!'/>
        ))
      })
  }

  const { errors, handleChange, handleSubmit } = useForm(
    onUpdateTarget,
    validate,
    monthlyTargets,
    setMonthlyTargets,
  )

  const { errors2, handleChange2, handleSubmit2 } = useForm2(
    onUpdateTarget2,
    validate,
    quarterlyTargets,
    setQuarterlyTargets
  )
  
  const { errors3, handleChange3, handleSubmit3 } = useForm3(
    onUpdateTarget3,
    validate,
    yearlyTargets,
    setYearlyTargets
  )

  useEffect(() => {
    if (currentTargets?.monthly) {

      if ((errors && Object.keys(errors).length) === 0 &&
        (monthlyTargets?.revenue !== currentTargets?.monthly?.revenue ||
            monthlyTargets?.appointments !== currentTargets?.monthly?.appointments ||
             monthlyTargets?.dna !== currentTargets?.monthly?.dna ||
              monthlyTargets?.cancellations !== currentTargets?.monthly?.cancellations ||
               monthlyTargets?.patients !== currentTargets?.monthly?.patients ||
                monthlyTargets?.new_patients !== currentTargets?.monthly?.new_patients )
      ) {
        setDisabled(false)
      }
      else {
        setDisabled(true)
      }
    }
  }, [currentTargets?.monthly, monthlyTargets, errors, period])

  useEffect(() => {
    if (currentTargets?.quarterly) {
      if ((errors2 && Object.keys(errors2).length) === 0 &&
        (quarterlyTargets?.revenue !== currentTargets?.quarterly?.revenue ||
         quarterlyTargets?.appointments !== currentTargets?.quarterly?.appointments ||
          quarterlyTargets?.dna !== currentTargets?.quarterly?.dna ||
           quarterlyTargets?.cancellations !== currentTargets?.quarterly?.cancellations ||
            quarterlyTargets?.patients !== currentTargets?.quarterly?.patients ||
             quarterlyTargets?.new_patients !== currentTargets?.quarterly?.new_patients )
      ) {
        setDisabled2(false)
      }
      else {
        setDisabled2(true)
      }
    }
  }, [currentTargets?.quarterly, quarterlyTargets, errors2, period])

  useEffect(() => {
    if (currentTargets?.yearly) {
      if ( (errors3 && Object.keys(errors3).length === 0) &&
      (yearlyTargets?.revenue !== currentTargets?.yearly?.revenue ||
         yearlyTargets?.appointments !== currentTargets?.yearly?.appointments ||
          yearlyTargets?.dna !== currentTargets?.yearly?.dna ||
           yearlyTargets?.cancellations !== currentTargets?.yearly?.cancellations ||
            yearlyTargets?.patients !== currentTargets?.yearly?.patients ||
             yearlyTargets?.new_patients !== currentTargets?.yearly?.new_patients )
      ) {
        setDisabled3(false)
      }
      else {
        setDisabled3(true)
      }
    }
    
  }, [currentTargets?.yearly, yearlyTargets, errors3, period])

  if (isLoading) {
    return (
      <SecondLoader/>
    )
  }

  if (isEmpty(subscription) && !is_trialing) {
    return (
      <NoPermission/>
    )
  }

  return (
    <div className={classes.containerTarget}>
      <TabsForTargets
        value={tabValue}
        handleChange={handleChangeTab}
      />
      <TabPanel value={tabValue} index={0}>
        <TargetDashboard setTavValue={setTabValue} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <>
          <div className={classes.targetsContainer}>
            <div className={classes.titleDiv}>
              <Text size='subnote' weight='regular' className={classes.mainTitle}>
              You can monitor your practice performance by setting targets for your preferred time period and metrics.
              </Text>
            </div>
            <div className={classes.tragetsWrapper}>
              <div className={classes.inputContent}>
                <div
                  className={classes.divider}
                />
                <Text weight='bold' size='subnote'>Select time period:</Text>
                <div className={classes.periodPicker}>
                  {periodItems.map((item) =>
                    <PeriodPicker key={item.id} id={item.id} title={item.title} />
                  )}
                </div>
                <div className={classes.addValues}>
                  <Text weight='bold' size='subnote'>Add target values</Text>
                </div>
               
                {period === 'monthly' && 
                 <form onSubmit={handleSubmit}>
                   <div className={classes.container}>
                     <div className={classes.aboveGrid}>
                       <Grid
                         item
                         lg={12}
                         md={12}
                         xl={12}
                         xs={12}
                         className={classes.grid}
                       >
                         <Grid
                           container
                           spacing={isMobile2 ? 0 : 3}
                         >
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}>
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>Revenue</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Year financial target"
                               name="revenue"
                               value={monthlyTargets?.revenue || ''}
                               //  setValue={(value) => handleChange(value)}
                               onChange={handleChange}
                               hiddenLabel
                               error={Boolean(errors?.revenue)}
                               placeholder={currentTargets?.monthly?.revenue}
                               endAdornment={
                                 <InputAdornment
                                   className={classes.alignedPlaceHolder}
                                   position="end"
                                 >
                                 </InputAdornment>
                               }
                             // disabled
                             />
                             {errors?.revenue && (
                               <p className={classes.errorInput}>
                                 {errors?.revenue}
                               </p>
                             )}
                           </Grid>
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}
                           >
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>Number of Appointments</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Year appointments target"
                               name="appointments"
                               hiddenLabel
                               value={monthlyTargets?.appointments || ''}
                               onChange={handleChange}
                               error={Boolean(errors?.appointments)}
                               placeholder={currentTargets?.monthly?.appointments}
                             // disabled
                             />
                             {errors?.appointments && (
                               <p className={classes.errorInput}>
                                 {errors?.appointments}
                               </p>
                             )}
                           </Grid>
                         </Grid>
                       </Grid>
                       <Grid
                         item
                         lg={12}
                         md={12}
                         xl={12}
                         xs={12}
                         className={classes.grid}
                       >
                         <Grid
                           container
                           spacing={isMobile2 ? 0 : 3}
                         >
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}>
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>Cancellations</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Percentage Cancelled"
                               name="cancellations"
                               hiddenLabel
                               value={monthlyTargets?.cancellations || ''}
                               error={Boolean(errors?.cancellations)}
                               onChange={handleChange}
                               placeholder={currentTargets?.monthly?.cancellations}
                             // disabled
                             />
                             {errors?.cancellations && (
                               <p className={classes.errorInput}>
                                 {errors?.cancellations}
                               </p>
                             )}
                           </Grid>
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}
                           >
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>DNAs</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Percentage DNA"
                               name="dna"
                               hiddenLabel
                               value={monthlyTargets?.dna || ''}
                               error={Boolean(errors?.dna)}
                               onChange={handleChange}
                               placeholder={currentTargets?.monthly?.dna}
                             // disabled
                             />
                             {errors?.dna && (
                               <p className={classes.errorInput}>{errors?.dna}</p>
                             )}
                           </Grid>
                         </Grid>
                       </Grid>

                       {(plan?.[0]?.name === 'Giga' || is_trialing) && 
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                          className={classes.grid}
                        >
                          <Grid
                            container
                            spacing={isMobile2 ? 0 : 3}
                          >
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              xs={12}>
                              <div className={classes.marginTop}>
                                <Text size='subnote' color={colors.primary} weight='normal'>Total patients</Text>
                              </div>
                              <TextInput
                                costumizedInputStyles={classes.input}
                                // label="Percentage Cancelled"
                                name="patients"
                                hiddenLabel
                                value={monthlyTargets?.patients || ''}
                                error={Boolean(errors?.patients)}
                                onChange={handleChange}
                                placeholder={currentTargets?.monthly?.patients}
                              />
                              {errors?.patients && (
                                <p className={classes.errorInput}>
                                  {errors?.patients}
                                </p>
                              )}
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              xs={12}
                            >
                              <div className={classes.marginTop}>
                                <Text size='subnote' color={colors.primary} weight='normal'>New patients</Text>
                              </div>
                              <TextInput
                                costumizedInputStyles={classes.input}
                                // label="Percentage DNA"
                                name="new_patients"
                                hiddenLabel
                                value={monthlyTargets?.new_patients || ''}
                                error={Boolean(errors?.new_patients)}
                                onChange={handleChange}
                                placeholder={currentTargets?.monthly?.new_patients}
                              />
                              {errors?.new_patients && (
                                <p className={classes.errorInput}>{errors?.new_patients}</p>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                       }

                       <Grid
                         item
                         lg={12}
                         md={12}
                         xl={12}
                         xs={12}
                         className={classes.grid}
                       >
                         <Grid
                           container
                           spacing={isMobile2 ? 0 : 3}
                         >
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}>
                             <PrimaryButton
                               type="submit"
                               classes={{
                                 root: classes.saveButton,
                               }}
                               disabled={disabled || isLoadingUpdate}
                               loading={isLoadingUpdate}
                               style={{
                                 backgroundColor: (disabled || isLoadingUpdate) ? colors.disabled : colors.primary,
                               }}
                             >
             Update targets
                             </PrimaryButton>
                           </Grid>
                         </Grid>
                       </Grid>
                     </div>
                     <div className={classes.targetsPicture}>
                       <img className={classes.targetImg} src={targetsImg} alt='targets' />
                     </div>
                   </div>
                 </form>
                }
                {period === 'quarterly' &&
                 <form onSubmit={handleSubmit2}>
                   <div className={classes.container}>
                     <div className={classes.aboveGrid}>
                       <Grid
                         item
                         lg={12}
                         md={12}
                         xl={12}
                         xs={12}
                         className={classes.grid}
                       >
                         <Grid
                           container
                           spacing={isMobile2 ? 0 : 3}
                         >
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}>
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>Revenue</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Year financial target"
                               name="revenue"
                               value={quarterlyTargets?.revenue || ''}
                               onChange={handleChange2}
                               hiddenLabel
                               error={Boolean(errors2?.revenue)}
                               placeholder={currentTargets?.quarterly?.revenue}
                               endAdornment={
                                 <InputAdornment
                                   className={classes.alignedPlaceHolder}
                                   position="end"
                                 >
                                 </InputAdornment>
                               }
                             // disabled
                             />
                             {errors2?.revenue && (
                               <p className={classes.errorInput}>
                                 {errors2?.revenue}
                               </p>
                             )}
                           </Grid>
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}
                           >
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>Number of Appointments</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Year appointments target"
                               name="appointments"
                               hiddenLabel
                               value={quarterlyTargets?.appointments || ''}
                               onChange={handleChange2}
                               error={Boolean(errors2?.appointments)}
                               placeholder={currentTargets?.quarterly?.appointments}
                             // disabled
                             />
                             {errors2?.appointments && (
                               <p className={classes.errorInput}>
                                 {errors2?.appointments}
                               </p>
                             )}
                           </Grid>
                         </Grid>
                       </Grid>

                       <Grid
                         item
                         lg={12}
                         md={12}
                         xl={12}
                         xs={12}
                         className={classes.grid}
                       >
                         <Grid
                           container
                           spacing={isMobile2 ? 0 : 3}
                         >
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}>
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>Cancellations</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Percentage Cancelled"
                               name="cancellations"
                               hiddenLabel
                               value={quarterlyTargets?.cancellations || ''}
                               error={Boolean(errors2?.cancellations)}
                               onChange={handleChange2}
                               placeholder={currentTargets?.quarterly?.cancellations}
                             // disabled
                             />
                             {errors2?.cancellations && (
                               <p className={classes.errorInput}>
                                 {errors2?.cancellations}
                               </p>
                             )}
                           </Grid>
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}
                           >
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>DNAs</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Percentage DNA"
                               name="dna"
                               hiddenLabel
                               value={quarterlyTargets?.dna || ''}
                               error={Boolean(errors2?.dna)}
                               onChange={handleChange2}
                               placeholder={currentTargets?.quarterly?.dna}
                             // disabled
                             />
                             {errors2?.dna && (
                               <p className={classes.errorInput}>{errors2?.dna}</p>
                             )}
                           </Grid>
                         </Grid>
                       </Grid>

                       {(plan?.[0]?.name === 'Giga' || is_trialing )&& 
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                          className={classes.grid}
                        >
                          <Grid
                            container
                            spacing={isMobile2 ? 0 : 3}
                          >
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              xs={12}>
                              <div className={classes.marginTop}>
                                <Text size='subnote' color={colors.primary} weight='normal'>Total patients</Text>
                              </div>
                              <TextInput
                                costumizedInputStyles={classes.input}
                                // label="Percentage Cancelled"
                                name="patients"
                                hiddenLabel
                                value={quarterlyTargets?.patients || ''}
                                error={Boolean(errors2?.patients)}
                                onChange={handleChange2}
                                placeholder={currentTargets?.quarterly?.patients}
                              />
                              {errors2?.patients && (
                                <p className={classes.errorInput}>
                                  {errors2?.patients}
                                </p>
                              )}
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              xs={12}
                            >
                              <div className={classes.marginTop}>
                                <Text size='subnote' color={colors.primary} weight='normal'>New patients</Text>
                              </div>
                              <TextInput
                                costumizedInputStyles={classes.input}
                                // label="Percentage DNA"
                                name="new_patients"
                                hiddenLabel
                                value={quarterlyTargets?.new_patients || ''}
                                error={Boolean(errors2?.new_patients)}
                                onChange={handleChange2}
                                placeholder={currentTargets?.quarterly?.new_patients}
                              />
                              {errors2?.new_patients && (
                                <p className={classes.errorInput}>{errors2?.new_patients}</p>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                       }

                       <Grid
                         item
                         lg={12}
                         md={12}
                         xl={12}
                         xs={12}
                         className={classes.grid}
                       >
                         <Grid
                           container
                           spacing={isMobile2 ? 0 : 3}
                         >
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}>
                             <PrimaryButton
                               type="submit"
                               classes={{
                                 root: classes.saveButton,
                               }}
                               disabled={disabled2 || isLoadingUpdate2}
                               loading={isLoadingUpdate2}
                               style={{
                                 backgroundColor: (disabled2 || isLoadingUpdate2) ? colors.disabled : colors.primary,
                               }}
                             >
             Update targets
                             </PrimaryButton>
                           </Grid>
                         </Grid>
                       </Grid>
                     </div>
                     <div className={classes.targetsPicture}>
                       <img className={classes.targetImg} src={targetsImg} alt='targets' />
                     </div>
                   </div>
                 </form>
                }
                {period === 'annually' && 
                 <form onSubmit={handleSubmit3}>
                   <div className={classes.container}>
                     <div className={classes.aboveGrid}>
                       <Grid
                         item
                         lg={12}
                         md={12}
                         xl={12}
                         xs={12}
                         className={classes.grid}
                       >
                         <Grid
                           container
                           spacing={isMobile2 ? 0 : 3}
                         >
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}>
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>Revenue</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Year financial target"
                               name="revenue"
                               value={yearlyTargets?.revenue || ''}
                               onChange={handleChange3}
                               hiddenLabel
                               error={Boolean(errors3?.revenue)}
                               placeholder={currentTargets?.yearly?.revenue}
                               endAdornment={
                                 <InputAdornment
                                   className={classes.alignedPlaceHolder}
                                   position="end"
                                 >
                                 </InputAdornment>
                               }
                             // disabled
                             />
                             {errors3?.revenue && (
                               <p className={classes.errorInput}>
                                 {errors3?.revenue}
                               </p>
                             )}
                           </Grid>
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}
                           >
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>Number of Appointments</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Year appointments target"
                               name="appointments"
                               hiddenLabel
                               value={yearlyTargets?.appointments || ''}
                               onChange={handleChange3}
                               error={Boolean(errors3?.appointments)}
                               placeholder={currentTargets?.yearly?.appointments}
                             // disabled
                             />
                             {errors3?.appointments && (
                               <p className={classes.errorInput}>
                                 {errors3?.appointments}
                               </p>
                             )}
                           </Grid>
                         </Grid>
                       </Grid>
                       <Grid
                         item
                         lg={12}
                         md={12}
                         xl={12}
                         xs={12}
                         className={classes.grid}
                       >
                         <Grid
                           container
                           spacing={isMobile2 ? 0 : 3}
                         >
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}>
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>Cancellations</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Percentage Cancelled"
                               name="cancellations"
                               hiddenLabel
                               value={yearlyTargets?.cancellations || ''}
                               error={Boolean(errors3?.cancellations)}
                               onChange={handleChange3}
                               placeholder={currentTargets?.yearly?.cancellations}
                             // disabled
                             />
                             {errors3?.cancellations && (
                               <p className={classes.errorInput}>
                                 {errors3?.cancellations}
                               </p>
                             )}
                           </Grid>
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}
                           >
                             <div className={classes.marginTop}>
                               <Text size='subnote' color={colors.primary} weight='normal'>DNAs</Text>
                             </div>
                             <TextInput
                               costumizedInputStyles={classes.input}
                               // label="Percentage DNA"
                               name="dna"
                               hiddenLabel
                               value={yearlyTargets?.dna || ''}
                               error={Boolean(errors3?.dna)}
                               onChange={handleChange3}
                               placeholder={currentTargets?.yearly?.dna}
                             // disabled
                             />
                             {errors3?.dna && (
                               <p className={classes.errorInput}>{errors3?.dna}</p>
                             )}
                           </Grid>
                         </Grid>
                       </Grid>

                       {(plan?.[0]?.name === 'Giga' || is_trialing) && 
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                          className={classes.grid}
                        >
                          <Grid
                            container
                            spacing={isMobile2 ? 0 : 3}
                          >
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              xs={12}>
                              <div className={classes.marginTop}>
                                <Text size='subnote' color={colors.primary} weight='normal'>Total patients</Text>
                              </div>
                              <TextInput
                                costumizedInputStyles={classes.input}
                                // label="Percentage Cancelled"
                                name="patients"
                                hiddenLabel
                                value={yearlyTargets?.patients || ''}
                                error={Boolean(errors3?.patients)}
                                onChange={handleChange3}
                                placeholder={currentTargets?.yearly?.patients}
                              />
                              {errors3?.patients && (
                                <p className={classes.errorInput}>
                                  {errors3?.patients}
                                </p>
                              )}
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              xs={12}
                            >
                              <div className={classes.marginTop}>
                                <Text size='subnote' color={colors.primary} weight='normal'>New patients</Text>
                              </div>
                              <TextInput
                                costumizedInputStyles={classes.input}
                                // label="Percentage DNA"
                                name="new_patients"
                                hiddenLabel
                                value={yearlyTargets?.new_patients || ''}
                                error={Boolean(errors3?.new_patients)}
                                onChange={handleChange3}
                                placeholder={currentTargets?.yearly?.new_patients}
                              />
                              {errors3?.new_patients && (
                                <p className={classes.errorInput}>{errors3?.new_patients}</p>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                       }

                       <Grid
                         item
                         lg={12}
                         md={12}
                         xl={12}
                         xs={12}
                         className={classes.grid}
                       >
                         <Grid
                           container
                           spacing={isMobile2 ? 0 : 3}
                         >
                           <Grid
                             item
                             xl={6}
                             lg={6}
                             md={6}
                             xs={12}>
                             <PrimaryButton
                               type="submit"
                               classes={{
                                 root: classes.saveButton,
                               }}
                               disabled={disabled3 || isLoadingUpdate3 }
                               loading={isLoadingUpdate3}
                               style={{
                                 backgroundColor: (disabled3 || isLoadingUpdate3) ? colors.disabled : colors.primary,
                               }}
                             >
             Update targets
                             </PrimaryButton>
                           </Grid>
                         </Grid>
                       </Grid>
                     </div>
                     <div className={classes.targetsPicture}>
                       <img className={classes.targetImg} src={targetsImg} alt='targets' />
                     </div>
                   </div>
                 </form>
                }
               
              </div>
            </div>
            {/* <div
              className={classes.info}
            >
              <img className={classes.img} width={24} height={24} src={info} />
              <a className={classes.link} href="https://dataplayerhelp.atlassian.net/servicedesk/customer/portal/7/article/451149838" target="_blank" rel="noreferrer" >
          Check out this article on setting targets and tracking your performance.
              </a>
            </div> */}
          </div>
          <LoadingModal
            isLoading={data_loading}
          />
        </>
      </TabPanel>
    </div>
  )
}

export default Targets
