
import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import '../../DatePicker/DatePicker.styles'
import { Grid } from '@material-ui/core'
import { Calendar } from '../../../Themes/Images'
import { useStyles, useSimpleDatePickerStyles } from '../../DatePicker/DatePicker.styles'
import Text from '../../Text'
import { colors } from '../../../Themes'

// const SELECT_DATE = [
//   { value: '1', label: '1' },
//   { value: '2', label: '2' },
//   { value: '3', label: '3' },
//   { value: '4', label: '4' },
//   { value: '5', label: '5' },
//   { value: '6', label: '6' },
//   { value: '7', label: '7' },
//   { value: '8', label: '8' },
//   { value: '9', label: '9' },
//   { value: '10', label: '10' },
//   { value: '11', label: '11' },
//   { value: '12', label: '12' },
//   { value: '13', label: '13' },
//   { value: '14', label: '14' },
//   { value: '15', label: '15' },
//   { value: '16', label: '16' },
//   { value: '17', label: '17' },
//   { value: '18', label: '18' },
//   { value: '19', label: '19' },
//   { value: '20', label: '20' },
//   { value: '21', label: '21' },
//   { value: '22', label: '22' },
//   { value: '23', label: '23' },
//   { value: '24', label: '24' },
//   { value: '25', label: '25' },
//   { value: '26', label: '26' },
//   { value: '27', label: '27' },
//   { value: '28', label: '28' },
//   { value: '29', label: '29' },
//   { value: '30', label: '30' },
//   { value: '31', label: '31' },

// ]
// const SELECT_MONTH = [
//   { value: 'January', label: 'January' },
//   { value: 'February', label: 'February' },
//   { value: 'March', label: 'March' },
//   { value: 'April', label: 'April' },
//   { value: 'May', label: 'May' },
//   { value: 'June', label: 'June' },
//   { value: 'July', label: 'July' },
//   { value: 'August', label: 'August' },
//   { value: 'September', label: 'September' },
//   { value: 'October', label: 'October' },
//   { value: 'November', label: 'November' },
//   { value: 'December', label: 'December' },
// ]

// function AccountFinancialDetails({ values, setFieldValue }) {
//   const theme = useTheme()
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

//   const handleChangeSelectDate=(value)=>{
//     setFieldValue('date', value.value)
//   }

//   const handleChangeSelectMonth=(value)=>{
//     setFieldValue('month', value.value)
//   }

//   return (
//     <Grid
//       item
//       lg={12}
//       md={12}
//       xl={12}
//       xs={12}
//     >
//       <Grid
//         container
//         spacing={isMobile ? 0 : 2}
//       >
//         <Grid
//           item
//           xl={4}
//           lg={4}
//           md={6}
//           xs={12}>
//           <SelectInput
//             value={ SELECT_DATE ? SELECT_DATE.find(option => option.value === values.date) : ''}
//             selectCostumStyles={isMobile ? selectStyles3 : selectStyles2}
//             placeholder="Date"
//             options={SELECT_DATE}
//             onChange={handleChangeSelectDate}
//             account
//           />
//         </Grid>
//         <Grid
//           style={{ marginTop: isMobile ? 10 : 0 }}
//           item
//           xl={4}
//           lg={4}
//           md={6}
//           xs={12}>
//           <SelectInput
//             selectCostumStyles={isMobile ? selectStyles3 : selectStyles2}
//             value={ SELECT_MONTH ? SELECT_MONTH.find(option => option.value === values.month) : ''}
//             placeholder="Month"
//             options={SELECT_MONTH}
//             onChange={handleChangeSelectMonth}
//             account
//           />
//         </Grid>
//       </Grid>
//     </Grid>
//   )
// }
function AccountFinancialDetails({ values, setFieldValue, handleBlur }) {
  const classes = useSimpleDatePickerStyles()

  return (
    <div className={classes.financialDatesContainer}>
      <div className={classes.startDateContainer}>
        <Text size='small' color={colors.footnote}>Start Date</Text>
        <div className={classes.datePickerWrapperDiv}>
          <DatePicker
            selected={values.financial_year_start_date ? new Date(values.financial_year_start_date) : null}
            onChange={(date) => setFieldValue('financial_year_start_date', date)}
            dateFormat="dd-MM-yyyy"
            selectsStart
            startDate={values.financial_year_start_date ? new Date(values.financial_year_start_date) : null}
            endDate={values.financial_year_end_date ? new Date(values.financial_year_end_date) : null}
            className={classes.datePickerInputContainerDiv}
            onBlur={handleBlur}
          />
          <Calendar className={classes.calendarIcon} />
        </div>
      </div>
      <div className={classes.startDateContainer}>
        <Text size='small' color={colors.footnote}>End Date</Text>
        <div className={classes.datePickerWrapperDiv}>
          <DatePicker
            selected={values.financial_year_end_date ? new Date(values.financial_year_end_date) : null}
            onChange={(date) => setFieldValue('financial_year_end_date', date)}
            dateFormat="dd-MM-yyyy"
            selectsEnd
            startDate={values.financial_year_start_date ? new Date(values.financial_year_start_date) : null}
            endDate={values.financial_year_end_date ? new Date(values.financial_year_end_date) : null}
            minDate={values.financial_year_start_date ? new Date(values.financial_year_start_date) : null}
            className={classes.datePickerInputContainerDiv}
            onBlur={handleBlur}
          />
          <Calendar className={classes.calendarIcon} />
        </div>
      </div>
    </div>
  )
}

export default AccountFinancialDetails
