import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useUpgradeCardStyle = makeStyles((theme) => ({
  upgradeCardContrainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    padding: '15px 32px 15px 30px',
    backgroundColor: colors.primary,
    borderRadius: 10,
    [theme.breakpoints.up('sm')]: {
      margin: '30px 16px 0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '15px 15px 0',
    },
  },
  text: {
    fontFamily: 'Roboto',
    color: colors.white,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  icon: {
    color: colors.white,
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  iconButtonRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: ' 15px 0 0',
    },
  },
  linkTo: {
    color: colors.white,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 18
  },
  closeIcon: {
    color: colors.white,
  },
  iconButtonClose: {
    position: 'absolute',
    right: 15,
    padding: 0,
  },
    
  iconButton: {
    // margin: 10,
  },
}))

const useStyles = makeStyles((theme) => ({
  rootIcon: {
    fill: colors.white,
    width: 40,
    height: 40,
  },
  rootBackIcon: {
    fill: colors.primary,
    width: 24,
    height: 24,
  },
  iconButtonFull: {
    position: 'absolute',
    right: '25px !important',
    paddingTop: '10px !important',
  },
  iconButton: {
    position: 'absolute',
    right: 45,
    top: 5,
    padding: 5,
  },
  dashboard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingInline: 24,
     
    [theme.breakpoints.down(760)]: {
      paddingInline: 10,
    },
  },
  dashboardFullScreen: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  // Loading Data styles
  loadingContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: ' center',
    alignItems: 'center',
    height: '100%',
    paddingTop: 30,
    overflow: 'scroll',
    WebkitOverflowScrolling: 'touch',
    // -webkit-overflow-scrolling: touch;
  },
  loadingDataMessage: {
    width: '50%',
    backgroundColor: colors.white,
    padding: 20,
    border: '1px solid ' + colors.white,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  lottie: {
    height: 700,
    [theme.breakpoints.down('sm')]: {
      height: 300,
    },
  },
  subheader1: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    // position: 'sticky',
    paddingTop: 10,
    paddingBottom: 10
    // marginBottom: 30,
  },
}))

export { useUpgradeCardStyle, useStyles }