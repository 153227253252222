import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    backgroundColor: colors.white,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      flexGrow: 1,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
      height: '100%',
    },
  },
  loginTitle: {
    color: colors.white,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  loginButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    alignItems: 'center',
  
  
    // [theme.breakpoints.down('sm')]: {
  
    //   flexDirection: 'column-reverse',
    //   alignItems: 'center',
    //   gap: 10,
    // },
    // [theme.breakpoints.up('sm')]: {
    //   // flexDirection: "row",
    //   flexDirection: 'column-reverse',
  
    //   alignItems: 'center',
    // },
  },
  loginForm: {
    padding: 20,
    marginBottom: 30,
    display: 'flex',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      // width: "100%",
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      flex: 1,
  
    },
  },
  loginAnimationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(70deg, ${colors.accent}, ${colors.secondary})`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'none',
  
        
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  gradientLogo: {
    height: 100,
    width: 200,
  },
  loginButton: {
    width: '100%',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  loginWrapper: {
    margin: 'auto',
    flexGrow: 0.7,
    maxWidth: 500,
  },
  forgotPassword: {
    margin: '17px 0 0 ',
    color: colors.secondary,
    fontFamily: 'Roboto',
    fontSize: 18,
    alignSelf: 'flex-start'
  },
  byLoginText: {
    margin: '32px 0 15px',
    color: colors.primary,
    opacity: 0.5,
    fontFamily: 'Roboto',
    fontSize: 18,
  },
  subLine: {
    borderWidth: '0px 0px 1px',
    borderStyle: 'solid',
    borderColor: colors.gray,
    margin: '35px 0 0 0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  
  infoWrapper: {
    display: 'flex',
    width: '100%', 
    alignItems: 'center'
  },
  infoText: {
    marginRight: 10,
    fontFamily: 'Roboto',
    fontSize: 18,
  },
  visitDataplayerButton: {
    backgroundColor: colors.white,
    color: colors.primary,
    marginTop: 100,
    maxWidth: 400,
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.accent,  
      
    },
    '&$focused': {
      backgroundColor: colors.gray,
    },
    width: '65%',
  
  },
  visitDataplayerMobileButton: {
    backgroundColor: colors.white,
    color: colors.primary,
    marginTop: 16,
    maxWidth: 400,
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.accent,  
      
    },
    '&$focused': {
      backgroundColor: colors.gray,
    },
    width: '100%',
  
  },
  rootBackIcon: {
    fill: colors.accent,
    width: 28,
    height: 28,
  },
  
  visitDataplayerContainer: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 32px 26px',
    backgroundColor: colors.primary
  },
}))

export default useStyles