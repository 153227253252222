// import React from 'react'
// import Text from '../Text'

// const MarkdownViewer = ({ markdownMessage }) => {

//   const convertToHtml = () => {
//     const lines = markdownMessage?.split('\n')

//     return lines?.map((line, index) => {
//       // Headers
//       if (line.startsWith('#')) {
//         const level = line.lastIndexOf('#') + 1
//         return React.createElement(`h${level}`, { key: index }, line.substring(level).trim())
//       }
//       // Ordered and unordered lists
//       else if (line.startsWith('-') || line.startsWith('*')) {
//         return React.createElement('li', { key: index }, line.substring(1).trim())
//       } else if (/^\d+\./.test(line)) {
//         const parts = line.split('.')
//         return React.createElement('li', { key: index }, parts[1].trim())
//       }
//       // Bold, italic, and strikethrough text
//       else if (line.includes('**') || line.includes('__') || line.includes('*') || line.includes('_') || line.includes('~~')) {
//         const parts = line.split(/(\*\*|__|\*|_|~~)/)
//         return (
//           <span key={index}>
//             {parts.map((part, i) => {
//               if (part === '**' || part === '__' || part === '~~') {
//                 return null
//               } else if (part === '*' || part === '_') {
//                 return <em key={i}>*</em>
//               } 
//               return <Text weight='bold' size='footnote' key={i}>{part}</Text>

//             })}
//           </span>
//         )
//       }
//       // Inline code
//       else if (line.startsWith('`')) {
//         return React.createElement('code', { key: index }, line.substring(1).trim())
//       }
//       // Blockquotes
//       else if (line.startsWith('>')) {
//         return React.createElement('blockquote', { key: index }, line.substring(1).trim())
//       }
//       // Horizontal rule
//       else if (line.startsWith('---')) {
//         return <hr key={index} />
//       }
//       // Links
//       else if (/\[.*\]\(.*\)/.test(line)) {
//         const textStart = line.indexOf('[')
//         const textEnd = line.indexOf(']')
//         const linkStart = line.indexOf('(')
//         const linkEnd = line.indexOf(')')
//         const linkText = line.substring(textStart + 1, textEnd)
//         const linkUrl = line.substring(linkStart + 1, linkEnd)
//         return <a href={linkUrl} key={index}>{linkText}</a>
//       }
//       // Images
//       else if (/!\[.*\]\(.*\)/.test(line)) {
//         const altTextStart = line.indexOf('[')
//         const altTextEnd = line.indexOf(']')
//         const altText = line.substring(altTextStart + 1, altTextEnd)
//         const urlStart = line.indexOf('(')
//         const urlEnd = line.indexOf(')')
//         const url = line.substring(urlStart + 1, urlEnd)
//         return <img src={url} alt={altText} key={index} />
//       }
//       // Normal text

//       return <Text size='footnote' key={index}>{line}</Text>

//     })
//   }

//   return (
//     <div>
//       {convertToHtml()}
//     </div>
//   )
// }

// export default MarkdownViewer


import React, { useEffect, useState } from 'react'
import { colors } from '../../Themes'
import { useChat } from '../../Global/Chat'


const MarkdownConverter = ({ markdownText, textKey, index }) => {
  if (textKey === 'explanation') {

    // console.log('🚀 ~ MarkdownConverter ~ markdownText:', markdownText)
  }
  const [dots, setDots] = useState('')
  const { stopDots, messageList } = useChat()

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(dots => {
        switch (dots) {
          case '':
            return '.'
          case '.':
            return '..'
          case '..':
            return '...'
          default:
            return ''
        }
      })
    }, 500) // Change the interval duration as needed

    return () => clearInterval(interval)
  }, [])
  let textColor = colors.primary
  const convertToHtml = () => {
    return { __html: `<div style="color: ${textColor};">${parseMarkdown(markdownText || ' ')}${textKey === 'aiquote' ? (!stopDots && index === messageList.length - 1 ? dots : '.') : ''}</div>` }
  }

  const parseMarkdown = (text) => {

    const parseTable = (tableText) => {
      const rows = tableText.split('\n')
      let html = '<table>'
      rows.forEach((row) => {
        html += '<tr>'
        const cells = row.split('|').filter(Boolean) // Remove empty cells
        cells.forEach((cell) => {
          html += `<td>${cell.trim()}</td>`
        })
        html += '</tr>'
      })
      html += '</table>'
      return html
    }

    if (text) {
      return text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // bold
        .replace(/_(.*?)_/g, '<em>$1</em>') // italic with underscore
        .replace(/\*(.*?)\*/g, '<em>$1</em>') // italic with asterisks
        .replace(/~~(.*?)~~/g, '<del>$1</del>') // strikethrough
        .replace(/`(.*?)`/g, '<code>$1</code>') // inline code
        .replace(/```([^```]+)```/g, '<pre>$1</pre>') // code block
        .replace(/#(.*?)\n/g, '<h1>$1</h1>') // headers
        .replace(/##(.*?)\n/g, '<h2>$1</h2>') // headers
        .replace(/###(.*?)\n/g, '<h3>$1</h3>') // headers
        .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>') // links
        .replace(/(?:^|\n)(\|.+\|)(?:\n|$)/g, (_, table) => parseTable(table)) // tables
        .replace(/\n/g, '<br>') // line breaks
    }
  }

  return (
    <div>
      <div dangerouslySetInnerHTML={convertToHtml()} />

    </div>
  )
}

export default MarkdownConverter