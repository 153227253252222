import { makeStyles } from '@material-ui/core'

const successModalStyles = makeStyles((theme) => ({
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  spaceText2: {
    margin: '17px 0 0px 0'
  },
  text: {
    marginTop: 40,
    marginBottom: 30,
    textAlign: 'center',
  },
  text2: {
    marginTop: 40,
    textAlign: 'center',
  },
  iconButtonClose: {
    position: 'absolute',
    right: 10,
    top: -30,
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: 0,
    },
    [theme.breakpoints.down('xs')]: {
      right: -10,
      top: -10,
    },
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  img: {
    objectFit: 'contain'
  }
}))

export default successModalStyles