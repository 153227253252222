import React from 'react'
import {  TextInput, Text } from '../..'
import {  Grid } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { colors } from '../../../Themes'
import useStyles5 from './AccountPersonalInformation.styles'
import PhoneNumberInput from '../../Input/PhoneNumberInput'

const NotVerifiedEmailMessage = ({ onVerifyEmail, isNumberVerified }) => {
  const classes=useStyles5()

  return (
    <>
      <div className={classes.notVerifiedEmailMessageWrapper} onClick={onVerifyEmail}>
        <Text
          size='subnote' color='#EE4352'
          className={isNumberVerified ? classes.notVerifiedEmailButtonPhoneVerified : classes.notVerifiedEmailButton }
        >
          Verify email
        </Text>
      </div>
    </>
  )
}

const NotVerifiedNumber = ({ onVerifyNumber, isDirty, isUndefined }) => {
  const classes=useStyles5()

  return (
    <>
      <div className={classes.notVerifiedNumberMessageWrapper} onClick={onVerifyNumber} style={{ visibility: isDirty || isUndefined && 'hidden' }}>
        <Text
          size='subnote' color='#EE4352'
          className={classes.notVerifiedNumberButton}
        >
          Verify number
        </Text>
      </div>
    </>
  )
}

function  AccountPersonalInformation
(
  { 
    changeEmailValues,
    setChangeEmailValues, 
    isEmailVerified,
    onVerifyEmail,
    isErrorConfirmEmail,
    values,
    handleChange,
    handleBlur,
    isMobile,
    changeNumberValues,
    setChangeNumberValues,
    isNumberVerified,
    onVerifyNumber,
    isErrorConfirmNumber,
    setFieldValue,
    isDirty,
    isUndefined
  }
) {
  const classes=useStyles5()
  
  const renderChangeEmailInfo = () => {

    return (
      <div className={classes.changeEmailWrapper}>
        {changeEmailValues?.success ?
          <div className={classes.successEmail}>
            <span className={classes.changeEmailLink}>Change email address</span>
            <span className={classes.requestSent} >Request sent</span>
          </div>
          :
          <div className={isNumberVerified ? classes.changeEmailPhoneVerified : classes.changeEmail}>
            <Text size='subnote' color='#EE4352' className={classes.changeEmailLink} onClick={() => setChangeEmailValues(prev => ({
              ...prev,
              isVisible: true,
            })
            )} >Change email address</Text>
          </div>
        }
      </div>
    )
  }

  const renderChangeNUmberInfo = () => {

    return (
      <div className={classes.changeEmailWrapper}>
        {changeNumberValues?.success ?
          <div className={classes.successEmail}>
            <span className={classes.changeEmailLink}>Change number</span>
            <span className={classes.requestSent} >Request sent</span>
          </div>
          :
          <div className={classes.changeEmail}>
            <Text size='subnote' color='#EE4352' className={classes.changeEmailLink} onClick={() => setChangeNumberValues(prev => ({
              ...prev,
              isVisible: true,
            })
            )} >Change number</Text>
          </div>
        }
      </div>
    )
  }

  return (
    <Grid
      item
      lg={12}
      md={12}
      xl={12}
      xs={12}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 2}
      >
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          xs={12}>
          <TextInput
            costumizedInputStyles={classes.input}
            name='fullName'
            label='Full name'
            value={values.fullName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <PhoneNumberInput
            value={values?.phone_number ?  values?.phone_number : ''}
            setValue={(value) => setFieldValue('phone_number', value)}
            // errorNumber={values?.phone_number && !isValidPhoneNumber(values?.phone_number) ? 'true' : 'false'}
            signup={false}
            name='phone_number'
            
          />
          {
            !isNumberVerified
              &&
              [isMobile ?  <NotVerifiedNumber 
                onVerifyNumber={onVerifyNumber}
                error={isErrorConfirmNumber} 
                isDirty={isDirty}
                isUndefined={isUndefined}
                // isNumberVerified={isNumberVerified}
              /> :  null]
              
            
          }
          <TextInput
            costumizedInputStyles={isMobile ? classes.jobTitleInput : classes.input}
            costumizedRootStyles={classes.margin}
            name='email'
            label='Email'
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            endAdornment={
              isEmailVerified ? null
                :
                <WarningIcon color="error" />
            }
            disabled
          />
          {
            isEmailVerified
              ?
              [isMobile ? renderChangeNUmberInfo() : null]
              :
              [isMobile ?  <NotVerifiedEmailMessage
                onVerifyEmail={onVerifyEmail}
                error={isErrorConfirmEmail} 
              /> : null]
          }
        </Grid>
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          xs={12}>
          <TextInput
            costumizedInputStyles={classes.input}
            name='role'
            label='Position'
            value={values.role}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {
            !isNumberVerified
             &&
             [isMobile ? null : 
               <NotVerifiedNumber 
                 onVerifyNumber={onVerifyNumber}
                 error={isErrorConfirmNumber} 
                 isDirty={isDirty}
                 isUndefined={isUndefined}
                 //  isNumberVerified={isNumberVerified}
               />
             ]
             
          }
          {
            isEmailVerified
              ? [isMobile ? null : renderChangeEmailInfo()]
              :
              [isMobile ? null : <NotVerifiedEmailMessage 
                onVerifyEmail={onVerifyEmail}
                error={isErrorConfirmEmail} 
                isNumberVerified={isNumberVerified}
              />]
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AccountPersonalInformation
