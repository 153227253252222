import React, { useEffect, useState } from 'react'
import {  IconButton,  useMediaQuery, useTheme } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuth, useMenu } from '../../Global'
import MenuIcon from '@material-ui/icons/Menu'
import Text from '../Text'
import { colors } from '../../Themes'
import { LogoWhite,  WhiteSettings } from '../../Themes/Images'
import BadgeAvatar from '../BadgeAvatar/BadgeAvatar'
import ClearIcon from '@material-ui/icons/Clear'
import useStyles from './Layout.styles'
import { PrimaryButton } from '../Button/Button'

const Layout = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { user, picture, roles, userAttributes } = useAuth()
  const history = useHistory()
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const {
    toggleDrawer,
  } = useMenu()

  useEffect(() => {
    if (userAttributes?.email_verified) {
      setIsEmailVerified(true)
    } else {
      setIsEmailVerified(false)
    }

  }, [userAttributes])
 

  const navigate = (url) => history.push(url)
  const isMobile = useMediaQuery(theme.breakpoints.down(760))

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex',  }}>
        <LogoWhite style={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard')} />
      </div>
      <div className={classes.iconContainer}>
        {
          (!isMobile && !roles.includes('secondary') ) &&
          <>
            <div className={classes.buttonDiv}>
              <PrimaryButton
                onClick={() => navigate('/upgradeplan')}
                className={classes.button}>
              Upgrade now
              </PrimaryButton>
            </div>
            <WhiteSettings onClick={() => navigate('/settings')} className={classes.icon} />
           
          </>
        }
        {isMobile ? 
          <IconButton
            disableRipple={false}
            style={{ backgroundColor: 'transparent', color: colors.white }}
            disableFocusRipple
            centerRipple={true}
            onClick={(history?.location?.pathname === '/upgradeplan' || history?.location?.pathname === '/cancelPlan' || history?.location?.pathname.includes('/storedetails/') ) ? history.goBack : toggleDrawer}
          >
            {(history?.location?.pathname === '/upgradeplan' || history?.location?.pathname === '/cancelPlan' || history?.location?.pathname.includes('/storedetails/') ) ? <ClearIcon /> : <MenuIcon />}
          </IconButton>
          : 
          <>
            {roles.includes('secondary') ? null : <div className={classes.verticalLine}></div>}
            <div onClick={() => navigate('/settings/account')}>
              <Text style={{ cursor: roles.includes('secondary') ? 'default' : 'pointer' }} size='footnote' color={colors.white}>
                {user}
              </Text>
            </div>
            <BadgeAvatar  layout={true} invisible={roles.includes('secondary') ? true : userAttributes?.email_verified} alt={user} picture={picture} onClick={() => navigate('/settings/account')} />
           
          </>}
      </div>
    </div>
  )
}

export default Layout