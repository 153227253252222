import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const useStyles = makeStyles((theme)=> ({
  sectionTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  sectionTitleAddOn: {
    display: 'flex',
    gap: 24
  },
  cardWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    flexWrap: 'wrap',
    [theme.breakpoints.down(1200)]: {
      justifyContent: 'center'
    }
  },
  subtitle: {
    marginTop: 8,
    marginBottom: 12
  },
  border: {
    width: '100%',
    height: 1,
    marginTop: '24px',
    marginBottom: '24px',
    borderBottom: `1px solid ${colors.borderGray}`
  },
  carouselContainer: {
    width: '100%',
    height: '225px',
  },
  sliderContainer: {
    height: '200px',
    margin: '0 42px'
  },
  sectionTitleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', 
  },
  sectionTitleWrapperAddOn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 5
  },
  noteWrapper: {
    width: '100%',
    padding: '18px 16px',
    border: `1px solid ${colors.borderGray}`,
    display: 'flex',
    alignItems: 'center',
  },
  note: {
    marginRight: '10px',
  },
  link: {
    color: colors.lightRed,
    marginLeft: 4
  },
  info: {
    marginRight: 18
  },
  subheader1: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    // position: 'sticky',
    paddingTop: 10,
    paddingBottom: 13
    // marginBottom: 30,
  },
}))

export default useStyles