/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {  ContentContainer, SettingsItem, TabPanel, Text } from '../../Components'
import { Breadcrumbs, IconButton } from '@material-ui/core'
import { Route, Link, Switch, useHistory } from 'react-router-dom'
import ChangePassword from '../ChangePassword/ChangePassword'
import DeleteAccount from '../DeleteAccount/DeleteAccount'
import Acount from '../Account/Account'
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions'
import Targets from '../Targets/Targets'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import { colors } from '../../Themes'
import { SETTINGS_ITEMS, SETTINGS_ITEMS_FOR_FREE_USER } from '../../Utils/SettingsItems'
import { InfoManual, Refresh, SettingsImage, empty } from '../../Themes/Images'
import { PaymentInformation } from '..'
import { useAuth } from '../../Global'
import Reports from '../Reports/Reports'
import Unsubscribe from '../Addons/Unsubscribe'
import useStyles from './Settings.styles'
import UploadFile from '../UploadFile'
import { Axios } from '../../Config'
import  { PaymentTableFiles, PaymentTable } from '../../Components/PaymentDetailsForFileUpload/PaymentTableForFileUpload'
import DateRangePicker from '../../Components/DatePicker'
import { useWindowDimensions } from '../../Utils'
import PaymentList from '../../Components/PaymentDetailsForFileUpload/PaymentList'
import TabsForManualFiles from '../../Components/TabsForManualFiles/TabsForManualFiles'
import ManualFileHeader from '../../Components/ManualFileHeader'
import UploadFileModal from '../../Components/UploadFileModal'
import UpdateFileUpload from '../../Components/UpdateFileUpload'
import { SecondaryOutlinedButton } from '../../Components/Button/Button'
import RequestFileChangeModal from '../../Components/RequestFileChangeModal/RequestFileChangeModal'
import { PaymentListFiles } from '../../Components/PaymentDetailsForFileUpload'

function sliceFiles(files, limit) {
  if (files && files?.length > 0) {
    return files?.slice(0, limit).reverse()
  }
  return []
}

function receiveDate(str, time) {
  let date = new Date(str),
    getYear = date.toLocaleString('default', { year: 'numeric' }),
    getMonth = date.toLocaleString('default', { month: '2-digit' }),
    getDay = date.toLocaleString('default', { day: '2-digit' }),
    dateFormat = getYear + '-' + getMonth + '-' + getDay + time
  return [dateFormat]
}

const InlineSettings = () => {
  const classes = useStyles()
  const { is_trialing, plan }=useAuth()

  return (
    <div className={classes.cardsContainer}>
      <div className={classes.cards}>
        {plan==='free' || is_trialing ? 
          <> 
            {SETTINGS_ITEMS_FOR_FREE_USER.map((item, index) => (
              <SettingsItem
                key={index}
                Icon={item.Icon}
                title={item.title}
                description={item.description}
                to={item.to}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
              />
            ))}
          </>
          :
          <> 
            {SETTINGS_ITEMS.map((item, index) => (
              <SettingsItem
                key={index}
                Icon={item.Icon}
                title={item.title}
                description={item.description}
                to={item.to}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
              />
            ))}
          </>
        }
      </div>
      <div className={classes.imageContainer}>
        <SettingsImage className={classes.image} />
      </div>
    </div>
  )
}

const ItemsInReview = () => {
  const classes = useStyles()

  return (
    <div className={classes.successDiv}>
      <div className={classes.infoDiv}>
        <InfoManual />
      </div>
      <div className={classes.inReviewText}>
        <Text size='footnote'>We got your request and the team will contact you shortly.</Text>
      </div>
    </div>
  )
}

function  Settings() {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const [filesHistory, setFilesHistory] = useState({
    files: [],
    limit: 5,
    loading: false
  })
  const [files, setFiles] = useState({
    files: [],
    limit: 5,
    loading: false
  })
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(0)
  const [tokenData, setTokenData] = useState([]) 
  let tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1))
  const [endDate, setEndDate] = useState(tomorrow)
  const [nextToken, setNextToken] = useState(null) 
  const [error, setError] = useState('')
  const loc = useHistory()
  const { width } = useWindowDimensions()
  const { getUpdatedUser, isModalOpen, setIsModalOpen, isUpdateModalOpen, setIsUpdateModalOpen, handleFileUpdate,
    fileToEdit, activeFileTypesCount, userUploadedFileTypeCount, setIsRequestFileChangeModalOpen, isRequestFileChangeModalOpen }=useAuth()

  const found = SETTINGS_ITEMS.findIndex(
    (item) => item?.to === loc?.location?.pathname
  )

  const hasReview = files?.files?.find((user) => user.status === 'IN REVIEW')

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  const title = loc?.location?.pathname.slice(0, 22) === '/settings/unsubscribe/' ? 'Cancel Subscription' 
    : loc?.location?.pathname === '/settings/targets' ? 'Targets' : loc?.location?.pathname === '/settings/report' ? 'Email Reports' : 
      (loc?.location?.pathname === '/settings/myFiles' || loc?.location?.pathname === '/settings/uploadFile') ? 'My files' :
        loc?.location?.pathname === '/settings' ? 'Settings' :
          <Breadcrumbs separator={<ArrowBackIosRoundedIcon style={{ transform: 'rotate(-180deg)', height: 15, width: 15 }} htmlColor={colors.black} />} aria-label="breadcrumb">
            <a style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => loc?.push('/settings')} >
              <Text size='subnote' weight='regular' className="sectionTitle">Settings</Text>
            </a>
            <a style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => loc?.push(SETTINGS_ITEMS?.[found]?.to )} >
              <Text size='subnote' weight='bold' className="sectionTitle">{SETTINGS_ITEMS?.[found]?.title}</Text>
            </a>
          </Breadcrumbs>

  useEffect(() => {
    getUpdatedUser()
    return () => {
      getUpdatedUser()
    }
  }, [])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setCurrentPage(0)
    setTokenData([null]) // so the tokenData array doesnt get hold old tokens
  }

  const goBackPage = async (token) => {
    try {
      setFilesHistory({
        ...filesHistory,
        loading: true
      })
      if (token === null) {
        const { data } = await Axios.get(`/prod/user/fileUploads/getUploadsHistory?limit=${rowsPerPage}&beginTime=${receiveDate(startDate, 'T00:00:00Z')}&endTime=${receiveDate(endDate, 'T23:59:59Z')}`)
        setFilesHistory({
          ...filesHistory,
          files: data?.data?.items,
          loading: false
        })
        const newNextToken = data?.data?.nextToken
        setNextToken(newNextToken)
      } else {
        const { data } = await Axios.get(`/prod/user/fileUploads/getUploadsHistory?nextToken=${token}&limit=${rowsPerPage}&beginTime=${receiveDate(startDate, 'T00:00:00Z')}&endTime=${receiveDate(endDate, 'T23:59:59Z')}`)
        setFilesHistory({
          ...filesHistory,
          files: data?.data?.items,
          loading: false
        })
        const newNextToken = data?.data?.nextToken
        setNextToken(newNextToken)
      }
      setCurrentPage((prevstate)=> prevstate-1)
    } catch (error) {
      console.log(error)
    }
  }


  const handleChangePage = async (token) => {
    if (token === null) {
      return
    } 
    try {
      setFilesHistory({
        ...filesHistory,
        loading: true
      })
      const { data } = await Axios.get(`/prod/user/fileUploads/getUploadsHistory?nextToken=${token}&limit=${rowsPerPage}&beginTime=${receiveDate(startDate, 'T00:00:00Z')}&endTime=${receiveDate(endDate, 'T23:59:59Z')}`)
      setFilesHistory({
        ...filesHistory,
        files: data?.data?.items,
        loading: false
      })
      const newNextToken = data.data.nextToken
      setNextToken(newNextToken)
      // checking if the nextToken is already in the array so we don't push the same token twice when navigating back and forth
      let isTokenAlreadyInState = tokenData.includes(nextToken)
      !isTokenAlreadyInState && setTokenData((prevState)=> [...prevState, nextToken]) 
      setCurrentPage((prevstate)=> prevstate+1)
    }
    catch (e) {
      console.log(e)
    }
  }

  const getFilesHistory = async () => {
    setFilesHistory({
      ...filesHistory,
      loading: true
    })
    try {
      const { data }= await Axios.get(`/prod/user/fileUploads/getUploadsHistory?limit=${rowsPerPage}&beginTime=${receiveDate(startDate, 'T00:00:00Z')}&endTime=${receiveDate(endDate, 'T23:59:59Z')}`)
      if (data.success === true) {
        setFilesHistory({
          ...filesHistory,
          files: data?.data?.items,
          loading: false
        })
        const token = data.data.nextToken
        setNextToken(token)
        setTokenData((prevState)=> [ ...prevState, nextToken]) // so the first token is null when navigating back to the first Page
      } else {
        setFilesHistory({
          ...filesHistory,
          loading: false
        })
      }
    } catch (error) {
      setError(error)
      setFilesHistory({
        loading: false
      })
    }
  }

  const getFiles = async () => {
    setFiles({
      ...files,
      loading: true
    })
    try {
      const { data }= await Axios.get('/prod/user/fileUploads/getUploadsTypes')
      if (data?.success === true) {
        setFiles({
          ...files,
          files: data?.data?.items,
          loading: false
        })
        // const token = data.data.nextToken
        // setNextTokenFile(token)
        // setTokenDataFile((prevState)=> [ ...prevState, nextTokenFile]) // so the first token is null when navigating back to the first Page
      } else {
        setFiles({
          ...files,
          loading: false
        })
      }
    } catch (error) {
      setError(error)
      setFiles({
        loading: false
      })
    }
  }
 
  const getFilesAndHistory = () => {
    getFilesHistory()
    getFiles()
    getUpdatedUser()
  }

  useEffect(() => {
    getFilesHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, startDate, endDate])

  useEffect(() => {
    // const interval = setInterval(() => {
    //   getFiles()
    // }, 3600)
  
    // return () => clearInterval(interval)
    getFiles()
    getUpdatedUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  useEffect(() => {
    getFilesHistory()
    getFiles()
    getUpdatedUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue])

  return (
    <Switch>
      <Route
        path={[
          '/settings',
          '/settings/account',
          '/settings/delete-account',
          '/settings/change-password',
          '/settings/terms&conditions',
          '/settings/targets',
          '/settings/payment-information',
          '/settings/unsubscribe/:productId',
          '/report-settings',
          '/settings/myFiles',
          '/settings/uploadFile',

        ]}
      >
        <div className="profile">
          <div className="tooltipsContentContainer">
            <ContentContainer
              header={
                <div className={classes.subHeader1}>
                  {loc?.location?.pathname?.slice(0, 22) === '/settings/unsubscribe/' && (
                    <IconButton
                      disableFocusRipple
                      centerRipple={true}
                      component={Link}
                      to={{
                        pathname: '/settings/payment-information',
                        state: {
                          selectedTab: 1
                        }
                      }}
                    >
                      <ArrowBackIosRoundedIcon htmlColor={colors.black} />
                    </IconButton>
                  )}
                  <Text size='subnote' weight='bold' className="sectionTitle">{title}</Text>
                </div>
              }
            >
              <Route exact path="/settings" component={InlineSettings} />
              <Route exact path="/settings/account" component={Acount} />
              <Route
                exact
                path="/settings/delete-account"
                component={DeleteAccount}
              />
              <Route
                exact
                path="/settings/change-password"
                component={ChangePassword}
              />
              <Route
                exact
                path="/settings/terms&conditions"
                component={TermsAndConditions}
              />
              <Route
                exact
                path="/settings/targets" 
                component={Targets} 
              />
             
              <Route
                exact
                path="/settings/myFiles" 
                component={UploadFile} 
              />
              
              <Route
                exact
                path="/settings/uploadFile" 
                component={ManualFileHeader} 
              />
              
              <Route
                exact 
                path="/settings/payment-information"
                component={PaymentInformation} 
              />
              
              <Route
                exact
                path='/settings/report'
                component={Reports}
              />
              
              <Route
                exact
                path='/settings/unsubscribe/:productId'
                component={Unsubscribe}
              />
            </ContentContainer>
            {loc?.location?.pathname === '/settings/uploadFile' &&
            <>
              {hasReview && 
             <div style={{ flexDirection: width < 1150 && 'column', paddingBlock: '0px', paddingLeft: 0, marginTop: -14, marginBottom: 16 }} className={width < 1150 ? 'contentContainerFile' : 'contentContainer'}>
               <ItemsInReview />
             </div>
              }
             
              <div style={{ flexDirection: width < 1150 && 'column' }} className={width < 1150 ? 'contentContainerFile' : 'contentContainer'}>
                <div className={classes.smallWrapper}>
                  <TabsForManualFiles
                    value={tabValue}
                    handleChange={handleChangeTab}
                    userUploadedFileTypeCount={userUploadedFileTypeCount}
                    activeFileTypesCount={activeFileTypesCount}
                  />
             
                  <TabPanel value={tabValue} index={0}>
                    <div className={classes.requestChange}>
                      {files?.files?.length > 0 && 
                        <div className={classes.refreshButton } onClick={() => getFilesAndHistory()}>
                          {(window.innerWidth > 960) ? <Text size='subnote'>Refresh</Text> : null}
                          <Refresh />
                        </div>
                      }
                    
                      {/* <SecondaryOutlinedButton 
                        disabled={userUploadedFileTypeCount === 0} 
                        className={classes.requestChangeButton}
                        onClick={() => setIsRequestFileChangeModalOpen(true)}
                        style={{
                          backgroundColor: userUploadedFileTypeCount === 0 ? colors.disabled : colors.white,
                          color: userUploadedFileTypeCount === 0 ? colors.white : colors.primary,
                        }}
                      >
                        Request file change
                      </SecondaryOutlinedButton> */}
                    </div>
                    {width > 1150  ?
                      <PaymentTableFiles
                      // error={error?.response?.data?.error?.message}
                        loading={files?.loading}
                        files={files?.files}
                        editRow={handleFileUpdate}
                      /> :
                      <PaymentListFiles
                      // error={error?.response?.data?.error?.message}
                        loading={files?.loading}
                        files={files?.files}
                        editRow={handleFileUpdate}
                      />
                    }
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}> 
                    <div className={classes.refresh}>
                      <DateRangePicker
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        callBack={getFilesHistory}
                        isMobile={width < 770}
                      />
                      {filesHistory?.files?.length > 0 && 
                       <div className={classes.refreshButton } onClick={() => getFilesAndHistory()}>
                         {(window.innerWidth > 960)  && <Text size='subnote'>Refresh</Text>}
                         <Refresh />
                       </div>
                      }
                     
                    </div>
                    {width > 1150  ?
                      <PaymentTable
                      // error={error?.response?.data?.error?.message}
                        loading={filesHistory.loading}
                        files={sliceFiles(filesHistory?.files, rowsPerPage)}
                        page={currentPage}
                        rowsPerPage={rowsPerPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        btnProps={ { onClick: () => goBackPage(tokenData[currentPage-1]) } }
                        nextIconButtonProps= { { onClick: ()=>handleChangePage(nextToken), disabled: nextToken === null ? true : false }}
                      /> :
                      <PaymentList
                      // error={error?.response?.data?.error?.message}
                        loading={filesHistory?.loading}
                        files={sliceFiles(filesHistory?.files, filesHistory?.limit)}
                        page={currentPage}
                        rowsPerPage={rowsPerPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        btnProps={ { onClick: () => goBackPage(tokenData[currentPage-1]) } }
                        nextIconButtonProps= { { onClick: ()=>handleChangePage(nextToken), disabled: nextToken === null ? true : false }}
                      />
                    }
                  </TabPanel>
                  <UploadFileModal isVisible={isModalOpen} cancel={() => setIsModalOpen(false)} onSuccess={() => getFilesAndHistory()} />
                  <UpdateFileUpload defaultValue={fileToEdit !== null && files?.files[fileToEdit]} isVisible={isUpdateModalOpen} cancel={() => setIsUpdateModalOpen(false)} onSuccess={() => getFilesAndHistory()} />
                  <RequestFileChangeModal isVisible={isRequestFileChangeModalOpen} cancel={() => setIsRequestFileChangeModalOpen(false)} onSuccess={() => getFilesAndHistory()} />
                </div>
              </div>
            </>
            }
          </div>
        </div>
      </Route>
    </Switch>
  )
}

export default Settings
