import React, { useState, useEffect } from 'react'
import Modal1 from '../../../Components/Modal/Modal'
import { makeStyles } from '@material-ui/core'
import { ClosePopup } from '../../../Themes/Images'
import { ImageModal } from '../../../Components/Modal'

const useStyles = makeStyles((theme) => ({
    closeZoomedImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 10,
        cursor: 'pointer',
    },
    zoomedImg: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        height: '100%', // Ensure the container takes up full height
    },
    img: {
        maxWidth: '100%',
        maxHeight: '80vh', // Set a maximum height for the image
        objectFit: 'contain', // Ensure the image scales without distortion
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto', // Make content scrollable if it overflows
    },
}))

function ZoomImageModal({ isZoomed, onClose, image_url }) {
    const [success, setSuccess] = useState(false)
    const classes = useStyles()

    const handleClose = () => {
    
        setSuccess(false)
        onClose()
    }

    // Add event listener for 'Esc' key press
    useEffect(() => {
        const handleKeyDown = (event) => {
          
            if (event.key === 'Escape') {
                handleClose()
            }
        }

        if (isZoomed) {
            document.addEventListener('keydown', handleKeyDown)
            
        }

        // Clean up event listener on component unmount or when isZoomed changes
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
           
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isZoomed])

    return (
        <ImageModal
            opened={isZoomed}
            onClose={onClose}
            type="noBlur"
            costumizedPaperStyles={success ? classes.paperCostum2 : classes.paperCostum}
        >
            <div className={classes.modalContent}>
                <div className={classes.closeZoomedImage} onClick={handleClose}>
                    <ClosePopup />
                </div>
                <div className={classes.zoomedImg}>
                    <img src={image_url} alt="Zoomed Image" className={classes.img} />
                </div>
            </div>
        </ImageModal>
    )
}

export default ZoomImageModal
