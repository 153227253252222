/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { colors } from '../../Themes'
import { Skeleton } from '@material-ui/lab'
import useStyles from './CardSkeleton.styles'

export default function CardSkeleton({  
  staticCard, 
  smallCard, 
  isEligible, 
  store,
}) {
  const classes = useStyles()

  const smallCardActions = (
    <CardActions style={{ flexDirection: 'column' }} className={classes.actionsRoot}>
      <div style={{ borderBottom: `1px solid ${colors.borderGray}` }} className={classes.smallCardActionWrapper}>
        <div className={classes.smallCardPriceTextWrapper}>
          <Skeleton classes={{ root: classes.root2 }} width={100} height={20} />
        </div>
        <div className={classes.smallCardActionTextWrapper}>
          <Skeleton classes={{ root: classes.root2 }} width={160} height={15} />
          <Skeleton classes={{ root: classes.root2 }} width={160} height={15} />
        </div>
      </div>
      <div className={classes.smallCardLastSection}> 
        <Skeleton classes={{ root: classes.root2 }} style={{ marginLeft: 170 }} width={100} height={15} />
      </div>
    </CardActions>
  )

  const cardActions = (
    <CardActions className={classes.actionsRoot}>
      <div className={classes.actionsWrapper}>
        <div className={classes.priceTextWrapper}>
          { !staticCard && <Skeleton classes={{ root: classes.root2 }} width={100} height={15} />}
        </div>
        { isEligible && 
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Skeleton classes={{ root: classes.root2 }} width={190} height={80} />
          </div>
        }
        {!isEligible &&  
          <div className={classes.upgradeNow}>
            <Skeleton classes={{ root: classes.root2 }} width={100} height={15} />
            <Skeleton classes={{ root: classes.root2 }} width={100} height={15} />
          </div>
        }
      </div>
    </CardActions>
  )
 
  return (
    <Card className={ `${classes.root} ${  classes.hover} ${smallCard && classes.smallCard}`}>
      <CardContent style={{ padding: 0 }}  >
        <div className={classes.contentInnerWrapper}  >
          <div className={ classes.imgWrapper}>
            <Skeleton classes={{ root: classes.root2 }} width={store ? 200 : 120} height={store ? 150 : 130} />
          </div>
          <div className={classes.contentTextWrapper}>
            <div className={classes.nameWrapper} >
              <Skeleton classes={{ root: classes.root2 }} width={150} height={20} />
            </div>
            <div style={{ flex: 3 }} className={classes.descriptionWrapper} >
              <Skeleton classes={{ root: classes.root2 }} width={200} height={15} />
              <Skeleton classes={{ root: classes.root2 }} width={150} height={15} />
            </div>
          </div>
        </div>
      </CardContent>
      {smallCard ? smallCardActions : cardActions } 
    </Card>
  )
}