import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Formik } from 'formik'
import toast from 'react-hot-toast'
import { Divider, Grid, IconButton, Tooltip, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core'
import { colors } from '../../Themes'
import {
  AccountAvatarInfo,
  AccountPersonalInformation,
  AccountClinicInformation,
  AccountLocationDetails,
  AccountFinancialDetails
} from '../../Components/Account'
import { PrimaryButton, SecondLoader, ChangeEmailAddressModal, ConfirmEmailModal, Text, TextInput } from '../../Components'
import PictureModal from '../../Components/Input/CropImageInput/PictureModal'
import { useAuth, useSubscription } from '../../Global'
import { Axios } from '../../Config'
import { ErrorToast, SuccessToast } from '../../Components/Notification/Notification'
import TooltipMobileModal from '../../Components/TooltipMobileModal'
import Fade from '@material-ui/core/Fade'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyIconWhite, Info, info } from '../../Themes/Images'
import useStyles from './Account.styles'
import Activate2FA from '../../Components/Activate2FA'
import ConfirmNumberModal from '../../Components/ConfirmNumberModal'
import TabsForProfile from '../../Components/TabsForProfile.js'
import { TabPanel } from '../../Components/TabsForProfile.js/TabsForProfile.js'
import AccountCurrencyInfo from '../../Components/Account/AccountCurrencyInfo/AccountCurrencyInfo.js'
import { SELECT_OPTIONS } from '../Wrapper/utils/data.js'
import moment from 'moment'


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: colors.primary,
    maxWidth: 800,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0px 3px 6px #00000029'
  },
}))(Tooltip)

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

const ReferalCode = ({ value, handleOpenModal, isMobile, isMobile2 }) => {
  const classes = useStyles()
  const [isCopied, setIsCopied] = useState(false)

  return (
    <div className={classes.referalWrapper}>
      <div className={classes.referalInfo}>
        <div>
          <Text style={{ textAlign: 'start', fontSize: isMobile2 && 20 }} color={colors.primary} weight='bold' size='body'>My Referral Code</Text>
        </div>
        <div>
          <HtmlTooltip style={{ cursor: isMobile ? 'pointer' : 'auto' }} TransitionComponent={Fade} TransitionProps={{ timeout: 500 }}
            onClick={isMobile ? handleOpenModal : null} interactive disableHoverListener={isMobile ? true : false}
            placement='right' arrow title={
              <div className={classes.textTooltip}>
                <Text size='subnote' weight='bold' color={colors.primary}>Invite your colleagues to use Dataplayer.</Text>
                <ul style={{ paddingLeft: 20 }}>
                  <li className={classes.marginBottom}><Text color={colors.primary} size='footnote'>When they upgrade, they can use your referral code to get 1 free month. </Text></li>
                  <li className={classes.marginBottom}><Text color={colors.primary} size='footnote'>Each time your referral code is used, you get 15 to 30 free days too.  </Text></li>
                  <li><Text color={colors.primary} size='footnote'>Share Dataplayer, grow your practice together! </Text></li>
                </ul>
              </div>
            }
          >
            <IconButton aria-label="info">
              <Info />
            </IconButton>
          </HtmlTooltip>
        </div>
      </div>
      <div className={classes.subtext}>
        <Text size='footnote' color='#878787'>Gift 1 extra month and get 15 to 30 free days for you!
        </Text>
      </div>
      <div className={classes.inputButton}>
        <div style={{ minWidth: 115 }}>
          <TextInput
            costumizedInputStyles={classes.inputCode}
            account
            // label="Referral code"
            hiddenLabel={true}
            value={value}
            readOnly={true}
          />
        </div>
        <div className={classes.button} >
          <Tooltip open={isCopied === true} title="Referral code copied to clipboard" placement="top" arrow>
            <CopyToClipboard text={value}
              onCopy={() => {
                setIsCopied(true)
                setTimeout(() => { setIsCopied(false) }, 3000)
              }}>
              <PrimaryButton style={{ paddingInline: 20, minHeight: 40, width: 117 }}>
                <React.Fragment>
                  <CopyIconWhite style={{ width: 22, height: 22, marginRight: 10 }} />
                  <Text size='footnote' color={colors.white} >
                    Copy
                  </Text>
                </React.Fragment>
              </PrimaryButton>
            </CopyToClipboard>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
function formatDate(date = new Date()) {
  // console.log(date)
  const formatedDate = moment(date).format('YYYY-MM-DD').toString()
  // console.log('🚀 ~ formatDate ~ formatedDate:', formatedDate)
  return formatedDate
}

function Account() {
  const classes = useStyles()
  const theme = useTheme()
  const { user, picture } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [userData, setUserData] = useState({})
  const [phoneNumber, setPhoneNumber] = useState('')

  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1))
  const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), 0, 1))

  const [pictureValues, setPicturesValues] = useState({
    url: picture,
    data: undefined,
  })
  const [changePhotoValues, setChangePhotoValues] = useState({
    isVisible: false,
    success: false
  })
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [is2FA, setis2FA] = useState('false')
  const [isSUbmitting2FA, setiSsSUbmitting2FA] = useState(false)
  const [currencyValue, setCurrencyValue] = useState([])
  const [country, setCountry] = useState([])
  const [timezone, setTimezone] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(true)

  const [isVisibleVerifyEmailPin, setIsVisibleVerifyEmailPin] = useState(false)
  const [isErrorConfirmEmail, setErrorConfirmEmail] = useState()
  const [emailVerifySuccess, setEmailVerifySuccess] = useState(false)
  const [changeEmailValues, setChangeEmailValues] = useState({
    isVisible: false,
    success: false
  })

  const [isNumberVerified, setIsNumberVerified] = useState(false)
  const [isVisibleVerifyNumberPin, setIsVisibleVerifyNumberPin] = useState(false)
  const [isErrorConfirmNumber, setErrorConfirmNumber] = useState()
  const [numberVerifySuccess, setNumberVerifySuccess] = useState(false)
  const [changeNumberValues, setChangeNumberValues] = useState({
    isVisible: false,
    success: false
  })
  const [tabValue, setTabValue] = useState(0)
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { is_trialing, plan: globalPlan } = useAuth()
  const { subscription, } = useSubscription()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs'))

  const free = globalPlan === 'free'

  const getUser = async () => {
    try {
      const { attributes } = await Auth.currentUserInfo()
      let { data } = await Axios.get('/prod/user')
      let newUserData = { ...attributes, ...data }
      // console.log(newUserData)
      setUserData(newUserData)
      setCurrencyValue(newUserData?.currency.currencyLabel)
      setCountry(newUserData.country)
      setTimezone(newUserData.timezone)
      setStartDate(newUserData.financial_year_start_date)
      setEndDate(newUserData.financial_year_end_date)
      setIsEmailVerified(attributes?.email_verified)
      // console.log(newUserData.data_loading)
      setIsDataLoading(newUserData.data_loading)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }


  const getUserCognito = async () => {
    try {
      const userToUpdate = await Auth.currentAuthenticatedUser()
      setis2FA(userToUpdate?.attributes['custom:enabledMFA'])
      setIsNumberVerified(userToUpdate?.attributes?.phone_number_verified)
      setPhoneNumber(userToUpdate?.attributes?.phone_number)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onActivate2FA = async () => {
    setiSsSUbmitting2FA(true)
    try {
      const userToUpdate = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(userToUpdate, {
        'custom:enabledMFA': 'true',
      })
      toast.custom((t) => (
        <SuccessToast t={t} message='2FA enabled' />
      ))
      setiSsSUbmitting2FA(false)
    } catch (err) {
      // if (err?.name==='LimitExceededException'){
      //   setErrorConfirmEmail(err.message)
      // }
      setiSsSUbmitting2FA(false)
      toast.custom((t) => (
        <ErrorToast t={t} message='Error!' />
      ))
      console.log(err)
    }
  }

  const onDisable2FA = async () => {
    setiSsSUbmitting2FA(true)
    try {
      const userToUpdate = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(userToUpdate, {
        'custom:enabledMFA': 'false',
      })
      setiSsSUbmitting2FA(false)
      toast.custom((t) => (
        <SuccessToast t={t} message='2FA disabled' />
      ))
    } catch (err) {
      // if (err?.name==='LimitExceededException'){
      //   setErrorConfirmEmail(err.message)
      // }
      setiSsSUbmitting2FA(false)
      toast.custom((t) => (
        <ErrorToast t={t} message='Error!' />
      ))
      console.log(err)
    }
  }

  useEffect(() => {
    getUser()
  }, [emailVerifySuccess])

  useEffect(() => {
    getUserCognito()
  }, [isSUbmitting2FA, numberVerifySuccess])

  const onVerifyEmail = async () => {
    try {
      await Auth.verifyCurrentUserAttribute('email')
      setIsVisibleVerifyEmailPin(true)
    } catch (err) {
      if (err?.name === 'LimitExceededException') {
        setErrorConfirmEmail(err.message)
      }
    }
  }

  const onVerifyNumber = async () => {
    try {
      await Auth.verifyCurrentUserAttribute('phone_number')
      setIsVisibleVerifyNumberPin(true)
    } catch (err) {
      if (err?.name === 'LimitExceededException') {
        setErrorConfirmNumber(err.message)
      }
    }
  }


  const { getUpdatedUser } = useAuth()

  const handleUpdateSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    try {
      const userToUpdate = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(userToUpdate, {
        name: values?.fullName || '',
        middle_name: values?.role || '',
        'custom:enabledMFA': values?.MFA?.toString() ?? undefined,
        phone_number: values?.phone_number || ''
      })
      toast.custom((t) => (
        <SuccessToast t={t} message='Your account has been updated successfully!' />
      ))
      await getUser()
      await getUserCognito()
      await getUpdatedUser()
      setSubmitting(false)
    } catch (err) {
      setErrors({ submit: err.message })
      setSubmitting(false)
      toast.custom((t) => (
        <ErrorToast t={t} message='Something went wrong. Try again!' />
      ))
    }
  }


  const handleUpdateSubmitTab2 = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {

    try {

      if (isDataLoading) {
        setErrors({ submit: 'Your data is still loading. Please wait!' })
        setSubmitting(false)
        toast.custom((t) => (
          <ErrorToast t={t} message='You can only make changes after your data is fully loaded!' />
        ))
        return
      }
      const startDate = new Date(values.financial_year_start_date)
      const endDate = new Date(values.financial_year_end_date)

      if (startDate > endDate) {
        setErrors({ submit: 'The financial year start date cannot be later than the end date.' })
        setSubmitting(false)
        toast.custom((t) => (
          <ErrorToast t={t} message='The fiscal year start date cannot be later than the end date.' />
        ))
        return
      }

      const userToUpdate = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(userToUpdate, {
        locale: values?.numberOfEmployees || '',
        nickname: values?.clinicName || ' ',
      })
      await Axios.post('/prod/user', {
        country: values?.country,
        timezone: values?.timezone || 'Australia/Perth',
        // This will be used later - sending hardcoded data for the moment
        // financial_year_end_date: formatDate(values.financial_year_end_date),
        // financial_year_start_date: formatDate(values.financial_year_start_date)
        financial_year_end_date: moment().endOf('year').format('YYYY-MM-DD'),
        financial_year_start_date: moment().startOf('year').format('YYYY-MM-DD')

      })
      toast.custom((t) => (
        <SuccessToast t={t} message='Your account has been updated successfully!' />
      ))
      await getUser()
      await getUserCognito()
      await getUpdatedUser()
      setSubmitting(false)
    } catch (err) {
      setErrors({ submit: err.message })
      setSubmitting(false)
      toast.custom((t) => (
        <ErrorToast t={t} message='Something went wrong. Try again!' />
      ))
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  if (isLoading) {
    return <SecondLoader />
  }

  let content = ''
  if (!isEmailVerified) {
    content = 'Verifying your email is essential to get all important account communications.'
  } else {
    content = 'To change your email, please make a request and we\'ll update it.'
  }

  return (
    <>
      <TabsForProfile
        value={tabValue}
        handleChange={handleChangeTab}
      />
      <TabPanel value={tabValue} index={0} style={{ width: '100%' }} >
        <Grid
          container
          style={{ maxWidth: '100%' }}
          setTavValue={setTabValue}
        >
          <Formik
            enableReinitialize
            initialValues={{
              fullName: userData?.name || '',
              email: userData?.email || '',
              role: userData?.middle_name || '',
              MFA: userData?.email_verified ?? '',
              phone_number: phoneNumber ?? '',
              // / +'' to parse to strinng
            }}
            onSubmit={handleUpdateSubmit}

          >
            {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values, dirty, isValid }) => {
              return (
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                  <div
                    className={(is_trialing || isEmpty(subscription) || free) ? classes.gridContainer3 : classes.gridContainer2}
                  >
                    <AccountAvatarInfo
                      user={user}
                      setChangePhotoValues={setChangePhotoValues}
                      value={pictureValues}
                    />

                  </div>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} className="dividerFullWidth" />
                  <Grid
                    container
                    className={classes.gridContainer}
                  >
                    <Typography
                      color="textPrimary"

                      variant="h6"
                    >
                      Personal Information
                    </Typography>
                    <AccountPersonalInformation
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      // handleChangeNumber={handleChangeNumber}

                      isEmailVerified={isEmailVerified}
                      changeEmailValues={changeEmailValues}
                      setChangeEmailValues={setChangeEmailValues}
                      isErrorConfirmEmail={isErrorConfirmEmail}
                      onVerifyEmail={onVerifyEmail}

                      isNumberVerified={isNumberVerified}
                      changeNumberValues={changeNumberValues}
                      setChangeNumberValues={setChangeNumberValues}
                      isErrorConfirmNumber={isErrorConfirmNumber}
                      onVerifyNumber={onVerifyNumber}
                      isDirty={dirty}
                      isUndefined={phoneNumber === undefined}
                      phoneNumber={phoneNumber}

                      isMobile={isMobile}
                    />
                  </Grid>
                  <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 24, maxWidth: 660
                  }}>
                    <div style={{ display: 'flex', marginRight: 8 }}>
                      <img width={20} height={20} src={info} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Text size='footnote'>
                        <Text className={classes.note} weight='medium' size='subnote'>Note: </Text>
                        {content}
                      </Text>
                    </div>
                  </div>
                  {(is_trialing || isEmpty(subscription) || free) ? null :
                    <>
                      <Divider style={{ marginBottom: 10, marginTop: 5 }} className="dividerFullWidth" />
                      <Grid
                        container
                        className={classes.gridContainer}
                      >
                        <div className={classes.verticalLine}></div>
                        <ReferalCode value={userData?.referralCode} handleOpenModal={handleOpenModal} isMobile={isMobile} isMobile2={isMobile2} />
                      </Grid>
                    </>
                  }
                  <Divider style={{ marginBottom: 10 }} className="dividerFullWidth" />

                  <Activate2FA enabled={is2FA === 'true'} onSubmit={is2FA === 'true' ? () => onDisable2FA() : () => onActivate2FA()} isEmailVerified={isEmailVerified} submitting={isSUbmitting2FA} />

                  <Divider style={{ marginBottom: 10 }} className="dividerFullWidth" />

                  <PrimaryButton
                    type='submit'
                    disabled={isSubmitting || !dirty || !isValid}
                    loading={isSubmitting}
                    classes={{
                      root: classes.saveButton,
                    }}
                    style={{
                      backgroundColor: isSubmitting || !dirty || !isValid ? colors.disabled : colors.primary,
                      color: colors.white
                    }}
                  >
                    Update
                  </PrimaryButton>
                </form>
              )
            }}
          </Formik>
          <PictureModal
            isVisible={changePhotoValues.isVisible}
            picture={pictureValues?.url}
            alt={user}
            setValue={setPicturesValues}
            onClose={
              () => setChangePhotoValues(prev => ({
                ...prev,
                isVisible: false,
              })
              )}
          />
          <ChangeEmailAddressModal
            isVisible={changeEmailValues.isVisible}
            cancel={
              () => setChangeEmailValues(prev => ({
                ...prev,
                isVisible: false,
              })
              )}
            onSuccess={
              () => setChangeEmailValues(prev => ({
                success: true,
                isVisible: false,
              })
              )}
          />
          <ConfirmEmailModal
            isVisible={isVisibleVerifyEmailPin}
            username={user}
            cancel={() => setIsVisibleVerifyEmailPin(false)}
            setEmailVerifySuccess={setEmailVerifySuccess}
          />
          <ConfirmNumberModal
            isVisible={isVisibleVerifyNumberPin}
            username={user}
            cancel={() => setIsVisibleVerifyNumberPin(false)}
            setNumberVerifySuccess={setNumberVerifySuccess}
          />
          <TooltipMobileModal
            isVisible={isModalOpen}
            cancel={handleCloseModal}
          />

        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1} style={{ width: '100%' }}>
        <Grid container
          style={{ maxWidth: '100%' }}
          setTavValue={setTabValue}>
          <Formik
            enableReinitialize
            initialValues={{
              clinicName: userData?.nickname || '',
              numberOfEmployees: userData?.locale || '',
              country: userData?.country || '',
              timezone: userData?.timezone || '',
              financial_year_end_date: userData?.financial_year_end_date || '',
              financial_year_start_date: userData?.financial_year_start_date || ''
            }}
            onSubmit={handleUpdateSubmitTab2}
          >

            {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values, dirty, isValid }) => {
              return (
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                  <Grid container className={classes.gridContainer}>
                    <AccountClinicInformation
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isMobile={isMobile}
                    />
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Business Location
                    </Typography>
                    <AccountLocationDetails
                      values={values}
                      // countryValue={country}
                      // timezoneValue={timezone}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                    />
                  </Grid>
                  <Divider style={{ marginBottom: 10 }} className={classes.dividerFullWidth} />
                  {/* <Grid
                    container
                    direction='column'
                    className={classes.gridContainer}
                  >
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h6"
                    >
                      Fiscal Year
                    </Typography>
                    <Text size='footnote' style={{ marginBottom: 10 }}>Define the fiscal year for the metrics in the fiscal year tab.</Text>
                     <br style={{ height: 6 }} /> 
                    <AccountFinancialDetails
                      values={values}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                    />
                  </Grid>
                  <Divider style={{ marginBottom: 10, marginTop: 16 }} className="dividerFullWidth" /> */}
                  <Grid
                    container
                    direction='column'
                    className={classes.gridContainer}
                  >
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h6"
                    >
                      Currency
                    </Typography>
                    <Text size='footnote'>This is the currency your business operates in, as defined when you created your account.</Text>
                    <AccountCurrencyInfo
                      values={currencyValue}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                    />
                  </Grid>
                  <Divider style={{ marginBottom: 10 }} className="dividerFullWidth" />

                  <PrimaryButton
                    type='submit'
                    disabled={isSubmitting || !dirty || !isValid}
                    loading={isSubmitting}
                    classes={{
                      root: classes.saveButton,
                    }}
                    style={{
                      backgroundColor: isSubmitting || !dirty || !isValid ? colors.disabled : colors.primary,
                      color: colors.white
                    }}
                  >
                    Update
                  </PrimaryButton>
                </form>
              )
            }}
          </Formik>
          <TooltipMobileModal
            isVisible={isModalOpen}
            cancel={handleCloseModal}
          />
        </Grid>
      </TabPanel>
    </>
  )
}

export default Account
