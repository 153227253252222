import React from 'react'
import clsx from 'clsx'
import {
  SwipeableDrawer as MuiDrawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from '@material-ui/core'
import useStyles from './Drawer.styles'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Text from '../Text'
import { colors } from '../../Themes'
import { useAuth, useMenu } from '../../Global'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'

function Drawer({ isExpanded, isVisible, children, ...rest }) {
  const classes = useStyles()

  return (
    <MuiDrawer
      open={isVisible}
      BackdropProps={{ invisible: true }}
      className={clsx(classes.drawer, {
        [classes.drawerBgOpen]: isExpanded,
        [classes.drawerBgClose]: !isExpanded,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isExpanded,
          [classes.drawerClose]: !isExpanded,
        }),
      }}
      {...rest}
    >
      {children}
    </MuiDrawer>
  )
}

function DrawerListItem({
  index,
  title,
  Icon,
  isActive,
  withTitle,
  onClick,
  component,
  to,
}) {
  return (
    <ListItem
      button
      key={index + title}
      onClick={onClick}
      component={component}
      to={to}
      className={!isActive ? 'itemInactive' : undefined}
      disableGutters
    >
      <ListItemIcon className="listItemIcon">
        <Icon className="listIcon" />
      </ListItemIcon>
      {withTitle && (
        <span
          className={
            isActive ? 'listItemTitle listItemTitleActive' : 'listItemTitle'
          }
        >
          {title}
        </span>
      )}
    </ListItem>
  )
}

function DrawerNestedListItem({
  index,
  title,
  Icon,
  isActive,
  withTitle,
  onClick,
}) {
  const classes = useStyles()

  return (
    <>
      <ListItem
        button
        key={title + index}
        onClick={onClick}
        className={!isActive ? 'itemInactive' : 'itemInactive'}
        disableGutters
      >
        <ListItemIcon className="listItemIcon">
          <Icon className="listIcon" />
        </ListItemIcon>
        {withTitle && (
          <span
            className={isActive ? 'listItemNestedTitle' : 'listItemNestedTitle'}
          >
            {title}
          </span>
        )}
      </ListItem>
    </>
  )
}


function DrawerNestedListItemDashboards({
  index,
  title,
  Icon,
  open,
  isActive,
  withTitle,
  onClick,
  id,
  items,
  component,
  to,
}) {
  const classes = useStyles()
  const history = useHistory()

  const {
    additional_dashboards,
    user
  } = useAuth()

  const { toggleDrawer } = useMenu()

  const location = history?.location?.pathname

  const isActiveDashboard = location === '/dashboard'


  const DashbordList = ({ idd, name }) => {
    return (
      additional_dashboards?.map((item) => {
        const active = location?.includes(item.dashboard_id)
        return (
          <ListItem  key={item.dashboard_id} style={{ borderRadius: 5, cursor: 'pointer', paddingLeft: 30, background: active && colors.background }} 
            button
            onClick={() =>
            {
              history.push('/dashboard/' + item.dashboard_id)
              toggleDrawer()
            }
            }
              
          >
                   
            <Text size='footnote' color={colors.primary} weight={active ? 'bold' : 'regular'} >{item.dashboardName}</Text>
          </ListItem>
        )
      })
      
    )
    
  }

  return (
    <>
      
      <ListItem
        id={id}
        button
        key={title + index}
        onClick={onClick}
        className={!isActive ? 'itemInactive' : 'itemInactive'}
        disableGutters
      >
        <ListItemIcon className="listItemIcon">
          <Icon className="listIcon" />
        </ListItemIcon>
        {withTitle && (
          <span
            className={isActive ? 'listItemNestedTitle' : 'listItemNestedTitle'}
          >
            {title}
          </span>
        )}
        {withTitle && [
          open ? (
            <ExpandLess
              key={11}
              style={{
                position: 'absolute',
                right: 10,

                color: colors.accent,
              }}
            />
          ) : (
            <ExpandMore
              key={11}
              style={{ position: 'absolute', right: 10, color: colors.accent }}
            />
          ),
        ]}
      </ListItem>
      <Collapse
        style={{ padding: '0px 25px ' }}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {withTitle &&
          <List style={{ overflowY: 'auto', height: additional_dashboards?.length === 2 ? 110 : 170 }} >

            <ListItem  key={1} style={{ borderRadius: 5, cursor: 'pointer', paddingLeft: 30, background: isActiveDashboard && colors.background }}   button
              onClick={() =>
              {
                history.push('/dashboard')
                toggleDrawer()
              }
                
              }
            >
              <Text size='footnote' color={colors.primary} weight={isActiveDashboard ? 'bold' : 'regular'} >{user}</Text>
            </ListItem>

            <DashbordList />
          </List>
        }
      </Collapse>
    </>
  )
}

export default Drawer
export { DrawerListItem, DrawerNestedListItem, DrawerNestedListItemDashboards }
