import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    height: 104,
    backgroundColor: colors.white,
    color: colors.white,
    marginBottom: 24,
    marginInline: 25,
    boxShadow: 'none',
    border: '1px solid #E2E2E2',
    borderRadius: 5,
  },
  cardContentRoot: {
    display: 'flex',
    padding: '18px 12px 18px 0px',
    paddingBottom: '18px !important',
    marginLeft: 0,
    whiteSpace: 'normal',  
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    [theme.breakpoints.down(760)]: {
      paddingLeft: 125,
      paddingTop: 18,
    },
    [theme.breakpoints.down(500)]: {
      paddingLeft: 45,
      paddingTop: 23,
      paddingRight: 15
    },
    [theme.breakpoints.down(420)]: {
      paddingRight: 5
    },
  },
  upperText: {
    paddingBottom: 8,
  },
  link: {
    color: colors.purple,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  image: {
    marginTop: -5
  }
}))

export default useStyles