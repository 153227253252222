import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'
import { DRAWER_WIDTH } from '../../Themes/Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
  drawerOpen: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: colors.white,
    position: 'absolute',
    bottom: 64,
    left: DRAWER_WIDTH - 20,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 1250,
    '&:hover': {
      backgroundColor: colors.background,
    },
  },
  drawerClose: {
    padding: 0,
    width: 40,
    height: 40,
    backgroundColor: colors.white,
    position: 'absolute',
    bottom: 64,
    left: 52.5,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1250,
    '&:hover': {
      backgroundColor: colors.background,
    },
  },
  iconButton: {
    marginRight: 10,
  },
  iconButtonMobile: {
    marginRight: 10,
    marginLeft: 10,
  },
  menuCompanyInfo: {
    display: 'flex',
    margin: '0 10px 0px 25px',
    marginBottom: 30,
    alignItems: 'center'
  },
  menuCompanyInfo1: {
    display: 'flex',
    marginBottom: 30,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: 25,
    },
  },
  companyLogo: {
    minWidth: 53,
    maxWidth: 100,
    height: 100,
    objectFit: 'contain',
    cursor: 'pointer'
  },
  text: {
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  text1: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // Modal
  paperCostum: {
    padding: '50px 70px',
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  inputContainer: {
    margin: '15px 0',
  },
  firstTitle: {
    fontSize: 25,
    color: colors.accent,
    marginBottom: 22,
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  helperMessage: {
    fontSize: 15,
    marginBottom: 22,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  input_true: {
    border: '1px solid ' + colors.secondary,
    flexGrow: 1,
    borderRadius: 5,
    marginTop: 15,
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  errorInput: {
    color: colors.secondary,
    padding: '0 12px',
    fontSize: 16,
    marginBottom: 10,
  },
  // Modal Confirm
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 20,
    },
  },
  cancelButton: {
    flex: '0 0 40%',
    backgroundColor: colors.white,
    color: colors.primary,
    '&:hover': {
      backgroundColor: colors.grayHover,
    },
  },
  nextButton: {
    flex: '0 0 40%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 12
    },
  },
}))

export default useStyles