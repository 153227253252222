import React from 'react'
import {  TextInput, Text, PrimaryButton } from '../..'
import {  Grid } from '@material-ui/core'
import useStyles2 from './AccountClinicInformation.styles'

function AccountClinicInformation({ values, handleChange, handleBlur, isMobile }) {
  const classes=useStyles2()

  return (
    <Grid
      item
      lg={12}
      md={12}
      xl={12}
      xs={12}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 2}
      >
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          xs={12}>
          <TextInput
            costumizedInputStyles={classes.input}
            label='Clinic name'
            name='clinicName'
            value={values.clinicName}
            onBlur={handleBlur}
            onChange={handleChange}
            // disabled
          />
        </Grid>
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          xs={12}>
          <TextInput
            costumizedInputStyles={isMobile ? classes.input2 : classes.input}
            label='Number of employees'
            name='numberOfEmployees'
            value={values.numberOfEmployees}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AccountClinicInformation
