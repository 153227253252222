/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ManuallyDataEnterTable, NumberInput, PrimaryButton, SecondaryButton, SimpleDatePicker, SuccessNotify, Text } from '../../Components'
import { colors } from '../../Themes'
import { Axios } from '../../Config'
import { SliderInput } from '../../Components/Input'
import clsx from 'clsx'
const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  fistWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 62,
    [theme.breakpoints.down('sm')]: {
      width: '100%',

    },
  },
  space: {
    margin: '0px 0 24px'
  },
  scoreInput: {
    maxWidth: '45%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
  
    },
  },
  button: {
    maxWidth: '45%',
    marginTop: 32,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
  
    },
  },
  secondWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  selectRangeWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    display: 'flex',
    margin: '16px 0 24px '
  },
  selectRangeButtonActive: {
    flex: '0 0 40%',
    maxWidth: 150,
    borderRadius: '50px'
  },
  selectRangeButton: {
    flex: '0 0 40%',
    maxWidth: 150,
    borderRadius: '50px',
    backgroundColor: colors.white,
    color: colors.primary,
    border: '1px solid' + colors.primary,
    '&:hover': {
      backgroundColor: colors.drawer
    }
  }
}))
function NpsScore() {
  const classes = useStyles()
  const [date, setDate] = useState(new Date(new Date().getFullYear(), 0, 1))
  const [score, setScore] = useState('')
  const [npsHistory, setNpsHistory]=useState([])
  const [isCreating, setIsCreating] = useState(false)
  const [isCreated, setIsCreated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [paginationValues, setPaginationValues] = useState({
    current_page: 1,
    more: false,
    next: '',
    per_page: 5,
    previus: null
  })
  const [rangeValues, setRangeValues] = useState({
    isHiden: true,
    max: 10,
    buttonIndex: -1
  })


  
  const onDelete=async (id)=>{
    setIsDeleting(true)
    try { 
      await Axios.delete(`/prod/nps/${id}`)
      setIsDeleting(false)

      getHistory()
    } catch (error) {
      setIsDeleting(false)

    }
  }

  const  onSave= async ()=>{
    setIsCreating(true)
    let body= {
      data: [
        {
          created_at: date,
          added_at: new Date().toISOString(),
          score: parseInt(score)
        }
      ]
    }
    try { 
      await Axios.post('/prod/nps', body )
      getHistory()
      setScore('')
      setIsCreated(true)
      setIsCreating(false)
    } catch (error) {
      setIsCreating(false)
    }
  }


  const getHistory= async()=>{
    const { current_page, per_page }=paginationValues
    setIsLoading(true)
    try {
      const { data }=  await Axios.get(`/prod/nps?page=${current_page}&per_page=${per_page}`)
      setNpsHistory(data?.data)
      setPaginationValues((prev) => {
        const next = {
          ...prev,
          more: data?.meta?.pagination?.more,
          previus: data?.meta?.pagination?.previus,

        }
        return next
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }


  const handleOnButtonClick=(buttonIndex)=>{
    if (buttonIndex===0){
      setRangeValues({
        isHiden: false,
        max: 10,
        buttonIndex: buttonIndex
      })
    } else {
      setRangeValues({
        isHiden: false,
        max: 100,
        buttonIndex: 1
      })
    }
    setScore('')
  }

  useEffect(() => {
    getHistory()

  }, [paginationValues.current_page, paginationValues.per_page])




  return (
    <div className={classes.container}>
      <div className={classes.fistWrapper}>
        {/* <Text size="h5" weight='bold'>Net Promoter Score</Text> */}
        {/* <Text className={classes.space}  size="body" weight='bold' color={colors.accent}>Enter new NPS score here</Text> */}
        <Text  size="subnote" weight='regular'>Select the date that the score was created in your NPS software</Text>
        <SimpleDatePicker 
          date={date}
          setDate={setDate}
        />
        <div className={classes.selectRangeWrapper}>
          <Text  size="subnote" weight='regular'>Select the scale of the NPS score</Text>
          <div className={classes.buttonWrapper}>
            <PrimaryButton 
              className={rangeValues.buttonIndex===0? classes.selectRangeButtonActive:classes.selectRangeButton}
              onClick={()=>handleOnButtonClick(0)} >
            1-10
            </PrimaryButton>
            <div style={{ width: 16 }}/>
            <PrimaryButton  
              className={rangeValues.buttonIndex===1? classes.selectRangeButtonActive:classes.selectRangeButton}
              onClick={handleOnButtonClick}>
            1-100
            </PrimaryButton>
          </div>
       
        </div>
        {!rangeValues.isHiden && 
        <>
          <Text  size="subnote" weight='regular'>Enter the score for the date selected above</Text>
          <SliderInput
            value={score}
            setValue={setScore}
            max={rangeValues.max}
          />
        </>
        }
     

        <SecondaryButton
          className={classes.button}
          disabled={score===''|| isCreating}
          loading={isCreating}
          onClick={onSave}
        >
            Save score
        </SecondaryButton>
      </div>
      <div className={classes.secondWrapper}>
        <Text size="h5" weight='bold'>NPS History</Text>
        <ManuallyDataEnterTable
          data={npsHistory}
          loading={isLoading} 
          onDelete={onDelete}
          isDeleteLoading={isDeleting}
          {...paginationValues}
          setPaginationValues={setPaginationValues}
        />
      </div>
      <SuccessNotify open={isCreated} setOpen={setIsCreated} message='NPS entered successfully' /> 
    </div>
  )
}

export default NpsScore
