import React from 'react'
import { Password, PrimaryButton, RenderPasswordCondition, Text } from '../../Components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'react-router-dom'
import { colors } from '../../Themes'
import useStyles from './NewPassword.styles'

function NewPassword({
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  error,
  submitChanges,
  validatePassowrd,
  setValidatePassword,
  onChangePassword
}) {
  const classes = useStyles()

  return (
    <>
      <h1 style={{ color: colors.accent, marginBottom: 10 }}>Set new password</h1>
      {/* <span className={classes.description}>
        Enter the email connected to this account and we will send you an email
        with the instructions to reset your password.
      </span> */}
      <Password
        label="New password"
        value={newPassword}
        setValue={onChangePassword}
        onFocus={() =>
          setValidatePassword((prev) => ({
            ...prev,
            focused: true,
            isVisible: true,
          }))
        }
        onBlur={() =>
          setValidatePassword((prev) => ({
            ...prev,
            focused: false,
          }))
        }
      />
      {validatePassowrd?.isVisible && <RenderPasswordCondition validatePassowrd={validatePassowrd} />}
      <Password
        label="Confirm new password"
        value={confirmPassword}
        setValue={setConfirmPassword}
      />
      {Boolean(error) && <span className="loginError">{error}</span>}
      <Text size='small' color='#666'>After you confirm changing your password, your account will be
        logged out of any device you were primarily logged in.</Text>
      <PrimaryButton
        style={{ marginTop: 30, backgroundColor: colors.primary, color: colors.white }}
        className={classes.loginButton}
        onClick={submitChanges}
      >
        Save
      </PrimaryButton>
      <Link
        style={{
          display: 'flex',
          alignSelf: 'flex-start',
          textDecoration: 'none',
          color: colors.secondary,
          fontWeight: 700,
          marginTop: 15,
          alignItems: 'center',
        }}
        to="/login"
      >
        <ArrowBackIcon classes={{ root: classes.rootBackIcon }} />
        <span className="logoutDrawer">Back to Login</span>
      </Link>
    </>
  )
}

export default NewPassword
