import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const referalEnterUpgradeStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    maxWidth: 1300,
    borderRadius: 5,
    border: '1px solid #C9C9C9',
    padding: 24,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginInline: 'auto',
    [theme.breakpoints.down(760)]: {
      flexDirection: 'column',
      gap: 24,
      alignItems: 'start',
      // width: '100%'
    },
  },
  wrapperSuccess: {
    display: 'flex',
    maxWidth: 1300,
    borderRadius: 5,
    border: '1px solid #C9C9C9',
    paddingBlock: 8,
    paddingInline: 24,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginInline: 'auto',
    gap: 60,
    [theme.breakpoints.down(1500)]: {
      paddingBlock: 10,
    },
    [theme.breakpoints.down('sm')]: {
      paddingBlock: 15,
    },
    [theme.breakpoints.down(760)]: {
      flexDirection: 'column',
      gap: 24,
      alignItems: 'start',
      padding: 24,
      paddingBottom: 16,
      marginInline: 'auto',
      minWidth: '91%'
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      gap: 24,
      alignItems: 'start',
      padding: 24,
      paddingBottom: 16,
      marginInline: 'auto',
      minWidth: '89%'
    },
    [theme.breakpoints.down(400)]: {
      flexDirection: 'column',
      gap: 24,
      alignItems: 'start',
      padding: 24,
      paddingBottom: 16,
      marginInline: 'auto',
      minWidth: '85%'
    },
  },
  bigContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down(760)]: {
      alignItems: 'start',
      maxWidth: '100%',
   
    },
  },
  text: {
    width: '70%',
    [theme.breakpoints.down(760)]: {
      width: '100%',
    },
  },
  button: {
    minWidth: 250,
    [theme.breakpoints.down(760)]: {
      minWidth: 150,
    },
    [theme.breakpoints.down(500)]: {
      minWidth: '100%',
    },
  },
  divider: {
    width: '100%',
    background: '#C9C9C9',
    height: 1,
    marginBlock: 40
  },
  afterSuccessNote: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    justifyContent: 'center',
    maxWidth: 500
  },
  afterSuccessSubNote: {
    display: 'flex',
    gap: 10
  },
  // second step //
  inputDiv: {
    display: 'flex',
    alignItems: 'center',
    // height: 56,
    // [theme.breakpoints.down(760)]: {
    //   maxWidth: 300
    // },
  },
  applyButton: {
    minHeight: 58, 
    width: 80,
    borderBottomLeftWidth: '0px !important',
    borderBottomLeftRadius: '0px !important',
    borderTopLeftWidth: '0px !important',
    borderTopLeftRadius: '0px !important',
  },
  input: {
    borderBottomRightWidth: '0px !important',
    borderBottomRightRadius: '0px !important',
    borderTopRightWidth: '0px !important',
    borderTopRightRadius: '0px !important',
    height: 56, 
  },
  errorDiv: {
    display: 'flex', 
    alignItems: 'center',
    height: 21
  },
  errorICon: {
    width: 20, 
    height: 20, 
    marginRight: 5
  },
  choosePlanContainer: {
    display: 'flex',
    flexDirection: 'column', 
    width: '100%',
    position: 'relative', 
    marginBlock: 40
  },
  choosePlanText: {
    display: 'flex', 
    textAlign: 'center',
    border: '1px solid #fff', 
    borderRadius: 50, 
    background: '#fff', 
    paddingBlock: 25, 
    paddingInline: 30,
    width: ' 180px', 
    position: 'absolute', 
    top: 0,
    left: '40%',
    justifyContent: 'center',
    [theme.breakpoints.down(1500)]: {
      left: '35%',
      paddingBlock: 25, 
    },
    [theme.breakpoints.down('sm')]: {
      left: '37%',
      paddingBlock: 30, 
    },
    [theme.breakpoints.down(760)]: {
      left: '30%',
      paddingBlock: 30, 
    },
    [theme.breakpoints.down(550)]: {
      left: '20%',
      paddingBlock: 30, 
    },
    [theme.breakpoints.down(400)]: {
      left: '15%',
      paddingBlock: 30, 
      paddingInline: 20,
    },
    [theme.breakpoints.down(360)]: {
      left: '12%',
      paddingBlock: 30, 
    },
  }
}))

export default referalEnterUpgradeStyles