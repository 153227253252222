import { colors } from '../../../Themes'

const selectStyles = {
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    borderRadius: 5,
    zIndex: 999 
  }),
  container: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    minHeight: 50,
    margin: '15px 0',
    borderRadius: 5,
    '&:hover': {
      border: `1px solid ${colors.accentHover}`,
    },
    border: '0px solid lightgray',
    boxShadow: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: colors.background,
      color: colors.primary,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: colors.backgroundHover,
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : colors.white),
      },
    }
  },
}

export default selectStyles