import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const tableCellStyles = {
  head: {
    backgroundColor: colors.white,
    color: colors.primary,
    fontSize: 18
  },
  body: {
    fontSize: 16,
    color: colors.primary,
    padding: '24px 16px'
  },
}
  
const tableRowStyles = {
  root: {
    backgroundColor: colors.white,
  },
}

const useStylesTable = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  container: {
    marginTop: 25,
    boxShadow: 'none'
  },
  showMore: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    color: colors.accent,
    cursor: 'pointer'
  },
  paymentId: {
    color: colors.accent,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  statusPaid: {
    padding: '4px 15px',
    backgroundColor: 'transparent',
    color: '#12C730 !important ',
    border: '1px solid #12C730',
    borderRadius: 22
  },
  statusFailed: {
    padding: '4px 15px',
    backgroundColor: 'transparent',
    color: '#EE4352 !important ',
    border: '1px solid #EE4352',
    borderRadius: 22
  },
  copyBase: {
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.white,
    borderRadius: 10,
    flexShrink: 0,
    '&:hover': {
      backgroundColor: colors.grayHover,
    },
  },
  afterCopied: {
    position: 'absolute',
    width: 250,
    top: -20,
  },
  caption: {
    textAlign: 'center'
  },
  margin: {
    marginTop: 32 
  },
  tableFont: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  successBadge: {
    paddingBlock: 5,
    paddingInline: 10,
    backgroundColor: colors.white,
    color: '#11C32F',
    border: '1px solid #11C32F',
    fontSize: 12,
    fontFamily: 'Roboto',
    borderRadius: 50,
    textAlign: 'center'
  },
  errorBadge: {
    paddingBlock: 5,
    paddingInline: 10,
    backgroundColor: colors.white,
    color: '#EE4352',
    border: '1px solid #EE4352',
    fontSize: 12,
    fontFamily: 'Roboto',
    borderRadius: 50,
    textAlign: 'center'
  },
  inReview: {
    paddingBlock: 5,
    paddingInline: 10,
    backgroundColor: colors.white,
    color: '#78A9FF',
    border: '1px solid #78A9FF',
    fontSize: 12,
    fontFamily: 'Roboto',
    borderRadius: 50,
    textAlign: 'center'
  },
  paused: {
    paddingBlock: 5,
    paddingInline: 10,
    backgroundColor: colors.white,
    color: '#AAAAAA',
    border: '1px solid #AAAAAA',
    fontSize: 12,
    fontFamily: 'Roboto',
    borderRadius: 50,
    textAlign: 'center'
  },
  cancelled: {
    paddingBlock: 5,
    paddingInline: 10,
    backgroundColor: colors.white,
    color: '#AAAAAA',
    border: '1px solid #AAAAAA',
    fontSize: 12,
    fontFamily: 'Roboto',
    borderRadius: 50,
    textAlign: 'center'
  },
  update: {
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'flex',
    gap: 10
  },
  noUpdate: {
    visibility: 'hidden'
  },
  statusPadding: {
    [theme.breakpoints.between(1150, 1300)]: {
      paddingInline: '0px !important'
    },
  }
}))

export { tableCellStyles, tableRowStyles, useStylesTable }