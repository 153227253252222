import React, { useMemo, useState } from 'react'
import Loading from 'react-lottie'
import { withStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, TablePagination } from '@material-ui/core'
import { colors } from '../../../Themes'
import { empty, CopyIcon } from '../../../Themes/Images'
import { Pressable, Text } from '../..'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment'
import SkeletonTable from '../../SkeletonTable'
import { tableCellStyles, tableRowStyles, useStylesTable } from './PaymentsTableForAddons.styles'

const EMPTY = {
  loop: true,
  autoplay: true,
  animationData: empty,
  rendererSettings: {
    className: 'loading',
  },
}

const CURRENCY = {
  USD: '$',
}

const StyledTableCell = withStyles((theme) => (tableCellStyles))(TableCell)

const StyledTableRow = withStyles((theme) => (tableRowStyles))(TableRow)

export default function PaymentsTableForAddons({ 
  payments, 
  onLoadMore, 
  showMore, 
  openReceipt, 
  loading, 
  rowsPerPage, 
  page, 
  handleChangePage, 
  handleChangeRowsPerPage, 
  rowsPerPageOptions, 
  btnProps,
  nextIconButtonProps,
  error
}) {
  const classes = useStylesTable()
  const [isCopied, setIsCopied] = useState(null)

  const sortedPayments = useMemo(() => payments.sort(((a, b) => {
    return new Date(b.eventTime) - new Date(a.eventTime)
  })), [payments])

  if (loading) {
    return (
      <SkeletonTable purchase={true} />
    )
  }

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        {showMore && <caption className={classes.caption} onClick={onLoadMore} ><Text size='footnote' color={colors.accent} className={classes.showMore} > Show More</Text> </caption>}
        <TableHead>
          <TableRow className={classes.tableFont}>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="left">Purchase</StyledTableCell>
            <StyledTableCell align="left">Payment ID</StyledTableCell>
            <StyledTableCell align="left">Amount</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPayments.length > 0 && sortedPayments.map((payment, index) =>
            (
              <StyledTableRow key={payment?.id}>
                <StyledTableCell component="th" scope="row">
                  <Text 
                    size='footnote' 
                    weight="medium">{moment(payment?.eventTime).format('LL')}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text 
                    size='footnote' 
                    weight="medium" 
                    color={colors.primary} 
                  >  
                    {payment?.productName}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text 
                    size='footnote' 
                    weight="medium" 
                    color={payment?.isPaid === 1 ? colors.accent : colors.primary} 
                    className={payment?.isPaid === 1 ? classes.paymentId : null} 
                    onClick={() => openReceipt(payment?.receiptUrl)} 
                  >  
                    {payment?.paymentId}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text size='footnote'  >
                US  {CURRENCY?.[payment?.currency]}{payment?.saleGross}
                  </Text>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {payment?.isPaid === 1 ?
                    <Text size='footnote' className={classes.statusPaid}>
                    Paid
                    </Text> :
                    <Text size='footnote' className={classes.statusFailed} >
                    Failed
                    </Text>
                  }
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip key={payment?.id} open={index === isCopied} title="Receipt url copied to clipboard" placement="top" arrow>
                    <CopyToClipboard
                      onCopy={() => {
                        setIsCopied(index)
                        setTimeout(() => { setIsCopied(null) }, 3000)
                      }} text={payment?.receiptUrl}>
                      <Pressable classes={{
                        root: classes.copyBase
                      }}
                      disabled={payment?.is_paid === 0}
                      >
                        <CopyIcon className="copyIcon" />
                      </Pressable>
                    </CopyToClipboard>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {sortedPayments.length !== 0 && 
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ page } ) =>  'Page ' + (page+1)}
        backIconButtonProps={btnProps}
        nextIconButtonProps={nextIconButtonProps}
      />}
      {
        sortedPayments.length === 0 && !error &&
        <div className="loadingContainer">
          <Text size='footnote' color={colors.grey} className={classes.margin} >No items found</Text>
          <Loading options={EMPTY} height={180} />
        </div>
      }
    </TableContainer>
  )
}