import React, { useCallback, useMemo, useState } from 'react'
import Loading from 'react-lottie'
import moment from 'moment'
import {
  ListItem,
  List,
  TablePagination,
} from '@material-ui/core'
import { UpdateManual, empty } from '../../Themes/Images'
import { Text } from '..'
import { colors } from '../../Themes'
import { useStyles } from './PaymentList.styles'
import { SkeletonListFileManualFile } from '../SkeletonList/SkeletonList'
import updatedReportName from '../../Utils/uppercaseFIrstLetter'

const EMPTY = {
  loop: true,
  autoplay: true,
  animationData: empty,
  rendererSettings: {
    className: 'loading',
  },
}

function PaymentListFiles({ 
  files, 
  loading,
  rowsPerPage, 
  page, 
  handleChangePage, 
  handleChangeRowsPerPage, 
  rowsPerPageOptions, 
  btnProps,
  nextIconButtonProps,
  error,
  editRow
}) {
  const classes = useStyles()
  const [show, setShow] = useState(0)

  const sortedFiles = useMemo(() => files?.sort(((a, b) => {
    return new Date(b.uploadDate) - new Date(a.uploadDate)
  })), [files])

  const handleShow = useCallback((id) => {
    // setCurrentId(id)
    setShow(id)
  }, [])

  if (loading) {
    return (
      <SkeletonListFileManualFile />
    )
  }

  if (sortedFiles?.length === 0) {

    return (
      <div className="loadingContainer">
        <Text size='footnote' color={colors.grey} className={classes.margin} >No items found</Text>
        <Loading options={EMPTY} height={180} />
      </div>
    )
  }

  return (
    <List classes={{ root: classes.listRoot }}>
      {sortedFiles?.length > 0 && sortedFiles?.map((file) => (
        <ListItem key={file.id} classes={{ root: classes.listItemRoot }} >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div className={classes.firstWrapper}>
              <Text size="body" weight='bold'>{file?.fileName}</Text>
              <Text size="body">{updatedReportName(file?.reportName)} </Text>
              <span className={file?.status === 'ERROR' ? classes.errorBadge : file?.status === 'ACTIVE' ?  classes.successBadge : file?.status === 'IN REVIEW' ? classes.inReview : 
                file?.status === 'PAUSED' ? classes.paused : file?.status === 'CANCELLED' ? classes.cancelled : ''}>{file?.status === '_' ? '' : updatedReportName(file?.status)}</span>
            </div>
            <div className={classes.secondWrapper}>
              <div className={file?.status === 'ACTIVE' ? classes.update : classes.noUpdate} onClick={file?.status === 'ACTIVE' ? () => editRow(file) : null}>
                <UpdateManual />
                <Text 
                  size='body' 
                  weight="normal" 
                  color={colors.primary} 
                >  
                    Update
                </Text>
              </div>
            </div>
          </div>
          {show === file?.id && 
          <div id='showMore' key={file?.id} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div className={classes.border2} />
            <div className={classes.showMoreItems}>
              <Text size='small' weight='bold'>Last update</Text>
              <Text size='small'>{moment(file?.updatedAt).format('LLL')}</Text> 
            </div>
            <div className={classes.showMoreItems}>
              <Text size='small' weight='bold'>Upload date</Text>
              <Text size='small'>{file?.uploadDate}</Text> 
            </div>
            <div className={classes.showMoreItems2}>
              <Text size='small' weight='bold'>Type</Text>
              <Text size='small'> {file?.fileType}</Text> 
            </div>
          </div>
          }
          <div className={classes.border} />
          {show === file?.id ? (
            <div className={classes.linkName} onClick={() => handleShow(0)}>
              <Text style={{ cursor: 'pointer', textDecoration: 'underline' }} color='#344157' size='small'>Show less</Text>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg' alt='arrowUp' style={{ marginLeft: 10, height: 8, transform: 'rotateX(0.5turn)', fill: '#344157', objectFit: 'contain' }} />
            </div>
          ) : (
            <div className={classes.linkName} onClick={() => handleShow(file?.id)}>
              <Text style={{ cursor: 'pointer', textDecoration: 'underline' }} color='#344157' size='small'>Show more</Text>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg' alt='arrowDown' style={{ marginLeft: 10, height: 8, fill: '#344157', objectFit: 'contain' }} />
            </div>
          )}
        </ListItem>
      ))}
      {sortedFiles?.length !== 0 && 
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ page } ) =>  'Page ' + (page+1)}
        backIconButtonProps={btnProps}
        nextIconButtonProps={nextIconButtonProps}
      />}
      {
        sortedFiles?.length === 0 && !error &&
        <div className="loadingContainer">
          <Text size='footnote' color={colors.grey} className={classes.margin} >No items found</Text>
          <Loading options={EMPTY} height={180} />
        </div>
      }
    </List>
  )
}

export default PaymentListFiles
