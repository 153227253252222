import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../Themes'

const useStylesAvatar = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  root1: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    margin: '10px 0'
  },
  large: {
    width: 100,
    height: 100,
    cursor: 'pointer',
    fontSize: 60,
    position: 'relative',
    backgroundColor: colors.primary,
    [theme.breakpoints.down('sm')]: {
      width: 70,
      height: 70,
    },
  },
  large1: {
    width: 100,
    height: 100,
    fontSize: 60,
    backgroundColor: colors.primary
  },
  large2: {
    width: 20,
    height: 20,
    fontSize: 10,
    backgroundColor: colors.primary
  },
  large3: {
    width: 220,
    height: 220,
    fontSize: 80,
    backgroundColor: colors.primary
  }
    
}))

export default useStylesAvatar