import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  saveButton: {
    marginTop: 15,
    width: 345,
    fontSize: 14,
    [theme.breakpoints.between(1280, 1525)]: {
      width: '285px',
    },
    [theme.breakpoints.between(970, 1280)]: {
      width: '345px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  gridContainer: {
    padding: '0px 0px 5px',
    maxWidth: '1050px',
    // [theme.breakpoints.between(1280, 1525)]: {
    //   width: '850px',
    // },
    [theme.breakpoints.down(1280)]: {
      maxWidth: '700px',
    },
  },
  dividerFullWidth: {
    width: '100% !important',
    [theme.breakpoints.down(960)]: {
      marginTop: 10
    }
  },
  gridContainer2: {
    // marginTop: '23px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '90%',
    // justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
      width: '100%',
      marginTop: '0px',
    },
    [theme.breakpoints.between('sm', 1280)]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up(2100)]: {
      maxWidth: '50%',
    },
  },
  gridContainer3: {
    padding: '0px',
    display: 'flex',
    alignItems: 'start',
    maxWidth: '90%',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    // [theme.breakpoints.between(1330, 1500)]: {
    //   maxWidth: '80%',
    // },
    // [theme.breakpoints.between(1280, 1330)]: {
    //   maxWidth: '90%',
    // },
    [theme.breakpoints.between('sm', 1280)]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up(2100)]: {
      maxWidth: '50%',
    },
  },
  verticalLine: {
    height: '140px',
    marginTop: 0,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  referalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      paddingLeft: 0,
    }
  },
  referalInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  codeWrapper: {
    display: 'flex'
  },
  inputCode: {
    display: 'flex',
    // border: '1px solid black',
    // borderRadius: 5,
    height: 50,
    // width: 120,
    borderRightWidth: '0px !important',
    borderRightRadius: '0px !important',
      
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    }
  },
  button: {
    height: 50,
    display: 'flex',
    marginLeft: -7,
      
    [theme.breakpoints.down('sm')]: {
      marginTop: -8,
    }
  }, 
  textTooltip: {
    display: 'flex', 
    flexDirection: 'column', 
    padding: '20px 10px 0px 10px',
    zIndex: '9999 !important'
  },
  inputButton: {
    display: 'flex', 
    alignItems: 'center' 
  },
  subtext: {
    display: 'flex', 
    marginBottom: 15
  },
  buttonPadding: {
    padding: '20px !important'
  },
  marginBottom: {
    marginBottom: 10
  },
}))

export default useStyles