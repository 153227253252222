import React from 'react'
import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import { withStyles } from '@material-ui/core/styles'
import { badgeStyles, useStyles } from './BadgeAvatar.styles'

const StyledBadge = withStyles(badgeStyles)(Badge)

export default function BadgeAvatar({ alt, picture, onClick, invisible, layout, chat }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        variant="dot"
        invisible={invisible}
      > 
        {picture ? 
          <Avatar alt={alt}  src={picture} className={layout ? classes.large2 : chat ? classes.chat : classes.large} onClick={onClick} />
          :
          <Avatar alt={alt}  className={layout ? classes.large2 : chat ? classes.chat : classes.large} onClick={onClick} > {alt.charAt(0).toUpperCase()}</Avatar>
        }
      </StyledBadge>
    </div>
  )
}