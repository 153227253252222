import { makeStyles } from '@material-ui/core'

const useStyles2 = makeStyles((theme) => ({
  input: {
    maxWidth: 450,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  input2: {
    maxWidth: 450,
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

export default useStyles2