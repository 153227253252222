/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Password, PrimaryButton, Text } from '..'
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { colors } from '../../Themes'
import useStyles from './NewPassword.styles'

const NewPassword=({ error, disabled, goToPinStep, newPassword, setNewPassword, setConfirmPassword, confirmPassword, onSubmit, loading })=>{
  const classes=useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('1050'))
  return (
    <div  className='passwordSection'>
      <IconButton
        disableFocusRipple
        centerRipple={true}
        disableRipple={true}
        style={{ backgroundColor: 'transparent' }}
        classes={{ label: classes.iconLabel, root: classes.iconRoot }}
        onClick={goToPinStep}
      >
        <ArrowBackIcon />
        <p className={classes.span}>Back</p>
      </IconButton>
      <Text size="h5" color={colors.accent} weight="bold" >Set a new password</Text>
      <Password
        costumizedInputStyles={classes.passwordInput}
        costumizedInputRootStyles={classes.passwordRootInput}
        label="New password"
        value={newPassword}
        setValue={setNewPassword}
      />
      <Password
        costumizedInputRootStyles={classes.passwordRootInput}
        label="Confirm password"
        value={confirmPassword}
        setValue={setConfirmPassword}
      />
      {Boolean(error) && (
        <span className="ConfirmPasswordError" style={{ maxWidth: isMobile ? '100%' : '430px', marginBottom: 10 }}>{error}</span>
      )}
      <span style={{ lineHeight: 1.4, maxWidth: isMobile ? '100%' : '430px' }} className="resetPasswordInfo"><b> Note</b>: 
      Once you reset or change your password, you'll need to re-log into Dataplayer on all of your devices.</span>
      <PrimaryButton
        disabled={Boolean(error) || disabled || loading}
        loading={loading}
        className={classes.continueButton}
        style={{
          backgroundColor: Boolean(error) ||loading || disabled ? colors.disabled : colors.primary,
          color: colors.white,
          marginTop: 15
        }}
        onClick={onSubmit}
      >
          Save
      </PrimaryButton>
    </div>
  )
}

export default NewPassword