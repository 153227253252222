import React from 'react'
import { TextInput, Text } from '../..'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles5 = makeStyles((theme) => ({
    input: {
        maxWidth: 450,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
}))

function AccountCurrencyInfo
    (
        {
            values,
            handleChange,
            handleBlur
        }
    ) 
    {
    const classes = useStyles5()

    return (
        <Grid
            item
            xl={4}
            lg={4}
            md={6}
            xs={12}>
            <TextInput
                costumizedInputStyles={classes.input}
                name='currency'
                label='Currency'
                value={values}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
            />

        </Grid>
    )
}

export default AccountCurrencyInfo
