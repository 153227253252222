import React from 'react'
import { email } from '../../Themes/Images'
import { Modal } from '..'
import { PrimaryButton } from '../Button/Button'
import { useHistory } from 'react-router-dom'
import useStyles2 from './LoadingModal.styles'

const LoadingModal = ({ isLoading }) => {
  const classes = useStyles2()
  const history= useHistory()

  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.costumizedPaperStyles} opened={isLoading}  >
      <div className={classes.modalContent} >
        <div className={classes.modalImg} >
          <img className={classes.img} src={email} alt='' />
        </div>
        <p className={classes.modalTittle} > Your new dashboard is still loading. </p> 
        <p className={classes.desc} >Depending on how many records your clinic has, loading <br />
              your new dashboard may take up to <b>6 hours.</b>  <br /><br />
              When everything is ready, we will notify you via email. In the <br />
              meantime, you can continue using your previous plan.</p>
        <PrimaryButton
          classes={{ root: classes.goBackButton }}
          onClick={()=>history.push('/')}
        >
              Go back to the dashboard
        </PrimaryButton>
      </div>
    </Modal>
  )
}
export default LoadingModal