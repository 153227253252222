import { makeStyles } from '@material-ui/core'

const useStyles4 = makeStyles((theme) => ({
  input: {
    maxWidth: 450,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

export default useStyles4