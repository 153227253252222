import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './text.styles'
// font options are: regular, bold , extraBold , light
// the imported fonts can be changed at App.css

function Text(props) {
  const { size, color, weight, children, ...rest } = props
  
  const useStyles = makeStyles((theme) => ({
    default: {
      ...styles.default,
      color: color ? color : styles.default.color,
      [theme.breakpoints.up('md')]: {
        ...styles.defaultTablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.defaultDesktop
      }
    },

    h1: {
      ...styles.h1.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.h1.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.h1.desktop
      }
    },
    h2: {
      ...styles.h2.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.h2.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.h2.desktop
      }
    },
    h3: {
      ...styles.h3.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.h3.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.h3.desktop
      }
    },
    h4: {
      ...styles.h4.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.h4.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.h4.desktop
      }
    },
    h5: {
      ...styles.h5.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.h5.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.h5.desktop
      }
    },
    h6: {
      ...styles.h6.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.h6.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.h6.desktop
      }
    },
    // the default
    body: {
      ...styles.body.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.body.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.body.desktop
      }
    },
    subnote: {
      ...styles.subnote.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.subnote.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.subnote.desktop
      }
    },
    footnote: {
      ...styles.footnote.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.footnote.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.footnote.desktop
      }
    },
    small: {
      ...styles.small.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.small.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.small.desktop
      }
    },
    smaller: {
      ...styles.smaller.mobile,
      [theme.breakpoints.up('md')]: {
        ...styles.smaller.tablet
      },
      [theme.breakpoints.up('lg')]: {
        ...styles.smaller.desktop
      }
    },
    
    light: {
      ...styles.light,
    },
    regular: {
      ...styles.regular,
    },
    bold: {
      ...styles.bold,
    },
    extraBold: {
      ...styles.extraBold,
    },
    medium: {
      ...styles.medium,
    },
    thin: {
      ...styles.thin,
    },
    primary: {
      ...styles.primary,
    },
    secondary: {
      ...styles.secondary,
    },
    bodyColor: {
      ...styles.bodyColor,
    },
    note: {
      ...styles.note
    }
  }))
  const classes = useStyles()
  
  return (
    <span {...rest} className={classNames(
      classes.default, 
      classes[size],
      classes[weight], 
      classes[color],
      props.className
    )} >  
      {children}
    </span>
  )
}

export default  Text
Text.propTypes = {
  width: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  weight: PropTypes.string,
  opacity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object
}

