import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import './../avatar.css'
import { Badge } from '@material-ui/core'
import { Edit } from '../../../../Themes/Images'
import useStylesAvatar from './ProfileAvatar.styles'

function ProfileAvatar({ picture, alt, onClick, isModal, change }) {
  const classes = useStylesAvatar()

  return (
    <>
      {isModal ?
        <div className={classes.root1}>
          {picture? 
            <Avatar alt={alt}  src={picture} className={change ? classes.large3 : classes.large1} />
            :
            <Avatar alt={alt}  className={change ? classes.large3 : classes.large1}  > {alt?.charAt(0).toUpperCase()} </Avatar>
          }
        </div>
        :
        <div  className={classes.root}>
          <div className='img__wrap'>
            <div onClick={onClick}>
              <Badge
                style={{ cursor: 'pointer' }}
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                badgeContent={<Edit />}
              >
                {picture? 
                  <Avatar alt={alt}  src={picture} className={classes.large} onClick={onClick} >
                  </Avatar>
                  :
                  <Avatar alt={alt}  className={classes.large} onClick={onClick} >
                    {alt?.charAt(0).toUpperCase()}
                  </Avatar>
                }
              </Badge>
            </div>
          </div>
        </div>
      }
    </>
  )
}

ProfileAvatar.defaultProps={
  isModal: false
}

export default ProfileAvatar
