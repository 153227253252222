import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStylesSuccesMessage = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiSnackbar-anchorOriginBottomRight': {
      marginBottom: 50
    },
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  primary: {
    backgroundColor: colors.primary,
  },
  error: {
    backgroundColor: 'rgba(238, 67, 82, 0.97)',
  },
  green: {
    backgroundColor: colors.green,
  },
  img: {
    objectFit: 'contain', 
    height: 25, 
    width: 25
  },
  message: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center' 
  }
}))

export default useStylesSuccesMessage