import React, { useState } from 'react'
import { ManuallyEnterTabs, Text, TabPanel, SecondLoader, NoPermission, ContentContainer } from '../../Components'
import NpsScore from './NpsScore'
import { useAuth, useSubscription } from '../../Global'
import LoadingModal from '../../Components/LoadingModal'
import useStyles from './ManuallyAddData.styles'

function isEmpty(obj) {
  return Object.keys(obj).length === 0
}
function ManuallyAddData() {
  const classes = useStyles()
  const { is_trialing, data_loading }=useAuth()
  const { subscription, isLoadingSub }=useSubscription()
  const [tabValue, setTabValue] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  let content

  if (isLoadingSub) {
    content= <SecondLoader/>
  } 
  else if (isEmpty(subscription) && !is_trialing) {
    content= <NoPermission/>
  } 
  else {
    content= <div className={classes.container}>
      <Text size="subnote" weight="bold" style={{ width: '100%' }} >Manually enter your data here</Text>
      <ManuallyEnterTabs
        value={tabValue}
        handleChange={handleChangeTab}
      />
      <TabPanel value={tabValue} index={0}>
        <NpsScore/>
      </TabPanel>
      <LoadingModal
        isLoading={data_loading}
      />
    </div>
  }

  return (
    <div className="tooltipsContentContainer">
      <ContentContainer
        header={
          <div className={classes.subHeader1}>
            <h1 className="sectionTitle">Manually Enter Data</h1>
          </div>
        }
      >
        {content}
      </ContentContainer>
  
      
    </div>
  )
}

export default ManuallyAddData
