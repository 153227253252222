// export const truncateByCharacters = (text, maxLength = 20) => {
//     if (!text) return ''

//     if (text.length <= maxLength) {
//       return text
//     }
//     return text.slice(0, maxLength) + '...'
//   }

export const truncateByCharacters = (text) => {
  if (!text) return ''

  let maxLength
  if (window.innerWidth < 400) {
    maxLength = 20
  } else if (window.innerWidth >= 550 && window.innerWidth < 760) {
    maxLength = 60
  } else {
    maxLength = 30
  }

  if (text.length <= maxLength) {
    return text
  }
  return text.slice(0, maxLength) + '...'
}