// export default ContactUsModalCustomisation  
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Text, TextInput } from '..'
import { IconButton } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import { Axios } from '../../Config'
import { PrimaryButton, SecondaryOutlinedButton3 } from '../Button/Button'
import { SelectInput } from '../Input'
import { requestFileChangeModalStyles, selectedStyles } from './RequestFileChangeModal.styles'
import ManualFileUpload from '../ManualFileUpload/ManualFileUpload'
import axios from 'axios'
import toast from 'react-hot-toast'
import { ErrorToast, SuccessToast } from '../Notification/Notification'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import { useAuth } from '../../Global'
import { email } from '../../Themes/Images'

let SELECT_OPTIONS = [
  { value: 'targets',  label: 'Targets', href: 'https://www.google.com/' },
  { value: 'reports', label: 'Reports', href: 'https://www.google.com/' },
  { value: 'wellness', label: 'Wellness', href: 'https://www.google.com/'  },
  { value: 'nps', label: 'Nps', href: 'https://www.google.com/'  },
  { value: 'other', label: 'Other', href: 'https://www.google.com/'  },
]

function containsWhitespace(str) {
  return /\s/.test(str)
}
  
function isCsv(fileName) {
  return fileName?.includes('csv')
}

const firstStep = <Text weight='bold' size='footnote'>General Info</Text>
const secondStep = <Text  weight='bold' size='footnote'>Details</Text>
const thirdStep = <Text  weight='bold' size='footnote'>Finalization</Text>

function getSteps() {
  return [firstStep, secondStep, thirdStep]
}

const FIrstStep = ({ dropdownValues, handleChange, value, setValue, SELECT_OPTIONS_HAS }) => {
  const classes = requestFileChangeModalStyles()
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ paddingBottom: 24 }}>
        <Text size='footnote'>Information text. What does this do.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text</Text>
      </div>
      <div>
        <Text size='footnote'>Select the file that you want to change</Text>
      </div>
      <div className={classes.dropwdownWrapper}>
        <SelectInput
          value={dropdownValues}
          selectCostumStyles={selectedStyles}
          label="Requests"
          placeholder={<Text size='subnote' color='hsl(0, 0%, 50%)'>Select</Text>}
          options={SELECT_OPTIONS_HAS}
          onChange={(val) => handleChange(val)}
        />
      </div>
      <div style={{ paddingTop: 24, paddingBottom: 1 }}>
        <Text size='footnote'>Why are you requesting this change?</Text>
      </div>
      <div className={classes.inputForm}>
        <TextInput
          name='reason'
          costumizedInputStyles={classes.input}
          label='Type all the details'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          multiline={true}
          rows={2}
        />
      </div>
    </div>
  )
}

const SecondStep = ({ dropdownValues, handleChange, value, setValue, myFile, setMyFile, error, cancelFileSubmit, isCsvFile, containsWhitespaceFile, progress, setProgress, isLoading }) => {
  const classes = requestFileChangeModalStyles()

  return (
    <>
      <div className={classes.subText2}>
        <Text size='footnote' color={colors.primary} weight='normal' className={classes.underTitle} >What type of contents does you file have?</Text>
      </div>
      <div className={classes.dropwdownWrapper}>
        <SelectInput
          value={dropdownValues}
          selectCostumStyles={selectedStyles}
          label="Requests"
          placeholder="Select"
          options={SELECT_OPTIONS}
          onChange={(val) => handleChange(val)}
          
        />
      </div>
      {dropdownValues?.value && 
        <div className={classes.subText}>
          <div>
            <Text size='small' color={colors.primary} weight='normal' className={classes.underTitle} >The file should be in the same format as this</Text>
          </div>
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              disableRipple={true}
              style={{ backgroundColor: 'transparent', padding: 0 }}
             
            // onClick={() => onCancel()}
            >
              <a style={{ textDecoration: 'none', color: colors.accent }} 
                href={dropdownValues?.href}
                target='_blank' rel="noreferrer"><SystemUpdateAltIcon /></a>
          
             
            </IconButton>
            <a style={{  color: colors.accent }} href='https://dyl2njn37ytxk.cloudfront.net/product/Template_Manual_Files_Sheet.csv' download="Template_Manual_Files_Sheet.csv" rel="noreferrer">
              <Text size='subnote' color={colors.accent} weight='bold' className={classes.underTitle} >{dropdownValues?.label}</Text>
            </a>
          </div>
        </div>
      }
    
      <div className={classes.inputForm}>
        <TextInput
          name='reason'
          costumizedInputStyles={classes.input}
          label='Type all the details'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          multiline={true}
          rows={2}
        />
      </div>
      <ManualFileUpload fileToUpload={myFile} setFileToUpload={setMyFile} cancelSubmit={cancelFileSubmit} isCsv={isCsvFile} containsWhitespace={containsWhitespaceFile} progress={progress} setProgress={setProgress} isLoading={isLoading} />
      {error &&
          <div className={classes.errorDiv}>
            <Text className={classes.errorText} color={colors.secondary} size='footnote'>{error?.response?.data?.error?.message}</Text>
          </div>
      }
      <div style={{ height: 16 }} />
    </>
  )
}

const ThirdStep = () => {
  const classes = requestFileChangeModalStyles()
  return (
    <div className={classes.thirdStepWrapper}>
      <img src={email} alt='email' height="80px" width='90px' className={classes.img} />
      <div className={ classes.text3}>
        <Text size='h4' color={colors.accent} weight='bold' className={classes.spaceText3}>{'Your request was sent successfully!'}</Text>
      </div>
      <div className={classes.text4}>
        <Text size='h6' color={colors.primary} className={classes.spaceText3}>We will reach out soon with details on the next steps.</Text>
      </div>
    </div>
  )
}

function RequestFileChangeModal({ isVisible, cancel, onSuccess }) {
  const classes = requestFileChangeModalStyles()
  const { userUploadedFileType } = useAuth()
  const [value, setValue] = useState('')
  const [dropdownValues, setDropdownValues] = useState('')
  const [isDisabled, setDisabled] = useState(true)
  const [isDisabledSecond, setDisabledSecond] = useState(true)
  const [error, setError] = useState()
  const [isLoading, setLoading] = useState(false)
  const [myFile, setMyFile] = useState(null)
  const [url, setUrl] = useState('')
  const [formDt, setFormDt] = useState()
  const [progress, setProgress] = useState(0)
  const controllerRef = useRef()

  const [dropdownValuesSecond, setDropdownValuesSecond] = useState('')
  const [valueSecond, setValueSecond] = useState('')

  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const RESULT_ARRAY = userUploadedFileType?.map(item => ({
    ...item,
    label: item.label.toLowerCase().charAt(0).toUpperCase() + item.label.slice(1).toLowerCase()
  }))

  const ARRAY_WITHOUT_REVIEW = RESULT_ARRAY.filter(item =>
    item.status !== 'IN REVIEW')

  SELECT_OPTIONS = SELECT_OPTIONS.filter(ar => !userUploadedFileType.find(rm => (rm.value.toLowerCase() === ar.value.toLowerCase() && ar.label.toLowerCase() === rm.label.toLowerCase() && ar.href === rm.href) ))

  const isCsvFile = isCsv(myFile?.type)
  const containsWhitespaceFile = containsWhitespace(myFile?.name)

  function getStepContent(step) {
    switch (step) {
    case 0:
      return <FIrstStep dropdownValues={dropdownValues} handleChange={handleChange} value={value} setValue={setValue} SELECT_OPTIONS_HAS={ARRAY_WITHOUT_REVIEW} />
    case 1:
      return <SecondStep dropdownValues={dropdownValuesSecond} handleChange={handleChangeSecond} value={valueSecond} error={error}
        setValue={setValueSecond} myFile={myFile} setMyFile={setMyFile} cancelFileSubmit={cancelFileSubmit} isCsvFile={isCsvFile} 
        containsWhitespaceFile={containsWhitespaceFile} progress={progress} setProgress={setProgress} isLoading={isLoading}/>
    case 2:
      return <ThirdStep />
    default:
      return 'Unknown step'
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleChangeSecond = (value) => {
    setDropdownValuesSecond(value)
  }

  useEffect(() => {
    if (
      value?.length === 0 || dropdownValues === ''
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [value, dropdownValues, activeStep, steps])

  useEffect(() => {
    if (activeStep === steps.length - 2) {
      if (
        valueSecond?.length === 0 || dropdownValuesSecond === '' || myFile === null
      ) {
        setDisabledSecond(true)
      } else {
        setDisabledSecond(false)
      }
    }
  }, [valueSecond, dropdownValuesSecond, myFile, activeStep, steps])
 
  const onCancel = () => {
    setValue('')
    setDropdownValues('')
    setValueSecond('')
    setDropdownValuesSecond('')
    setMyFile(null)
    controllerRef?.current?.abort()
    setError()
    setDisabled(true)
    setLoading(false)
    cancel()
    setActiveStep(0)
  }

  const cancelFileSubmit = () => {
    setMyFile(null)
  }

  const properSubmit = async (e) => {
    setLoading(true)
    controllerRef.current = new AbortController()
    try {
      await Axios.post('/prod/user/fileUploads/requestFileChange',
        {
          oldFileType: dropdownValues?.value,
          reasonOfChangeDescription: value,
          newFileType: dropdownValuesSecond?.value,
          requestFileChangeDescription: valueSecond,
        },
        { signal: controllerRef?.current?.signal },
      ).then((response) => console.log(response)
      )
    
      if ( typeof myFile === 'undefined' ) return

      const formData = new FormData()
  
      formData.append('file', myFile, 'manual file integration')

      setFormDt(formData)
      
      const data = await Axios.get(`/prod/user/fileUploads/getPresignedURL?fileName=${myFile?.name}.csv&reportContentType=${dropdownValuesSecond?.value.toUpperCase()}&fileProcessType=initial`,
      )
      setUrl(data?.data?.data?.signedURL)
      
      await axios( {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          let percent = 0
          percent = Math.floor((loaded * 100) / total)
                
          if (progress <= 100) {
            setProgress(percent) 
          }
        },
        method: 'put',
        // eslint-disable-next-line max-len
        url: data?.data?.data?.signedURL,
        data: myFile,
        signal: controllerRef?.current?.signal
      })
     
      setLoading(false)
      setValue('')
      setDropdownValues('')
      setValueSecond('')
      setDropdownValuesSecond('')
      setMyFile(null)
      setProgress(0)
      handleNext()
      onSuccess()
      setDisabled(true)
      setDisabledSecond(true)
    } catch (error) {
      setError(error)
      setLoading(false)
      setProgress(0)
      setValue('')
      setDropdownValues('')
      setValueSecond('')
      setDropdownValuesSecond('')
      setMyFile(null)
      if (error?.name === 'CanceledError') {
        toast.custom((t) => (
          <ErrorToast t={t} message='You canceled the change request'/>
        ))
      }
    }
  }

  const handleChange = (value) => {
    setDropdownValues(value)
  }

  const requestFileChange = (
    <div className={classes.ModalWrapper}>
      <div className={classes.subModal}>
        <div className={classes.text}>
          <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >Request file change</Text>
        </div>
        <IconButton
          disableFocusRipple
          centerRipple={true}
          disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          classes={{ root: classes.iconButtonClose }}
          onClick={() => onCancel()}
        >
          <ClearIcon classes={{ root: classes.iconButtonClose }} />
        </IconButton>
      </div>
      <div className={classes.border2} />
      <div className={classes.root}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                   
                </StepContent>
              </Step>
            )
          })}
        </Stepper>
        {getStepContent(activeStep)}
        {activeStep === steps.length - 1 ?
          <>
            <div className={classes.border4} />
            <div className={classes.buttonsWrapper2}>
              <SecondaryOutlinedButton3
                className={classes.cancelButton2}
                onClick={onCancel}
              > Close
              </SecondaryOutlinedButton3>
            </div>
          </>
          :
          <>
            <div className={classes.border3} />
            <div className={classes.buttonsWrapper}>
              <SecondaryOutlinedButton3
                className={classes.cancelButton}
                onClick={onCancel}
              > Cancel
              </SecondaryOutlinedButton3>
              {activeStep === steps.length - 2 ? 
                <SecondaryOutlinedButton3
                  className={classes.nextButton}
                  disabled={isDisabledSecond || !isCsvFile || containsWhitespaceFile || isLoading}
                  loading={isLoading}
                  style={{
                    backgroundColor: isDisabledSecond || isLoading || !isCsvFile || containsWhitespaceFile ? colors.disabled : colors.primary,
                    color: colors.white,
                  }}
                  onClick={() => properSubmit()}
                >
              Request
                </SecondaryOutlinedButton3>
                :
                <SecondaryOutlinedButton3
                  className={classes.nextButton}
                  disabled={isDisabled}
                  loading={isLoading}
                  style={{
                    backgroundColor: isDisabled || isLoading ? colors.disabled : colors.primary,
                    color: colors.white,
                  }}
                  onClick={() => handleNext()}
                >
              Next
                </SecondaryOutlinedButton3>
              }
            </div>
          </>                                                               
        }
        {/* <Stepper activeStep={activeStep} orientation='horizontal' style={{ alignItems: 'start' }}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Text>{getStepContent(index)}</Text>
                  {activeStep === steps.length - 1 ?
                    <div className={classes.buttonsWrapper2}>
                      <SecondaryOutlinedButton3
                        className={classes.cancelButton2}
                        onClick={onCancel}
                      > Cancel
                      </SecondaryOutlinedButton3>
                    </div>
                    :
                    <div className={classes.buttonsWrapper}>
                      <SecondaryOutlinedButton3
                        className={classes.cancelButton}
                        onClick={onCancel}
                      > Cancel
                      </SecondaryOutlinedButton3>
                      {activeStep === steps.length - 2 ? 
                        <SecondaryOutlinedButton3
                          className={classes.nextButton}
                          disabled={isDisabledSecond || !isCsvFile || containsWhitespaceFile || isLoading}
                          loading={isLoading}
                          style={{
                            backgroundColor: isDisabledSecond || isLoading || !isCsvFile || containsWhitespaceFile ? colors.disabled : colors.primary,
                            color: colors.white,
                          }}
                          onClick={() => properSubmit()}
                        >
              Request
                        </SecondaryOutlinedButton3>
                        :
                        <SecondaryOutlinedButton3
                          className={classes.nextButton}
                          disabled={isDisabled}
                          loading={isLoading}
                          style={{
                            backgroundColor: isDisabled || isLoading ? colors.disabled : colors.primary,
                            color: colors.white,
                          }}
                          onClick={() => handleNext()}
                        >
              Next
                        </SecondaryOutlinedButton3>
                      }
                    </div>
                  }
                </StepContent>
              </Step>
            )
          })}
        </Stepper> */}
      </div>
      {/* <div className={classes.border} /> */}
      {/* {activeStep === steps.length - 1 ?
        <div className={classes.buttonsWrapper2}>
          <SecondaryOutlinedButton3
            className={classes.cancelButton2}
            onClick={onCancel}
          > Cancel
          </SecondaryOutlinedButton3>
        </div>
        :
        <div className={classes.buttonsWrapper}>
          <SecondaryOutlinedButton3
            className={classes.cancelButton}
            onClick={onCancel}
          > Cancel
          </SecondaryOutlinedButton3>
          {activeStep === steps.length - 2 ? 
            <SecondaryOutlinedButton3
              className={classes.nextButton}
              disabled={isDisabledSecond || !isCsvFile || containsWhitespaceFile || isLoading}
              loading={isLoading}
              style={{
                backgroundColor: isDisabledSecond || isLoading || !isCsvFile || containsWhitespaceFile ? colors.disabled : colors.primary,
                color: colors.white,
              }}
              onClick={() => properSubmit()}
            >
              Request
            </SecondaryOutlinedButton3>
            :
            <SecondaryOutlinedButton3
              className={classes.nextButton}
              disabled={isDisabled}
              loading={isLoading}
              style={{
                backgroundColor: isDisabled || isLoading ? colors.disabled : colors.primary,
                color: colors.white,
              }}
              onClick={() => handleNext()}
            >
              Next
            </SecondaryOutlinedButton3>
          }
        </div>
      } */}
    </div>
  )
  
  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      {requestFileChange}
    </Modal>
  )
}

export default RequestFileChangeModal

