/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { List, Divider, IconButton, Hidden, Badge, Avatar, useTheme, useMediaQuery } from '@material-ui/core'
import { Route, Link, Redirect, Switch, withRouter, useHistory } from 'react-router-dom'
import moment from 'moment'
import Axios from '../../Config/Axios'
import { Drawer, DrawerListItem, DrawerNestedListItem } from '../../Components'
import {
  DashboardIcon,
  LogoutIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  logoIcon,
  logoName,
  HelpCenter,
} from '../../Themes/Images'
import MenuIcon from '@material-ui/icons/Menu'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { DRAWER_WIDTH } from '../../Themes/Constants'
import { colors } from '../../Themes'


import { useAuth, useMenu } from '../../Global'
import { useWindowDimensions } from '../../Utils'

import Dashboard from '../Dashboard/Dashboard'
import Tooltips from '../Tooltips/Tooltips'

import NotFound from '../NotFound/NotFound'
import useStyles from './WrapperSecondary.styles'
import { DrawerNestedListItemDashboards } from '../../Components/Drawer/Drawer'
import MultipleDashboard from '../MultipleDashboards'

const DRAWER_ITEMS = [
  {
    title: 'Dashboard',
    Icon: DashboardIcon,
    to: '/dashboard',
    component: Link,
  },
]

function MiniDrawer() {
  const classes = useStyles()
  const history=useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { logout, additional_dashboards } = useAuth()
  const { width } = useWindowDimensions()
  const {
    selectedItem,
    isVisible,
    isExpanded,
    isFullScreen,
    toggleDrawer,
    toggleDrawerExpansion,
    toggleDrawerOnHelpCenterExpansion,
    setSelectedRoute,
  } = useMenu()

  const current = window.location.pathname
  const getGreetingTime = (currentTime) => {
    if (!currentTime || !currentTime.isValid()) {
      return 'Hello'
    }
    const splitAfternoon = 12
    const splitEvening = 17
    const currentHour = parseFloat(currentTime.format('HH'))

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      return 'Good afternoon'
    } else if (currentHour >= splitEvening) {
      return 'Good evening'
    }
    return 'Good morning'
  }

  useEffect(() => {
    setSelectedRoute(current)
  }, [current])

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
    toggleDrawerOnHelpCenterExpansion()
  }

  const greeting = getGreetingTime(moment())

  const handleOpenAdditional = () => {
    setOpen(!open)
    toggleDrawerOnHelpCenterExpansion()
  }

  const noAdditionalDashboards = additional_dashboards?.length === 0

  return (
    <Switch>
      <Route
        path={[
          '/dashboard',
          '/dashboard/:dashboard_id',
          '/tooltips',
        ]}
      >
        <div className={classes.root}>
          <div className={isFullScreen ? 'drawerWrapper' : ''}>
            <Hidden xsDown>
              <IconButton
                disableFocusRipple
                centerRipple={true}
                onClick={toggleDrawerExpansion}
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: isExpanded,
                  [classes.drawerClose]: !isExpanded,
                })}
              >
                {isExpanded ? (
                  <ArrowLeftIcon className="toggleDrawerIcon" />
                ) : (
                  <ArrowRightIcon className="toggleDrawerIcon" />
                )}
              </IconButton>
            </Hidden>
            <Drawer
              onOpen={toggleDrawer}
              onClose={toggleDrawer}
              variant={width > 760 ? 'permanent' : 'temporary'}
              isExpanded={isExpanded}
              isVisible={isVisible}
            >
              <Hidden smUp>
                <div className="logoContainer">
                  <div style={{ display: 'flex' }}>
                    <img src={logoIcon} className="logoIcon" onClick={() => {
                      history.push('/dashboard')
                      toggleDrawer()
                    }} />
                    {(isExpanded || width <= 760) && (
                      <img
                        src={logoName}
                        className="logoName"
                        onClick={() => {
                          history.push('/dashboard')
                          toggleDrawer()
                        }}
                      />
                    )}
                  </div>
                  <IconButton
                    disableFocusRipple
                    centerRipple={true}
                    classes={{ root: classes.iconButton }}
                    onClick={toggleDrawer}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </div> 
              </Hidden>
              <div className="drawerContent">
                <div>
                  <Divider className="divider" variant="middle" />
                  <List>
                    {noAdditionalDashboards && DRAWER_ITEMS.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))}

                    {!noAdditionalDashboards &&
                   <DrawerNestedListItemDashboards
                     index={14}
                     key={14}
                     title="Dashboards"
                     Icon={DashboardIcon}
                     isActive={open}
                     withTitle={isExpanded || width <= 760}
                     onClick={() =>
                       handleOpenAdditional()
                     }
                     open={open}
                     //  items={additional_dashboards}
                   />}
                    {/* {DRAWER_ITEMS.map((item, index) => (
                      <DrawerListItem
                        index={index}
                        key={index}
                        {...item}
                        isActive={selectedItem === item.to}
                        withTitle={isExpanded || width <= 760}
                        onClick={toggleDrawer}
                        component={item.component}
                        to={item.to}
                      />
                    ))} */}
                    <DrawerNestedListItem
                      index={4}
                      key={4}
                      title="Help Centre"
                      Icon={HelpCenter}
                      isActive={open}
                      withTitle={isExpanded || width <= 760}
                      onClick={() =>
                        window.open(
                          'https://dataplayer.zohodesk.eu/portal/en-gb/signin',
                          '_blank'
                        )
                      }
                      // open={open}
                    />
                  </List>
                </div>
                <div>
                  <Divider className="divider" variant="middle" />
                  <DrawerListItem
                    index={9999}
                    title="Log out"
                    Icon={LogoutIcon}
                    isActive={true}
                    withTitle={isExpanded || width <= 760}
                    onClick={logout}
                  />
                </div>
              </div>
            </Drawer>
          </div>
          <main className={classes.content}>
            <div className="homeContent">
              <Route exact path="/dashboard" component={Dashboard} />
              {additional_dashboards?.length > 0 && <Route exact path="/dashboard/:dashboard_id" component={MultipleDashboard} />}
              <Route exact path="/tooltips" component={Tooltips} />
            </div>
          </main>
        </div>
      </Route>
      <Redirect to="/dashboard" path={['/', '/reset-password', '/login']} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default withRouter(MiniDrawer)
