import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '47%',
    padding: 12,
    border: '1px solid #EBEBEB',
    transition: 'all 0.3s ease-in-out',
    borderRadius: 5,
    boxShadow: 'none',
    position: 'relative',
    // [theme.breakpoints.between(2500, 4000)]: {
    //   width: '23%'
    // },
    [theme.breakpoints.up(2580 )]: {
      width: '30.5%'
    },
    [theme.breakpoints.down(1665)]: {
      width: '45%'
    },
    [theme.breakpoints.down(1200)]: {
      width: '100%'
    },
    [theme.breakpoints.down(430)]: {
      maxWidth: 330
    },
    [theme.breakpoints.down(390)]: {
      maxWidth: 300
    },
    [theme.breakpoints.down(360)]: {
      maxWidth: 270
    },
    [theme.breakpoints.down(330)]: {
      maxWidth: 240
    },
      
  },
  hover: {
    '&:hover': {
      transition: 'all 0.3s ease-in',
      // zIndex: 1300,
      // transform: 'scaleX(1.2) scaleY(1.3)',
      boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
      top: '-4px',
      backgroundColor: 'white', 
      zIndex: 1000,
      transform: 'scaleX(1.05) scaleY(1.1)',
        
    },
  },
  hoveredCard: {
    transition: 'all 0.3s ease-in-out',
    // zIndex: 1300,
    // transform: 'scaleX(1.2) scaleY(1.3)',
      
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 144,
    height: 95,
    objectFit: 'contain',
    [theme.breakpoints.down(390)]: {
      width: 100,
      height: 85,
    },
    [theme.breakpoints.down(330)]: {
      width: 100,
      height: 65,
    },
  },
  link: {
    color: colors.purple,
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  linkMobile: {
    color: colors.purple,
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 8
  },
  linkXero: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  link2: {
    color: colors.purple,
    fontSize: 18,
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  img: {
    display: 'block',
    width: '144px',
    height: '95px',
    borderRadius: '5px',
    objectFit: 'scale-down',
    [theme.breakpoints.down(390)]: {
      width: 110,
      height: 85,
    },
    [theme.breakpoints.down(330)]: {
      width: 100,
      height: 65,
    },
  },
  contentInnerWrapper: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    [theme.breakpoints.between(1200, 1250)]: {
      gap: 6,
    },
    [theme.breakpoints.down(360)]: {
      gap: 10,
    },
  },
  contentTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: 98,
    width: '100%',
    borderBottom: '1px solid #EBEBEB',
    overflow: 'hidden',
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    width: '200px',
    borderRadius: '5px',
    fontSize: '14px',
    backgroundColor: colors.primary,
    color: colors.white,
    [theme.breakpoints.between(760, 835)]: {
      width: '150px',
    },
    [theme.breakpoints.down(430)]: {  
      maxWidth: 170
    },
    [theme.breakpoints.down(390)]: {
      maxWidth: '150px !important'
    },
     
    // [theme.breakpoints.down(340)]: {
    //   maxWidth: '150px !important'
    // },
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.primary,
    },
  },
  actionsRoot: {
    padding: 0,
    marginTop: 12,
    flexDirection: 'column' 
  },
  actionsRootStore: {
    padding: 0,
    marginTop: 12,
    flexDirection: 'column',
    alignItems: 'start'
  },
  priceTextWrapper: {
    width: '144px',
    textAlign: 'center'
  },
  descriptionWrapper: {
    marginTop: 8,
    marginBottom: 8
  
      
  },
  descriptionText: {
    whiteSpace: 'initial',
    width: '100%',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // display: '-webkit-box',
    // WebkitLineClamp: '2',
    // '-webkit-box-orient': 'vertical',
  },
  hideDescText: {
    [theme.breakpoints.up(900)]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      '-webkit-box-orient': 'vertical',
    },
    
  },
   
  upgradeNow: { 
    display: 'flex', 
    flexDirection: 'column', 
    gap: 4,
    alignItems: 'flex-end',
    width: '40%'
  },
  chip: {
    height: 23,
    backgroundColor: colors.background,
    position: 'absolute',
    right: 5,
    top: 7,
  },
  chipHolderMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    paddingTop: 10,
    width: '100%'
  },
  chipPrice: {
    height: 23,
    backgroundColor: colors.background,
    maxWidth: 200
  },
  chipActive: {
    height: 25,
    backgroundColor: colors.green,
    position: 'absolute',
    top: 7,
    right: 0,
    // [theme.breakpoints.down(360)]: {
    //   width: 50
    // }
  },
  smallCard: {
    width: '30.5%',
    [theme.breakpoints.down(1823)]: {
      width: '47%'
    },
    [theme.breakpoints.down(1665)]: {
      width: '45%'
    },
    [theme.breakpoints.down(1200)]: {
      width: '100%'
    },
    // [theme.breakpoints.between(2500, 4000)]: {
    //   width: '23%'
    // },
    [theme.breakpoints.between(2100, 2500 )]: {
      width: '30.5%'
    },
  },
  smallCardPriceTextWrapper: {
    minWidth: '144px',
    textAlign: 'center',
    [theme.breakpoints.down(430)]: {
      minWidth: '100px',
    },
  },
  smallCardActionWrapper: {
    width: '100%',
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    borderBottom: `1px solid ${colors.borderGray}`
  },
  smallCardActionTextWrapper: { 
    display: 'flex', 
    flexDirection: 'column', 
    width: '75%', 
    padding: '8px 0px', 
    gap: 6,
    [theme.breakpoints.down(1680)]: {
      width: '70%'
    },
  },
  smallCardLastSection: { 
    width: '100%', 
    textAlign: 'center', 
    paddingTop: '16px',
    paddingBottom: '4px',
    marginRight: 8
  },
  removeButton: { 
    color: colors.grayish,  
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    textDecoration: 'underline',
    fontWeight: 500
  }
}))


export default useStyles