import React, { useEffect, useState } from 'react'
import Loading from 'react-lottie'
import { loading2 } from '../../Themes/Images'
import parse from 'html-react-parser'
import { ContentContainer } from '../../Components'
import CollapseTooltips from '../../Components/Collapse/Collapse'
import { TOOLTIPS, useAuth } from '../../Global'
import { useHistory } from 'react-router'
import useStyles from './Tooltips.styles'

const LOADING_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loading2,
  rendererSettings: {
    className: 'loading',
  },
}

function Tooltips() {
  const classes = useStyles()
  const history=useHistory()
  const { tooltips } = useAuth()
  const [expandedItem, setExpandedItem] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const key = tooltips||''

  const expand = (key) => {
    setExpandedItem((prev) => {
      if (prev === key) {
        return -1
      }
      return key
    })
  }

  useEffect(() => {
    if (key==='FREE'){
      window.open('https://dataplayerhelp.atlassian.net/servicedesk/customer/portal/6/topic/c5ac9cef-96b6-43e6-993f-a2abadd9b677', '_blank')
      history.push('/')
      setIsLoading(false)
    } else if (key==='GIGA'){
      window.open('https://dataplayerhelp.atlassian.net/servicedesk/customer/portal/6/topic/715b11b7-0e6a-4539-9490-be109964f9f1', '_blank')
      history.push('/')
      setIsLoading(false)
    } else if (key==='MEGA'){
      window.open('https://dataplayerhelp.atlassian.net/servicedesk/customer/portal/6/topic/dec6504a-28de-4418-9893-150e00f1e7fb', '_blank')
      history.push('/')
      setIsLoading(false)
    }    
    setIsLoading(false)
  }, [key, history])


  if (isLoading) {
    return (
      <div className="loadingContainer">
        <Loading options={LOADING_OPTIONS} height={180} />
      </div>
    )
  }
  return (
    <div className="profile">
      <div className="profileHeader">
        <div className="backgroundHeader" />
      </div>
      <div className="tooltipsContentContainer">
        <ContentContainer
          header={
            <div className={classes.subHeader}>
              <h1 className="sectionTitle">Tooltips</h1>
            </div>
          }
        >
          <div className={classes.ContentWrapper}>
            <h3 className={classes.title}>{parse(TOOLTIPS?.[key]?.title||'')}</h3>

            <p className={classes.desc}>
              <span className="preformated">
                {parse(TOOLTIPS?.[key]?.desc||'')}
              </span>
            </p>
            <div className={classes.collapseItem}>
              { Object.keys(TOOLTIPS[key]).length!==0   &&  TOOLTIPS?.[key]?.tooltips.map((item, index) => {
                return (
                  <CollapseTooltips
                    isExpanded={index === expandedItem}
                    expand={() => expand(index)}
                    key={index}
                    title={item.title}
                    description={item.desc}
                    definitions={item.definitions}
                    number={index + 1}
                  />
                )
              })}
            </div>
          </div>
        </ContentContainer>
      </div>
    </div>
  )
}

export default Tooltips
