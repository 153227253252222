import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'flex',
    width: '100%',
    paddingBottom: 16,
    paddingTop: 5,
  },
  inputSignUp: {
    display: 'flex',
    paddingTop: 16,
    width: '100%'
  }
}))

export default useStyles