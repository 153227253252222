import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {},
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: colors.secondary,
  },
  avatar: {
    backgroundColor: red[500],
  },
  collapseContent: {
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 50,
    width: '100%',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.drawer,
  },
  collapseItem: {
    display: 'flex',
    backgroundColor: colors.drawer,
    alignItems: 'center',
  },
  pressable: {
    width: '100%',
    color: colors.primary,
    marginTop: '3px',
    transitionDelay: '0.38s',
    overflow: 'hidden',
  },
  pressableOpen: {
    width: '100%',
    color: colors.primary,
    marginTop: '3px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: 'hidden',
  },
  title: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 18,
    textAlign: 'start'
  },
  expandOpenTittle: {
    color: colors.secondary,
  },
  collapse: {
    backgroundColor: colors.drawer,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    width: '100%',
  }
}))

export default useStyles