import { colors } from '../../../Themes'

export const styles = theme => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    margin: '28px 0px 24px 0',
    background: colors.background,
    [theme.breakpoints.up('sm')]: {
      height: 200,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: '24px 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
   
    '.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
      boxShadow: 'none'
    },
    '.MuiSlider-thumbColorSecondary.MuiSlider-active': {
      boxShadow: 'none'
    }
  
  },
  sliderColor: {
    color: colors.primary,
  },
  thumbColor: {
    color: colors.primary,
    boxShadow: 'none'

  },
  button: {
    minWidth: 150
  },
  linearProggres: {
    backgroundColor: colors.primary
  }
})
  