/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { SuccessToast } from '../Notification/Notification'
import { Modal, PrimaryButton, Text, TextInput } from '..'
import { colors } from '../../Themes'
import { Axios } from '../../Config'
import { IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import changeEmailModalStyles from './ChangeEmailAddressModal.styles'

function validate(input) {
  let errors = {}
  let reg = /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/
  switch (input.key) {
  case 'newEmail': {
    if (!input.value) {
      errors.newEmail = 'Please fill out the required information.'
    } else if (
      !reg.test(input.value)
    ) {
      errors.newEmail = 'Please enter a valid email address.'
    }
    return errors
  }
  case 'reason': {
    if (!input.value) {
      errors.reason = 'Please fill out the required information.'
    } else if (input.value.length < 10) {
      errors.reason = 'Your reason must be at least 10 charters.'
    }
    return errors
  }
  default:
    return errors
  }
}

function ChangeEmailAddressModal({ isVisible, cancel, onSuccess }) {
  const classes = changeEmailModalStyles()
  const [values, setValues] = useState({
    newEmail: '',
    reason: ''
  })
  const [isDisabled, setDisabled] = useState(true)
  const [errors, setErrors] = useState({})
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (
      values.newEmail !== '' &&
            values.reason !== '' && Object.keys(errors).length === 0
    ) 
    {
      setDisabled(false)
    } else {
      setDisabled(true)
    }

  }, [values.newEmail, values.reason, errors])

  const handleChange = (value, name) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
    delete errors[name]
  }

  const handleOnBlur = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const input = {
      key: name,
      value,
    }
    const validateError = validate(input)
    setErrors({
      ...errors,
      ...validateError,
    })
  }

  const onSubmit= async ()=>{
    setLoading(true)
    try {
      await Axios.post('/prod/user/emailticket',
        {
          requested_email: values.newEmail,
          reason: values.reason
        }
      )
      setLoading(false)
      setValues({
        newEmail: '',
        reason: ''
      })
      toast.custom((t) => (
        <SuccessToast t={t} message='Request successfully sent!'/>
      ))
      setErrors({})
      setDisabled(true)
      onSuccess()
    } catch (error) {
      setLoading(false)
    }
  }

  const onCancel=()=>{
    setValues({
      newEmail: '',
      reason: ''
    })
    setErrors({})
    setDisabled(true)
    cancel()
  }

  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Text style={{ alignItems: 'center' }} size='h5' color={colors.accent} weight='bold' className={classes.spaceTittle} >Request to change email</Text>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              disableRipple={true}
              style={{ backgroundColor: 'transparent' }}
              classes={{ root: classes.iconButtonClose }}
              onClick={() => onCancel()}
            >
              <ClearIcon classes={{ root: classes.closeIcon }} />
            </IconButton>
          </div>
        </div>
        <div className={classes.divider} style={{ marginTop: 25, marginBottom: 15 }}></div>
        <Text size='footnote' color={colors.primary} className={classes.spaceText2}>Please insert here the new email address to be connected to your account:</Text>
        <TextInput
          name='newEmail'
          costumizedInputStyles={classes.input}
          label="New email address"
          value={values.newEmail}
          setValue={(value) => handleChange(value, 'newEmail')}
          onBlur={handleOnBlur}
        />
        {errors?.newEmail &&
                    <p className={classes.errorInput}>{errors?.newEmail}</p>
        }
        <Text size='footnote' color={colors.primary} className={classes.spaceText2}>Why did you change your email address?</Text>
        <TextInput
          name='reason'
          costumizedInputStyles={classes.input}
          label="Reason"
          value={values.reason}
          setValue={(value) => handleChange(value, 'reason')}
          multiline={true}
          rows={4}
          onBlur={handleOnBlur}
        />
        {errors?.reason &&
                    <p className={classes.errorInput}>{errors?.reason}</p>
        }
        <div className={classes.divider} style={{ marginTop: 15, marginBottom: 5 }}></div>
        <div className={classes.buttonsWrapper}>
          <PrimaryButton
            style={{ border: '#cccccc 1px solid' }}
            className={classes.cancelButton}
            onClick={onCancel}
          > Cancel
          </PrimaryButton>
          <PrimaryButton
            className={classes.nextButton}
            disabled={isDisabled||isLoading}
            loading={isLoading}
            style={{
              backgroundColor: isDisabled||isLoading ? colors.disabled : colors.primary,
              color: colors.white,
            }}
            onClick={onSubmit}
          >
                        Send request
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}



export default ChangeEmailAddressModal
