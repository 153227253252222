import React from 'react'
import { Typography } from '@material-ui/core'
import Activate2FAStyles from './Activate2FA.styles'
import { SecondaryOutlinedButton } from '../Button/Button'
import { colors } from '../../Themes'

const Activate2FA = ({ isEmailVerified, submitting, onSubmit, enabled }) => {

  const classes = Activate2FAStyles()
  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <div className={enabled && classes.isActive}>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h6"
          >
          Two-Factor Authentication
          </Typography>
          {enabled && <span className={classes.activeBadge}>Active</span>}
        </div>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="subtitle2"
          className={classes.typography}
          
        >
        Two-factor authentication (2FA) is a security mechanism that adds an extra layer of protection to your accounts beyond just a password.
        </Typography>
        {!isEmailVerified &&
        <Typography
          color="textPrimary"
          gutterBottom
          variant="body2"
          className={classes.typographyBold}
        
        >
      Please verify your email address to enable 2FA.
        </Typography>
        }
      </div>
      <div className={classes.button}>
        <SecondaryOutlinedButton
          onClick={() => onSubmit()}
          disabled={!isEmailVerified }
          loading={submitting}
          classes={{
            root: classes.saveButton,
          }}
          style={{
            backgroundColor: submitting || !isEmailVerified  && colors.disabled,
            borderColor: submitting || !isEmailVerified  && colors.disabled,
            color: submitting || !isEmailVerified  && colors.white
          }}
        >
          {enabled ? 'Disable 2FA' : 'Enable 2FA'}
        </SecondaryOutlinedButton>
      </div>
              
    </div>
  )
}

export default Activate2FA