
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { tabStyles, tabsStyles, useStyles } from './TabsForManualFiles.styles'
import Text from '../Text'
import { PrimaryButton } from '../Button/Button'

const StyledTabs = withStyles(tabsStyles)((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles(tabStyles)((props) => <Tab disableRipple {...props} />)


export default function TabsForManualFiles({ value, handleChange, userUploadedFileTypeCount, activeFileTypesCount }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <StyledTabs
        value={value} 
        onChange={handleChange}
        aria-label="targets"    
      >
        <StyledTab label={<Text size='subnote' weight='medium'>Files</Text>}/>
        <StyledTab label={<Text size='subnote' weight='medium'>History</Text>}/>
      </StyledTabs>
      {value === 0 && 
       <div>
         <Text weight='bold' size='subnote'>{userUploadedFileTypeCount} / {activeFileTypesCount} files</Text>
       </div>
      }
    </div>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  
  return (
  
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          { children }
        </>
      )}
    </div>
  )
}
  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export { TabPanel }