/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import './DateRangePicker.css'
import { Calendar } from '../../Themes/Images'
import { Text } from '..'
import { useStyles, useSimpleDatePickerStyles, useSimpleDatePickerStylesModal } from './DatePicker.styles'

function DateRangePicker({ startDate, setStartDate, endDate, setEndDate, callBack, isMobile }) {
  const classes = useStyles()

  const CostumStartInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className='firstInputContainerDatePicker' onClick={onClick} ref={ref}>
        <Text size="footnote" color='grey'>
          {value}
        </Text>
        <Calendar className='calendarIcon' />
      </div>
    ),
  )

  const CostumEndInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className={classes.datePickerEndInputContainer} onClick={onClick} ref={ref}>
        <Text size="footnote" color='grey'>
          {value}
        </Text>
      </div>
    ),
  )

  const renderDatePicker = () => {
    if (isMobile) {

      return (
        <>
          <div className="firstInputDate">
            <DatePicker
              customInput={<CostumStartInput />}
              selected={startDate}
              dateFormat="dd-MM-yyyy"
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onCalendarClose={() => callBack()}
              withPortal={isMobile}
            />
          </div>
          <DatePicker
            customInput={<CostumEndInput />}
            selected={endDate}
            dateFormat="dd-MM-yyyy"
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            onCalendarClose={() => callBack()}
            withPortal={isMobile}
          />
        </>
      )
    }

    return (
      <>
        <div className="firstInputDate">
          <DatePicker
            selected={startDate}
            dateFormat="dd-MM-yyyy"
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onCalendarClose={() => callBack()}
            className={classes.datePickerStartInputContainer}
          />
        </div>
        <DatePicker
          selected={endDate}
          dateFormat="dd-MM-yyyy"
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className={classes.datePickerEndInputContainer}
          onCalendarClose={() => callBack()}
        />
        <Calendar className={classes.calendarIcon} />
      </>
    )
  }

  return (
    <div className={classes.datePickerWrapper} >
      {renderDatePicker()}
    </div>
  )
}

DateRangePicker.defaultProps = {
  isMobile: false
}

function DateRangePickerDivided({ startDate, setStartDate, endDate, setEndDate, callBack, isMobile }) {

  const classes = useSimpleDatePickerStylesModal()

  return (
    <>
      <div className={classes.datePickerWrapperDiv} >
        <DatePicker
          selected={startDate}
          dateFormat="dd-MM-yyyy"
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className={classes.datePickerInputContainerDiv}
          onCalendarClose={() => callBack()}
        />
        <Calendar className={classes.calendarIcon} />
      </div>
      <div className={classes.datePickerWrapperDiv} >
        <DatePicker
          selected={endDate}
          dateFormat="dd-MM-yyyy"
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={endDate}
          minDate={startDate}
          className={classes.datePickerInputContainerDiv}
          onCalendarClose={() => callBack()}
        />
        <Calendar className={classes.calendarIcon} />
      </div>
    </>
  )

}
DateRangePickerDivided.defaultProps = {
  isMobile: false
}


function SimpleDatePicker({ date, setDate }) {
  const classes = useSimpleDatePickerStyles()

  return (
    <div className={classes.datePickerWrapper} >
      <DatePicker
        selected={date}
        dateFormat="dd-MM-yyyy"
        onChange={date => setDate(date)}
        selectsStart
        startDate={date}
        className={classes.datePickerInputContainer}
      />
      <Calendar className={classes.calendarIcon} />
    </div>

  )
}

SimpleDatePicker.defaultProps = {
}

export default DateRangePicker
export { SimpleDatePicker, DateRangePickerDivided }