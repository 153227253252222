import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  // Pssword conditional styles
  RenderPasswordConditionContainer: {
    padding: '0 7px ',
    display: 'flex',
    flexWrap: 'wrap',
  },
  RenderPasswordWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  RenderPasswordRow: {
    display: 'flex',
    alignItems: 'center',
    paddingInline: 15,
    margin: '10px 0',
  },
  dotSucces_true_disabled_false: {
    alignSelf: 'center',
    width: 10,
    height: 10,
    backgroundColor: colors.green,
    borderRadius: 10,
    marginRight: 10,
  },
  dotSucces_false_disabled_true: {
    alignSelf: 'center',
    marginRight: 10,
    width: 10,
    height: 10,
    backgroundColor: colors.primaryRGBA(0.5),
    borderRadius: 10,
  },
  dotSucces_false_disabled_false: {
    alignSelf: 'center',
    marginRight: 10,
    width: 10,
    height: 10,
    backgroundColor: colors.secondary,
    borderRadius: 10,
  },
  dotSucces_true_disabled_true: {
    alignSelf: 'center',
    marginRight: 10,
    width: 10,
    height: 10,
    backgroundColor: colors.green,
    borderRadius: 10,
  },
  textSucces_true_disabled_false: {
    fontSize: 14,
    color: colors.green,
  },
  textSucces_false_disabled_false: {
    fontSize: 14,
    color: colors.secondary,
  },
  textSucces_false_disabled_true: {
    fontSize: 14,
    color: colors.primaryRGBA(0.5),
  },
  textSucces_true_disabled_true: {
    fontSize: 14,
    color: colors.green,
  },
}))


export default useStyles