import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    margin: 0,
  },
  image: {
    margin: 'auto',
    width: '70%',
    height: '70%',
  },
  cardsContainer: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    flexShrink: 0,
  },
  cards: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  subHeader: {
    display: 'flex',
    flex: 1,
  },
  iconButton: {
    margin: 10,
  },
  collapseItem: {
    display: 'flex',
  
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
  },
  ContentWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    marginTop: 0,
  },
  desc: {
    marginBottom: 30,
  },
}))

export default useStyles