import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const codeCustomModalStyles = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 25px',
      maxWidth: 320,
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  spaceText: {
    margin: '22px 0 15px 0'
  },
  spaceText2: {
    margin: '17px 0 0px 0'
  },
  spaceTittle: {
    marginTop: 16,
    alignSelf: 'center'
  },
  buttonsWrapper: {
    display: 'flex',
    gap: 10,
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  cancelButton: {
    width: '50%',
    backgroundColor: colors.white,
    color: colors.primary,
    border: '1px solid #cccccc',
    height: 50,
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white,
    },
    [theme.breakpoints.down(345)]: {
      height: 60,
    },
  },
  nextButton: {
    width: '50%',
    height: 50,
    [theme.breakpoints.down(345)]: {
      height: 60,
      lineHeight: 1.2
    },
  },
  text: {
    marginBottom: 30,
    textAlign: 'center',
  },
  text2: {
    textAlign: 'center',
    marginBottom: 7
  },
  iconButtonClose: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  reactCodeInput: {
    display: 'flex !important',
    gap: '13px !important',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      gap: '5px !important',
    },
  },
  errorDiv: {
    display: 'flex', 
    alignItems: 'center',
  },
  errorICon: { 
    marginTop: 5 
  },
  errorText: {
    marginLeft: 5, 
    marginTop: 5 
  }
}))

export default codeCustomModalStyles