import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
    color: '#293E57',
  },
  text: {
    fontSize: 'smaller', 
    fontWeight: 'normal'
  }
}))

export default useStyles