import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const Activate2FAStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    paddingBlock: 10,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
  },
  isActive: {
    display: 'flex',
    alignItems: 'center',
    gap: 16
  },
  activeBadge: {
    padding: '2px 12px',
    backgroundColor: colors.green,
    color: colors.white,
    fontSize: 14,
    fontFamily: 'Roboto',
    borderRadius: 10
  },
  button: {
    display: 'flex',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  typography: {
    fontWeight: 400
  },
  typographyBold: {
    fontWeight: 700
  },
  saveButton: {
    width: 200,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
}))

export default Activate2FAStyles