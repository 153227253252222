import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 150,
    maxHeight: 150,
    minWidth: 72,
    border: '1px solid' + colors.disabled,
    borderRadius: 5,
    padding: '22px 0 32px ',
    boxSizing: 'border-box',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down(450)]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
    [theme.breakpoints.down(380)]: {
      paddingRight: 10,
      paddingLeft: 10,
    },
    '&:hover': {
      boxShadow: '0 1px 6px 0 rgb(32 33 36 / 28%)',
    }
  },
  selected: {
    border: '1px solid' + colors.secondary,
  },
  disabled: {
    backgroundColor: colors.background,
    cursor: 'not-allowed ',
    '&:hover': {
      boxShadow: '0 0px 0px 0 ',
    }
  },
  
  img: {
    objectfit: ' contain',
    maxHeight: 53,
    maxWidth: 53,
  },
  titleDiv: {
    marginTop: 22, 
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center', 
    textAlign: 'center'
  }
}))

export default useStyles