import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'
import { width } from '@material-ui/system'

const useStyles = makeStyles((theme) => ({
  datePickerWrapper: {
    display: 'flex',
    position: 'relative',
  },
  calendarIcon: {
    position: 'absolute',
    left: 18,
    top: 9
  },
  datePickerStartInputContainer: {
    position: 'relative',
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'center',
    borderRadius: 5,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    color: 'grey',
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 5,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
  datePickerEndInputContainer: {
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'center',
    borderRadius: 5,
    color: 'grey',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 5,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
}))


const useSimpleDatePickerStyles= makeStyles((theme) => ({
  financialDatesContainer: {
    display: 'flex', 
    flexDirection: 'row', 
    gap: 24,
    [theme.breakpoints.down(960)]:
    {
      flexDirection: 'column',
      gap: 14
    }
  },
  startDateContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 6,
    flexDirection: 'column'
  },
  datePickerWrapper: {
    display: 'flex',
    position: 'relative',
    marginTop: 12,
    marginBottom: 25
  },
  datePickerWrapperDiv: {
    display: 'flex',
    position: 'relative',
  },
  calendarIcon: {
    position: 'absolute',
    left: 18,
    top: 9
  },
  datePickerInputContainer: {
    position: 'relative',
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'center',
    borderRadius: 10,
    color: 'grey',
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 10,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
  datePickerInputContainerDiv: {
    position: 'relative',
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'left',
    paddingLeft: 64,
    borderRadius: 10,
    width: 235,
    color: 'grey',
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 10,
    },
    '&:focus-visible': {
      outline: 'none'
    },
    [theme.breakpoints.up(1300)]: {
      width: 250
    },
    [theme.breakpoints.up(1200)]: {
      width: 256
    },
    [theme.breakpoints.down(960)]: {
      width: '-webkit-fill-available'
    },
  },
}))

const useSimpleDatePickerStylesModal= makeStyles((theme) => ({
  financialDatesContainer: {
    display: 'flex', 
    flexDirection: 'row', 
    gap: 24,
    [theme.breakpoints.down(960)]:
    {
      flexDirection: 'column'
    }
  },
  startDateContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 6,
    flexDirection: 'column'
  },
  datePickerWrapper: {
    display: 'flex',
    position: 'relative',
    marginTop: 12,
    marginBottom: 25
  },
  datePickerWrapperDiv: {
    display: 'flex',
    position: 'relative',
  },
  calendarIcon: {
    position: 'absolute',
    left: 18,
    top: 9
  },
  datePickerInputContainer: {
    position: 'relative',
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'center',
    borderRadius: 10,
    color: 'grey',
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 10,
    },
    '&:focus-visible': {
      outline: 'none'
    },
  },
  datePickerInputContainerDiv: {
    position: 'relative',
    padding: 15,
    backgroundColor: colors.background,
    border: '1px solid' + colors.background,
    textAlign: 'left',
    borderRadius: 10,
    paddingLeft: 64,
    width: 185,
    color: 'grey',
    '&:focus': {
      backgroundColor: colors.background,
      border: '1px solid' + colors.background,
      borderRadius: 10,
    },
    '&:focus-visible': {
      outline: 'none'
    },
    // [theme.breakpoints.up(1200)]: {
    //   width: 304
    // },
    [theme.breakpoints.down(960)]: {
      width: '-webkit-fill-available'
    },
  },
}))

export { useStyles, useSimpleDatePickerStyles, useSimpleDatePickerStylesModal }