import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const useStyles = makeStyles((theme) => ({
  paperCostum: {
    padding: '32px 50px',
    maxWidth: 450,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  image: {
    display: 'flex',
    justifyContent: 'center'
  },
  spaceText: {
    margin: '18px 0 40px 0'
  },
  spaceTittle: {
    marginTop: 16
  },
  contactUsButton: {
    width: '60%',
    color: colors.primary,
    backgroundColor: colors.white,
    border: `1px solid ${colors.primary}`,
    marginTop: 20,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: colors.grayHover,
      border: `1px solid ${colors.grayHover}`,
    },
  },
  updatePaymentButton: {
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export default useStyles