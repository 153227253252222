import React, { useState, useEffect } from 'react'
import { Text, TextInput } from '../../../Components'
import { makeStyles, IconButton, Modal } from '@material-ui/core'
import { SecondaryOutlinedButton3 } from '../../../Components/Button/Button'
import { AmazingIcon, BadIcon, FeedbackAILogo, OkayIcon } from '../../../Themes/Images'
import ClearIcon from '@material-ui/icons/Clear'
import Modal1 from '../../../Components/Modal/Modal'
import SucessModal from '../../../Components/SuccessModal/SuccessModal'
import { colors } from '../../../Themes'
import axios from 'axios'
import { ErrorToast, SuccessToast } from '../../../Components/Notification/Notification'
import toast from 'react-hot-toast'
import { useChat } from '../../../Global/Chat'
import { Axios, AxiosChat } from '../../../Config'

const useStyles = makeStyles((theme) => ({
    subModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        paddingBottom: 16,
        borderBottom: '1px solid #ebebeb'
    },
    ModalWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        position: 'relative',
        paddingRight: 20,
        paddingLeft: 20,
        // root: {
        //     maxWidth: 484
        // }
    },
    buttonsWrapper: {
        display: 'flex',
        borderTop: '1px solid #ebebeb',
        paddingTop: 24,
        gap: 10,
        marginTop: 16,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    firstPart: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        marginBlock: 16
    },
    input: {
        marginTop: 8,
        marginBottom: 0,
        fontSize: 14
    },
    cancelButton: {
        width: '50%',
        backgroundColor: colors.white,
        border: '1px solid #cccccc',
        height: 50,
        color: colors.primary,
        '&:hover': {
            backgroundColor: colors.grayHover,
            color: colors.white,
            border: `1px solid ${colors.grayHover}`,
        },
    },
    nextButton: {
        width: '50%',
        height: 50,
    },
}
))

function AIReportModal({ isOpen, onCancel }) {
    const classes = useStyles()
    const [value, setValue] = useState({
        message: ''
    })
    const [isDisabled, setDisabled] = useState(true)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const { getAuth } = useChat


    const handleClose = () => {
        setSuccess(false)
        setValue('')
        setError()
        setDisabled(true)
        setLoading(false)
        onCancel()
    }
    const handleReport = async () => {

        setLoading(true)
        Axios.post('/prod/user/aiFeedback?type=problem',
            {
                message: value?.message,
            }
        )
            .then(
                // eslint-disable-next-line promise/always-return
                (res) => {
                    console.log(res)
                    toast.custom((t) => (
                        <SuccessToast t={t} message='Thank you for your message. We will reach out soon.' />
                    ))
                    setLoading(false)
                }).catch(e => {
                    console.log(e)
                    setLoading(false)
                    toast.custom((t) => (
                        <ErrorToast t={t} message='Something went wrong, please try again.' />
                    ))
                })

    }
    useEffect(() => {
        if (
            (error && Object.keys(error).length !== 0) ||
            value.message === ''
        ) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [value.message, error])

    const reportModal = (
        <div className={classes.ModalWrapper}>
            <div className={classes.subModal}>
                <div></div>
                <div className={classes.text}>
                    <Text size='h5' color={colors.purple} weight='bold' className={classes.spaceTittle} >Report a problem</Text>
                </div>
                <IconButton
                    disableFocusRipple
                    centerRipple={true}
                    disableRipple={true}
                    style={{ backgroundColor: 'transparent' }}
                    classes={{ root: classes.iconButtonClose }}
                    onClick={() => onCancel()}
                >
                    <ClearIcon classes={{ root: classes.iconButtonClose }} />
                </IconButton>
            </div>
            <div className={classes.firstPart}>
                <Text size='subnote' className={classes.spaceTittle} >Is there anything bothering you? Help us get better by reporting what you think should be improved.</Text>
                <TextInput
                    name='comment'
                    costumizedInputStyles={classes.input}
                    label='More is less'
                    value={value.message}
                    onChange={(e) => setValue({ message: e.target.value })}
                    multiline={true}
                    rows={6}
                />
            </div>
            <div className={classes.buttonsWrapper}>
                <SecondaryOutlinedButton3
                    className={classes.cancelButton}
                    onClick={handleClose}
                >
                    Cancel
                </SecondaryOutlinedButton3>
                <SecondaryOutlinedButton3
                    className={classes.nextButton}
                    disabled={isDisabled || isLoading}
                    loading={isLoading}
                    onClick={() => {
                        handleReport()
                        setLoading(true)
                        setTimeout(() => {
                            setLoading(false)
                            onCancel()
                        }, 2000)
                    }}
                >
                    Report
                </SecondaryOutlinedButton3>
            </div>
        </div>
    )
    return (
        <Modal1 opened={isOpen} onClose={handleClose} type="noBlur" costumizedPaperStyles={success ? classes.paperCostum2 : classes.paperCostum} >
            {success ? <SucessModal isSmart={false} onCancel={onCancel} /> :
                reportModal}

        </Modal1>
    )
}

export default AIReportModal
