import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const referralCodeStyles = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 25px',
      maxWidth: 320,
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  spaceText: {
    margin: '22px 0 15px 0'
  },
  spaceText2: {
    margin: '17px 0 0px 0'
  },
  spaceTittle: {
    marginTop: 16,
    alignSelf: 'center'
  },
  buttonsWrapper: {
    display: 'flex',
    gap: 10,
    marginTop: 25,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  cancelButton: {
    width: '50%',
    backgroundColor: colors.white,
    color: colors.primary,
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white
    },
  },
  nextButton: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
    },
  },
  text: {
    marginBottom: 30,
    textAlign: 'center',
  },
  text2: {
    textAlign: 'center',
    marginBottom: 7,
    marginTop: 15
  },
  textTitle: {
    textAlign: 'start',
    marginBottom: 7,
    marginTop: 15
  },
  text3: {
    textAlign: 'center',
    marginBottom: 15,
    marginTop: 15
  },
  iconButtonClose: {
    position: 'absolute',
    right: 0,
    top: -5
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  reactCodeInput: {
    display: 'flex !important',
    gap: '13px !important',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      gap: '5px !important',
    },
  },
  errorDiv: {
    display: 'flex', 
    alignItems: 'center',
  },
  errorICon: { 
    marginTop: 5 
  },
  errorText: {
    marginLeft: 5, 
    marginTop: 5 
  },
  divider: {
    height: 1, 
    backgroundColor: '#EBEBEB'
  },
  noteWrapper: {
    width: '100%',
    padding: '0px 0px 18px 0px ',
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: colors.accent,
    marginLeft: 4
  },
  info: {
    marginRight: 10
  },
}))

export default referralCodeStyles