import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const tabsStyles = {
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: colors.accent,
    },
  },
}

const tabStyles = (theme) => ({
  root: {
    textTransform: 'none',
    color: colors.primary,
    fontWeight: 500,
    fontSize: 18,
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: '32px 0 16px',
    borderBottom: '1px solid' + colors.disabled
  },
}))

export { tabsStyles, tabStyles, useStyles }