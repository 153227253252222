import React from 'react'
import { Pressable } from '../Button/Button'
import '../../App.css'
import { Link } from 'react-router-dom'
import { useSettingItemStyles, useStyles } from './Card.styles'

function Card({ Icon, title, description, onClick, to }) {
  const classes = useStyles()

  return (
    <Pressable component={Link} to={to} onClick={onClick} classes={{ root: classes.cardContainer }} >
      <div className={classes.icon}>
        <Icon className='cardIcon' />
      </div>
      <span className={classes.title}>{title}</span>
      <span className={classes.desc}>{description}</span>
    </Pressable>
  )
}

function SettingsItem({ Icon, title, description, onClick, to }) {
  const classes = useSettingItemStyles()

  return (
    <Pressable role="button" component={Link} to={to} onClick={onClick} classes={{ root: classes.cardContainer }} >
      <div className={classes.icon}>
        <img className={classes.img} src={Icon}/>
      </div>
      <div className={classes.text}>
        <span className={classes.title}>{title}</span>
        <span className={classes.desc}>{description}</span>
      </div>
    </Pressable>
  )
}

export { SettingsItem }
export default Card
