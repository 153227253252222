/* eslint-disable camelcase */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import Loading from 'react-lottie'
import { dataLoading } from '../../Themes/Images'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import Axios from '../../Config/Axios'
import { useMenu, useAuth, useSubscription } from '../../Global'
import { Hidden, IconButton } from '@material-ui/core'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import { colors } from '../../Themes'
import '../../App.css'
import { ContentContainer, DiscoundCard, SuccessNotify, Text } from '../../Components'
import SkeletonDashboard from '../../Components/SkeletonDashboard'
import { useStyles, useUpgradeCardStyle } from './XERODashboard.styles'
import TabsForDashboard from '../../Components/TabsForDashboard'
import { TabPanel } from '../../Components/TabsForDashboard/TabsForDashboard'

const LOADING_DATA_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: dataLoading,
  rendererSettings: {
    className: 'loading',
  },
}

function XERODashboard() {
  const divRef2 = useRef()
  const classes = useStyles()
  const { data_loading, xeroDataLoading, user, upgradeSucces, setUpgradeSuccess, getUpdatedUser, isLoading: isLoadingUser, dashboard_id, is_trialing, roles, active_dash, xeroDashboardId } = useAuth()
  const { isFullScreen, toggleFullScreen } = useMenu()
  const { refetchSubscription, isLoadingSub } = useSubscription()
  const [isLoading, setLoading] = useState(true)
  const [isDataLoading, setDataLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  const embedDashboard = async (url) => {

    const embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata)
      },
    })

    const frameOptions = {
      url: url,
      container: document.getElementById('containerDashboard3'),
      height: '100%',
      width: '100%',
      // resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent, metadata) => {
        switch (changeEvent.eventName) {
          case 'FRAME_MOUNTED': {
            console.log('Do something when the experience frame is mounted.')
            break
          }
          case 'FRAME_LOADED': {
            console.log('Do something when the experience frame is loaded.')
            break
          }
        }
      },
    }

    const contentOptions = {
      toolbarOptions: {
        export: true,
        undoRedo: true,
      },
    }
    //   attributionOptions: {
    //     overlayContent: false,
    //   },
    //   onMessage: async (messageEvent, experienceMetadata) => {
    //     switch (messageEvent.eventName) {
    //     case 'CONTENT_LOADED': {
    //       console.log('All visuals are loaded. The title of the document:', messageEvent.message.title)
    //       break
    //     }
    //     case 'ERROR_OCCURRED': {
    //       console.log('Error occurred while rendering the experience. Error code:', messageEvent.message.errorCode)
    //       break
    //     }
    //     case 'PARAMETERS_CHANGED': {
    //       console.log('Parameters changed. Changed parameters:', messageEvent.message.changedParameters)
    //       break
    //     }
    //     case 'SELECTED_SHEET_CHANGED': {
    //       console.log('Selected sheet changed. Selected sheet:', messageEvent.message.selectedSheet)
    //       break
    //     }
    //     case 'SIZE_CHANGED': {
    //       console.log('Size changed. New dimensions:', messageEvent.message)
    //       break
    //     }
    //     case 'MODAL_OPENED': {
    //       window.scrollTo({
    //         top: 0 // iframe top position
    //       })
    //       break
    //     }
    //     }
    //   },
    // }

    const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions)
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    getUpdatedUser()
    refetchSubscription()
  }, [])


  useEffect(() => {
    if (is_trialing === false) {
      return setLoading(false)
    }
    if (!xeroDashboardId) {
      setLoading(false)
      setDataLoading(true)
    } else {
      Axios({
        method: 'GET',
        url: `/prod/user/dashboard/${xeroDashboardId}`,
      })
        .then((response) => {
          setLoading(false)
          embedDashboard(response?.data?.EmbedUrl)
        })
        .catch(() => {
          setLoading(false)

        })
    }
  }, [is_trialing, xeroDashboardId])

  const renderUpgradeCard = () => {
    if (isLoadingUser || isLoadingSub) {
      return null
    }
    else if (is_trialing) {
      return null
    }
    else if (roles.includes('secondary')) {
      return null
    }
    return (
      <>
        {/* {Object.keys(subscription).length > 0 && data_loading && !isFullScreen &&
            <UpgradeCard isLoading={true} />
        }
        {Object.keys(subscription).length === 0 && !data_loading && !isFullScreen &&
            <Collapse in={isCardShowing}>
              <UpgradeCard onClose={() => setCardShowing(false)} />
            </Collapse>
        } */}
      </>
    )

  }

  // if ((isDataLoading|| data_loading) && active_dash === true) {
  //   return (
  //     <>
  //       <div className={classes.loadingContainer}>
  //         {/* {upgradeSucces && <SuccessNotify open={upgradeSucces} setOpen={setUpgradeSuccess} message="You request was sent successfully" />} */}
  //         <div className={classes.loadingDataMessage}>
  //           <p
  //             style={{
  //               fontWeight: 600,
  //               fontSize: 20,
  //               fontFamily: 'Roboto',
  //               marginBottom: 'revert',
  //               color: colors.accent,
  //             }}
  //           >
  //             Welcome {user}!
  //           </p>
  //           <p>
  //          Account active
  //           </p>
  //         </div>
  //         <Loading options={LOADING_DATA_OPTIONS} height={700} />
  //       </div>
  //     </>
  //   )
  // }


  if (isDataLoading || xeroDataLoading) {
    return (
      <>
        <div className={classes.loadingContainer}>
          {/* {upgradeSucces && <SuccessNotify open={upgradeSucces} setOpen={setUpgradeSuccess} message="You request was sent successfully" />} */}
          <div className={classes.loadingDataMessage}>
            <p
              style={{
                fontWeight: 600,
                fontSize: 20,
                fontFamily: 'Roboto',
                marginBottom: 'revert',
                color: colors.accent,
              }}
            >
              Welcome {user}!
            </p>
            <p>
              Thank you for connecting to XERO. <br /> <br /> We are currently getting all your data to prepare your financial dashboard.  <br /> <br />
              Depending on how much data you have, this may take up to <span style={{ color: colors.accent, fontWeight: 'bold' }}>30 minutes</span>.<br /> <br />
              We&apos;ll notify you by email as soon as it is ready.
            </p>
          </div>
          <Loading options={LOADING_DATA_OPTIONS} height={700} />
        </div>
      </>
    )
  }

  return (
    <>
      {/* {false ? 
        <ContentContainer dashboard
          header={
            <TabsForDashboard
              value={tabValue}
              handleChange={handleChangeTab}
            />
          
          }
        /> 
        : 
        <ContentContainer dashboard
          header={
            <div className={classes.subheader1}>
              <Text size='subnote' weight='bold' className="sectionTitle">Dashboard</Text>
            </div>
      
          } />
       
      } */}
      <ContentContainer dashboard
        header={
          <div className={classes.subheader1}>
            <Text size='subnote' weight='bold' className="sectionTitle">XERO Dashboard</Text>
          </div>

        } />
      {/* <ContentContainer dashboard
        header={
          <TabsForDashboard
            value={tabValue}
            handleChange={handleChangeTab}
          />
          
        }
      />  */}

      {renderUpgradeCard()}

      {/* <TabPanel value={tabValue} index={0}> */}
      {isLoading ? (
        <SkeletonDashboard />
      ) : (
        <div
          className={
            isFullScreen ? classes.dashboardFullScreen : classes.dashboard
          }
        >
          {/* If sub exist && data is not loading && is not fullscreen then show card upgrade card else if user has a subscription and data is loading show the info card when data is loading */}

          <Hidden xsDown>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              classes={{
                root: isFullScreen
                  ? classes.iconButtonFull
                  : classes.iconButton,
              }}
              onClick={toggleFullScreen}
            >
              {isFullScreen ? (
                <FullscreenExitIcon classes={{ root: classes.rootBackIcon }} />
              ) : (
                <FullscreenIcon classes={{ root: classes.rootBackIcon }} />
              )}
            </IconButton>
          </Hidden>

          <div ref={divRef2} id='containerDashboard3' style={{ flex: 2 }} />
        </div>
      )}
      {/* </TabPanel> */}
      {/* <TabPanel value={tabValue} index={1}>
        Hello
      </TabPanel> */}


    </>
  )
}

export default XERODashboard