import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const useStyles2 = makeStyles((theme) => ({
  // Modal
  costumizedPaperStyles: {
    padding: ' 40px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: ' 30px 15px',
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  modalImg: {
    height: 100
  },
  img: {
    height: '100%'
  },
  modalTittle: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 28,
    color: colors.accent,
    margin: '30px 0 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      margin: '15px 0 0',
    },
  },
  desc: {
    fontFamily: 'Roboto',
    fontSize: 18,
    color: colors.primary,
    margin: '32px 0',
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0 20px',
      fontSize: 16,
    },
  },
  goBackButton: {
    width: '70%'
  },
}))

export default useStyles2