import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const confirmNumberModalStyles = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  spaceText: {
    margin: '30px 0',
    textAlign: 'center'
  },
  spaceTittle: {
    alignSelf: 'center'
  },
  resendCode: {
    color: colors.primary,
    textDecoration: 'underline',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '30px 0 20px'
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  cancelButton: {
    flex: '0 0 40%',
    backgroundColor: colors.white,
    color: colors.primary,
    height: 50,
    fontSize: 14,
    '&:hover': {
      backgroundColor: colors.grayHover,
      color: colors.white
    },
  },
  nextButton: {
    flex: '0 0 40%',
    height: 50,
    fontSize: 14,
  },
  iconButtonClose: {
    position: 'absolute',
    right: 10,
    top: 15
  },
  divider: {
    display: 'flex', 
    height: 1, 
    width: '100%', 
    backgroundColor: '#E6E6E6',
  }
}))

export default confirmNumberModalStyles