/* eslint-disable camelcase */
/* eslint-disable promise/always-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import Axios from '../../Config/Axios'
import { useMenu, useAuth, useSubscription } from '../../Global'
import { Hidden, IconButton } from '@material-ui/core'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import '../../App.css'
import SkeletonDashboard from '../SkeletonDashboard'
import { useStyles } from './TargetsDashboard.styles'


function TargetsDashboard() {
  const divRef = useRef()
  const classes = useStyles()
  const { getUpdatedUser, targetsDashboardId } = useAuth()
  const { isFullScreen, toggleFullScreen } = useMenu()
  const { refetchSubscription } = useSubscription()
  const [isLoading, setLoading] = useState(true)

  const embedDashboard = async(url) => {

    const embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata)
      },
    })

    const frameOptions = {
      url: url,
      container: document.getElementById('containerDashboardTargets'),
      height: '100%',
      width: '100%',
      className: 'quicksight-embedding-iframe-target',
      // resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent, metadata) => {
        switch (changeEvent.eventName) {
        case 'FRAME_MOUNTED': {
          console.log('Do something when the experience frame is mounted.')
          break
        }
        case 'FRAME_LOADED': {
          console.log('Do something when the experience frame is loaded.')
          break
        }
        }
      },
    }

    const contentOptions = {
      toolbarOptions: {
        export: true,
        undoRedo: true,
      },
    }
    //   attributionOptions: {
    //     overlayContent: false,
    //   },
    //   onMessage: async (messageEvent, experienceMetadata) => {
    //     switch (messageEvent.eventName) {
    //     case 'CONTENT_LOADED': {
    //       console.log('All visuals are loaded. The title of the document:', messageEvent.message.title)
    //       break
    //     }
    //     case 'ERROR_OCCURRED': {
    //       console.log('Error occurred while rendering the experience. Error code:', messageEvent.message.errorCode)
    //       break
    //     }
    //     case 'PARAMETERS_CHANGED': {
    //       console.log('Parameters changed. Changed parameters:', messageEvent.message.changedParameters)
    //       break
    //     }
    //     case 'SELECTED_SHEET_CHANGED': {
    //       console.log('Selected sheet changed. Selected sheet:', messageEvent.message.selectedSheet)
    //       break
    //     }
    //     case 'SIZE_CHANGED': {
    //       console.log('Size changed. New dimensions:', messageEvent.message)
    //       break
    //     }
    //     case 'MODAL_OPENED': {
    //       window.scrollTo({
    //         top: 0 // iframe top position
    //       })
    //       break
    //     }
    //     }
    //   },
    // }

    const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions)
  }

  useEffect(() => {
    getUpdatedUser()
    // refetchSubscription()    
  }, [])


  useEffect(() => {
    Axios({
      method: 'GET',
      url: `/prod/user/dashboard/${targetsDashboardId}`,
    })
      .then((response) => {
        embedDashboard(response?.data?.EmbedUrl)
        setLoading(false)
      
      })
      .catch((error) => {
        setLoading(false)
      })

  }, [])

  return (
    <>
      {isLoading ? (
        <SkeletonDashboard target={true} />
      ) : (
        <div
          className={
            isFullScreen ? classes.dashboardFullScreen : classes.dashboard
          }
        >
          <Hidden xsDown>
            <IconButton
              disableFocusRipple
              centerRipple={true}
              classes={{
                root: isFullScreen
                  ? classes.iconButtonFull
                  : classes.iconButton,
              }}
              onClick={toggleFullScreen}
            >
              {isFullScreen ? (
                <FullscreenExitIcon classes={{ root: classes.rootBackIcon }} />
              ) : (
                <FullscreenIcon classes={{ root: classes.rootBackIcon }} />
              )}
            </IconButton>
          </Hidden>

          <div ref={divRef} id='containerDashboardTargets' style={{ flex: 2 }} />
        </div>
      )}
    </>
  )
}

export default TargetsDashboard