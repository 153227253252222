/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { TextInput, PrimaryButton } from '../../Components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'react-router-dom'
import { colors } from '../../Themes'
import useStyles from './Verification.styles'

function Verification({ pin, setPin, error, next }) {
  const classes = useStyles()
  
  return (
    <>
      <h1 style={{ color: colors.accent }}>Verification PIN</h1>
      <span className={classes.description}>
        We've sent a verification PIN in your email. Enter it below:
      </span>
      <TextInput label="PIN" value={pin} setValue={setPin} />
      {/* <CodeInput type='number' fields={6} value={pin} onChange={setPin} /> */}
      {Boolean(error) && <span className="loginError">{error}</span>}
      <PrimaryButton
        style={
          pin===''
            ? { marginTop: 30, backgroundColor: colors.disabled, minHeight: 50 }
            : { marginTop: 30, backgroundColor: colors.primary, minHeight: 50 }
        }
        className={classes.loginButton}
        onClick={next}
        disabled={pin===''}
      >
        Next
      </PrimaryButton>
      <Link
        style={{
          display: 'flex',
          alignSelf: 'flex-start',
          textDecoration: 'none',
          color: colors.secondary,
          fontWeight: 700,
          marginTop: 15,
          alignItems: 'center',
        }}
        to="/login"
      >
        <ArrowBackIcon classes={{ root: classes.rootBackIcon }} />
        <span className="logoutDrawer">Back to Login</span>
      </Link>
    </>
  )
}

export default Verification
