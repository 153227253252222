/* eslint-disable promise/always-return */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React from 'react'
import { Modal, Text } from '..'
import { IconButton, useMediaQuery } from '@material-ui/core'
import { colors } from '../../Themes'
import ClearIcon from '@material-ui/icons/Clear'
import DashboardPlanModalStyles from './DashboardPlanModal.styles'
import { useAuth } from '../../Global'
import useCancellationDate from '../../Utils/CancellationDate'

function DashboardPlanModal({ isVisible, cancel }) {
  const classes = DashboardPlanModalStyles()
  const { cancellationEffectiveDate } = useAuth()

  const { day, month, year } = useCancellationDate(cancellationEffectiveDate)

  const onCancel = () => {
    cancel()
  }
  
  return (
    <Modal type="noBlur" costumizedPaperStyles={classes.paperCostum} opened={isVisible}>
      <div className={classes.ModalWrapper}>
        <div className={classes.subModal}>
          {/* <div className={classes.text}>
            <Text size='subnote' weight='bold' color={colors.purple}>Plan switch</Text>
          </div> */}
          <IconButton
            disableFocusRipple
            centerRipple={true}
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            classes={{ root: classes.iconButtonClose }}
            onClick={() => onCancel()}
          >
            <ClearIcon classes={{ root: classes.closeIcon }} />
          </IconButton>
        </div>
        <div className={classes.divider}>
        </div>
        <div className={classes.listContainer}>
          <Text style={{ textAlign: 'center' }} size='footnote'>You will switch to the Free plan on <Text size='footnote' weight='medium'>{day} {month} {year}.</Text>
          </Text>
        </div>
      </div>
    </Modal>
  )
}

export default DashboardPlanModal  