import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  containertWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  
  },
  firstWrapper: {
    flex: '0 0 50%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
    },
  },
  divider: {
    height: 1, 
    backgroundColor: '#CECECE',
    width: '100%', 
    marginTop: 20,
    marginBottom: 20
  },
  gap: {
    display: 'flex', 
    gap: 50,
    justifyContent: 'space-between' 
  },
  columnFlex: {
    display: 'flex', 
    flexDirection: 'column'
  },
  space: {
    margin: '0 0 16px '
  },
  mainTittle: {
    margin: '0 0 16px '
  },
  cardWrapper: {
    display: 'flex'
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
  
    },
  },
  img: {
    objectfit: ' contain',
    maxHeight: 30,
  
  },
  button: { 
    maxWidth: '350px',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

export default useStyles