import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const contactUsModalStyles = makeStyles((theme) => ({
  paperCostum: {
    position: 'relative',
    padding: '24px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  paperCostum2: {
    padding: '48px 10px ',
    maxWidth: 450,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      padding: 25,
      margin: '0 10px',
    },
  },
  ModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  spaceText: {
    margin: '22px 0 15px 0'
  },
  spaceText2: {
    margin: '17px 0 0px 0'
  },
  spaceTittle: {
    marginTop: 16,
    alignSelf: 'center'
  },
  buttonsWrapper: {
    display: 'flex',
    gap: 10,
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  cancelButton: {
    width: '50%',
    backgroundColor: colors.white,
    color: colors.primary,
    border: '1px solid #cccccc',
    '&:hover': {
      backgroundColor: colors.grayHover,
    },
  },
  nextButton: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 12
    },
  },
  text: {
    marginBottom: 25,
    textAlign: 'center',
  },
  text2: {
    alignSelf: 'start',
    marginBottom: 7
  },
  iconButtonClose: {
    position: 'absolute',
    right: 0,
    marginRight: 0,
    marginTop: -15,
  },
  subModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  errorDiv: {
    display: 'flex', 
    alignItems: 'center' 
  },
  errorText: {
    marginLeft: 5, 
    marginTop: 5 
  },
  subText: {
    marginTop: 15,
    marginBottom: -10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  border: {
    borderTop: '1px solid #EBEBEB',
    marginBottom: 30,
    // marginLeft: -65,
    width: '100%'
  },
  border2: {
    borderTop: '1px solid #EBEBEB',
    marginTop: 20,
    width: '100%'
  }
}))

export default contactUsModalStyles