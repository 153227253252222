import { makeStyles } from '@material-ui/core'
import { colors } from '../../../Themes'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'stretch',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '30ch',
  },
  inputfalse: {
    flexGrow: 1,
    maxWidth: '50%',
    borderWidth: 1,
    borderColor: colors.accent,
    marginTop: 15,
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  inputtrue: {
    flexGrow: 1,
    maxWidth: '50%',
    border: '1px solid ' + colors.secondary,
    borderRadius: 10,
    marginTop: 15,
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

const inputStyles = {
  root: {
    borderRadius: 5,
    color: colors.primary,
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 18,
    backgroundColor: colors.background,
    '&:hover': {
      backgroundColor: colors.backgroundHover,
    },
    '&$focused': {
      backgroundColor: colors.background,
    },
    '&$disabled': {
      color: colors.primary,
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 18,
      backgroundColor: colors.background,
    },
  },
    
  input: {
    // remove arrows
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  underline: {
    '&:after': {
      border: '1px solid ' + colors.accent,
      top: 0,
      borderRadius: 5,
      transform: 'scaleX(1)',
      opacity: 0,
    },
    '&$focused:after': {
      transform: 'scaleX(1)',
      opacity: 1,
    },
    '&:before': {
      borderWidth: 0,
    },
    '&:hover:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
    '&$disabled:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
  },
  focused: {},
  disabled: {},
}

export  { useStyles, inputStyles }