import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

const useCStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  container: {
    marginTop: 25,
    boxShadow: 'none'
  },
  showMore: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    color: colors.accent,
    cursor: 'pointer'
  },
  confrimDeleteButton: {
    minHeight: 40,
    minWidth: 90
  },
  caption: {
    textAlign: 'center' 
  },
  tableFont: {
    fontSize: 18 
  },
  margin: {
    marginTop: 32 
  }
})

const cellStyles = {
  head: {
    backgroundColor: colors.white,
    color: colors.primary,
    fontSize: 18
  },
  body: {
    fontSize: 16,
    color: colors.primary,
    padding: '24px 16px'
  },
}

const rowStyles = {
  //   root: {
  //     '&:nth-of-type(odd)': {
  //       backgroundColor: theme.palette.action.hover,
  //     },
  //   },
  root: {
    backgroundColor: colors.white,
  },
}

export { useStyles, useStyles1, useCStyles, cellStyles, rowStyles }