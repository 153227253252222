import React, { memo } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'
import { colors } from '../../Themes'
import useStyles from './PriceTabsMobileTable.styles'
import { gigaMonth, gigaYear, megaMonth, megaYear } from '../../Utils/useCurrency'

const StyledTabs = withStyles({
  root: {
    width: '100%',
    boxShadow: '0px 5px 5px 0px #00000014'
  },
  indicator: {
    display: 'none',
    justifyContent: 'center',
    backgroundColor: 'red',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props) => <Tabs {...props}  TabIndicatorProps={{ children: <span /> }} />)

function PriceTabsMobileTable({ value, handleChange, value2, isFree, isTrialing, isGiga, isMega, signValue, priceId }) {
  const classes = useStyles()

  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: colors.primary,
      backgroundColor: colors.white,
      minWidth: isMega ? '33%' : isGiga ? '50%' : '25%',
      opacity: 1,
      fontWeight: 'bold',
      fontSize: 18,
      border: '1px solid #C9C9C9',
    },
    selected: {
      opacity: 1,
      backgroundColor: colors.accent,
      zIndex: 2,
      color: colors.white,
    }
  }))((props) => <Tab disableRipple {...props} />)

  return (
    <div className={classes.tabsWrapper}>
      <StyledTabs value={value} onChange={handleChange} selected={true} centered aria-label="styled tabs table" >
        {(isFree || isTrialing) && <StyledTab label={
          <React.Fragment>
            Free
            <br />
            <div>
              <span style={{ fontSize: isFree ?  10 : 'smaller' }}>{signValue === undefined ? '$' : signValue}0</span>
              <span className={classes.text}>{value2 === 0 ? '/mo' : '/yr'}</span>
            </div>
          </React.Fragment>
        }/> }
        { isGiga ? null : 
          <StyledTab  label={
            <React.Fragment>
               Mega
              <br />
              <div>
                <span style={{ fontSize: isFree ?  10 : 'smaller' }}>{value2 === 0 ? megaMonth(priceId, signValue) : megaYear(priceId, signValue)}</span>
                <span className={classes.text}>{value2 === 0 ? '/mo' : '/yr'}</span>
              </div>
            </React.Fragment>
          }/>}
        <StyledTab  label={
          <React.Fragment>
               Giga
            <br />
            <div>
              <span style={{ fontSize: isFree ?  10 : 'smaller' }}>{value2 === 0 ? gigaMonth(priceId, signValue) : gigaYear(priceId, signValue)}</span>
              <span className={classes.text}>{value2 === 0 ? '/mo' : '/yr'}</span>
            </div>
          </React.Fragment>
        }/>  
        <StyledTab label={
          <React.Fragment>
               Tera
            <br />
            <span style={{ fontSize: isFree ?  10 : 'smaller' }}>Custom</span>
          </React.Fragment>
        }/>
      </StyledTabs>
    </div>
  )
}


export default memo(PriceTabsMobileTable)
